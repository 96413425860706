import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import {
  Box,
  Hidden,
  Tooltip,
  IconButton,
  Icon,
  Badge,
} from '@material-ui/core';
import { SaveAlt, CallMade, Home, Chat } from '@material-ui/icons';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { LogoutButton } from '_core/components/LogoutButton';
import { BalancePopup } from '_core/components/BalancePopup';
import { SettingsPopup } from '_core/components/SettingsPopup';
import { ContactsPopup } from '_core/components/ContactsPopup';
import { UserAvatar } from '_core/components/UserAvatar';

// style
import { useStyles } from './headerControlsStyle';
import { UserNotifier } from './UserNotifier';
import { MessageNotifier } from './MessageNotifier';

export const RawHeaderControls = (props) => {
  const { onProfileOpen, getLangString: l } = props;

  const classes = useStyles();
  const { routes } = useGlobal();
  const cart = useCartContext();

  const {
    config: { HAS_CART, ROUTE_URL, HAS_CONTACTS_BUTTON, HAS_HOME_BUTTON, HAS_CHAT, HAS_WALLET, HAS_TOPUP, HAS_WITHDRAW, HAS_MESSAGES },
  } = useGlobal();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="nowrap"
      className={classes.root}
    >
    
	   {HAS_WALLET ? (
        <BalancePopup />
	   ) : null}

      {HAS_CHAT ? (
        <Hidden xsDown implementation="css">
          <a target="_blank" href="/templ/discord.html">
            <Tooltip title={l('r._.header.chat')}>
              <IconButton>
                <Chat />
              </IconButton>
            </Tooltip>
          </a>
        </Hidden>
      ) : null}
      
      {HAS_TOPUP ? (
        routes.Topup != null ? (
          <Hidden xsDown implementation="css">
            <Link to={ROUTE_URL.TOPUP}>
              <Tooltip title={l('r.popolnit.schet')}>
                <IconButton>
                  <SaveAlt />
                </IconButton>
              </Tooltip>
            </Link>
          </Hidden>
        ) : null
      ) : null}

      {HAS_WITHDRAW ? (
        routes.Withdraw != null ? (
          <Hidden xsDown implementation="css">
            <Link to={ROUTE_URL.WITHDRAW}>
              <Tooltip title={l('r.vyvesti.sredstva')}>
                <IconButton>
                  <CallMade />
                </IconButton>
              </Tooltip>
            </Link>
          </Hidden>
        ) : null
      ) : null}

      {HAS_CART ? (
        <Hidden xsDown implementation="css">
          <Tooltip
            title={
              cart.isModalVisible
                ? l('r.core.good.closecart')
                : l('r.core.good.opencart')
            }
          >
            <IconButton onClick={() => cart.toggleModalVisible()} ref={cart.setModalBtnRef}>
              <Badge
                color="secondary"
                badgeContent={cart.count_total}
                classes={{ badge: classes.cartBadge }}
              >
                <Icon
                  className={cart.isModalVisible ? classes.cartActive : undefined}
                >
                  shopping_cart
                </Icon>
              </Badge>
            </IconButton>
          </Tooltip>
        </Hidden>
      ) : null}

      {HAS_HOME_BUTTON ? (
        <Hidden xsDown implementation="css">
          <Tooltip title={l('r._.gotoold')}>
            <IconButton href={HAS_HOME_BUTTON} target="_blank">
              <Home />
            </IconButton>
          </Tooltip>
        </Hidden>
      ) : null}

      <UserNotifier />
      
      {HAS_MESSAGES ? (
        <MessageNotifier />
      ) : null}
      
      {HAS_CONTACTS_BUTTON ? (
        <Hidden xsDown implementation="css">
          <ContactsPopup />
        </Hidden>
      ) : null}
      
      <Hidden xsDown implementation="css">
        <SettingsPopup />
      </Hidden>
      

      <Hidden xsDown implementation="css">
        <UserAvatar
          className={classes.userAvatar}
          onClick={onProfileOpen}
          tooltip={l('r._.header.editprofile')}
          size="md"
        />
      </Hidden>

      <Hidden xsDown implementation="css">
        <LogoutButton />
      </Hidden>
    </Box>
  );
};

RawHeaderControls.defaultProps = {
  isRtl: false,
  title: process.env.REACT_APP_NAME
};

RawHeaderControls.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const HeaderControls = withLang(RawHeaderControls);
