import React from 'react';
import PropTypes from 'prop-types';

import Tag from './tags/AbstractTag';

import './tags/Bold';
import './tags/Color';
import './tags/Header2';
import './tags/Header3';
import './tags/Header4';
import './tags/Header5';
import './tags/Header6';
import './tags/HorizontalRule';
import './tags/Image';
import './tags/Italic';
import './tags/LineBreak';
import './tags/Link';
import './tags/List';
import './tags/Quotation';
import './tags/Strikethrough';
import './tags/Underline';
import './tags/Video';


export default class extends React.PureComponent {
  
  static propTypes = {
    // ? style: PropTypes.object,
    children: PropTypes.string
  };
  
  static defaultProps = {
  };
  
  
  constructor(props) {
    super(props);
  }
  
  
  render() {
    const { children } = this.props;
    
    return Tag.parser.toReact(String(children || '').replace(/(\r\n|\r|\n)/g, '[br="auto"]'));
  }
  
}
