import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// @material-ui
import { SvgIcon, InputAdornment, ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'
import { TextInputBase } from '_core/components/TextInputBase'

// style
import { clipboardInputStyle } from './clipboardInputStyle'

const RawClipboardInput = props => {
  const {
    classes,
    className,
    value,
    label,
    disabled,
    readOnly,
    render,
    Component,
    getLangString: l,

    // Filter out `withLang` HOC props
    langInfo, // eslint-disable-line no-unused-vars
    getLangObject, // eslint-disable-line no-unused-vars
    getLangStringSet, // eslint-disable-line no-unused-vars
    getFirstLangString, // eslint-disable-line no-unused-vars

    ...rest
  } = props

  const textInput = useRef()
  const [isCopied, setIsCopied] = useState(false)

  const aValue = value === '' ? l('r.zagruzka') : value
  const aLabel = (isCopied ? l('r.skopirovano') : label) || ' '

  const rootClasses = classNames({
    [classes.root]: true,
    [className]: className != null,
  })

  const iconPathClasses = classNames({
    [classes.iconPath]: true,
    [classes.iconPathCopied]: isCopied,
  })

  const endAdornment = (
    <InputAdornment position="end" className={classes.adornment}>
      <SvgIcon className={classes.icon}>
        <path
          className={iconPathClasses}
          d="M17,9H7V7H17M17,13H7V11H17M14,17H7V15H14M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z"
        />
      </SvgIcon>
    </InputAdornment>
  )

  const childProps = {
    disabled,
    readOnly,
    value: aValue,
    label: aLabel,
    success: isCopied,
    inputRef: textInput,
    endAdornment,
    ...rest,
  }

  return (
    <div className={rootClasses}>
      <ClickAwayListener onClickAway={() => setIsCopied(false)}>
        <CopyToClipboard text={aValue} onCopy={() => setIsCopied(true)}>
          {render != null ? (
            render(childProps)
          ) : Component != null ? (
            <Component {...childProps} />
          ) : (
            <TextInputBase
              disabled={disabled}
              readOnly={readOnly}
              value={aValue}
              label={aLabel}
              success={isCopied}
              inputRef={textInput}
              inputProps={{
                endAdornment,
              }}
              classes={{
                input: classes.input,
                label: classes.inputLabel,
                helperText: classes.inputHelperText,
              }}
              {...rest}
            />
          )}
        </CopyToClipboard>
      </ClickAwayListener>
    </div>
  )
}

RawClipboardInput.defaultProps = {
  Component: TextInputBase,
  readOnly: true,
}

RawClipboardInput.propTypes = {
  // self props
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  render: PropTypes.func,
  Component: PropTypes.elementType,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ClipboardInput = withLang(
  withStyles(clipboardInputStyle)(RawClipboardInput)
)
