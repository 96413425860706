import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Tooltip, Icon, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// core
import { DEFAULT_MESSAGE } from 'config'
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withVisitorInfo } from '_core/utils/visitorInfo'

export const RawErrorMessage = (props) => {
  const {
    error,
    isNull,
    message,
    severity,
    className,
    hasButton,
    buttonIcon,
    buttonTooltip,
    onClick,
    renderButton,
    renderContent,
    usePrivileges,
    alertProps,
    buttonProps,
    buttonIconProps,
    visitorInfo,
    getLangString: l,
  } = props;

  const { config: { VISITOR_PRIVILEGES } } = useGlobal();

  const isPrivileged = visitorInfo.isAllowed(VISITOR_PRIVILEGES.ADMIN);

  if (isNull && !usePrivileges) {
    return null
  }

  const getContent = () => {
    if (!usePrivileges) {
      return l(error.message)
    }

    if (error.isInfo) {
      return error.message
    }

    if (isPrivileged) {
      return l(error.debug != null ? error.debug : error.message)
    }

    return l(message)
  }

  const getButton = () =>
    typeof renderButton === 'function' ? (
      renderButton({ error, message, isPrivileged })
    ) : (
      <Tooltip title={l(buttonTooltip)}>
        <IconButton
          onClick={onClick}
          size="small"
          color="inherit"
          {...buttonProps}
        >
          <Icon {...buttonIconProps}>{buttonIcon}</Icon>
        </IconButton>
      </Tooltip>
    )

  return (
    <Alert
      severity={severity || 'error'}
      className={className}
      action={hasButton ? getButton() : null}
      {...alertProps}
    >
      {typeof renderContent === 'function'
        ? renderContent({ error, message, isPrivileged })
        : getContent()}
    </Alert>
  )
};

RawErrorMessage.defaultProps = {
  buttonIcon: 'replay',
  severity: '',
  usePrivileges: true,
  message: DEFAULT_MESSAGE.ERROR,
  buttonTooltip: DEFAULT_MESSAGE.RETRY,
}

RawErrorMessage.propTypes = {
  // self props
  severity: PropTypes.string,
  error: PropTypes.object,
  message: PropTypes.string,
  className: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonTooltip: PropTypes.string,
  isNull: PropTypes.bool,
  hasButton: PropTypes.bool,
  usePrivileges: PropTypes.bool,
  onClick: PropTypes.func,
  renderButton: PropTypes.func,
  renderContent: PropTypes.func,
  alertProps: PropTypes.object,
  buttonProps: PropTypes.object,
  buttonIconProps: PropTypes.object,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ErrorMessage = withVisitorInfo(withLang(RawErrorMessage))
