import React from 'react'
// import PropTypes from 'prop-types'

// core
import { APPEAL } from 'config'
import { SelectInput } from '_core/components/SelectInput'

const RawAppealInput = props => {
  const options = Object.values(APPEAL)

  return <SelectInput {...props} options={options} />
}

RawAppealInput.defaultProps = {}

RawAppealInput.propTypes = {}

export const AppealInput = RawAppealInput
