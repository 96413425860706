import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Button,
  Grid,
  Collapse,
  Fab,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Close, Check, SaveAlt } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'
import { getBytes } from '_core/utils/getBytes'
import { Loading } from '_core/components/Loading'
import { Flex } from '_core/components/Flex'

// style
import { fileUploadStyle } from './fileUploadStyle'

const ACCEPT_SCAN = 'image/*,application/pdf'

const ACCEPT_ALL =
  ACCEPT_SCAN + 'video/*,audio/*,application/msword,application/excel'

const RawFileUploadView = props => {
  const {
    classes,
    maxSize,
    maxFiles,
    maxSizeText,
    maxFilesText,
    hasMaxSizeMessage,
    hasMaxFilesMessage,
    hasHelperText,
    removeFileText,
    hideMessageText,
    buttonProps,
    filesGridProps,
    buttonGridProps,
    tooBigGridProps,
    tooManyGridProps,
    helperTextGridProps,
    name,
    title,
    files,
    multiple,
    accept,
    acceptAll,
    acceptScan,
    tooBigFiles,
    tooManyFiles,
    renderFiles,
    renderTooBigFiles,
    renderTooManyFiles,
    renderHelperText,
    onRemoveFile,
    onInputChange,
    onTooBigFilesMessageClose,
    onTooManyFilesMessageClose,
    getLangString: l,
  } = props

  const input = useRef()

  const onChange = () => {
    onInputChange(Array.from(input.current.files))
  }

  const onClick = () => {
    input.current.click()
  }

  const _onRemoveFile = async name => {
    !multiple && (input.current.value = '')
    await onRemoveFile(name)
  }

  const _renderFiles = ({ files, onClick, onRemoveFile }) => {
    if (files == null) {
      return null
    }

    return (
      <div className={classes.files}>
        {Object.keys(files).map(name => (
          <Collapse key={name} in={true}>
            <Flex className={classes.file}>
              <div className={classes.filePreview}>
                {files[name].result == null ? (
                  <Loading />
                ) : (
                  <img
                    className={classes.fileImage}
                    src={files[name].preview}
                    alt=""
                    // alt={files[name].data.name}
                  />
                )}
              </div>

              <div className={classes.fileInfo}>
                <Typography variant="caption">
                  {files[name].data.name}
                </Typography>
                <Typography variant="caption">
                  {getBytes(files[name].data.size)}
                </Typography>
              </div>

              {files[name].result != null ? (
                <Tooltip title={l(removeFileText)} placement="right">
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => onRemoveFile(name)}
                    className={classes.fab}
                  >
                    <Close />
                  </Fab>
                </Tooltip>
              ) : null}
            </Flex>
          </Collapse>
        ))}
      </div>
    )
  }

  const _renderTooManyFiles = ({ files, onClose }) => (
    <div className={classes.message}>
      <Typography
        color="error"
        variant="body2"
        className={classes.messageTitle}
      >
        {l('r._.fileupload.text.toomanyfiles')}:
      </Typography>

      <Typography
        color="error"
        variant="body2"
        className={classes.messageFiles}
      >
        {files
          .map((file, index) => (index > 0 ? [<br />, file.name] : file.name))
          .flat()}
      </Typography>

      <Tooltip title={l(hideMessageText)} placement="right">
        <Fab
          size="small"
          color="secondary"
          onClick={onClose}
          className={classes.fab}
        >
          <Check />
        </Fab>
      </Tooltip>
    </div>
  )

  const _renderTooBigFiles = ({ files, onClose }) => (
    <div className={classes.message}>
      <Typography
        color="error"
        variant="body2"
        className={classes.messageTitle}
      >
        {l('r._.fileupload.text.toobigsize')}:
      </Typography>

      <Typography
        color="error"
        variant="body2"
        className={classes.messageFiles}
      >
        {files
          .map((file, index) => (index > 0 ? [<br />, file.name] : file.name))
          .flat()}
      </Typography>

      <Tooltip title={l(hideMessageText)} placement="right">
        <Fab
          size="small"
          color="secondary"
          onClick={onClose}
          className={classes.fab}
        >
          <Check />
        </Fab>
      </Tooltip>
    </div>
  )

  const _renderHelperText = ({
    maxSize,
    maxFiles,
    maxSizeText,
    maxFilesText,
    hasMaxSizeMessage,
    hasMaxFilesMessage,
  }) => {
    const bytes = getBytes(maxSize)

    return (
      <div className={classes.helperText}>
        {hasMaxSizeMessage ? (
          <Typography variant="caption" className={classes.helperTextItem}>
            {l([maxSizeText, bytes], bytes)}
          </Typography>
        ) : null}

        {multiple && hasMaxFilesMessage ? (
          <Typography variant="caption" className={classes.helperTextItem}>
            {l([maxFilesText, maxFiles], maxFiles)}
          </Typography>
        ) : null}
      </div>
    )
  }

  const filesProps = {
    files,
    onClick,
    onRemoveFile: _onRemoveFile,
  }

  const tooManyFilesProps = {
    files: tooManyFiles,
    onClose: onTooManyFilesMessageClose,
  }

  const tooBigFilesProps = {
    files: tooBigFiles,
    onClose: onTooBigFilesMessageClose,
  }

  const helperTextProps = {
    maxSize,
    maxFiles,
    maxSizeText,
    maxFilesText,
    hasMaxSizeMessage,
    hasMaxFilesMessage,
  }

  return (
    <Grid container={true} className={classes.root}>
      <Grid item={true} xs={12} {...buttonGridProps}>
        <Button
          size="small"
          variant="text"
          onClick={onClick}
          startIcon={<SaveAlt />}
          className={classes.button}
          {...buttonProps}
        >
          {l(title)}
        </Button>
      </Grid>

      {hasHelperText ? (
        <Grid item={true} xs={12} {...helperTextGridProps}>
          {typeof renderHelperText === 'function'
            ? renderHelperText(helperTextProps)
            : _renderHelperText(helperTextProps)}
        </Grid>
      ) : null}

      {/* {files != null ? ( */}
      <Grid item={true} xs={12} {...filesGridProps}>
        {typeof renderFiles === 'function'
          ? renderFiles(filesProps)
          : _renderFiles(filesProps)}
      </Grid>
      {/* ) : null} */}

      {multiple && tooManyFiles.length > 0 ? (
        <Grid item={true} xs={12} {...tooManyGridProps}>
          {typeof renderTooManyFiles === 'function'
            ? renderTooManyFiles(tooManyFilesProps)
            : _renderTooManyFiles(tooManyFilesProps)}
        </Grid>
      ) : null}

      {tooBigFiles.length > 0 ? (
        <Grid item={true} xs={12} {...tooBigGridProps}>
          {typeof renderTooBigFiles === 'function'
            ? renderTooBigFiles(tooBigFilesProps)
            : _renderTooBigFiles(tooBigFilesProps)}
        </Grid>
      ) : null}

      <input
        name={name}
        type="file"
        ref={input}
        onChange={onChange}
        style={{ display: 'none' }}
        multiple={multiple}
        accept={acceptAll ? ACCEPT_ALL : acceptScan ? ACCEPT_SCAN : accept}
      />
    </Grid>
  )
}

RawFileUploadView.defaultProps = {}

RawFileUploadView.propTypes = {
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  maxSizeText: PropTypes.string,
  maxFilesText: PropTypes.string,
  hasMaxSizeMessage: PropTypes.bool,
  hasMaxFilesMessage: PropTypes.bool,
  hasHelperText: PropTypes.bool,
  title: PropTypes.string,
  removeFileText: PropTypes.string,
  hideMessageText: PropTypes.string,
  accept: PropTypes.string,
  acceptAll: PropTypes.bool,
  acceptScan: PropTypes.bool,
  multiple: PropTypes.bool,
  files: PropTypes.object,
  buttonProps: PropTypes.object,
  filesGridProps: PropTypes.object,
  buttonGridProps: PropTypes.object,
  tooBigGridProps: PropTypes.object,
  tooManyGridProps: PropTypes.object,
  helperTextGridProps: PropTypes.object,
  tooBigFiles: PropTypes.arrayOf(PropTypes.object),
  tooManyFiles: PropTypes.arrayOf(PropTypes.object),
  renderFiles: PropTypes.func,
  renderTooManyFiles: PropTypes.func,
  renderTooBigFiles: PropTypes.func,
  renderHelperText: PropTypes.func,
  onRemoveFile: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onTooBigFilesMessageClose: PropTypes.func.isRequired,
  onTooManyFilesMessageClose: PropTypes.func.isRequired,
}

export const FileUploadView = withLang(
  withStyles(fileUploadStyle)(RawFileUploadView)
)
