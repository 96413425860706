import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'

export const RawCountInputHelperText = props => {
  const { isValid, minCount, maxCount, errorText, getLangString: l } = props

  if (!isValid) {
    return l(errorText)
  }

  const hasMinCount = minCount != null && minCount > 1
  const hasMaxCount = maxCount != null && maxCount > minCount

  if (hasMinCount && hasMaxCount) {
    return l('r.core.shopping.countminmax', minCount, maxCount)
  }

  if (hasMinCount && !hasMaxCount) {
    return l('r.core.shopping.countmin', minCount)
  }

  if (!hasMinCount && hasMaxCount) {
    return l('r.core.shopping.countmax', maxCount)
  }

  return null
}

RawCountInputHelperText.defaultProps = {
  errorText: 'r.nedopustimoe.znachenie',
}

RawCountInputHelperText.propTypes = {
  // self props
  isValid: PropTypes.bool,
  errorText: PropTypes.string,
  minCount: PropTypes.number,
  maxCount: PropTypes.number,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const CountInputHelperText = withLang(RawCountInputHelperText)
