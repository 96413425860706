export const alterObjectKeys = (obj, func) => {
  if (typeof obj !== 'object' || typeof func !== 'function') {
    return obj
  }

  return Object.keys(obj).reduce((result, key) => {
    result[func(key)] = obj[key]
    return result
  }, {})
}
