import React from 'react';

import Link from '_core/components/Link';

import Tag from './AbstractTag';


Tag.parser.registerTag('a', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    let url = this.renderer.strip(this.params[this.name] || this.getContent(true));
    
    if (/javascript:/i.test(url))
      url = '';
    
    if (!url)
      return this.getComponents();
    
    return (
      <Link
        lang={this.params.lang}
        type={this.params.type || 'auto'}
        target="_blank"
        href={url}
        rel="noopener noreferrer"
      >
        {this.getComponents()}
      </Link>
    );
  }
  
});
