export const paymentResultInvoiceStyle = theme => ({
  root: {},

  title: {
    flexGrow: 1,
  },

  button: {
    minWidth: 'auto',
    marginLeft: theme.spacing(),
  },

  popup: {
    padding: theme.spacing(2),
  },

  code: {
    display: 'block',
  },

  group: {
    margin: theme.spacing(4, 0),
  },

  valueInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.5rem',
      color: theme.palette.error.main,
    },
  },
})
