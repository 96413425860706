import React from 'react';
import PropTypes from 'prop-types';

// core
import { getProductCustomField } from '_core/utils/getProductCustomField';
import { getValue, setValue } from '_core/utils/storage';
import { useGlobal } from '_core/hooks/useGlobal';
import { usePayment } from '_core/hooks/usePayment';
import { withLang } from '_core/hocs/withLang';
import * as api from '_core/utils/api';
import { useCartContext } from '_core/contexts/Cart';
import { Stairs } from '_core/components/Stairs';
import { PaymentStepMethod } from '_core/views/PaymentStepMethod';
import { PaymentStepAgreement } from '_core/views/PaymentStepAgreement';
import { PaymentStepConfirm } from '_core/views/PaymentStepConfirm';
import { PaymentStepProduct } from '_core/views/PaymentStepProduct';


const RawPaymentSteps = (props) => {
  const { setResult, goodsData, getLangString: l } = props

  const {
    request: { queue },
    config: { ROUTE_URL, API_METHOD, STORAGE_KEY, REPLACE_CURRENCY },
  } = useGlobal()

  const { processPayment } = usePayment()
  
  const cart = useCartContext();

  const maxBuys = goodsData.goods.properties.max_buys
  const hasMaxBuys = maxBuys != null && maxBuys > 0

  if (hasMaxBuys) {
    const data = getValue(STORAGE_KEY.GOODS) || {}
    const buys = Number.isInteger(data[goodsData.goods.id])
      ? data[goodsData.goods.id]
      : 0

    if (buys >= maxBuys) {
      throw new Error(l('r._.shopping.payment.ban.title'))
    }
  }

  const getProductListField = name => {
    const prop = getProductCustomField(goodsData.goods, name)
    return Array.isArray(prop) && prop.length > 0 ? prop : null
  }

  const childrenGoods = getProductListField('child_goods')
  const optionGoods = getProductListField('option_goods')

  const getGoodsPropsFunc = names => async () => {
    const result = await queue(API_METHOD.GOODS, {
      currency: REPLACE_CURRENCY.SHOP_GOODS,
      names,
      hidden: true,
      currency: cart.currency.id,
    })

    return result.list
  }

  return (
    <Stairs
      goodsData={goodsData}
      stairs={[
        {
          title: l('r.vvod.summy'),
          Component: PaymentStepProduct,
          useCache: true,
          getProps:
            optionGoods != null
              ? { optionGoods: getGoodsPropsFunc(optionGoods) }
              : childrenGoods != null
              ? { childrenGoods: getGoodsPropsFunc(childrenGoods) }
              : {},
        },
        
        ...(goodsData.goods.agreement_article_list?.length ? [{
          title: l('r._.cartstep.agreement.title'),
          Component: PaymentStepAgreement,
          getProps: {
            articles: async () => await Promise.all(goodsData.goods.agreement_article_list.map((id) => api.request(API_METHOD.ARTICLE_INFO, { id })))
          },
        }] : []),
        
        {
          title: l('r._.cartstep.payment.title'),
          Component: PaymentStepMethod,
          useCache: true,
          waitProps: {
            storage: () => {
              if (!hasMaxBuys) {
                return false
              }

              const data = getValue(STORAGE_KEY.GOODS) || {};
              const buys = Number.isInteger(data[goodsData.goods.id])
                ? data[goodsData.goods.id] + 1
                : 1;
              
              data[goodsData.goods.id] = buys;
              
              setValue(STORAGE_KEY.GOODS, data);
              
              return true
            },
          },
          getProps: {
            payment: ({ load }) =>
              queue(
                API_METHOD.DOCUMENT_CREATE,
                {
                  goods: load.goods,
                  currency: cart.currency.id,
                },
                RawPaymentSteps.displayName
              ),
          },
        },
        {
          title: l('r._.cartstep.confirm.title'),
          Component: PaymentStepConfirm,
          isInvestment: true,
        },
        {
          render: () => null,
          getProps: {
            result: async ({ load, goPrev }) => {
              const { payment, method, isInternalCash } = load

              const url =
                document.location.origin +
                process.env.PUBLIC_URL +
                ROUTE_URL.PAYMENT +
                '/' +
                goodsData.goods.id +
                '/' +
                payment.document

              try {
                const request = await queue(
                  API_METHOD.PAYMENT_REQUEST_CREATE,
                  {
                    currency: REPLACE_CURRENCY.SHOP_PAYMENT,
                    id: method,
                    internal: isInternalCash,
                    document: payment.document,
                    visitor_error_url: url,
                    visitor_success_url: url,
                  },
                  RawPaymentSteps.displayName
                )

                processPayment({
                  request,
                  payment,
                  method,
                  completePath: '/dashboard',
                  onScript: () => goPrev(),
                  callback: (result) => setResult({
                    success: {
                      isInternalCash,
                      ...result,
                    },
                  })
                })
              } catch (error) {
                setResult({ failure: error })
              }
            },
          },
        },
      ]}
    />
  )
};

RawPaymentSteps.displayName = 'RawPaymentSteps'

RawPaymentSteps.defaultProps = {}

RawPaymentSteps.propTypes = {
  // self props
  goodsData: PropTypes.object.isRequired,
  setResult: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentSteps = withLang(RawPaymentSteps)
