// @material-ui
import { makeStyles } from '@material-ui/styles'

export const orderDetailsStyle = theme => ({
  root: {},

  group: {
    margin: theme.spacing(4, 0),
  },

  label: {},
})

export const useOrderDetailsStyle = makeStyles(orderDetailsStyle)
