export const timeoutPromise = ({ promise, time, reason, callback }) => {
  if (!time) {
    return promise
  }

  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      typeof callback === 'function' && callback()
      reject(reason)
    }, time)
  })

  return Promise.race([timeout, promise])
}
