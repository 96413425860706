import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { usePayment } from '_core/hooks/usePayment'
import { withLang } from '_core/hocs/withLang'
import { Stairs } from '_core/components/Stairs'
import { ServiceCount } from '_core/views/ServiceCount'
import { PaymentStepMethod } from '_core/views/PaymentStepMethod'
import { ServiceAgreementStep } from '_core/views/ServiceAgreementStep'
import { PaymentStepConfirm } from '../PaymentStepConfirm'

const RawServiceSteps = (props) => {
  const { setResult, goodsData, paymentDoc, getLangString: l } = props

  const {
    request: { queue },
    config: { API_METHOD, REPLACE_CURRENCY },
  } = useGlobal()

  const { processPayment } = usePayment()

  const ord = paymentDoc ? paymentDoc.order : {}

  const stairs = [
    {
      title: l('r._.dagavor'),
      Component: ServiceAgreementStep,
      controlsProps: { hasBackButton: false },
      useCache: true,
    },
    {
      title: l('r._.cartstep.payment.title'),
      Component: PaymentStepMethod,
      controlsProps: { hasBackButton: false },
      useCache: true,
    },
    {
      title: l('r._.auction.confirm.title'),
      Component: PaymentStepConfirm,
    },
    {
      render: () => null,
      getProps: {
        result: async ({ load, goPrev }) => {
          const { order, method, isInternalCash } = load
          const orderData = order != null ? order : paymentDoc.order
          try {
            const request = await queue(
              API_METHOD.PAYMENT_REQUEST_CREATE,
              {
                currency: REPLACE_CURRENCY.SHOP_PAYMENT,
                id: method,
                internal: isInternalCash,
                document: orderData.id,
              },
              RawServiceSteps.displayName
            )

            processPayment({
              request,
              payment: orderData,
              method,
              completePath: '/dashboard',
              onScript: () => goPrev(),
              callback: (result) => setResult({
                success: {
                  isInternalCash,
                  ...result,
                },
              })
            })
          } catch (error) {
            setResult({ failure: error })
          }
        },
      },
    },
  ]

  if (isEmpty(ord)) {
    stairs.unshift({
      title: l('r._.kolichestvo'),
      Component: ServiceCount,
      controlsProps: { hasBackButton: false },
      useCache: true,
    })
  }

  return <Stairs order={ord} goodsData={goodsData} stairs={stairs} />
};

RawServiceSteps.displayName = 'RawPaymentSteps'

RawServiceSteps.defaultProps = {}

RawServiceSteps.propTypes = {
  // self props
  goodsData: PropTypes.object.isRequired,
  setResult: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ServiceSteps = withLang(RawServiceSteps)
