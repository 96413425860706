import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box } from '@material-ui/core'

// core
import { Steps } from '_core/components/Steps'

// style
import { useStairsStyle } from './stairsStyle'

const RawStairsView = props => {
  const { activeStep, stairs, goPrev, children, hasSteps } = props
  const classes = useStairsStyle()

  const steps = Object.entries(stairs).reduce((result, [key, value]) => {
    value.title != null && (result[key] = value.title)
    return result
  }, {})

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      className={classes.root}
    >
      {hasSteps && stairs[activeStep].title != null ? (
        <Steps steps={steps} activeStep={activeStep} onStepClick={goPrev} />
      ) : null}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className={classes.root}
      >
        {children}
      </Box>
    </Box>
  )
}

RawStairsView.defaultProps = {
  hasSteps: true,
}

RawStairsView.propTypes = {
  // self props
  hasSteps: PropTypes.bool,
  goPrev: PropTypes.func.isRequired,
  activeStep: PropTypes.string.isRequired,
  stairs: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        Component: PropTypes.elementType,
        render: PropTypes.func,
        useCache: PropTypes.bool,
        getProps: PropTypes.object,
        waitProps: PropTypes.object,
        sideProps: PropTypes.object,
        title: PropTypes.string,
        hasTitle: PropTypes.bool,
      })
    ),
  ]).isRequired,
}

export const StairsView = RawStairsView
