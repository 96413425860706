import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

// @material-ui
import { FormControl, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
// import Icon from '@material-ui/core/Icon'
// import InputAdornment from '@material-ui/core/InputAdornment'

// core
import { withLang } from '_core/hocs/withLang'
import GrayLink from '_core/components/GrayLink/GrayLink'
import Label from '_core/components/Label/Label'

import { authKeyInputStyle } from './authKeyInputStyle'

const RawAuthKeyInput = props => {
  const {
    id,
    // name,
    // value,
    // type,
    classes,
    error,
    success,
    // disabled,
    helperText,
    // label,
    // labelProps,
    // onBlur,
    // onChange,
    // white,
    // inputProps,
    // startAdornment,
    // endAdornment,
    formControlProps,
    // inputRootCustomClasses,
    setFieldValue,
    setFieldTouched,
    getLangString: l,
  } = props

  const helperTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  })

  let formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    )
  } else {
    formControlClasses = classes.formControl
  }

  const _input = React.createRef()
  const [file, setFile] = useState()

  const _onInputClick = () => {
    _input.current.click()
  }

  const _onInputChange = () => {
    setFile(_input.current.files[0])
  }

  const _onFileChange = () => {
    if (file == null) {
      return
    }

    let reader = new FileReader()

    reader.onloadend = event => {
      setFieldValue(event.target.result)
      setFieldTouched()
    }

    reader.readAsText(file)
  }

  useEffect(_onFileChange, [file])

  // const customInputProps = {
  //   id,
  //   label,
  //   labelProps,
  //   error,
  //   success,
  //   helperText,
  //   formControlProps,
  //   inputRootCustomClasses,
  //   onBlur,
  //   onChange,
  //   white,
  // }

  // const allInputProps = {
  //   ...inputProps,
  //   type: 'file',
  //   name,
  //   onBlur,
  //   onChange,
  //   disabled,
  //   value: value || '',
  //   startAdornment: startAdornment && (
  //     <InputAdornment position="start" className={classes.startAdornment}>
  //       <Icon className={classes.startAdornmentIcon}>{startAdornment}</Icon>
  //     </InputAdornment>
  //   ),
  //   endAdornment: endAdornment && (
  //     <InputAdornment position="end" className={classes.endAdornment}>
  //       <Icon className={classes.endAdornmentIcon}>{endAdornment}</Icon>
  //     </InputAdornment>
  //   ),
  // }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <input
        id={id}
        type="file"
        ref={_input}
        onChange={_onInputChange}
        style={{ display: 'none' }}
        accept="pem"
      />

      <GrayLink
        icon="save_alt"
        title={l('r.vybrat.fayl')}
        onClick={_onInputClick}
      />

      {file != null ? (
        <div className={classes.infoContainer}>
          <Label size="small" margin="bottom">
            {file.name}
          </Label>
        </div>
      ) : null}

      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helperTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export const AuthKeyInput = withStyles(authKeyInputStyle)(
  withLang(RawAuthKeyInput)
)
