export const overrides = theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  text: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  sizeSmall: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    // padding: 0,
    fontSize: theme.typography.overline.fontSize,
  },
  sizeLarge: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    fontSize: theme.typography.body1.fontSize,
  },
  outlinedPrimary: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    // borderColor: theme.palette.background.paper,
    // color: theme.palette.background.paper,
    // '&:hover, &:active, &:focus': {
    //   background: theme.palette.background.paper,
    //   borderColor: theme.palette.background.paper,
    //   color: theme.palette.primary.main,
    // },
  },
  outlinedSecondary: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    // borderColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.main,
    // '&:hover, &:active, &:focus': {
    //   background: theme.palette.primary.main,
    //   borderColor: theme.palette.primary.main,
    //   color: '#fff',
    // },
  },
  label: {
    // fontSize: theme.typography.overline.fontSize,
    fontWeight: 500,
    lineHeight: 1,
  },
})

export const props = {
  variant: 'contained',
  color: 'primary',
}
