import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box, IconButton, Typography } from '@material-ui/core'

import BackspaceIcon from '@material-ui/icons/Backspace';

// core
import { withCatch } from '_core/hocs/withCatch'
import { withLang } from '_core/hocs/withLang'

import { TopicList } from '_core/views/MessagesSplash/TopicList'
import { TopicView } from '_core/views/MessagesSplash/TopicView'

import './Messenger.css'

const RawMessagerMobile = (props) => {
  const { topics, topicId, selectedMessageId, getLangString: l } = props;

  const [selectedTopic, setSelectedTopic] = useState(topics.find(x => x.topicId === topicId));
  
  if (selectedTopic == null) {
    return (
      <Box>
        <Typography variant="h6">
          {l('r.soobscheniya')}
        </Typography>
        <div className="sidebar">
          <TopicList topics={topics} onSelect={setSelectedTopic} />
        </div>
      </Box>
    );
  } else {
    const topic = selectedTopic;
    return (
      <Box>
        <div>
            <IconButton classes={{ root: "mobile-topic-view-header-button" }} onClick={() => setSelectedTopic(null)}>
              <BackspaceIcon />
            </IconButton>
          <div style={{"display": "inline"}}>
            <Typography inline variant="h6" className="conversation-snippet">{topic.subject}</Typography>
            <Typography inline variant="subtitle1" className="conversation-snippet">{topic.author}</Typography>
          </div>
        </div>
        <TopicView topicId={topic.id} selectedMessageId={selectedMessageId} />
      </Box>
    );
  }
}

RawMessagerMobile.defaultProps = {}

RawMessagerMobile.propTypes = {
  // self props
  topicId: PropTypes.number,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const MessagerMobile = withCatch(
  withLang(
    (RawMessagerMobile)
  )
)
