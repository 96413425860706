// @material-ui
import { makeStyles } from '@material-ui/styles'

export const style = theme => ({
  root: {},
  
  title: { marginBottom: '0.5em' },
  
  content: {}
})

export const useStyle = makeStyles(style)
