import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';

// core
import { getTheme } from '_core/themes/getTheme';
import { App } from '_core/components/App';


const init = async () => {
  const config = await import(`${process.env.REACT_APP_PROJECT}/config`);
  const { slots } = await import(`${process.env.REACT_APP_PROJECT}/slots`);
  const { routes } = await import(`${process.env.REACT_APP_PROJECT}/routes`);
  const { menus } = await import(`${process.env.REACT_APP_PROJECT}/menus`);
  const { client, guest } = await import('_core/utils/i18n');
  const { themes } = await import(`${process.env.REACT_APP_PROJECT}/themes`);

  render(
      <App
        globalContext={{
          config,
          menus,
          slots,
          routes,
          i18n: { client, guest },
          themes: themes?.length ? themes.map((theme) => getTheme(theme)) : [getTheme()]
        }}
      />,
    document.getElementById('app')
  );
};

init();
