import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

// @material-ui
import { Alert } from '@material-ui/lab'

// core
import { withLang } from '_core/hocs/withLang'
import { ErrorMessage } from '_core/components/ErrorMessage'
import { FieldErrorMessage } from '_core/components/FieldErrorMessage'
import { StepsControls } from '_core/components/StepsControls'

export const RawFormResultStep = props => {
  const {
    failure,
    reset,
    goPrev,
    fieldLabels,
    failureMessage,
    failureButtonText,
    failureControlsProps,
    renderFailureMessage,
    successMessage,
    successButtonText,
    successControlsProps,
    renderSuccessMessage,
    getLangString: l,
  } = props

  if (isEmpty(failure)) {
    return (
      <>
        <Alert severity="success">
          {typeof renderSuccessMessage === 'function'
            ? renderSuccessMessage()
            : l(successMessage)}
        </Alert>

        <StepsControls
          nextButtonText={successButtonText}
          onNextStep={reset}
          hasBackButton={false}
          {...successControlsProps}
        />
      </>
    )
  }

  return (
    <>
      {typeof failure.errors === 'object' ? (
        Object.keys(failure.errors).map(field => (
          <FieldErrorMessage
            field={field}
            label={fieldLabels[field]}
            code={failure.errors[field].code}
          />
        ))
      ) : (
        <ErrorMessage
          error={failure}
          message={failureMessage}
          renderContent={renderFailureMessage}
        />
      )}

      <StepsControls
        nextButtonText={failureButtonText}
        onNextStep={() => goPrev()}
        hasBackButton={false}
        {...failureControlsProps}
      />
    </>
  )
}

RawFormResultStep.defaultProps = {
  fieldLabels: {},
  failureMessage: 'r.pri.provedenii.operacii.proizoshla.oshibka',
  successMessage: 'r.operaciya.proshla.uspeshno',
  failureButtonText: 'r._.finance.exchange.control.retry',
  successButtonText: 'r._.finance.exchange.control.reset',
}

RawFormResultStep.propTypes = {
  // self props
  fieldNames: PropTypes.object.isRequired,
  failure: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  failureMessage: PropTypes.string,
  successMessage: PropTypes.string,
  failureButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  failureButtonProps: PropTypes.object,
  successButtonProps: PropTypes.object,
  renderFailureMessage: PropTypes.func,
  renderSuccessMessage: PropTypes.func,
}

export const FormResultStep = withLang(RawFormResultStep)
