import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui
import { DialogContent, Typography, Grid, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

// core
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { Price } from '_core/components/Price';

// style
import { balanceDetailsStyle } from './balanceDetailsStyle';


const RawBalanceDetails = (props) => {
  const { classes, accounts, accountTypes, visitorInfo, getLangString: l } = props;

  const cart = useCartContext();

  const {
    config: { ACCOUNT_MONEY_FIELDS },
  } = useGlobal();

  const accountTypeMap = new Map();
  
  accountTypes.forEach(accountType => {
    const key = accountType.id;
    accountTypeMap.set(key, accountType);
  })

  return (
    <div className={classes.root}>
      <DialogContent className={classes.content}>
        <Grid container={true} className={classes.captionRow}>
          {['currency', 'moneyamount', 'moneyblocked', 'moneytotal'].map(
            key => (
              <Grid key={key} item={true} xs={3}>
                <Typography
                  noWrap={true}
                  variant="caption"
                  className={classes.columnCaption}
                >
                  {l(`r._.header.${key}`)}
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </DialogContent>

      {accounts.filter((item) => visitorInfo.inGroup(item.header_visitor_group))
        .map((account) => {
          const accountType = accountTypeMap.get(account.account_type);
          const currencyData = cart.findCurrency(account.currency);

          return (
            <React.Fragment key={accountType.id}>
              <Divider className={classes.divider} />

              <DialogContent className={classes.content}>
                <Typography variant="subtitle1" className={classes.account}>
                  {accountType.title}
                </Typography>
                <Grid container={true} key={account.id} className={classes.row}>
                  <Grid item={true} xs={3}>
                    <Typography variant="caption" className={classes.currency}>
                      {currencyData?.title}
                    </Typography>
                  </Grid>

                  {ACCOUNT_MONEY_FIELDS.map(key => {
                    const valueClasses = clsx({
                      [classes.value]: true,
                      [classes.valueAmount]: key === 'amount',
                      [classes.valueClear]: key === 'blocked' && +account[key].value === 0,
                      [classes.valueBlocked]: key === 'blocked' && +account[key].value > 0,
                    });

                    return (
                      <Grid key={key} item={true} xs={3}>
                        <Price
                          className={valueClasses}
                          value={account[key].value}
                          currency={account.currency}
                          rootProps={{ variant: 'body2' }}
                          hasTooltip={true}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </DialogContent>
            </React.Fragment>
          )
        })
      }
    </div>
  );
};

RawBalanceDetails.defaultProps = {};

RawBalanceDetails.propTypes = {
  // self props
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountTypes: PropTypes.arrayOf(PropTypes.object).isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const BalanceDetails = withVisitorInfo(withLang(
  withStyles(balanceDetailsStyle, { name: 'RtsBalanceDetails' })(RawBalanceDetails)
));
