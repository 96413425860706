import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Radio, FormHelperText, FormControlLabel } from '@material-ui/core'

// core
import { usePaymentSwitchStyle } from '_core/components/PaymentSwitch'

export const RawPaymentOption = props => {
  const {
    value,
    name,
    label,
    image,
    children,
    isChecked,
    onChange,
    disabled,
  } = props

  const classes = usePaymentSwitchStyle()

  return (
    <div className={classes.option}>
      <FormControlLabel
        disabled={disabled}
        className={classes.optionLabel}
        control={<Radio color="secondary" className={classes.radio} />}
        name={name}
        onChange={onChange}
        checked={isChecked}
        label={label}
        value={value}
      />

      <FormHelperText className={classes.helperText}>{children}</FormHelperText>

      {image != null ? (
        <img src={image} alt="" className={classes.logo} />
      ) : null}
    </div>
  )
}

RawPaymentOption.defaultProps = {}

RawPaymentOption.propTypes = {
  // self props
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export const PaymentOption = RawPaymentOption
