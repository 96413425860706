import 'react-daterange-picker/dist/css/react-calendar.css'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-daterange-picker'
import moment from 'moment'

// @material-ui
import {
  IconButton,
  InputAdornment,
  Popper,
  Paper,
  ClickAwayListener,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Clear } from '@material-ui/icons'

// core
import { createId } from '_core/utils/createId'
import { TextInputBase } from '_core/components/TextInputBase'

const style = theme => ({})

class RawCalendar extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
    }

    this._id = createId()
    this._inputRef = React.createRef()

    this.onSelect = this.onSelect.bind(this)

    this._toggle = this._toggle.bind(this)
    this._clearInput = this._clearInput.bind(this)
    this._getInputValue = this._getInputValue.bind(this)
    this._onClickAway = this._onClickAway.bind(this)
  }

  onSelect(value, ...args) {
    //console.log(args)
    this.props.onChange(value, this._inputRef.current)
    this._toggle(false)
  }

  _clearInput() {
    this.props.onChange(null)
  }

  _toggle(isVisible) {
    this.setState({ isVisible })
  }

  _getInputValue() {
    const { value, format } = this.props;

    if (this.props.calendarProps.selectionType === 'single' && value != null) {
      if (typeof value === 'string') {
        // eslint-disable-next-line react/prop-types
        return moment(value, 'DD.MM.YYYY').format(format);
      }
      // eslint-disable-next-line react/prop-types
      return value.format(format);
    }

    if (!value || (!value.start && !value.end))
      return '';

    const start = (typeof value.start === 'string' ? moment(value.start, 'DD.MM.YYYY') : value.start).format(format);
    const end = (typeof value.end === 'string' ? moment(value.end, 'DD.MM.YYYY') : value.end).format(format);

    return start === end ? start : `${start} ${end}`;
  }

  _onClickAway(event) {
    event.target !== this._inputRef.current && this._toggle(false);
  }

  render() {
    const {
      value,
      noClear,
      // onChange,
      disabled,
      inputProps,
      calendarProps,
      popupProps,
    } = this.props
    const { isVisible } = this.state

    return (
      <>
        <TextInputBase
          disabled={disabled}
          inputRef={this._inputRef}
          value={this._getInputValue()}
          onFocus={() => this._toggle(true)}
          aria-describedby={this._id}
          readOnly
          inputProps={{
            endAdornment: !noClear ? (
              <InputAdornment position="end">
                <IconButton disabled={value == null} onClick={this._clearInput}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          {...inputProps}
        />

        {isVisible ? (
          <Popper
            {...popupProps}
            transition
            // zIndex is needed to overlap modals
            style={{ zIndex: 1500 }}
            id={this._id}
            open={true}
            anchorEl={this._inputRef.current}
            // TODO: Popper modifiers don't work as expected
            // modifiers={{
            //   flip: {
            //     enabled: true,
            //   },
            //   preventOverflow: {
            //     enabled: true,
            //     boundariesElement: 'scrollParent',
            //   },
            // }}
          >
            <ClickAwayListener onClickAway={this._onClickAway}>
              <Paper melevation={2}>
                <DateRangePicker
                  {...calendarProps}
                  value={value}
                  onSelect={this.onSelect}
                  // onSelect={onChange}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : null}
      </>
    )
  }
}

RawCalendar.defaultProps = {
  disabled: false,
  noClear: false,
  inputProps: {
    fullWidth: true,
  },
}

RawCalendar.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  format: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  calendarProps: PropTypes.object,
  popupProps: PropTypes.object,
  inputProps: PropTypes.object,
  noClear: PropTypes.bool,
}

export const Calendar = withStyles(style)(RawCalendar)
