import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { PriceInput } from '_core/components/PriceInput'

const RawPriceFormChildren = props => {
  const { childGoods, currency } = props

  return childGoods.map(item => (
    <PriceInput
      value={item.price.value}
      label={item.goods.title}
      currency={currency}
      readOnly={true}
    />
  ))
}

RawPriceFormChildren.defaultProps = {}

RawPriceFormChildren.propTypes = {
  // self props
  childGoods: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceFormChildren = withLang(RawPriceFormChildren)
