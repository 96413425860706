import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { IconButton, Tooltip, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Settings, Policy } from '@material-ui/icons'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withVisitorInfo } from '_core/utils/visitorInfo'
import { LangSwitch } from '_core/components/LangSwitch'
import { ThemeSwitch } from '_core/components/ThemeSwitch'
import { CurrencySwitch } from '_core/components/CurrencySwitch'
import { Menu } from '_core/components/Menu'

// style
import { settingsPopupStyle } from './settingsPopupStyle'

const RawSettingsPopup = (props) => {
  const { classes, inMenu, tooltip, visitorInfo, getLangString: l, ...rest } = props;

  const { config: { VISITOR_PRIVILEGES } } = useGlobal();

  const isPrivileged = visitorInfo.isAllowed(VISITOR_PRIVILEGES.ADMIN);
  //const isSystemUser = isPrivileged && visitorInfo.isAllowed(VISITOR_PRIVILEGES.SERVICE);

  const buttonRootClasses = clsx({
    [classes.button]: true,
    [classes.buttonContrast]: inMenu,
  })

  return (
    <Menu
      tooltip={l(tooltip)}
      buttonBody={<Settings />}
      ButtonComponent={IconButton}
      // TODO: fix react warning on refs
      // ButtonComponent={forwardRef((props, ref) => (
      //   <IconButton ref={ref} {...props} />
      // ))}
      buttonProps={{
        classes: {
          root: buttonRootClasses,
          label: classes.label,
        },
      }}
      content={
        <Box display="flex">
          <LangSwitch />
          <CurrencySwitch hasSigns={false} />
          <ThemeSwitch />

          {isPrivileged ? (
            <Tooltip title={l('r._.header.adminpanel')}>
              <IconButton
                href={process.env.REACT_APP_ADMIN_URL}
                target="_blank"
              >
                <Policy />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      }
      paperProps={{
        className: classes.paper,
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
      {...rest}
    />
  )
};

RawSettingsPopup.defaultProps = {
  tooltip: 'r._.header.settings',
}

RawSettingsPopup.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  tooltip: PropTypes.string,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const SettingsPopup = withVisitorInfo(withLang(
  withStyles(settingsPopupStyle)(RawSettingsPopup)
))
