export const selectInputBaseStyle = theme => ({
  open: {},

  option: {
    alignItems: 'baseline',
  },

  optionSelected: {},

  optionDefault: {
    color: theme.palette.text.secondary,
  },

  optionGroup: {
    fontWeight: theme.typography.fontWeightBold,
  },
})
