import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { get } from 'lodash';

// @material-ui
import { Box, Icon, IconButton, Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

// core
import { useClientVisitorStatusTypes } from '_core/utils/api';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { withLang } from '_core/hocs/withLang';
import { UserAvatar } from '_core/components/UserAvatar';

// style
import { sidebarUserStyle } from './sidebarUserStyle';


const getTextFromFields = (userInfo, fields) =>
  fields
    .map(field => get(userInfo, field))
    .filter(Boolean)
    .join(' ');


export const RawSidebarUser = (props) => {
  const {
    classes,
    isMini,
    hasTooltip,
    onProfileOpen,
    majorFields,
    majorTooltipFields,
    minorFields,
    minorTooltipFields,
    visitorInfo,
    getLangString: l,
  } = props;

  const statusTypes = useClientVisitorStatusTypes();

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.rootMini]: isMini,
  })

  const avatarClasses = clsx({
    [classes.avatar]: true,
    [classes.avatarMini]: isMini,
  })

  const editClasses = clsx({
    [classes.edit]: true,
    [classes.editMini]: isMini,
  })

  return (
    <Box display="flex" alignItems="center" className={rootClasses}>
      <UserAvatar
        className={avatarClasses}
        onClick={onProfileOpen}
        tooltip={l('r._.header.editprofile')}
        tooltipProps={{
          disableFocusListener: !hasTooltip,
          disableHoverListener: !hasTooltip,
          disableTouchListener: !hasTooltip,
          placement: 'right',
          enterDelay: 0,
        }}
      />
      <Tooltip
            title={getTextFromFields(
              visitorInfo.info,
              minorTooltipFields
            )}
          >
            <Typography className={classes.login} noWrap={true}>
              {getTextFromFields(visitorInfo.info, minorFields)}
            </Typography>
          </Tooltip>

      <Box display="flex" alignItems="center" className={classes.textWrap}>
        <Box display="flex" flexDirection="column" className={classes.text}>
          <Tooltip
            title={getTextFromFields(
              visitorInfo.info,
              majorTooltipFields
            )}
          >
            <Typography className={classes.login} noWrap={true}>
              {getTextFromFields(visitorInfo.info, majorFields)}
            </Typography>
          </Tooltip>

          {statusTypes?.data ? statusTypes.data.map(({ name, title, is_query_info, visitor_group }) => is_query_info && visitorInfo.info?.[name] && visitorInfo.inGroup(visitor_group) && (
            <Tooltip
              key={name}
              title={title + ': ' + visitorInfo.info[name]?.title}
            >
              <Typography variant="caption" noWrap={true}>
                {visitorInfo.info[name]?.title}
              </Typography>
            </Tooltip>
          )) : null}
        </Box>

        <Tooltip title={l('r._.header.editprofile')} placement="right">
          <IconButton
            size="small"
            onClick={onProfileOpen}
            className={editClasses}
          >
            <Icon fontSize="small">edit</Icon>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
};

RawSidebarUser.defaultProps = {
  minorFields: [''],
  minorTooltipFields: [''],
  majorFields: ['login'],
  majorTooltipFields: ['login']
}

RawSidebarUser.propTypes = {
  // self props
  isMini: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  onProfileOpen: PropTypes.func.isRequired,
  minorFields: PropTypes.arrayOf(PropTypes.string),
  minorTooltipFields: PropTypes.arrayOf(PropTypes.string),
  majorFields: PropTypes.arrayOf(PropTypes.string),
  majorTooltipFields: PropTypes.arrayOf(PropTypes.string),

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const SidebarUser = withVisitorInfo(withLang(
  withStyles(sidebarUserStyle, { name: 'RtsSidebarUser' })(RawSidebarUser)
))
