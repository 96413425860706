export const authKeyInputStyle = theme => ({
  disabled: {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },
  labelRootError: {
    color: theme.palette.error.main,
  },
  labelRootSuccess: {
    color: theme.palette.success,
  },
})
