export const overrides = theme => ({
  root: {
    borderBottomColor: theme.palette.divider,
  },
  head: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
})

export const props = {}
