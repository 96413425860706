import { LANG_ERROR_MESSAGE_PREFIX } from 'config'

export const getErrorCode = (field, code) => {
  const fixedField = field.replace(/^\$/, '')

  return [
    `${LANG_ERROR_MESSAGE_PREFIX}.${fixedField}.${code}`,
    `${LANG_ERROR_MESSAGE_PREFIX}.${code}`,
    `${LANG_ERROR_MESSAGE_PREFIX}.${fixedField}.bad`,
    `${LANG_ERROR_MESSAGE_PREFIX}.bad`,
  ]
}
