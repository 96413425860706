//import React from 'react';


export const sqr = (value) => value * value;

export const isNone = (value) => value === undefined || value === null;

export const deepEqual = (value1, value2) => {
  if (value1 === value2)
    return true;
  
  if (isNone(value1) || isNone(value2))
    return false;
  
  const type1 = typeof value1;
  
  if (type1 !== typeof value2 || type1 !== 'object')
    return false;
  
  if (Array.isArray(value1)) {
    if (!Array.isArray(value2) || value1.length !== value2.length)
      return false;
    
    return value1.every((item, index) => deepEqual(item, value2[index]));
  }
  
  if (value1.constructor !== value2.constructor)
    return false;
  
  const keys1 = Object.keys(value1);
  
  if (keys1.length !== Object.keys(value2).length)
    return false;
  
  return keys1.every((key, index) => deepEqual(value1[key], value2[key]));
};

export const deepCopy = (value) => typeof window.structuredClone === 'function' ? window.structuredClone(value) : JSON.parse(JSON.stringify(value));

export const getFields = (object, path) => {
  if (!Array.isArray(path)) {
    path = String(path || '').split('.');
    
    if (path.length === 1 && path[0] === '')
      return [];
  }
  
  if (!path.length)
    return [];
  
  let result = [];
  
  path.forEach((name, index) => {
    if (!index)
      result.push([[undefined, object]]);
    
    if (name === '*') {
      const oldResult = result;
      
      result = [];
      
      oldResult.forEach((current) => {
        if (current[0][1] && typeof current[0][1] === 'object')
          Object.entries(current[0][1]).forEach(([key, value]) => result.push([[key, value], ...current]));
      });
    } else {
      result.forEach((current) => {
        if (current[0][1] && typeof current[0][1] === 'object') {
          current.unshift([name, current[0][1][name]]);
        } else {
          current.unshift([name, undefined]);
        }
      });
    }
  });
  
  return result;
};

export const setFields = (object, path, value) => {
  getFields(object, path).forEach((field) => field.length > 1 && (field[1][1][field[0][0]] = value));
};
