// @material-ui
import { makeStyles } from '@material-ui/styles'

export const paymentBriefStyle = theme => ({
  root: {},

  row: {
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

export const usePaymentBriefStyle = makeStyles(paymentBriefStyle)
