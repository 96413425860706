export const overrides = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: theme.spacing(3),
    overflow: 'hidden',
    // height: `calc(100% - ${theme.spacing(3)}px)`,
  },
})

export const props = {
  raised: false,
}
