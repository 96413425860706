export const sidebarItemActive = theme => ({
  background: `linear-gradient(90deg, ${theme.variables.colors.sidebarItemsActive} 0%, transparent 70%)`,

  '&::before': {
    background: 'transparent',
  },

  '&:hover': {
    background: `linear-gradient(90deg, ${theme.variables.colors.sidebarItemsActive} 0%, transparent 70%)`,
  },
})
