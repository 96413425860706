/* eslint-disable */
import React from 'react';


const DEFAULT_DECORATORS = {
};

export default (Component) => {
  let ResultComponent = Component;
  
  // decorateWith
  
  if (Component.decorateWith) {
    const decorateEntries = Object.entries(Component.decorateWith);
    
    if (decorateEntries.length) {
      const WithPropsComponent = React.memo(ResultComponent);
      
      ResultComponent = (props) => <WithPropsComponent {...props} {...decorateWithProps(props, decorateEntries)} />;
      
      //if (typeof Component.getInitialProps === 'function')
      //  ResultComponent.getInitialProps = (...args) => Component.getInitialProps.call(Component, ...args);
      
      if (Component.propTypes)
        ResultComponent.propTypes = Component.propTypes;
      
      if (Component.defaultProps)
        ResultComponent.defaultProps = Component.defaultProps;
    }
  }
  
  // decorateForwardRef
  
  if (Component.decorateForwardRef) {
    const WithRefComponent = ResultComponent;
    
    ResultComponent = React.forwardRef((props, ref) => <WithRefComponent {...{ ...props, [Component.decorateForwardRef]: ref }} />);
  }
  
  // decorateStatic
  
  if (Component.decorateStatic)
    Object.entries(Component.decorateStatic).forEach(([key, value]) => ResultComponent[key] = value);
  
  return ResultComponent;
};


const decorateWithProps = (props, entries) => {
  const decorateProps = {};
  
  entries.forEach(([key, value]) => {
    if (typeof value === 'function') {
      decorateProps[key] = value(props);
    } else if (value === true) {
      value = DEFAULT_DECORATORS[key];
      
      if (typeof value === 'function')
        decorateProps[key] = value(props);
    } else if (typeof value === 'object') {
      const decorateEntries = Object.entries(value);
      
      if (!decorateEntries.length)
        return;
      
      decorateProps[key] = decorateWithProps(props, decorateEntries);
    }
  });
  
  return decorateProps;
};
