import { isEqual } from 'lodash'

// core
import {
  FETCH_TIMEOUT,
  FETCH_EFFORTS,
  DEFAULT_TIMEOUT_ERROR,
} from 'basic/config'
import { timeoutPromise } from '_core/utils/timeoutPromise'

export const loadedScripts = []

export const loadScript = ({
  src,
  className,
  onSuccess,
  onFailure,
  attrs,
  dataset,
  parent = document.getElementsByTagName('head')[0],
}) => {
  const data = {
    src,
    className,
    dataset,
    attrs,
  }

  if (loadedScripts.some(item => isEqual(item, data))) {
    typeof onSuccess === 'function' && onSuccess()
    return
  }

  try {
    const element = document.createElement('script')
    const attributes = {
      src,
      async: 'true',
      type: 'text/javascript',
      onload: () => {
        loadedScripts.push(data)
        typeof onSuccess === 'function' && onSuccess()
      },
      onerror: error => {
        typeof onFailure === 'function' && onFailure(error)
      },
      ...(className != null ? { className } : {}),
      ...attrs,
    }

    Object.assign(element, attributes)
    Object.assign(element.dataset, dataset)
    parent.appendChild(element)
  } catch (error) {
    process.env.NODE_ENV !== 'production' && console.error(error)
    typeof onFailure === 'function' && onFailure(error)
  }
}

export const loadScriptPromise = params => {
  const promise = new Promise((resolve, reject) => {
    const args = {
      ...params,
      onSuccess: () => {
        typeof params.onSuccess === 'function' && params.onSuccess()
        resolve()
      },
      onFailure: () => {
        typeof params.onFailure === 'function' && params.onFailure()
        reject()
      },
    }

    loadScript(args)
  })

  return timeoutPromise({
    promise,
    time: FETCH_TIMEOUT + FETCH_EFFORTS,
    reason: { ...DEFAULT_TIMEOUT_ERROR },
    callback: params.onFailure,
  })
}
