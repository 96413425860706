import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { TextInputBase } from '_core/components/TextInputBase'

export const RawLoginInput = props => {
  const { value, onChange, helperText, ...rest } = props
  const [name, setName] = useState()
  const isEmpty = useRef(true)

  const {
    request: { queue },
    config: { API_METHOD },
  } = useGlobal()

  const rawOnChange = event => {
    onChange(event)

    if (event.target.value.length > 0) {
      isEmpty.current = false
      getUserName(event.target.value)
    } else {
      isEmpty.current = true
      setName()
    }
  }

  const getUserName = useCallback(
    debounce(login => {
      if (isEmpty.current) {
        return
      }
      
      queue(API_METHOD.VISITOR_LOGIN_SEARCH, { value: login })
        .then(({ visitor_info }) => setName(visitor_info?.fullname));
    }, 250),
    []
  )

  useEffect(() => {
    if (value != null && value.length > 0) {
      isEmpty.current = false
      getUserName(value)
    }
  }, [])

  return (
    <TextInputBase
      value={value}
      onChange={rawOnChange}
      helperText={helperText ?? name}
      {...rest}
    />
  )
}

RawLoginInput.displayName = 'RawLoginInput'

RawLoginInput.defaultProps = {}

RawLoginInput.propTypes = {
  // self props
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export const LoginInput = RawLoginInput
