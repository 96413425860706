import React from 'react';

import lodash from 'lodash';

import { Typography } from '@material-ui/core';

import { API_METHOD, DELIVERY_TYPES, MAP_DELIVERY_POINT_ZOOM, MAP_DELIVERY_ICON, MAP_STORE_ICON, STORAGE_KEY, REPLACE_CURRENCY } from 'config';

import * as filter from '_core/utils/filter';
import { getValue, setValue } from '_core/utils/storage';
import { join as addressJoin } from '_core/utils/address';
import * as api from '_core/utils/api';
import { useVisitorInfo } from '_core/utils/visitorInfo';
import { withLang } from '_core/hocs/withLang';
import { Map } from '_core/components/Map';


const STORE_TIMEOUT = 500;

const DELIVERY_VARIANT_REQUIRED_FIELDS = [
  'city_with_type', 'country_iso_code', 'house', 'postal_code', 'region_iso_code', 'street_with_type'
];

const PICKUP_POINT_SEARCH_FIELDS = [
  'title', 'description', 'address', 'type'
];

const DELIVERY_VARIANTS_FETCH_TIMEOUT = 500;

const PICKUP_POINTS_FETCH_TIMEOUT = 500;


let pointTypeList = null;


export const DeliveryContext = React.createContext();

export const DeliveryConsumer = DeliveryContext.Consumer;

export const DeliveryProvider = withLang((props) => {
  const { children, getLangString: l } = props;
  
  const [state, setState] = React.useState({ methodChecked: DELIVERY_TYPES.DELIVERY });
  
  useAddressStorage(state, setState);
  usePersonalStorage(state, setState);
  
  const deliveryControl = {
    // method
    
    isNextAllowed: state.methodChecked === DELIVERY_TYPES.DELIVERY && !!state.deliveryVariantTariff
      || state.methodChecked === DELIVERY_TYPES.PICKUP && !!state.pickupPointTariff
      || false,
    
    method: state.methodChecked,
    
    setMethod: (value) => {
      deliveryControl.method = value;
      
      setState((state) => ({ ...state, methodChecked: value }))
    },
    
    // source
    
    source: state.methodChecked === DELIVERY_TYPES.DELIVERY && state.deliveryVariant?.source
      || state.methodChecked === DELIVERY_TYPES.PICKUP && state.pickupPoint?.source
      || null,
    
    // address
    
    addressCity: state.addressData ? state.addressData.settlement_with_type || state.addressData.settlement || state.addressData.city : null,
    
    addressProps: (type) => {
      const resultProps = {
        disabled: state.orderCreateLoading
      };
      
      if (type === 'city' || type === 'street') {
        resultProps.type = type;
        resultProps.value = state[type + 'AddressData'] || null;
        resultProps.ownerValue = type === 'street' ? state.cityAddressData : null;
        resultProps.onSelectValue = (data) => {
          deliveryVariantUpdate++;
          
          if (type === 'city')
            pickupPointUpdate++;
          
          setState((state) => {
            state = {
              ...state,
              [type + 'AddressData']: data
            };
            
            state.deliveryVariantList = null;
            
            if (!state.addressData)
              state.addressData = {};
            
            if (type === 'city') {
              state.streetAddressData = null;
              state.pickupPointList = null;
              
              state.addressData.country = data.data.country;
              state.addressData.country_iso_code = data.data.country_iso_code;
              
              state.addressData.region = data.data.region;
              state.addressData.region_iso_code = data.data.region_iso_code;
              state.addressData.region_with_type = data.data.region_with_type;
              
              state.addressData.city = data.data.city;
              state.addressData.city_with_type = data.data.city_with_type;
              state.addressData.city_fias_id = data.data.city_fias_id;
              
              state.addressData.city_district = data.data.city_district;
              state.addressData.city_district_with_type = data.data.city_district_with_type;
              
              state.addressData.area = data.data.area;
              state.addressData.area_with_type = data.data.area_with_type;
              
              state.addressData.settlement = data.data.settlement;
              state.addressData.settlement_with_type = data.data.settlement_with_type;
              state.addressData.settlement_fias_id = data.data.settlement_fias_id;
              
              state.addressData.geo_lat = data.data.geo_lat;
              state.addressData.geo_lon = data.data.geo_lon;
              
              state.addressData.postal_code = data.data.postal_code || '';
            } else if (type === 'street') {
              state.addressData.street = data.data.street;
              state.addressData.street_with_type = data.data.street_with_type;
              
              state.addressData.postal_code = data.data.postal_code || state.cityAddressData?.data.postal_code || '';
            }
            
            return state;
          });
        };
        
        if (type === 'street') {
          resultProps.onChange = (event) => {
            const value = event?.target ? event.target.value : String(event || '');
            
            deliveryVariantUpdate++;
            
            setState((state) => {
              state = { ...state };
              
              state.deliveryVariantList = null;
              
              if (!state.addressData)
                state.addressData = {};
              
              state.addressData.street = value;
              state.addressData.street_with_type = value;
              
              return state;
            });
          };
        }
      } else {
        resultProps.value = state.addressData?.[type] || '';
        resultProps.onChange = (event) => {
          const value = event?.target ? event.target.value : String(event || '');
          
          deliveryVariantUpdate++;
          
          setState((state) => {
            state = { ...state };
            
            state.deliveryVariantList = null;
            
            if (!state.addressData)
              state.addressData = {};
            
            state.addressData[type] = value;
            
            return state;
          });
        };
      }
      
      return resultProps;
    },
    
    // delivery
    
    deliveryVariantLoadingCheck: () => {
      const addressData = state.addressData;
      
      if (!addressData || !deliveryControl.isDeliveryVariantAllowed || deliveryControl.isDeliveryVariantLoading || state.methodChecked !== 'delivery')
        return false;
      
      const variantList = state.deliveryVariantList;
      
      if (!variantList) {
        let update = deliveryVariantUpdate;
        
        deliveryControl.isDeliveryVariantLoading = true;
        
        setTimeout(() => setState((state) => ({ ...state, deliveryVariantLoading: true })), 1);
        
        if (deliveryVariantFetchInterval)
          clearInterval(deliveryVariantFetchInterval);
        
        deliveryVariantFetchInterval = setInterval(() => {
          const newUpdate = deliveryVariantUpdate;
          
          if (update !== newUpdate) {
            update = newUpdate;
            
            return;
          }
          
          clearInterval(deliveryVariantFetchInterval);
          deliveryVariantFetchInterval = null;
          
          const address = { value: addressJoin('address', addressData), data: addressData };
          
          fetchList(l, deliveryControl.method, address)
            .then((list) => setState((state) => {
              if (update !== deliveryVariantUpdate)
                return { ...state, deliveryVariantLoading: false };
              
              return { ...state, deliveryVariantLoading: false, deliveryVariantList: list };
            }))
            .catch((error) => console.log(error) || setState((state) => {
              if (update !== deliveryVariantUpdate)
                return { ...state, deliveryVariantLoading: false };
              
              return { ...state, deliveryVariantLoading: false, deliveryVariantList: [] };
            }));
        }, DELIVERY_VARIANTS_FETCH_TIMEOUT);
        
        return false;
      }
      
      return true;
    },
    
    isDeliveryVariantLoading: state.deliveryVariantLoading || false,
    
    isDeliveryVariantAllowed: !!(state.addressData && DELIVERY_VARIANT_REQUIRED_FIELDS.every((name) => !!state.addressData?.[name])),
    
    get deliveryVariantCount() { return deliveryControl.deliveryVariantLoadingCheck() && state.deliveryVariantList?.length || 0 },
    
    deliveryVariantList: (callback) => {
      if (!deliveryControl.deliveryVariantLoadingCheck())
        return;
      
      const variantList = state.deliveryVariantList;
      
      const deliveryVariant = state.deliveryVariant;
      const deliveryVariantTariff = state.deliveryVariantTariff;
      
      if (!variantList.length) {
        if (deliveryVariant || deliveryVariantTariff)
          setTimeout(() => setState((state) => ({ ...state, deliveryVariant: null, deliveryVariantTariff: null })), 1);
        
        return;
      }
      
      if (!deliveryVariant || !variantList.find((item) => item.key === deliveryVariant.key)) {
        setTimeout(() => setState((state) => ({ ...state, deliveryVariant: variantList[0], deliveryVariantTariff: variantList[0].tariffs[0] })), 1);
      } else if (!deliveryVariantTariff || !deliveryVariant.tariffs.find((item) => item.key === deliveryVariantTariff.key)) {
        setTimeout(() => setState((state) => ({ ...state, deliveryVariantTariff: deliveryVariant.tariffs[0] })), 1);
      }
      
      return variantList.map((item, index) => callback({
        ...item,
        
        tariffList: (callback) => item.tariffs.map((tariffItem, tariffndex) => callback(tariffItem, tariffndex))
      }, index));
    },
    
    deliveryVariant: state.deliveryVariant,
    
    deliveryVariantTariff: state.deliveryVariantTariff,
    
    setDeliveryVariantTariff: (key) => {
      const deliveryVariantList = state.deliveryVariantList;
      
      if (!deliveryVariantList?.length)
        return;
      
      let variant = null;
      let tariff = null;
      
      deliveryVariantList.some((item) => {
        tariff = item.tariffs.find((tariffItem) => tariffItem.key === key)
        
        if (tariff)
          variant = item;
        
        return tariff;
      });
      
      if (!tariff)
        return;
      
      setState((state) => ({ ...state, deliveryVariant: variant, deliveryVariantTariff: tariff }));
    },
    
    // pickup
    
    isPickupPointLoading: state.pickupPointLoading || false,
    
    pickupPointCount: state.pickupPointList?.length || 0,
    
    pickupPointList: (callback, searchFilter) => {
      const addressData = state.addressData;
      
      if (!addressData || deliveryControl.isPickupPointLoading || state.methodChecked !== 'pickup')
        return;
      
      let pointsList = state.pickupPointList;
      
      if (!pointsList) {
        if (!state.pickupMapDisplay)
          return;
        
        let update = pickupPointUpdate;
        
        deliveryControl.isPickupPointLoading = true;
        
        setTimeout(() => setState((state) => ({ ...state, pickupPointLoading: true })), 1);
        
        if (pickupPointFetchInterval)
          clearInterval(pickupPointFetchInterval);
        
        pickupPointFetchInterval = setInterval(() => {
          const newUpdate = pickupPointUpdate;
          
          if (update !== newUpdate) {
            update = newUpdate;
            
            return;
          }
          
          clearInterval(pickupPointFetchInterval);
          pickupPointFetchInterval = null;
          
          const city = { value: addressJoin('city', addressData), data: addressData };
          
          fetchList(l, deliveryControl.method, city)
            .then((list) => setState((state) => ({ ...state, pickupPointLoading: false, pickupPointList: update === pickupPointUpdate ? list : state.pickupPointList })))
            .catch((error) => console.log(error) || setState((state) => ({ ...state, pickupPointLoading: false, pickupPointList: update === pickupPointUpdate ? [] : state.pickupPointList })));
        }, PICKUP_POINTS_FETCH_TIMEOUT);
        
        return;
      }
      
      const pickupPoint = state.pickupPoint;
      const pickupPointTariff = state.pickupPointTariff;
      
      if (!pointsList.length) {
        if (pickupPoint || pickupPointTariff)
          setTimeout(() => setState((state) => ({ ...state, pickupPoint: null, pickupPointTariff: null, pickupMapPointModal: false, pickupMapPoint: null, pickupMapPointTariff: null })), 1);
        
        return;
      }
      
      if (!pickupPoint || !pointsList.find((item) => item.key === pickupPoint.key)) {
        setTimeout(() => setState((state) => ({ ...state, pickupPoint: pointsList[0], pickupPointTariff: pointsList[0].tariffs[0], pickupMapPointModal: true, pickupMapPoint: pointsList[0], pickupMapPointTariff: pointsList[0].tariffs[0] })), 1);
      } else if (!pickupPointTariff || !pickupPoint.tariffs.find((item) => item.key === pickupPointTariff.key)) {
        setTimeout(() => setState((state) => ({ ...state, pickupPointTariff: pickupPoint.tariffs[0] })), 1);
      }
      
      if (Array.isArray(searchFilter) && searchFilter.length) {
        searchFilter = searchFilter
          .flatMap((item) => item)
          .map((item) => (String(item || '').trim() || null)?.split(/[^a-zа-яё0-9]+/i))
          .flatMap((item) => item)
          .filter((item) => item)
          .map((item) => new RegExp(filter.regexp(item), 'i'));
        
        pointsList = pointsList.map((item) => {
          let found = false;
          
          PICKUP_POINT_SEARCH_FIELDS.forEach((field) => {
            const highlight = item[field + 'Highlight'] = [String((field === 'type' ? item.type?.title : item[field]) || '').trim()];
            
            if (!highlight[0].length)
              return;
            
            found = searchFilter.every((filterItem, index) => {
              let filterFound = false;
              
              for (let h = 0; h < highlight.length; h++) {
                const exec = filterItem.exec(highlight[h]);
                
                if (!exec)
                  continue;
                
                highlight[h] = exec.input.substring(0, exec.index);
                highlight.push((<Typography key={'search-' + field + '-' + (h + 1) + '-' + (index + 1)} color="secondary" variant="string">{exec.input.substring(exec.index, exec.index + exec[0].length)}</Typography>));
                highlight.push(exec.input.substring(exec.index + exec[0].length));
                
                filterFound = true;
                h += 2;
              }
              
              return filterFound;
            }) || found;
          });
          
          return found ? item : null;
        }).filter((item) => item);
      }
      
      return pointsList.map((item, index) => callback({
        ...item,
        
        point: state.pickupMapPoint,
        setPoint: () => setState((state) => state.pickupMapPoint === item ? state : { ...state, pickupMapPoint: item, pickupMapPointTariff: item.tariffs[0] }),
        
        tariff: state.pickupMapPointTariff,
        setTariff: (key) => setState((state) => ({ ...state, pickupMapPointTariff: state.pickupMapPoint?.tariffs.find((item) => item.key === key) })),
        tariffList: (callback) => state.pickupMapPoint?.tariffs.map((tariffItem, index) => callback(tariffItem, index))
      }, index));
    },
    
    pickupPointTariffList: (callback) => {
      return state.pickupPoint?.tariffs.map((tariffItem, index) => callback(tariffItem, index));
    },
    
    pickupPoint: state.pickupPoint,
    
    pickupPointTariff: state.pickupPointTariff,
    
    setPickupPointTariff: (key) => {
      const find = state.pickupPoint?.tariffs.find((item) => item.key === key);
      
      if (!find)
        return;
      
      setState((state) => ({ ...state, pickupPointTariff: find }));
    },
    
    pickupPointDetails: (callback) => {
      const pickupPoint = state.pickupPoint;
      
      if (!pickupPoint)
        return;
      
      return callback({
        ...pickupPoint,
        
        tariffList: (callback) => pickupPoint?.tariffs.map((tariffItem, index) => callback(tariffItem, index))
      });
    },
    
    pickupPointSearchValue: (state.pickupPointSearchValue?.trim() || null)?.split(/[^a-zа-яё0-9]+/i),
    
    pickupPointSearchProps: () => {
      return {
        value: state.pickupPointSearchValue || '',
        onChange: (event) => {
          const value = event?.target ? event.target.value : String(event || '');
          
          setState((state) => ({ ...state, pickupPointSearchValue: value, pickupMapPointModal: false, pickupMapPoint: null, pickupMapPointTariff: null }));
        }
      };
    },
    
    pickupPointSearchList: (callback) => {
      return deliveryControl.pickupPointList(callback, deliveryControl.pickupPointSearchValue);
    },
    
    pickupMapDisplay: !!state.pickupMapDisplay,
    
    setPickupMapDisplay: (value) => setState((state) => ({ ...state, pickupMapDisplay: value })),
    
    pickupMapConfirm: () => {
      const pickupMapPoint = state.pickupMapPoint;
      const pickupMapPointTariff = state.pickupMapPointTariff;
      
      if (!pickupMapPoint || !pickupMapPointTariff)
        return;
      
      setState((state) => ({ ...state, pickupPoint: pickupMapPoint, pickupPointTariff: pickupMapPointTariff, pickupMapDisplay: false }));
    },
    
    pickupMapProps: () => {
      const children = (
        <Map type="clusterer">
          {state.pickupPointList?.map((item) => (
            <Map
              key={item.key}
              type="marker"
              position={item}
              hint={item.address || item.title}
              onClick={() => setState((state) => ({ ...state, pickupMapPointModal: true, pickupMapPoint: item, pickupMapPointTariff: item.tariffs[0] }))}
              icon={item.icon}
            />
          ))}
        </Map>
      );
      
      return {
        center:  state.pickupMapPoint,
        zoom: state.pickupMapPoint && (MAP_DELIVERY_POINT_ZOOM + Math.random() * 0.01),
        defaultCenter: state.pickupPointList?.[0] || { lat: state.addressData?.geo_lat, lng: state.addressData?.geo_lon },
        defaultZoom: 12,
        children
      };
    },
    
    pickupMapPointModal: (callback) => {
      const pickupMapPoint = state.pickupMapPoint;
      
      if (!pickupMapPoint || !state.pickupMapPointModal || !state.pickupMapDisplay)
        return;
      
      return callback({
        ...pickupMapPoint,
        
        onConfirm: () => setState((state) => ({ ...state, pickupPoint: pickupMapPoint, pickupPointTariff: state.pickupMapPointTariff, pickupMapDisplay: false })),
        onClose: () => setState((state) => ({ ...state, pickupMapPointModal: false })),
        
        tariff: state.pickupMapPointTariff,
        setTariff: (key) => setState((state) => ({ ...state, pickupMapPointTariff: pickupMapPoint.tariffs.find((item) => item.key === key) })),
        tariffList: (callback) => pickupMapPoint.tariffs.map((tariffItem, index) => callback(tariffItem, index))
      });
    },
    
    // personal
    
    personalData: state.personalData,
    
    personalProps: (type) => {
      const resultProps = {
        disabled: state.orderCreateLoading,
        value: state.personalData?.[type] || '',
        onChange: (event) => {
          const value = event?.target ? event.target.value : String(event || '');
          
          setState((state) => {
            state = { ...state };
            
            if (!state.personalData)
              state.personalData = {};
            
            state.personalData[type] = value;
            
            return state;
          })
        }
      };
      
      return resultProps;
    },
    
    // order
    
    orderCreate: (cartStore) => createOrder(state, cartStore?.buildApiList())
  };
  
  return (
    <DeliveryContext.Provider value={deliveryControl}>
      {children}
    </DeliveryContext.Provider>
  );
});

export const useDeliveryContext = () => {
  const context = React.useContext(DeliveryContext);
  
  if (!context)
    throw new Error('useDeliveryContext should be used inside the DeliveryProvider');
  
  return context;
};

export const genMapIcon = (icon) => icon
  ? { ...MAP_DELIVERY_ICON, url: icon }
  : MAP_STORE_ICON;

export const genDeliveryTime = (l, daysMin, daysMax) => (
    daysMin === 0 && daysMax === 0 ? (
      'в день заказа'
    ) : !daysMin || !daysMax ? (
      l('r._.cartstep.time.unavailable')
    ) : daysMin === daysMax ? (
      l(['r._.cartstep.time.days', daysMax], daysMax)
    ) : (
      l('r._.cartstep.time.fromtodays', daysMin, daysMax)
    )
  );

export const fetchList = async (l, method, city) => {
  let apiMethod;
  
  if (method === DELIVERY_TYPES.DELIVERY) {
    apiMethod = API_METHOD.DELIVERY_LIST;
  } else if (method === DELIVERY_TYPES.PICKUP) {
    apiMethod = API_METHOD.PICKUP_LIST;
  } else {
    return [];
  }
  
  const data = await api.request(apiMethod, { timeout: 60000, city });
  
  //console.log(data);
  
  const list = [];
  
  if (method === DELIVERY_TYPES.DELIVERY) {
    if (data.apiship?.length) {
      // apiship
      
      list.push(...data.apiship.map((item, index) => ({
        key: 'delivery-apiship-' + item.provider?.key,
        source: 'apiship',
        id: item.provider?.key,
        code: item.provider?.key,
        title: item.provider?.name,
        description: item.provider?.description,
        providerKey: item.provider?.key,
        providerName: item.provider?.name,
        icon: genMapIcon(item.provider?.logo_path),
        
        tariffs: item.tariffs
          .sort((a, b) => a.deliveryCost.value - b.deliveryCost.value)
          .map((tariff) => ({
            key: 'delivery-tariff-apiship-' + item.provider?.key + '-' + tariff.tariffId,
            id: tariff.tariffId,
            name: tariff.tariffName,
            price: tariff.deliveryCost.value,
            currency: tariff.deliveryCost.currency.id,
            daysMin: tariff.daysMin || null,
            daysMax: tariff.daysMax || null,
            deliveryTime: genDeliveryTime(l, tariff.daysMin, tariff.daysMax)
          }))
      })));
    } else if (data.ritos?.length) {
      // ritos
      
      list.push(...data.ritos.map((item, index) => ({
        key: 'delivery-ritos-' + item.type?.code,
        source: 'ritos',
        id: item.type?.code,
        code: item.type?.code,
        title: item.type?.title,
        description: item.type?.note,
        providerKey: item.type?.code,
        providerName: item.type?.title,
        icon: genMapIcon(),
        
        tariffs: [
          {
            key: 'delivery-tariff-ritos-' + item.type?.code + '-0',
            id: item.type?.code,
            name: null,
            price: item.cost.value,
            currency: item.cost.currency.id,
            daysMin: item.daysMin || null,
            daysMax: item.daysMax || null,
            deliveryTime: genDeliveryTime(l, item.daysMin, item.daysMax)
          }
        ]
      })));
    }
  } else if (method === DELIVERY_TYPES.PICKUP) {
    if (!pointTypeList)
      pointTypeList = api.request('delivery/points/type_list', {});
    
    const pointTypeMap = ((await pointTypeList)?.list || []).reduce((result, item) => (result[item.id] = { ...item }) && result, {});
    
    await Promise.all(Object.values(pointTypeMap).map((item) => item.title = l(item.title)));
    
    
    // ritos
    
    list.push(...data.ritos.sort((a, b) => a.cost.value - b.cost.value).map((item) => ({
      key: 'pickup-ritos-' + item.id,
      source: 'ritos',
      id: item.id,
      code: item.code,
      title: item.title,
      description: item.description,
      address: item.address,
      providerKey: item.provider?.key,
      providerName: item.provider?.name,
      timetable: item.timetable,
      icon: genMapIcon(),
      lat: item.lat,
      lng: item.lng,
      
      type: pointTypeMap[item.type?.id === 4 ? 102
          : item.type?.id === 5 ? 103
          : 101] 
        || pointTypeMap[101]
        || { id: 101 },
      
      tariffs: [
        {
          key: 'pickup-tariff-ritos-' + item.id + '-0',
          id: item.id,
          name: null,
          price: item.cost.value,
          currency: item.cost.currency.id,
          daysMin: item.daysMin || null,
          daysMax: item.daysMax || null,
          deliveryTime: genDeliveryTime(l, item.daysMin, item.daysMax)
        }
      ]
    })));
    
    // apiship
    
    list.push(...data.apiship.flatMap((item) =>
      item.points.map((point) => ({
        key: 'pickup-apiship-' + point.id,
        source: 'apiship',
        id: point.id,
        code: point.code,
        title: point.name,
        description: point.description,
        address: point.address,
        providerKey: item.provider?.key,
        providerName: item.provider?.name,
        timetable: point.timetable,
        icon: genMapIcon(item.provider?.logo_path),
        lat: point.lat,
        lng: point.lng,
        
        type: pointTypeMap[point.type] 
          || pointTypeMap[1]
          || { id: point.type || 1 },
        
        tariffs: item.tariffs
          .filter((tariff) => point.tariffIds.indexOf(tariff.tariffId) >= 0)
          .map((tariff) => ({
            key: 'pickup-tariff-apiship-' + point.id + '-' + tariff.tariffId,
            id: tariff.tariffId,
            name: tariff.tariffName,
            price: tariff.deliveryCost.value,
            currency: tariff.deliveryCost.currency.id,
            daysMin: item.daysMin || null,
            daysMax: item.daysMax || null,
            deliveryTime: genDeliveryTime(l, item.daysMin, item.daysMax)
          }))
      }))
    ));
  }
  
  return list;
};

export const createOrder = (state, goods) => {
  const method = state.methodChecked;
  
  const addressData = state.addressData;
  
  const param = {
    currency: REPLACE_CURRENCY.SHOP_ORDER,
    delivery: {
      user_info: state.personalData,
      delivery_info: { value: addressJoin('address', addressData), data: addressData }
    }
  };
  
  if (goods)
    param.goods = goods;
  
  if (method === DELIVERY_TYPES.DELIVERY) {
    const deliveryVariant = state.deliveryVariant;
    const deliveryVariantTariff = state.deliveryVariantTariff;
    
    if (!deliveryVariant || !deliveryVariantTariff)
      return null;
    
    param.delivery.type = deliveryVariant.source;
    
    if (deliveryVariant.source === 'ritos') {
      param.delivery.ritos_delivery = method;
      param.delivery.delivery_code = deliveryVariant.code;
    } else {
      param.delivery.apiship_info = {
        delivery_type: 1,
        provider_key: deliveryVariant.providerKey,
        tariff_id: deliveryVariantTariff.id
      };
    }
  } else if (method === DELIVERY_TYPES.PICKUP) {
    const pickupPoint = state.pickupPoint;
    const pickupPointTariff = state.pickupPointTariff;
    
    if (!pickupPoint || !pickupPointTariff)
      return null;
    
    param.delivery.type = pickupPoint.source;
    
    if (pickupPoint.source === 'ritos') {
      param.delivery.ritos_delivery = method;
      param.delivery.store_id = pickupPoint.id;
    } else {
      param.delivery.apiship_info = {
        delivery_type: 2,
        provider_key: pickupPoint.providerKey,
        point_out_id: pickupPoint.id,
        tariff_id: pickupPointTariff.id
      };
    }
  } else {
    return null;
  }
  
  return api.request(API_METHOD.ORDER_CREATE, param);
};


let deliveryVariantUpdate = 0;
let pickupPointUpdate = 0;
let deliveryVariantFetchInterval = null;
let pickupPointFetchInterval = null;

const useAddressStorage = (state, setState) => {
  const storeData = React.useCallback(lodash.debounce((id, addressData) => {
    if (!addressData)
      return;
    
    setValue(STORAGE_KEY.ADDRESS, { ...addressData, id }, { prefixKey: false, encode: false });
  }, STORE_TIMEOUT), []);
  
  const visitorInfo = useVisitorInfo();
  
  React.useEffect(() => {
    if (!visitorInfo.info)
      return;
    
    const addressData = getValue(STORAGE_KEY.ADDRESS, { prefixKey: false, encode: false });
    
    if (!addressData || addressData?.id !== visitorInfo.info.id)
      return;
    
    delete addressData.id;
    
    setState((state) => {
      return {
        ...state,
        
        addressData,
        cityAddressData: { value: addressJoin('city', addressData), data: addressData },
        streetAddressData: addressData.street_with_type ? { value: addressJoin('street', addressData), data: addressData } : null,
        houseAddressData: addressData.house ? { value: addressJoin('house', addressData), data: addressData } : null
      };
    });
  }, [visitorInfo.info]);
  
  React.useEffect(() => {
    storeData(visitorInfo.info?.id, state.addressData);
  }, [state]);
};

const usePersonalStorage = (state, setState) => {
  const storeData = React.useCallback(lodash.debounce((personalData) => {
    if (!personalData)
      return;
    
    setValue(STORAGE_KEY.PERSONAL, personalData, { prefixKey: false, encode: false });
  }, STORE_TIMEOUT), []);
  
  const visitorInfo = useVisitorInfo();
  
  React.useEffect(() => {
    if (!visitorInfo.info)
      return;
    
    let personalData = state.personalData;
    
    if (!personalData)
      personalData = getValue(STORAGE_KEY.PERSONAL, { prefixKey: false, encode: false });
    
    if (personalData?.id !== visitorInfo.info.id)
      personalData = null;
    
    if (!personalData) {
      personalData = {
        id: visitorInfo.info.id,
        lastname: visitorInfo.info.lastname,
        firstname: visitorInfo.info.firstname,
        phone: visitorInfo.info.mobile || visitorInfo.phone,
        email: visitorInfo.info.email
      };
    }
    
    setState((state) => {
      return {
        ...state,
        
        personalData
      };
    });
  }, [visitorInfo.info]);
  
  React.useEffect(() => {
    storeData(state.personalData);
  }, [state]);
};
