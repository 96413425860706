import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { FormControlLabel } from '@material-ui/core'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'

// style
import { useServiceCountStyle } from './serviceCountStyle'
import { CountInput } from '_core/components/CountInput'
import { CountInputHelperText } from '_core/components/CountInputHelperText'
import { getProductCustomField } from '_core/utils/getProductCustomField'
import { StepsControls } from '_core/components/StepsControls'
import { Loading } from '_core/components/Loading'

const RawServiceCount = props => {
  const {
    cache,
    goNext,
    controlsProps,
    setCache,
    countLabel,
    goodsData,
    getLangString: l,
  } = props

  const classes = useServiceCountStyle()

  const {
    request: { queue },
    config: { API_METHOD },
  } = useGlobal()

  const [isLoading, setIsLoading] = useState(false)
  const rawMinCount = getProductCustomField(goodsData.goods, 'min_count')
  const rawMaxCount = getProductCustomField(goodsData.goods, 'max_count')
  const minCount = rawMinCount > 0 ? rawMinCount : 1
  const maxCount = rawMaxCount > 0 ? rawMaxCount : undefined

  const [canSubmit, setCanSubmit] = useState(true)
  const [count, setCount] = useState(
    cache.count != null ? cache.count : minCount
  )

  const countHelperTextProps = {
    minCount,
    maxCount,
    isValid: canSubmit,
  }

  const onChange = newCount => {
    if (/[^0-9]/.test(newCount)) {
      return
    }

    setCanSubmit(
      (minCount == null || newCount >= minCount) &&
        (maxCount == null || newCount <= maxCount)
    )

    setCount(newCount)
  }

  const createDoc = () =>
    queue(
      API_METHOD.ORDER_CREATE,
      {
        shop: 'start',
        goods: [{ goods_id: parseInt(goodsData.id, 10), count }],
      },
      RawServiceCount.displayName
    )

  const onNextStep = () => {
    setIsLoading(true)
    setCanSubmit(false)
    createDoc().then(response => {
      const data = {
        goodsData,
        order: { ...response.order },
      }
      setIsLoading(false)
      setCache(data)
      goNext(data)
    })
  }

  return isLoading ? (
    <Loading progressProps={{ size: 36 }} />
  ) : (
    <>
      <FormControlLabel
        labelPlacement="start"
        label={l(countLabel)}
        error={!canSubmit}
        classes={{
          root: classes.field,
          label: classes.label,
        }}
        control={
          <CountInput
            error={!canSubmit}
            min={minCount}
            max={maxCount}
            value={count}
            onChange={onChange}
            helperText={<CountInputHelperText {...countHelperTextProps} />}
          />
        }
      />
      <StepsControls onNextStep={onNextStep} {...controlsProps} />
    </>
  )
}

RawServiceCount.defaultProps = {
  valueLabel: 'r._.shopping.price',
  countLabel: 'r._.shopping.goodscount',
  helperText: 'r._.shopping.itemprice',
}

RawServiceCount.propTypes = {
  // self props
  data: PropTypes.object,
  initialPage: PropTypes.number,
  initialRows: PropTypes.number,
  inRowLg: PropTypes.number,
  inRowMd: PropTypes.number,
  inRowSm: PropTypes.number,
  inRowXs: PropTypes.number,
  hasTitle: PropTypes.bool,
  hasPaging: PropTypes.bool,
  hasPagingTop: PropTypes.bool,
  hasPagingBottom: PropTypes.bool,
  requestParams: PropTypes.object,
  renderEmpty: PropTypes.func,
  renderError: PropTypes.func,
  emptyMessage: PropTypes.string,
  failureMessage: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderItem: PropTypes.func,
  ItemComponent: PropTypes.elementType,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ServiceCount = withLang(RawServiceCount)
