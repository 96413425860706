import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { Price } from '_core/components/Price'
import { PriceInput } from '_core/components/PriceInput'

const RawPriceFormTax = props => {
  const {
    tax,
    value,
    label,
    helperText,
    isValid,
    currency,
    getLangString: l,
  } = props

  if (tax == null) {
    return null
  }

  const helper = (
    <>
      {l(helperText)}: <Price value={tax * value} currency={currency} />
    </>
  )

  return (
    <PriceInput
      currency={currency}
      label={l(label, tax * 100)}
      value={isValid ? value * (1 + tax) : ''}
      helperText={helper}
      disabled={true}
    />
  )
}

RawPriceFormTax.defaultProps = {
  label: 'r._.shopping.payment.itemtaxlabel',
  helperText: 'r._.shopping.payment.itemtaxhelper',
}

RawPriceFormTax.propTypes = {
  // self props
  isValid: PropTypes.bool,
  value: PropTypes.number.isRequired,
  tax: PropTypes.number,
  label: PropTypes.string,
  helperText: PropTypes.string,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceFormTax = withLang(RawPriceFormTax)
