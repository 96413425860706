import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('b', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <b>{this.getComponents()}</b>
    );
  }
  
});
