import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

// core
import { withLang } from '_core/hocs/withLang'
import { PriceInput } from '_core/components/PriceInput'
import { ClipboardInput } from '_core/components/ClipboardInput'
import { ArticleContainer } from '_core/components/ArticleContainer'

// style
import { paymentConfirmStyle } from 'basic/views/PaymentConfirm/paymentConfirmStyle'

const RawPaymentResultQuick = props => {
  const { classes, data, getLangString: l } = props
  const { amount, method, quick, message, isTopup } = data

  const amountLabel = `r.core.finance.topup.label.${
    isTopup ? 'topupvalue' : 'purchasevalue'
  }`

  return (
    <>
      <PriceInput
        label={l(amountLabel)}
        value={amount.value}
        currency={amount.currency.id}
        disabled={true}
      />

      <ClipboardInput
        render={childProps => <PriceInput {...childProps} />}
        label={l('r._.finance.topup.label.paymentvalue')}
        value={method.payment.amount.value}
        currency={method.payment.amount.currency.id}
        className={classes.valueInput}
        variant="outlined"
      />

      <Alert severity="info" className={classes.warning}>
        <AlertTitle>
          <Typography variant="h6">
            {l('r._.finance.quick.warningtitle')}
          </Typography>
        </AlertTitle>

        {message != null ? (
          <ArticleContainer variant="body1">{message}</ArticleContainer>
        ) : (
          l('r._.finance.quick.warningbody')
        )}
      </Alert>

      <ClipboardInput
        label={l('r._.finance.quick.label.account')}
        value={quick.account}
      />

      <ClipboardInput
        label={l('r._.finance.quick.label.note')}
        value={quick.note}
      />
    </>
  )
}

RawPaymentResultQuick.defaultProps = {}

RawPaymentResultQuick.propTypes = {
  // self props
  data: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentResultQuick = withLang(
  withStyles(paymentConfirmStyle)(RawPaymentResultQuick)
)
