import { langSwitchStyle } from '_core/components/LangSwitch/langSwitchStyle'

export const balancePopupStyle = theme => ({
  ...langSwitchStyle(theme),

  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',

    '&$hasValue': {
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

    '&$inMenu': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },

      [theme.breakpoints.down('xs')]: {
        flexGrow: 'initial',
      },

      '&$hasValue': {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 0),
        },
      },
    },
  },

  inMenu: {},

  hasValue: {},

  wrapper: {
    '$hasValue &': {
      maxWidth: theme.spacing(40),
    },

    '$inMenu$hasValue &': {
      width: 'auto',
    },
  },

  paper: {
    minWidth: theme.spacing(100),

    [`${theme.breakpoints.down('md')}`]: {
      minWidth: theme.spacing(75),
      maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    },

    [`${theme.breakpoints.down('xs')}`]: {
      minWidth: 'auto',
      maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },

  progress: {
    width: 'auto',

    '&$hasValue': {
      width: '100%',
    },
  },
})
