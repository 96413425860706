export const sidebarItem = theme => ({
  whiteSpace: 'normal',

  '& *': {
    zIndex: 6,
  },

  '&::before': {
    zIndex: 5,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(90deg, ${theme.variables.colors.sidebarItemsActive} 0%, transparent 40%)`,
    transform: 'translateX(-100%)',
  },

  '&:hover': {
    background: 'none',

    '&::before': {
      transform: 'translateX(0)',
    },
  },
})
