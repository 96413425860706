// @material-ui
import { makeStyles } from '@material-ui/styles';

export const style = (theme) => ({
  input: {
    '& .MuiButton-root': {
      border: 0,
    },
    '& .MuiButton-root:hover': {
      background: 'transparent',
    },
  },

  dropdown: {
    '& .dial-code': {
      marginLeft: theme.spacing(),
    },
  },

  button: {
    boxShadow: 'none',
    background: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },
  },
});

export const useStyle = makeStyles(style);
