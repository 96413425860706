import React from 'react'
import PropTypes from 'prop-types'

// core
import { NUMBER_FORMAT_PROPS } from 'basic/config'
import { TextInputBase } from '_core/components/TextInputBase'
import { NumberFormatBase } from '_core/components/NumberFormatBase'

export const FormattedInputBase = props => {
  const {
    mask,
    type,
    prefix,
    suffix,
    inputMode,
    decimalScale,
    fixedDecimalScale,
    decimalSeparator,
    thousandSeparator,
    allowNegative,
    allowLeadingZeros,
    allowEmptyFormatting,
    allowedDecimalSeparators,
    ...rest
  } = props

  return (
    <TextInputBase
      {...rest}
      inputProps={{
        inputComponent: NumberFormatBase,
        inputProps: {
          mask,
          type,
          prefix,
          suffix,
          inputMode,
          decimalScale,
          fixedDecimalScale,
          decimalSeparator,
          thousandSeparator,
          allowNegative,
          allowLeadingZeros,
          allowEmptyFormatting,
          allowedDecimalSeparators,
        },
      }}
    />
  )
}

FormattedInputBase.defaultProps = NUMBER_FORMAT_PROPS

FormattedInputBase.propTypes = {
  mask: PropTypes.string,
  type: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  allowLeadingZeros: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
}
