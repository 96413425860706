import { makeStyles } from '@material-ui/styles';


export const useStyle = makeStyles((theme) => ({
  divider: {
    ...theme.mixins.sidebarDivider,
  },
  
  list: {
    padding: 0,
  },
  
  group: {},

  groupActive: {
    backgroundColor: theme.variables.colors.sidebarGroupBgActive,
  },
  
  item: {
    ...theme.mixins.sidebarItem,

    '$group $list &': {
      fontSize: theme.typography.caption.fontSize,

      '$group $list &': {
        fontSize: theme.typography.overline.fontSize,
      },
    },
  },

  active: {},

  child: {
    ...theme.mixins.sidebarItem,

    transition: [
      theme.transitions.create('paddingLeft', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
      }),
    ],

    '$group $list &': {
      paddingLeft: theme.spacing(5),

      '$isMini &': {
        paddingLeft: theme.spacing(2.5),
      },

      '$group $list &': {
        paddingLeft: theme.spacing(8),

        '$isMini &': {
          paddingLeft: theme.spacing(2.75),
        },
      },
    },
  },

  childActive: {
    ...theme.mixins.sidebarItemActive,
  },
  
  text: {
    whiteSpace: 'initial',
  },
  secondarytext: {
    paddingLeft: 10,
    fontSize: theme.typography.overline.fontSize,
  },
  
  toggle: {
    minWidth: 'auto',
  },
  
  icon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),

    transition: [
      theme.transitions.create('marginRight', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
      }),
    ],

    '& img': {
      width: '1.8rem',
      height: '1.8rem'
    },

    '& .MuiIcon-root, & .MuiSvgIcon-root': {
      fontSize: '1.8rem',
    },

    '$group $list &': {
      marginRight: theme.spacing(1.5),

      '$isMini &': {
        marginRight: theme.spacing(2.5),
      },

      '& .MuiIcon-root, & .MuiSvgIcon-root': {
        fontSize: '1.3rem',
      },

      '$group $list &': {
        marginRight: theme.spacing(),

        '$isMini &': {
          marginRight: theme.spacing(2.5),
        },

        '& .MuiIcon-root, & .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
    },
  },
  
  skeleton: {
  }
}));
