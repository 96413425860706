import fnv from 'fnv-lite';

import BigNumber from 'bignumber.js';


// BigNumber

BigNumber.config({ DECIMAL_PLACES: 30, ROUNDING_MODE: BigNumber.ROUND_HALF_EVEN, EXPONENTIAL_AT: 1e+9, FORMAT: { decimalSeparator: '.', groupSeparator: '' } });

// App defaults

export const APP_ID = fnv.hex(`${Date.now()}`)

// Static urls

export const FORM_STRUCT_PREFIX = process.env.NODE_ENV === 'production' ? 'forms/' : 'assets/forms/';
export const FORM_STRUCT_SUFFIX = '.json'

export const TABLE_STRUCT_PREFIX = process.env.NODE_ENV === 'production' ? 'tables/' : 'assets/tables/';
export const TABLE_STRUCT_SUFFIX = '.json'

export const VISITOR_PHOTO_PREFIX = '/data/files'

export const PROCESSED_STATIC_PREFIX = '/static'

// API urls

export const API_SERVER = '';

export const API_REQUEST_URI = '/office-data/request.json';

export const API_UPLOAD_URI = '/office-data/upload.json';

export const API_LISTEN_URI = '/office-data/notifications.json';

export const API_LISTEN_REQUIRED = true;

export const API_LISTEN_INTERVALS = {
  first: 500, // 0.5 sec
  active: 5000, // 5 sec
  activeAccel: 1500, // 1.5 sec
  activeError: 10000, // 10 sec
  inactive: 300000, // 5 min
  inactiveAccel: 5000, // 5 sec
  inactiveError: 300000, // 5 min
  inactiveTimeout: 60000 // 1 min
};

// API delays

export const PAGE_LOAD_DELAY = 250
export const PAGE_LOAD_TIMEOUT = 1000

export const FETCH_RETRY_DELAY = 2000
export const FETCH_SYNC_RETRY_DELAY = FETCH_RETRY_DELAY

export const FETCH_TIMEOUT = 2000
export const FETCH_SYNC_TIMEOUT = 0

export const FETCH_EFFORTS = 3
export const FETCH_SYNC_EFFORTS = FETCH_EFFORTS

export const FETCH_NOTICE_MIN_DELAY = 2000;
export const FETCH_NOTICE_MAX_DELAY = 120000;
export const FETCH_NOTICE_INACTIVE_COUNT = 30;
export const FETCH_NOTICE_COOLING_DOWN_COUNT = 30;

export const CART_UPDATE_DEBOUNCE_DELAY = 1000

// API methods

export const API_METHOD = {
  // API get methods

  THEME: 'office/theme/list',
  SETTINGS: 'office/settings/values',

  ARTICLE: 'portal/article/list',
  ARTICLE_INFO: 'portal/article/info',

  FORM_CHECK: 'client/form/check',

  LANG_LIST: 'client/lang/list',
  LANG_STRINGS: 'client/lang/strings',
  
  LINK_GENERATE: 'client/link/generate',

  MONEY_ACCOUNT_TYPE: 'money/account/list',
  MONEY_BALANCE: 'money/account/balance',

  CURRENCY: 'money/currency/list',
  COUNTRY: 'client/address/country_list',
  CITY_SUGGEST: 'client/address/city_suggest',

  VISITOR_INFO: 'client/visitor/info',
  VISITOR_LINK: 'client/visitor_link/list',
  VISITOR_ACCOUNT: 'money/visitor/accounts',
  VISITOR_STRUCTURE: 'client/visitor/structure/list',
  VISITOR_STRUCTURE_INFO: 'client/visitor/structure/info',
  VISITOR_STRUCTURE_ORDERS: 'client/visitor/structure/orders/list',
  VISITOR_STRUCTURE_FIND: 'client/visitor/structure/find',
  VISITOR_INCOME: 'client/visitor/income',
  VISITOR_SEARCH: 'client/visitor/search',
  VISITOR_LOGIN_SEARCH: 'client/visitor/find/by_login',
  VISITOR_STRUCTURE_PARENTS: 'client/visitor/structure/parents',
  VISITOR_STATUS_TYPES: 'client/visitor/status/types',
  VISITOR_EVENT_LIST: 'client/visitor/event/list',
  
  VISITOR_MESSAGE_TOPICS: 'client/visitor/message/topics',
  VISITOR_MESSAGE_LIST: 'client/visitor/message/list',
  VISITOR_MESSAGE_FIND: 'client/visitor/message/find',
  VISITOR_MESSAGE_SEND: 'client/visitor/message/send',
  VISITOR_MESSAGE_LIST_UNREAD: 'client/visitor/message/list_unread',
  VISITOR_MESSAGE_HAD_READ: 'client/visitor/message/had_read',
  VISITOR_MESSAGE_POPUP_MESSAGES: 'client/visitor/message/popup_messages',
  VISITOR_MESSAGE_TOPIC_FIND: 'client/visitor/message/topic_find',

  LINEAR_STRUCTURE_HEAD: 'client/visitor/structure/work/head',
  LINEAR_STRUCTURE_LIST: 'client/visitor/structure/work/list',
  LINEAR_STRUCTURE_DETAILS: 'client/visitor/structure/work/details',
  LINEAR_STRUCTURE_EXPORT: 'client/visitor/structure/work/export',

  BINARY: 'client/visitor/binary/tree',
  BINARY_FIND: 'client/visitor/binary/find',
  BINARY_DIRECT: 'client/visitor/binary/direction',
  
  MATRIX: 'client/visitor/matrix/tree',
  MATRIX_FIND: 'client/visitor/matrix/find',

  MATRIX3: 'client/visitor/matrix3/programs',
  MATRIX3_NODE: 'client/visitor/matrix3/tree',

  GOODS: 'store/goods/list',
  GOODS_INFO: 'store/goods/info',
  GOODS_CATEGORY: 'store/goods/category/list',
  GOODS_CATEGORY_INFO: 'store/goods/category/info',

  // DOCUMENT: 'store/document/list',
  DOCUMENT_INFO: 'store/document/info',
  DOCUMENT_GOODS: 'store/document/goods/list',
  DOCUMENT_STATUS: 'store/document/status/list',

  PACKET_PURCHASED: 'store/packet/purchased_list',

  PAYMENT_REQUEST: 'money/account/payment/info',

  // INVESTMENT: 'classic/investment/list',
  INVESTMENT_STAT: 'classic/investment/numbers',
  // INVESTMENT_CAREER: 'classic/investment/next_career',
  // INVESTMENT_MORE_STAT: 'classic/investment/extended_info',
  // INVESTMENT_VOLUME: 'classic/investment/volume_info',
  // INVESTMENT_STRUCTURE: 'classic/investment/volume_structure',

  MONEY_TRANSMIT: 'money/account/transmit/list',
  MONEY_TRANSMIT_TYPE: 'money/account/transmit/types',
  MONEY_WITHDRAWAL: 'money/account/withdrawal/list',
  MONEY_WITHDRAWAL_METHOD: 'money/account/withdrawal/methods',
  // MONEY_TOPUP_TYPE: 'money/account/payment/types',
  MONEY_TOPUP_METHOD: 'money/account/payment/methods',
  MONEY_CURRENCY_CALCULATOR: 'money/currency/calculator',
  MONEY_CURRENCY_CHART: 'money/currency/graph',

  TICKET: 'ticket/query/list',
  TICKET_MESSAGE: 'ticket/query/message_list',
  TICKET_ITEM: 'ticket/query/item',

  CART: 'client/visitor/cart',
  ORDER: 'client/visitor/order',
  PURCHASE_LIST: 'client/visitor/order/list',

  DELIVERY_LIST: 'store/delivery/list',
  PICKUP_LIST: 'store/pickup/list',

  GOOGLE_MAP_KEY: 'office/google/api/key',

  AUCTION_BET: 'auction/visitor/state',
  AUCTION_GOODS: 'auction/lot/types',
  AUCTION_LOT: 'auction/lot/list',
  AUCTION_BALANCE: 'auction/click/balance',

  // API create methods

  VISITOR_CREATE: 'client/visitor/create',
  // VISITOR_INVITE: 'client/visitor/invitation_email',

  MONEY_TRANSMIT_CREATE: 'money/account/transmit/create',
  MONEY_EXCHANGE_CREATE: 'money/account/exchange/create',
  MONEY_WITHDRAWAL_CREATE: 'money/account/withdrawal/create',

  TICKET_CREATE: 'ticket/query/create',
  TICKET_READ: 'ticket/query/read',
  TICKET_MESSAGE_CREATE: 'ticket/query/message_create',

  DOCUMENT_CREATE: 'store/document/create',
  DOCUMENT_REMOVE: 'store/document/remove',
  PAYMENT_REQUEST_CREATE: 'money/account/payment/request',

  ORDER_CREATE: 'client/visitor/order/create',

  THEME_CREATE: 'office/admin/theme/create',

  AUCTION_BET_PLACE: 'auction/lots/add_bet',
  AUCTION_BET_CANCEL: 'auction/claim/cancel',
  AUCTION_PAY: 'auction/payment/begin',

  // API update methods

  LANG_CHANGE: 'client/lang/change',
  CURRENCY_CHANGE: 'client/currency/change',

  LOGIN: 'client/visitor/authorize',
  UNLOCK: 'client/visitor/unlock',
  LOGOUT: 'client/visitor/logout',

  VISITOR_EDIT: 'client/visitor/edit',
  // VISITOR_PHOTO_EDIT: 'client/visitor/edit_photo',
  // VISITOR_SUBSCRIBE: 'client/visitor/subscribe',
  VISITOR_EDIT_PASSWORD: 'client/visitor/passwd',
  VISITOR_EDIT_FINPASSWORD: 'client/security/change/finpasswd',
  VISITOR_EDIT_SMSCHECK: 'client/security/change/sms',
  // VISITOR_BECOME_PARTNER: 'classic/visitor/partner_become',
  // VISITOR_STRUCTURE_UPDATE: 'client/visitor/structure/data_set',

  VISITOR_VERIFICATION: 'client/verification/request/status',
  VISITOR_VERIFICATION_START: 'client/verification/request/create',
  VISITOR_VERIFICATION_CHECK: 'client/verification/request/check',

  CLIENT_SECURITY_INFO: 'client/security/info',
  CLIENT_SECURITY_START: 'client/security/start',
  CLIENT_SECURITY_CHANGE: 'client/security/change',
  CLIENT_SECURITY_CONFIRM: 'client/security/confirm',
  CLIENT_SECURITY_CONFIRM_CODE: 'client/security/confirm_code',
  CLIENT_SECURITY_CONFIRM_AUTHKEY: 'client/security/confirm_authkey',
  CLIENT_SECURITY_REMIND_PASSWORD: 'client/security/password_recovery',

  CART_UPDATE: 'client/visitor/cart/update',

  WITHDRAWAL_CANCEL: 'money/account/withdrawal/cancel',

  OBJECT_BY_CITY: 'shop/object/by_city',

  SERVICE_AGREEMENT: 'store/document/agreement',

  //CGCG
  SERVICE: 'cosmosgng/visitor/options',
  CGCG_SERVICE_AGREEMENT: 'cosmosgng/order/agreements',
  CGCG_SERVICE_AGREEMENT_CONFIRM: 'cosmosgng/visitor/save_confirmation',
  
  // admin
  THEME_UPDATE: 'office/admin/theme/edit'
}

// API notifications

export const API_NOTICE = {
  APP_CREATE: 'office/frontend/create',
  APP_RELOAD: 'office/frontend/reload',
  THEME_APPLY: 'office/theme/apply',

  CART_UPDATE: 'client/visitor/cart/update',

  VISITOR_EDIT: 'client/visitor/edit',
  VISITOR_LANG: 'client/visitor/lang',
  VISITOR_LOGIN: 'client/visitor/login',
  VISITOR_CHANGE: 'client/visitor/change',
  VISITOR_LOGOUT: 'client/visitor/logout',
  
  VISITOR_STATUS: 'classic/visitor/status',

  VISITOR_MESSAGE_CREATE: 'client/visitor/message/create',
  VISITOR_MESSAGE_HAD_READ: 'client/visitor/message/had_read',
  
  VISITOR_BALANCE: 'money/visitor/balance',

  TOPUP_STATUS: 'moneyin/request/status',
  TOPUP_CREATE: 'moneyin/request/create',

  PAYMENT_STATUS: 'moneyout/request/status',
  PAYMENT_CREATE: 'moneyout/request/create',

  TICKET_STATUS: 'ticket/request/status',
  TICKET_MESSAGE: 'ticket/request/message',

  AUCTION_LOTS_UPDATE: 'auction/lots/update',
  AUCTION_STATE_UPDATE: 'auction/state/update',
  AUCTION_BALANCE_UPDATE: 'auction/balance/update',

  //internal notices
  INSUFFICIENT_GOODS_QUANTITY: 'insufficient/goods/quantity',
  STORE_DOCUMENT_PAYMENT: 'store/document/payment',
}

// export const UI_NOTICE = {
//   ACCESS_DENY: 'access-deny',
// }

// API errors

export const API_INFO = {
  METHOD_BAD: 'method-bad',
}

export const API_ERROR = {
  METHOD_BAD: 'method-bad',
  METHOD_EMPTY: 'method-empty',
  METHOD_ERROR: 'method-error',
  METHOD_NOT_FOUND: 'method-not-found',

  INSUFFICIENT_GOODS_QUANTITY: 'insufficient-goods-quantity',

  DATA_WRONG: 'data-wrong',
  DATA_INCORRECT: 'data-incorrect',
  DATA_NOT_FOUND: 'data-not-found',

  INTERNAL_ERROR: 'internal-error',

  VISITOR_UNAUTHORIZED: 'visitor-unauthorized',
  ACCESS_DENY: 'access-deny',

  PROTECTION_AUTH_WRONG: 'protection-auth-wrong',
  PROTECTION_FINOP_WRONG: 'protection-finop-wrong',
}

// Access

export const ACCESS_RIGHT_GROUP = {
  PROFILE: 'profile',
  FINANCE: 'finance',
  SUPPORT: 'support',
}

export const ACCESS_RIGHT = {
  MONEY_TOPUP: `${ACCESS_RIGHT_GROUP.FINANCE}.money_topup`,
  MONEY_CONVERT: `${ACCESS_RIGHT_GROUP.FINANCE}.money_convert`,
  MONEY_TRANSMIT: `${ACCESS_RIGHT_GROUP.FINANCE}.money_transmit`,
  MONEY_EXCHANGE: `${ACCESS_RIGHT_GROUP.FINANCE}.money_exchange`,
  MONEY_WITHDRAW: `${ACCESS_RIGHT_GROUP.FINANCE}.money_withdraw`,
  CHANGE_: ``,
  CHANGE_PASSWORD: ``,
  CHANGE_AUTH_CHECK: ``,
  CHANGE_FINANCE_CHECK: ``,
}

export const SECURITY_CHECK_TYPE = {
  AUTH: 'auth',
  FINOP: 'finop',
}

export const SECURITY_CHECK_MODE = {
  SMS: 'sms',
  NONE: 'none',
  GAUTH: 'gauth',
  PASSWD: 'passwd',
  AUTHKEY: 'authkey',
  FINPASSWD: 'finpasswd',
}

export const SECURITY_CHECK_MODE_NAME = {
  [SECURITY_CHECK_TYPE.AUTH]: {
    [SECURITY_CHECK_MODE.NONE]: 'r.parol',
    // [SECURITY_CHECK_MODE.AUTHKEY]: 'r.parol.klyuch',
    // [SECURITY_CHECK_MODE.GAUTH]: 'r.parol.google.auth',
    // [SECURITY_CHECK_MODE.SMS]: 'r.parol.sms',
  },

  [SECURITY_CHECK_TYPE.FINOP]: {
    [SECURITY_CHECK_MODE.NONE]: 'r.bez.proverki',
    [SECURITY_CHECK_MODE.PASSWD]: 'r.parol.na.vhod',
    [SECURITY_CHECK_MODE.FINPASSWD]: 'r.finansovyy.parol',
    //[SECURITY_CHECK_MODE.GAUTH]: 'r.google.auth',
    // [SECURITY_CHECK_MODE.SMS]: 'r.sms.code',
  },
}

export const SECURITY_CHECK_MODE_TITLE = {
  [SECURITY_CHECK_MODE.SMS]: 'backoffice.akey.smscheck.note',
  [SECURITY_CHECK_MODE.GAUTH]: 'backoffice.akey.gauth.key',
  [SECURITY_CHECK_MODE.PASSWD]: 'backoffice.shop.fchk.check.passwd.table',
  [SECURITY_CHECK_MODE.AUTHKEY]: 'backoffice.akey.check.select.note',
  [SECURITY_CHECK_MODE.FINPASSWD]: 'backoffice.shop.fchk.check.finpasswd.table',
}

// Account

export const ACCOUNT_MONEY_FIELDS = ['value', 'blocked', 'amount']

// Verification

export const VERIFICATION_TYPE = {
  MOBILE: 'mobile',
  EMAIL: 'email',
  PASSPORT: 'passport',
  // PHONE: 'phone',
}

export const VERIFICATION_STATUS = {
  BLANK: 1,
  PENDING: 2,
  SUCCESS: 3,
  FAILURE: 4,
}

export const VERIFICATION_POSTPONE_TIME = 7 * 24 * 60 * 60 * 1000 // 7 days

// Currency

export const CURRENCY_FORMAT_CHAR = '%'

export const DEFAULT_CURRENCY_ID = 1

export const REPLACE_CURRENCY = {
  // SHOP_GOODS
  // SHOP_CART
  // SHOP_ORDER
  // SHOP_PAYMENT
};

// Goods

export const ORDER_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  PREPAID: 'prepaid',
}

// Delivery

export const DELIVERY_TYPES = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
}

// Binary structure

export const BINARY_DIRECTION = {
  LEFT: 1,
  AUTO: 0,
  RIGHT: 2,
}

export const BINARY_REFLINKS = {
  LINK: 8,
  SHOW: false
};

// Gender

export const GENDER = {
  0: { id: 0, title: 'global.field.gender.other' },
  1: { id: 1, title: 'global.field.gender.male' },
  2: { id: 2, title: 'global.field.gender.female' },
}

// Appeal

export const APPEAL = {
  1: { id: 1, title: 'r.appeal.sir' },
  2: { id: 2, title: 'r.appeal.madame' },
  3: { id: 3, title: 'r.appeal.mister' },
  4: { id: 4, title: 'r.appeal.mistress' },
  5: { id: 5, title: 'r.appeal.missis' },
  6: { id: 6, title: 'r.appeal.miss' },
  7: { id: 7, title: 'r.appeal.comrade' },
  8: { id: 8, title: 'r.appeal.heyyou' },
}

// Payment

export const PAYMENT_REQUEST_STATUS = {
  ACCEPTED: 'is_accepted',
  CANCELED: 'is_canceled',
  CONFIRMED: 'is_confirmed',
  EXPIRED: 'is_expired',
  PAID: 'is_paid',
}

export const PAYMENT_REQUEST = {
  FORM_GET: 'form-get',
  FORM_POST: 'form-post',
  SHOW_ADDRESS: 'show-address',
  RECEIPT_RUS: 'receipt-rus',
  RECEIPT_USA: 'receipt-usa',
  RECEIPT_EUR: 'receipt-eur',
  QUICK: 'quick',
  SCRIPT: 'script',
}

// These keys are stored in database: `moneyin_rpay_category`
// WARNING!!! Don't edit it unless you know what you're doing

export const PAYMENT_GROUP = {
  ACCOUNT: 'account',
  INVOICE: 'invoice',
  GATEWAY: 'gateway',
  CRYPTO: 'crypto',
  CACHE: 'cache',
  CARD: 'card',
}

export const PAYMENT_GROUP_TYPE = {
  1: PAYMENT_GROUP.ACCOUNT,
  2: PAYMENT_GROUP.INVOICE,
  3: PAYMENT_GROUP.GATEWAY,
  4: PAYMENT_GROUP.CARD,
  5: PAYMENT_GROUP.CRYPTO,
}

export const PRODUCT_TYPE = {
  START: 2,
  GOODS: 3,
  INVEST: 4,
  INSTALLMENT: 6
}

// Cookies

export const COOKIES_KEY = {
  LANG: 'lang',
  VERIFY: 'verify',
  COOKIE: 'cookie',
  SIDEBAR: 'sidebar'
}

export const COOKIES_TIME = 10 * 365 * 24 * 60 * 60 * 1000 // 10 years

// Visitor

export const VISITOR_FIELD = {
  PARENT: 'parent',
  LOGIN: 'login',
  LOGIN2: 'login2',
  PHOTO: 'photo',
  EMAIL: 'email',
  ADDRESS: 'address',
  REGIONADDRESS: 'regionaddress',
  // PHONE: 'phone',
  MOBILE: 'mobile',
  LASTNAME: 'lastname',
  FIRSTNAME: 'firstname',
  PATRONYMIC: 'patronymic',
  PASSPORT_SERIES: 'passp_serial',
  PASSPORT_NUMBER: 'passp_number',
  BIRTH_DATE: 'birth_date',
  GENDER: 'gender',
  MAIL_INDEX: 'mail_index',
  PASSWORD: 'passwd',
  PASSWORD_REPEAT: 'passwd2',
  VISITOR_DATE: 'visitor_date',
  COOWNER_MOBILE: 'properties.coowner_mobile',
  COOWNER_EMAIL: 'properties.coowner_email',
  COOWNER_FIRSTNAME: 'properties.coowner_firstname',
  COOWNER_LASTNAME: 'properties.coowner_lastname',
  COOWNER_PATRONYMIC: 'properties.coowner_patronymic',
  FACEBOOK: 'properties.facebook',
  VK: 'properties.vk',
  OK: 'properties.ok',
  TELEGRAM: 'properties.telegram',
  WATSAPP: 'properties.watsapp',
  INSTAGRAM: 'properties.instagram',
  YOUTUBE: 'properties.youtube',
  TIKTOK: 'properties.tiktok',
  VIBER: 'properties.viber',
  TWITTER: 'properties.twitter',
}

export const REGISTER_FIELD = [
  VISITOR_FIELD.PARENT,
  VISITOR_FIELD.LOGIN,
  VISITOR_FIELD.LOGIN2,
  VISITOR_FIELD.MOBILE,
  VISITOR_FIELD.EMAIL,
  // VISITOR_FIELD.PHONE,
  VISITOR_FIELD.PASSWORD,
  VISITOR_FIELD.PASSWORD_REPEAT,
  VISITOR_FIELD.LASTNAME,
  VISITOR_FIELD.FIRSTNAME,
  VISITOR_FIELD.PATRONYMIC,
  VISITOR_FIELD.PASSPORT_SERIES,
  VISITOR_FIELD.PASSPORT_NUMBER,
  VISITOR_FIELD.BIRTH_DATE,
  VISITOR_FIELD.GENDER,
  VISITOR_FIELD.ADDRESS,
  VISITOR_FIELD.REGIONADDRESS,
  VISITOR_FIELD.MAIL_INDEX,
  VISITOR_FIELD.COOWNER_MOBILE,
  VISITOR_FIELD.COOWNER_EMAIL,
  VISITOR_FIELD.COOWNER_FIRSTNAME,
  VISITOR_FIELD.COOWNER_LASTNAME,
  VISITOR_FIELD.COOWNER_PATRONYMIC,
]

export const PROFILE_FIELD = [
  VISITOR_FIELD.PHOTO,
  VISITOR_FIELD.EMAIL,
  VISITOR_FIELD.LOGIN2,
  VISITOR_FIELD.MOBILE,
  VISITOR_FIELD.LASTNAME,
  VISITOR_FIELD.FIRSTNAME,
  VISITOR_FIELD.PATRONYMIC,
  VISITOR_FIELD.REGIONADDRESS,
  VISITOR_FIELD.ADDRESS,
  VISITOR_FIELD.BIRTH_DATE,
  VISITOR_FIELD.MAIL_INDEX,
  VISITOR_FIELD.GENDER,
  VISITOR_FIELD.VISITOR_DATE,
  VISITOR_FIELD.FACEBOOK,
  VISITOR_FIELD.VK,
  VISITOR_FIELD.OK,
  VISITOR_FIELD.TELEGRAM,
  VISITOR_FIELD.WATSAPP,
  VISITOR_FIELD.INSTAGRAM,
  VISITOR_FIELD.YOUTUBE,
  VISITOR_FIELD.TIKTOK,
  VISITOR_FIELD.VIBER,
  VISITOR_FIELD.TWITTER,
  VISITOR_FIELD.COOWNER_MOBILE,
  VISITOR_FIELD.COOWNER_EMAIL,
  VISITOR_FIELD.COOWNER_FIRSTNAME,
  VISITOR_FIELD.COOWNER_LASTNAME,
  VISITOR_FIELD.COOWNER_PATRONYMIC,
]

export const PROFILE_FIELD_VERIFICATION_TYPE = {
  [VISITOR_FIELD.EMAIL]: VERIFICATION_TYPE.EMAIL,
  // [VISITOR_FIELD.PHONE]: VERIFICATION_TYPE.PHONE,
  [VISITOR_FIELD.MOBILE]: VERIFICATION_TYPE.MOBILE,
  [VISITOR_FIELD.LASTNAME]: VERIFICATION_TYPE.PASSPORT,
  [VISITOR_FIELD.FIRSTNAME]: VERIFICATION_TYPE.PASSPORT,
  [VISITOR_FIELD.PATRONYMIC]: VERIFICATION_TYPE.PASSPORT,
  [VISITOR_FIELD.BIRTH_DATE]: VERIFICATION_TYPE.PASSPORT,
  [VISITOR_FIELD.MAIL_INDEX]: VERIFICATION_TYPE.PASSPORT,
  [VISITOR_FIELD.GENDER]: VERIFICATION_TYPE.PASSPORT,
}

export const VISITOR_PHOTO_DEFAULT = {
  id: 0,
  path: '/data/images/nophoto.jpg',
}

export const VISITOR_PHOTO_SUFFIXES = {
  sm: '-small',
  md: '-medium',
  lg: '-big',
  shop: '-shop',
  article: '-article',
}

export const VISITOR_CLIENT_STATUS_ID = 0

export const VISITOR_UPDATE_INTERVAL = 15 * 60000 // 15 minutes

export const VISITOR_PRIVILEGES = {
  GUEST: '__guest__',
  CLIENT: '_visitor_status_0',
  PARTNER: 'not _visitor_status_0',
  USER: 'not admin',
  ADMIN: 'admin',
  SERVICE: '_visitor_type_2 and system',
  STORE: 'admin and store and not store_objects and not store_objects_branch',
  SHOP_AVAILABLE: '_visitor_flags_4 and (_visitor_is_paid or not admin or not store or store_objects or store_objects_branch)',
  VERIFICATION_EMAIL: '_visitor_verification_email',
  VERIFICATION_MOBILE: '_visitor_verification_mobile',
  VERIFICATION_PHONE: '_visitor_verification_phone',
  VERIFICATION_PASSPORT: '_visitor_verification_passport'
};

export const VISITOR_REDIRECT = [
  // { pathname: null, privileges: VISITOR_PRIVILEGES.STORE, to: process.env.REACT_APP_ADMIN_URL }
];


// Storage

export const STORAGE_KEY = {
  CART: 'cart',
  GOODS: 'goods',
  UNLOCK: 'unlock',
  PROFILE: 'profile',
  ACTIVITY: 'activity',
  ADDRESS: 'shop-address-data',
  PERSONAL: 'shop-personal-data',
  THEME: 'theme'
}

// Messages

export const DEFAULT_MESSAGE = {
  ERROR: 'r._.error.text.failure',
  ERROR_RAW: 'Something went wrong...',
  TIMEOUT: 'r._.error.text.timeout',
  TIMEOUT_RAW: 'Please, be patient or',
  OUTDATED: 'r._.error.text.outdated',
  OUTDATED_RAW: 'The page is out of date',
  RETRY: 'r._.error.action.retry',
  RETRY_RAW: 'Try again',
  RELOAD: 'r._.error.action.reload',
  RELOAD_RAW: 'Please, reload',
  OR_RELOAD: 'r.ili.perezagruzit',
  OR_RELOAD_RAW: 'Or reload the page',
}

export const DEFAULT_TIMEOUT_ERROR = {
  code: 'timeout',
  message: 'Timeout',
}

export const LANG_TEMPLATE_VAR = '$$'

export const LANG_ERROR_MESSAGE_PREFIX = 'database.check'

// Decoration

export const PRICE_FORMAT = {
  decimalSeparator: '.',
  groupSeparator: '\u00a0',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '\u00a0',
  fractionGroupSize: 3,
}

export const NUMBER_FORMAT_PROPS = {
  thousandSeparator: '\u00a0',
  allowNegative: false,
  allowEmptyFormatting: false,
}

export const PHONE_INPUT_PROPS = {
  // preferredCountries: ['ru', 'kz', 'ab', 'by', 'ua', 'kg', 'uz', 'az'],
  // defaultCountry: 'ru',
  // disableAreaCodes: true
}

// Themes

export const THEME_TYPE = {
  LIGHT: 'light',
  DARK: 'dark',
}

// Content

export const ARTICLE_TIMEOUT = 10 * FETCH_TIMEOUT

// Moment

export const DEFAULT_MOMENT_LOCALE = 'en'

// Ticket

export const TICKET_MESSAGES_READ_TIMEOUT = 3000;

// Social Networks

export const SOCIAL_NETWORK_LIST = [
    { name: 'tr_referal', icon: require('_core/assets/img/social/teamring.png') },
    { name: 'facebook', icon: require('_core/assets/img/social/Facebook.png') },
    { name: 'vk', icon: require('_core/assets/img/social/VK.png') },
    { name: 'ok', icon: require('_core/assets/img/social/Ok.png') },
    { name: 'telegram', icon: require('_core/assets/img/social/Telegram.png') },
    { name: 'watsapp', icon: require('_core/assets/img/social/WhatsApp.png') },
    { name: 'instagram', icon: require('_core/assets/img/social/Instagram.png') },
    { name: 'youtube', icon: require('_core/assets/img/social/YouTube.png') },
    { name: 'tiktok', icon: require('_core/assets/img/social/TikTok.png') },
    { name: 'viber', icon: require('_core/assets/img/social/Viber.png') },
    { name: 'twitter', icon: require('_core/assets/img/social/Twitter.png') }
  ];

// Dashboard Messages

export const DASHBOARD_MESSAGES_SHOW_LIMIT = 2;

// Maps

export const MAP_TYPE = {
  AUTO: 'auto',
  GOOGLE: 'google',
  YANDEX: 'yandex'
};

export const MAP_DEFAULT = MAP_TYPE.AUTO;

export const MAP_AUTO_LANG = {
  DEFAULT: MAP_TYPE.GOOGLE,
  
  [MAP_TYPE.YANDEX]: ['ru']
};

export const MAP_DELIVERY_POINT_ZOOM = 15;

export const MAP_DELIVERY_ICON = {
  anchorX: 16,
  anchorY: 16,
  width: 32,
  height: 32
};

export const MAP_STORE_ICON = {
  ...MAP_DELIVERY_ICON,
  
  url: `${process.env.PUBLIC_URL}/assets/icons/favicon.ico`
};

export const MAP_LOCATION_ICON = {
  url: require('_core/assets/img/location.png'),
  anchorX: 24,
  anchorY: 39,
  width: 48,
  height: 48
};

// Auth Services

export const AUTH_SERVICES = {
  sberbusinessapi: false,
  sberidapi: false
};

//

export const BINARY_NODE_FIELDS = [
  'visitor.login',
  'visitor.visitor_date.date',
  'visitor.status.title',
  'tree_date.date',
  'visitor.sponsor.login',
];

export const GOODS_CATEGORY_MENU = false;

export const DELIVERY_WIDGET = false;
