import { makeStyles } from '@material-ui/styles'

export const useThemeSwitchStyle = makeStyles(theme => ({
  root: {},

  label: {},

  icon: {},

  buttonContrast: {
    backgroundColor: theme.palette.sidebarItemsHoverColor,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  progress: {
    width: '1em !important',
    height: '1em !important',
  },

  index: {
    position: 'absolute',
    fontSize: '0.45em',
    lineHeight: 1,
  },
}))
