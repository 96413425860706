import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// core
import { PaymentOption } from '_core/components/PaymentOption'
import Label from '_core/components/Label/Label'

// style
import { usePaymentSwitchStyle } from './paymentSwitchStyle'

export const RawPaymentSwitch = props => {
  const {
    options,
    title,
    name,
    value,
    onChange,
    disabled,
    renderOption,
    OptionComponent,
  } = props

  const classes = usePaymentSwitchStyle()

  const [activeOption, setActiveOption] = useState(options.find(item => value == item.id)?.id || options.length && options[0].id || 0);
  
  useEffect(() => {
    onChange(activeOption);
  }, [activeOption]);
  
  // render
  
  const render = () => {
    return options.map((option) => {
      const optionProps = {
        name,
        disabled,
        key: option.id,
        value: String(option.id),
        label: option.title,
        image: option.image,
        isChecked: activeOption == option.id,
        onChange: event => {
          setActiveOption(option.id);
        }
      };
      
      return renderOption instanceof Function ? (
        renderOption(option, optionProps)
      ) : (
        <OptionComponent {...optionProps} />
      );
    });
  };

  return (
    <div className={classes.root}>
      <Label className={classes.title} size="small">
        {title}
      </Label>

      {render()}
    </div>
  )
}

RawPaymentSwitch.defaultProps = {
  OptionComponent: PaymentOption
}

RawPaymentSwitch.propTypes = {
  // self props
  value: PropTypes.any,
  title: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  renderGroup: PropTypes.func,
  renderOption: PropTypes.func,
  GroupComponent: PropTypes.elementType,
  OptionComponent: PropTypes.elementType,
}

export const PaymentSwitch = RawPaymentSwitch
