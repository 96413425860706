import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

// @material-ui
import { Typography, Breadcrumbs, Link } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'

const RawCrumbs = props => {
  const {
    className,
    crumbs,
    lastText,
    firstText,
    firstUrl,
    crumbUrl,
    rootProps,
    lastProps,
    firstProps,
    crumbProps,
    getLangString: l,
  } = props

  const getCrumbUrl = id =>
    typeof crumbUrl === 'function'
      ? crumbUrl(id)
      : crumbUrl != null
      ? `${crumbUrl}/${id}`
      : `${firstUrl}/${id}`

  return (
    <Breadcrumbs className={className} {...rootProps}>
      <Link key={0} component={RouterLink} to={firstUrl} {...firstProps}>
        {l(firstText)}
      </Link>

      {Array.isArray(crumbs)
        ? crumbs.map(item => (
            <Link
              key={item.id}
              component={RouterLink}
              to={getCrumbUrl(item.id)}
              {...crumbProps}
            >
              {item.title}
            </Link>
          ))
        : null}

      {lastText != null ? (
        <Typography display="inline" key={-1} {...lastProps}>
          {lastText}
        </Typography>
      ) : null}
    </Breadcrumbs>
  )
}

RawCrumbs.defaultProps = {}

RawCrumbs.propTypes = {
  // self props
  rootProps: PropTypes.object,
  lastProps: PropTypes.object,
  firstProps: PropTypes.object,
  crumbProps: PropTypes.object,
  className: PropTypes.string,
  lastText: PropTypes.string,
  firstText: PropTypes.string.isRequired,
  firstUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  crumbUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ]),
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ),

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Crumbs = withLang(RawCrumbs)
