import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography } from '@material-ui/core'

// core
import { ArticleContainer } from '_core/components/ArticleContainer'
import { StepsControls } from '_core/components/StepsControls'

// style
import { useStyle } from './style'

export const RawPaymentStepAgreement = (props) => {
  const {
    load,
    hasBackButton,
    goPrev,
    goNext,
    articles
  } = props;

  const classes = useStyle();

  const [articleIndex, setArticleIndex] = useState(0);

  const titleRef = useRef(null);

  if (!articles?.length) {
    goNext(load);
    
    return null;
  }

  const onNextStep = () => {
    if (articleIndex < articles.length - 1) {
      setArticleIndex(articleIndex + 1);
      
      if (titleRef?.current)
        titleRef.current.scrollIntoView(false);
    } else {
      goNext(load);
    }
  };

  return (
    <>
      <Typography variant="h3" color="primary" ref={titleRef} className={classes.title}>{articles[articleIndex]?.title}</Typography>
      <ArticleContainer variant="body1" className={classes.content}>{articles[articleIndex]?.content}</ArticleContainer>
      
      <StepsControls
        key={articleIndex}
        hasBackButton={hasBackButton}
        hasCheckbox={true}
        nextButtonText={'global.buttons.confirm'}
        onPrevStep={() => goPrev({})}
        onNextStep={onNextStep}
      />
    </>
  )
}

RawPaymentStepAgreement.defaultProps = {
  hasBackButton: true
}

RawPaymentStepAgreement.propTypes = {
  // self props
  load: PropTypes.object.isRequired,
  hasBackButton: PropTypes.bool.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  articles: PropTypes.array.isRequired,
}

export const PaymentStepAgreement = RawPaymentStepAgreement
