// @material-ui
import { makeStyles } from '@material-ui/styles'

export const paymentResultStyle = theme => ({
  root: {
    height: '100%',
  },
})

export const usePaymentResultStyle = makeStyles(paymentResultStyle)
