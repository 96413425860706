import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

// @material-ui
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

// core
import { withLang } from '_core/hocs/withLang'
import { PriceInput } from '_core/components/PriceInput'
import { ClipboardInput } from '_core/components/ClipboardInput'
import { ArticleContainer } from '_core/components/ArticleContainer'

// style
import { paymentConfirmStyle } from 'basic/views/PaymentConfirm/paymentConfirmStyle'

const RawPaymentResultCrypto = props => {
  const { classes, data, getLangString: l } = props
  const { amount, method, crypto, message, isTopup } = data

  const cryptoUrl = crypto.qrcode.content

  const amountLabel = `r.core.finance.topup.label.${
    isTopup ? 'topupvalue' : 'purchasevalue'
  }`

  return (
    <>
      <PriceInput
        label={l(amountLabel)}
        value={amount.value}
        currency={amount.currency.id}
        disabled={true}
      />

      <ClipboardInput
        render={childProps => <PriceInput {...childProps} />}
        label={l('r._.finance.topup.label.paymentvalue')}
        value={method.payment.amount.value}
        currency={method.payment.amount.currency.id}
        className={classes.valueInput}
        variant="outlined"
      />

      {crypto.mode != null ? (
        <ClipboardInput
          label={l('r._.finance.crypto.label.mode')}
          value={crypto.mode}
        />
      ) : null}

      <Alert severity="info" className={classes.warning}>
        <AlertTitle>
          <Typography variant="h6">
            {l('r._.finance.crypto.warningtitle')}
          </Typography>
        </AlertTitle>

        {message != null ? (
          <ArticleContainer variant="body1">{message}</ArticleContainer>
        ) : (
          l('r._.finance.crypto.warningbody')
        )}
      </Alert>

      <ClipboardInput
        label={l('r._.finance.crypto.label.address')}
        value={crypto.address}
      />

      <ClipboardInput
        label={l('r._.finance.crypto.label.note')}
        value={crypto.note}
      />

      <Typography className={classes.title}>
        {l('r.ili.otskaniruyte')} {l('r.ili')}{' '}
        <a href={cryptoUrl}>{l('r.otkroyte.koshelek')}</a>
      </Typography>

      <QRCode value={cryptoUrl} level="L" size={175} />
    </>
  )
}

RawPaymentResultCrypto.defaultProps = {}

RawPaymentResultCrypto.propTypes = {
  // self props
  data: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentResultCrypto = withLang(
  withStyles(paymentConfirmStyle)(RawPaymentResultCrypto)
)
