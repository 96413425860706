import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// core
import { State } from '_core/components/State'
import { StairsView } from './StairsView'

export const RawStairs = props => {
  const {
    stairs,
    hasSteps,
    initialLoad,
    initialStair,
    render,
    Component,
    onFirstGoPrev,
    ...rest
  } = props

  const stairKeys = Object.keys(stairs)
  const initialKey = stairKeys.includes(initialStair)
    ? initialStair
    : stairKeys[0]

  const history = useRef([initialKey])
  const [stair, setStair] = useState(initialKey)

  const stairIndex = stairKeys.indexOf(stair)
  const historyIndex = history.current.length - 1
  const load = useRef({ [historyIndex]: initialLoad })
  const cache = useRef({})

  const {
    Component: Child,
    render: renderChild,
    waitProps,
    getProps,
    sideProps,
    useCache,
    ...stepProps
  } = stairs[stair]

  const stateProps = {
    ...rest,
    load: load.current[historyIndex] != null ? load.current[historyIndex] : {},

    cache:
      useCache && cache.current[historyIndex] != null
        ? cache.current[historyIndex]
        : {},

    reset: () => {
      history.current = [initialKey]
      load.current = { 0: initialLoad }
      cache.current = {}
      setStair(initialKey)
    },

    goPrev: stairKey => {
      if (!stairIndex) {
        if (typeof onFirstGoPrev === 'function')
          onFirstGoPrev();
        
        return;
      }
      
      const prevKey = stairKeys.includes(`${stairKey}`)
        ? `${stairKey}`
        : stairKeys[stairIndex - 1]

      const prevKeyIndex = history.current.lastIndexOf(prevKey)
      const count = history.current.length - prevKeyIndex - 1

      if (prevKeyIndex < 0) {
        return
      }

      for (let i = 0; i < count; i++) {
        delete load.current[historyIndex - i]
        delete cache.current[historyIndex - i]
      }

      history.current = history.current.slice(0, history.current.length - count)
      setStair(history.current[history.current.length - 1])
    },

    goPast: (count = 1) => {
      if (history.current.length < count + 1) {
        return
      }

      for (let i = 0; i < count; i++) {
        delete load.current[historyIndex - i]
        delete cache.current[historyIndex - i]
      }

      history.current = history.current.slice(0, history.current.length - count)
      setStair(history.current[history.current.length - 1])
    },

    goNext: (newLoad, stairKey) => {
      const nextKey = stairKeys.includes(`${stairKey}`)
        ? `${stairKey}`
        : stairKeys[stairIndex + 1]

      if (nextKey == null) {
        return
      }

      history.current.push(nextKey)
      load.current[historyIndex + 1] = newLoad
      setStair(nextKey)
    },

    setCache: newCache => {
      if (!useCache) {
        return
      }

      cache.current[historyIndex] = newCache
    },
  }

  const children = (
    <State
      {...stepProps}
      {...stateProps}
      key={stair}
      getProps={useCache && cache[stair] != null ? undefined : getProps}
      waitProps={useCache && cache[stair] != null ? undefined : waitProps}
      sideProps={useCache && cache[stair] != null ? undefined : sideProps}
      render={childProps =>
        renderChild != null ? (
          renderChild(childProps)
        ) : (
          <Child {...childProps} />
        )
      }
    />
  )

  const viewProps = {
    stairs,
    hasSteps,
    children,
    activeStep: stair,
    goPrev: stateProps.goPrev,
  }

  return render != null ? render(viewProps) : <Component {...viewProps} />
}

RawStairs.defaultProps = {
  Component: StairsView,
  initialLoad: {},
  initialStair: 0,
}

RawStairs.propTypes = {
  // self props
  initialStair: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialLoad: PropTypes.object,
  hasSteps: PropTypes.bool,
  stairs: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        Component: PropTypes.elementType,
        render: PropTypes.func,
        useCache: PropTypes.bool,
        getProps: PropTypes.object,
        waitProps: PropTypes.object,
        sideProps: PropTypes.object,
        title: PropTypes.string,
        noTitle: PropTypes.bool,
      })
    ),
  ]).isRequired,
  render: PropTypes.func,
  Component: PropTypes.elementType,
  onFirstGoPrev: PropTypes.func
}

export const Stairs = RawStairs
