import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'

// @material-ui
import { Typography } from '@material-ui/core'

// core
import { Price } from '_core/components/Price'

export const RawPriceMix = props => {
  const { className, rootProps, itemProps, items, Divider, item } = props
  let content
  if (isArray(items)) {
    content = items
      .map((item, index) => [
        index > 0 ? <Divider key={-index} position={index} /> : null,
        <Price key={index} {...itemProps} {...item} />,
      ])
      .flat()
  } else {
    content = <Price {...itemProps} {...item} />
  }

  return (
    <Typography className={className} {...rootProps}>
      {content}
    </Typography>
  )
}

RawPriceMix.defaultProps = {
  Divider: () => ' + ',
}

RawPriceMix.propTypes = {
  // self props
  item: PropTypes.object,
  className: PropTypes.string,
  rootProps: PropTypes.object,
  itemProps: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  Divider: PropTypes.elementType,
}

export const PriceMix = RawPriceMix
