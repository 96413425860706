import React, { useState, useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'

// @material-ui
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// style
import { clockStyle } from './clockStyle'

export const RawClock = props => {
  const { classes, className, hide, ...rest } = props
  const [time, setTime] = useState(moment())

  const clockClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  useEffect(() => {
    const id = setInterval(() => {
      setTime(moment())
    }, 1000)

    return () => clearInterval(id)
  }, [])
  return (
    <div className={clockClasses} {...rest}>
      <Typography className={classes.time} variant="h4" noWrap={true}>
        {time.format('HH:mm:ss')}
      </Typography>
      <Typography className={classes.date} variant="caption" noWrap={true}>
        {time.format('dddd, LL')}
      </Typography>
    </div>
  )
}

export const Clock = withStyles(clockStyle)(RawClock)
