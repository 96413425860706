import React from 'react'
// import PropTypes from 'prop-types'

// core
import { GENDER } from 'config'
import { SelectInput } from '_core/components/SelectInput'

const RawGenderInput = props => {
  const options = Object.values(GENDER)

  return <SelectInput {...props} options={options} />
}

RawGenderInput.defaultProps = {}

RawGenderInput.propTypes = {}

export const GenderInput = RawGenderInput
