import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { PriceInput } from '_core/components/PriceInput'
import { SelectInput } from '_core/components/SelectInput'

const RawPriceFormOptions = props => {
  const {
    value,
    options,
    optionId,
    onChange,
    currency,
    getLangString: l,
  } = props

  const getOptions = items =>
    items.map(option => ({
      id: option.goods.id,
      title: option.goods.title,
    }))

  return (
    <>
      <SelectInput
        value={optionId}
        label={l('r._.goods.options')}
        options={getOptions(options)}
        onChange={event => onChange(event.target.value)}
      />

      <PriceInput
        currency={currency}
        value={value}
        label={l('global.field.price')}
        disabled={true}
      />
    </>
  )
}

RawPriceFormOptions.defaultProps = {
  label: 'r.summa.investicii',
}

RawPriceFormOptions.propTypes = {
  // self props
  isValid: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionId: PropTypes.string.isRequired,
  optionGoods: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceFormOptions = withLang(RawPriceFormOptions)
