import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// moment
import moment from 'moment'

// @material-ui
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { useGlobal } from '_core/hooks/useGlobal'

import { Loading } from '_core/components/Loading'

import { withVisitorInfo } from '_core/utils/visitorInfo';

import { TopicSearch } from '_core/views/MessagesSplash/TopicSearch'

import {
  loadTopicEntry,
  createTopicEntry
} from '_core/views/MessagesSplash/util.js'

import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc'

import './Messenger.css'

const RawTopicList = (props) => {
  const { topics: x, visitorInfo, initialSelection, onSelect } = props;

  const {
    request: { queue },
    config: { API_METHOD, API_NOTICE }
  } = useGlobal();

  const [topics, setTopics] = useState(x);

  const [selectedTopic, setSelectedTopic] = useState(initialSelection);

  const topicRefs = useRef({
    topicsByElement: new Map(),
    elementsByTopic: new Map()
  });

  const handleSelect = (topic) => {
    setSelectedTopic(topic);
    onSelect(topic);
  }

  React.useEffect(() => {
    const currentTopicOrNull = selectedTopic;
    if (currentTopicOrNull != null) {
      const topicElement = topicRefs.current.elementsByTopic.get(currentTopicOrNull);
      if (topicElement != null) {
        topicElement.scrollIntoView();
      }
    };
  }, [topics]);

  React.useEffect(() => {
    const notifyListener = (event) => {
      if (event.detail.name === API_NOTICE.VISITOR_MESSAGE_CREATE) {
        const messageId = event.detail.data.id;
        
        const message = messageId && visitorInfo.getUnreadMessage(messageId);
        
        if (message) {
          const topic = message.topic;
          
          const topicEntry = createTopicEntry(topic, message.timestamp);
            setTopics(state => {
              const found = state.find(x => x.id === topicEntry.id);
              if (found) {
                Object.assign(found, topicEntry);
                return state;
              } else {
                return [ topicEntry, ...state ];
              }
          });
        }
      }
    };
    
    window.addEventListener('notify', notifyListener);
    
    return () => window.removeEventListener('notify', notifyListener);
  }, []);

  const handleSearch = (text) => {
    queue(API_METHOD.VISITOR_MESSAGE_TOPIC_FIND, { text }, RawTopicList.displayName)
      .then(data => {
        const result = data.list.map(loadTopicEntry);
        setTopics(result);
      });
  }

  const resetList = () => {
    queue(API_METHOD.VISITOR_MESSAGE_TOPICS, null, RawTopicList.displayName)
      .then(data => {
        const result = data.list.map(loadTopicEntry);
        setTopics(result);
      });
  }

  const renderMessageTimestamp = function(timestamp) {
    return moment(timestamp)
      .format("hh:mm:ss DD.MM.yyyy");
  }

  if (topics === null) {
    return (<Loading />);
  } else {
    const effectiveTopics = topics.map(topic => {
      const lastMessage = visitorInfo.lastMessage(topic.id);
  
      if (lastMessage != null) {
        const lastMessageTime = new Date(
          Math.max(
            topic.lastMessageTime.getTime(),
            lastMessage.timestamp.getTime()
          )
        );
  
        return { ...topic, lastMessageTime: lastMessageTime };
      } else {
        return topic;
      }
    });

    return (
      <div className="sidebar">
        <TopicSearch onSearch={handleSearch} onClear={resetList} />
        <div className="scrollable">
          <List className="conversation-list">
            {
              effectiveTopics
                .sort((a, b) => a.lastMessageTime.getTime() < b.lastMessageTime.getTime())
                .map(topic => {
                const { id, photo, subject, lastMessageTime } = topic;

                return (
                  <ListItem
                    ref={(el) => {
                      if (el != null) {
                        topicRefs.current.topicsByElement.set(el, topic);
                        topicRefs.current.elementsByTopic.set(topic, el);
                      }
                    }}
                    button
                    key={"message_" + id}
                    selected={topic.id === selectedTopic.id}
                    onClick={() => handleSelect(topic)}
                  >
                    <ListItemAvatar>
                       <Avatar src={getVisitorPhotoSrc(photo, 'md')} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={subject}
                      secondary={
                        <div>
                          <div className="conversation-snippet-timestamp">
                            {renderMessageTimestamp(lastMessageTime)}
                          </div>
                        </div>
                      }
                      classes={{
                        primary: "conversation-snippet"
                      }}
                    />
                  </ListItem>
                );
              })
            }
          </List>
        </div>
      </div>
    );
  }
}

RawTopicList.defaultProps = {}

RawTopicList.propTypes = {
  // self props
  topics: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onSelect: PropTypes.func,
  selectOnLoad: PropTypes.boolean,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const TopicList =
  withVisitorInfo(
    withLang(
      (RawTopicList)
    )
  )
