import { createReducer } from 'redux-starter-kit'

// Ritos store
import { privateDataDefaultState, privateDataActionTypes } from './index'

// === Reducers ===

const privateData = createReducer(privateDataDefaultState, {
  [privateDataActionTypes.VISITOR_LINK_UPDATE]: (draft, action) => {
    draft.visitorLink = action.payload.data.list
    // draft.visitorLink = action.payload.data.list.reduce((result, item) => {
    //   result[item.id] = item
    //   return result
    // }, {})
  },

  [privateDataActionTypes.VISITOR_LINK_RESET]: (draft, action) => {
    draft.visitorLink = privateDataDefaultState.visitorLink
  },

  [privateDataActionTypes.BALANCE_UPDATE]: (draft, action) => {
    draft.balance = action.payload.data
  },

  [privateDataActionTypes.BALANCE_RESET]: (draft, action) => {
    draft.balance = privateDataDefaultState.balance
  },

  [privateDataActionTypes.ACCOUNT_UPDATE]: (draft, action) => {
    draft.account == null && (draft.account = {})
    action.payload.data.list.forEach(item => {
      draft.account[item.id] = item
    })
  },

  [privateDataActionTypes.ACCOUNT_RESET]: (draft, action) => {
    draft.account = privateDataDefaultState.account
  },

  [privateDataActionTypes.INVESTMENT_UPDATE]: (draft, action) => {
    draft.investment == null && (draft.investment = {})
    action.payload.data.list.forEach(item => {
      draft.investment[item.id] = item
    })
  },

  [privateDataActionTypes.INVESTMENT_RESET]: (draft, action) => {
    draft.investment = privateDataDefaultState.investment
  },

  [privateDataActionTypes.INVESTMENT_STAT_UPDATE]: (draft, action) => {
    draft.investmentStat = action.payload.data
  },

  [privateDataActionTypes.INVESTMENT_STAT_RESET]: (draft, action) => {
    draft.investmentStat = privateDataDefaultState.investmentStat
  },

  [privateDataActionTypes.MORE_VOLUME_UPDATE]: (draft, action) => {
    draft.volumeMore = {
      lo: action.payload.data.lo,
      go: action.payload.data.go,
      po: action.payload.data.po,
      po_margin: action.payload.data.po_margin,
      po_margin_new: action.payload.data.po_margin_new,
      go_margin: action.payload.data.go_margin,
    }
  },

  [privateDataActionTypes.MORE_VOLUME_RESET]: (draft, action) => {
    draft.volumeMore = privateDataDefaultState.volumeMore
  },

  [privateDataActionTypes.TRANSMIT_TYPE_UPDATE]: (draft, action) => {
    draft.transmitType == null && (draft.transmitType = {})
    action.payload.data.list.forEach(item => {
      draft.transmitType[item.id] = item
    })
  },

  [privateDataActionTypes.TRANSMIT_TYPE_RESET]: (draft, action) => {
    draft.transmitType = privateDataDefaultState.transmitType
  },

  [privateDataActionTypes.TRANSMIT_UPDATE]: (draft, action) => {
    const data = action.payload.data
    const account = action.payload.account

    draft.transmit == null && (draft.transmit = {})
    draft.transmit[account] == null && (draft.transmit[account] = {})
    draft.transmit[account].list == null && (draft.transmit[account].list = {})

    draft.transmit[account].hits = data.hits
    data.list.forEach(item => {
      draft.transmit[account].list[item.id] = item
    })
  },

  [privateDataActionTypes.TRANSMIT_RESET]: (draft, action) => {
    draft.transmit = privateDataDefaultState.transmit
  },

  [privateDataActionTypes.WITHDRAWAL_UPDATE]: (draft, action) => {
    draft.withdrawal == null && (draft.withdrawal = {})
    draft.withdrawal.list == null && (draft.withdrawal.list = {})

    draft.withdrawal.hits = action.payload.data.hits
    action.payload.data.list.forEach(item => {
      draft.withdrawal.list[item.id] = item
    })
  },

  [privateDataActionTypes.WITHDRAWAL_RESET]: (draft, action) => {
    draft.withdrawal = privateDataDefaultState.withdrawal
  },

  [privateDataActionTypes.TICKET_UPDATE]: (draft, action) => {
    draft.ticket == null && (draft.ticket = {})
    draft.ticket.list == null && (draft.ticket.list = {})

    draft.ticket.hits = action.payload.data.hits
    action.payload.data.list.forEach(item => {
      draft.ticket.list[item.id] = item
    })
  },

  [privateDataActionTypes.TICKET_RESET]: (draft, action) => {
    draft.ticket = privateDataDefaultState.ticket
  },

  [privateDataActionTypes.TICKET_MESSAGE_UPDATE]: (draft, action) => {
    const { id, data } = action.payload

    draft.ticketMessage == null && (draft.ticketMessage = {})
    draft.ticketMessage[id] == null && (draft.ticketMessage[id] = {})
    draft.ticketMessage[id].list == null && (draft.ticketMessage[id].list = {})

    draft.ticketMessage[id].hits = data.hits
    action.payload.data.list.forEach(item => {
      draft.ticketMessage[id].list[item.id] = item
    })
  },

  [privateDataActionTypes.TICKET_MESSAGE_RESET]: (draft, action) => {
    const id = action.payload.id
    draft.ticketMessage[id] != null && delete draft.ticketMessage[id]
  },

  [privateDataActionTypes.TICKET_MESSAGE_RESET_ALL]: (draft, action) => {
    draft.ticketMessage = privateDataDefaultState.ticketMessage
  },

  [privateDataActionTypes.CAREER_UPDATE]: (draft, action) => {
    draft.career = action.payload.data
  },

  [privateDataActionTypes.CAREER_RESET]: (draft, action) => {
    draft.career = privateDataDefaultState.career
  },

  [privateDataActionTypes.STAT_UPDATE]: (draft, action) => {
    draft.stat = action.payload.data
  },

  [privateDataActionTypes.STAT_RESET]: (draft, action) => {
    draft.stat = privateDataDefaultState.stat
  },

  [privateDataActionTypes.LEVEL_UPDATE]: (draft, action) => {
    draft.level = action.payload.data
  },

  [privateDataActionTypes.LEVEL_RESET]: (draft, action) => {
    draft.level = privateDataDefaultState.level
  },

  [privateDataActionTypes.VOLUME_UPDATE]: (draft, action) => {
    draft.volume = action.payload.data
  },

  [privateDataActionTypes.VOLUME_RESET]: (draft, action) => {
    draft.volume = privateDataDefaultState.volume
  },

  [privateDataActionTypes.STRUCTURE_UPDATE]: (draft, action) => {
    draft.structure = action.payload.data
  },

  [privateDataActionTypes.STRUCTURE_RESET]: (draft, action) => {
    draft.structure = privateDataDefaultState.structure
  },

  [privateDataActionTypes.SECURITY_UPDATE]: (draft, action) => {
    draft.security = action.payload.data
  },

  [privateDataActionTypes.SECURITY_RESET]: (draft, action) => {
    draft.security = privateDataDefaultState.security
  },

  [privateDataActionTypes.GOODS_UPDATE]: (draft, action) => {
    draft.goods = action.payload.data
  },

  [privateDataActionTypes.GOODS_RESET]: (draft, action) => {
    draft.goods = privateDataDefaultState.goods
  },

  [privateDataActionTypes.GOODS_CATEGORY_UPDATE]: (draft, action) => {
    draft.goodsCategory = action.payload.data
  },

  [privateDataActionTypes.GOODS_CATEGORY_RESET]: (draft, action) => {
    draft.goodsCategory = privateDataDefaultState.goodsCategory
  },

  [privateDataActionTypes.OWN_GOODS_UPDATE]: (draft, action) => {
    draft.ownGoods = action.payload.data
  },

  [privateDataActionTypes.OWN_GOODS_RESET]: (draft, action) => {
    draft.ownGoods = privateDataDefaultState.ownGoods
  },

  [privateDataActionTypes.CURRENCY_RATE_UPDATE]: (draft, action) => {
    draft.currencyRate = action.payload.data
  },

  [privateDataActionTypes.CURRENCY_RATE_RESET]: (draft, action) => {
    draft.currencyRate = privateDataDefaultState.currencyRate
  },

  [privateDataActionTypes.ORDER_SET]: (draft, action) => {
    draft.order = action.payload.data
  },

  [privateDataActionTypes.ORDER_RESET]: draft => {
    draft.currencyRate = privateDataDefaultState.order
  },
})

// === Exports ===

export const privateDataReducers = {
  privateData,
}
