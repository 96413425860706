export const overrides = theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
    [`${theme.breakpoints.down('xs')}`]: {
      padding: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
})
