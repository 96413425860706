import { CURRENCY_FORMAT_CHAR } from 'config'

export const getCurrencyFormatProp = currency => {
  if (currency == null || currency.format == null) {
    return {}
  }

  const prop = currency.format[0] === CURRENCY_FORMAT_CHAR ? 'suffix' : 'prefix'
  const value = currency.format
    .replace(CURRENCY_FORMAT_CHAR, '')
    .replace(/\s+/, '\u00a0')

  const decimalProps =
    currency.scale > 0
      ? {
          inputMode: 'decimal',
          allowedDecimalSeparators: ['.', ','],
        }
      : {}

  return {
    [prop]: value,
    decimalScale: currency.scale,
    fixedDecimalScale: currency.scale === 2,
    ...decimalProps,
  }
}
