export const clockStyle = theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(1, 2),
  },

  time: {
    color: theme.variables.colors.sidebarItems,
  },

  date: {
    color: theme.variables.colors.sidebarItems,
  },
})
