import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import {
  Typography,
  CardMedia,
  Box,
  Icon,
  Tooltip,
  Grid,
  Chip,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

// core
import { withLang } from '_core/hocs/withLang';
import { getFormattedValue } from '_core/utils/formatPrice';
import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc';
import { useCartContext } from '_core/contexts/Cart';
import { PriceMix } from '_core/components/PriceMix';
import { CountInput } from '_core/components/CountInput';

// style
import { goodsListItemCartStyle } from '_core/components/GoodsListItem/goodsListItemStyle';


const RawGoodsListItemCart = (props) => {
  const {
    classes,
    goodsUrl,
    data,
    getLangString: l
  } = props;
  
  const theme = useTheme();
  
  const cart = useCartContext();
  
  const cartControl = cart.getControl(data);
  
  const { isLoading, title, description, photo, price, discount, points, group } = cartControl.info;
  
  const groupPrice = group?.length ? group[0].price : price?.value || price;
  const groupDiscount = group?.length ? group[0].discount : discount?.value || discount;
  const groupPoints = group?.length ? group[0].points : points;
  
  const disabled = cartControl.rest.isLoading || cartControl.rest.value <= 0;
  
  return (
    <div className={classes.itemContainer}>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={3} md={3} lg={3}>
          <Link to={`${goodsUrl}/${data.id}`}>
            {photo?.id ? (
              <CardMedia
                className={classes.cardMedia}
                image={getVisitorPhotoSrc(photo.path, 'shop')}
              />
            ) : null}
          </Link>
        </Grid>

        <Grid item={true} xs={12} sm={9} md={9} lg={9}>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={6} md={6} lg={6}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.headerTitle}
                gutterBottom
              >
                {isLoading ? (
                  <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
                ) : title}
              </Typography>

              {description ? (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.headerDesciption}
                >
                  {description}
                </Typography>
              ) : null}
            </Grid>

            <Grid item={true} xs={12} sm={6} md={6} lg={6}>
              <CountInput
                value={cartControl.count || cartControl.countTotal}
                className={classes.counter}
                disabled={disabled}
                onChange={(value) => cartControl.setCount((count) => value)}
                max={cartControl.rest.value}
                groupProps={{ variant: 'standard', helperText: null }}
              />

              <Box className={classes.prices}>
                {group?.length > 1 ? (
                  group.map((item, index) => (
                    <PriceMix
                      key={index}
                      item={{
                        currency: cart.currency.id,
                        value: item.price - item.discount
                      }}
                      itemProps={{
                        className: classes.priceText,
                        rootProps: { color: 'secondary', style: index > 0 ? { fontSize: '1rem' } : null },
                        postfix: ` (${item.count} ${l('r.core.good.pieces')})`
                      }}
                    />
                  ))
                ) : (
                  <PriceMix
                    item={{
                      currency: cart.currency.id,
                      value: groupPrice - groupDiscount
                    }}
                    itemProps={{
                      className: classes.priceText,
                      rootProps: { color: 'secondary' }
                    }}
                  />
                )}

                {groupDiscount > 0 ? (
                  <PriceMix
                    itemProps={{ className: classes.lineThrough + ' ' + classes.sale }}
                    item={{
                      currency: cart.currency.id,
                      value: groupPrice
                    }}
                  />
                ) : null}
              </Box>

              {groupPoints > 0 ? (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  className={classes.points}
                >
                  <Chip
                    size="small"
                    color="secondary"
                    className={classes.pointsChip}
                    label={
                      <Typography
                        component="span"
                        variant="caption"
                        className={classes.pointsValue}
                      >
                        {l(
                          ['r._.goods.text.points', groupPoints],
                          getFormattedValue(groupPoints, {
                            scale: theme.variables.pointsScale,
                          })
                        )}
                      </Typography>
                    }
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Tooltip
          title={
            disabled || !cartControl.count
              ? l('r.core.good.disabledelete')
              : l('r.core.good.removefromcart')
          }
          placement="left"
        >
          <Icon
            className={`${classes.clearIcon} ${
              disabled || !cartControl.count ? classes.clearIconDisable : ''
            }`}
            onClick={cartControl.count ? () => cartControl.remove() : undefined}
          >
            close
          </Icon>
        </Tooltip>
      </Grid>
    </div>
  );
};

RawGoodsListItemCart.defaultProps = {};

RawGoodsListItemCart.propTypes = {
  // self props
  goodsUrl: PropTypes.string,
  data: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
};

export const GoodsListItemCart = withLang(
  withStyles(goodsListItemCartStyle)(RawGoodsListItemCart)
);
