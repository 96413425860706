export const overrides = theme => ({
  paperFullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: 0,
  },

  paperScrollPaper: {
    [`${theme.breakpoints.down('md')}`]: {
      maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    },
    [`${theme.breakpoints.down('xs')}`]: {
      maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
})

export const props = {
  fullWidth: true,
  maxWidth: 'md',
  scroll: 'paper',
}
