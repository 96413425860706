import React from 'react'
import PropTypes from 'prop-types'

// core
import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc'
import { hasProfilePhoto } from '_core/utils/profilePhoto'
import { withVisitorInfo } from '_core/utils/visitorInfo'
import { Avatar } from '_core/components/Avatar'

export const RawUserAvatar = props => {
  const { userInfo, visitorInfo, ...rest } = props

  const profile = userInfo != null ? userInfo : visitorInfo.info

  const letterProps = {
    login: profile.login,
    firstname: profile.firstname,
    lastname: profile.lastname,
  }

  const imageProps = hasProfilePhoto(profile)
    ? {
        alt: profile.login,
        src: getVisitorPhotoSrc(profile.photo.path, 'md'),
      }
    : {}

  return <Avatar letterProps={letterProps} {...imageProps} {...rest} />
}

RawUserAvatar.defaultProps = {}

RawUserAvatar.propTypes = {
  // self props
  userInfo: PropTypes.object,
  
  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,
}

export const UserAvatar = withVisitorInfo(RawUserAvatar)
