import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Grid, Typography } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { Price } from '_core/components/Price'

// style
import { useBalanceButtonStyle } from './balanceButtonStyle'

export const RawBalanceButton = forwardRef((props, ref) => {
  const {
    className,
    accounts,
    accountTypes,
    onClick,
    inMenu,
    active,
    disabled,
    // hasBlocked,
    // getLangString: l,
  } = props

  const classes = useBalanceButtonStyle()

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.inMenu]: inMenu,
    [classes.active]: active && !disabled,
    [classes.disabled]: disabled,
    [className]: className != null,
  })

  const activeAccounts = accounts.filter(({ is_header }) => is_header)

  const activeAccountTypes = activeAccounts.reduce(
    (result, { account_type }) => {
      if (result[account_type] == null) {
        result[account_type] = accountTypes.find(
          ({ id }) => `${id}` === `${account_type}`
        )
      }

      return result
    },
    {}
  )

  const isSingleTitle = Object.keys(activeAccountTypes).length < 2
  const isSingleValue = activeAccounts.length < 2

  return (
    <div
      ref={ref}
      className={rootClasses}
      onClick={disabled ? undefined : onClick}
    >
      {activeAccounts.map(account => {
        const { id, account_type, amount, currency } = account
        const accountTitle = activeAccountTypes[account_type].title

        const value = (
          <Typography key={id} noWrap={true}>
            <Price
              renderTooltip={({ content }) => (
                <>
                  {accountTitle}
                  {': '}
                  {content}
                </>
              )}
              hasTooltip={true}
              className={isSingleValue ? classes.valueSingle : classes.value}
              value={amount.value}
              currency={currency}
            />
          </Typography>
        )

        return !isSingleTitle ? (
          <Grid key={id} container={true}>
            <Grid item={true} xs={6} className={classes.column}>
              <Typography
                noWrap={true}
                variant="caption"
                className={classes.label}
              >
                {`${accountTitle}:`}
              </Typography>
            </Grid>

            <Grid item={true} xs={6} className={classes.column}>
              {value}
            </Grid>
          </Grid>
        ) : (
          value
        )
      })}
    </div>
  )
})

RawBalanceButton.defaultProps = {}

RawBalanceButton.propTypes = {
  // self props
  onClick: PropTypes.func,
  inMenu: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  hasBlocked: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountTypes: PropTypes.arrayOf(PropTypes.object).isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const BalanceButton = withLang(RawBalanceButton)
