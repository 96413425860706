import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { noop as _noop } from 'lodash'

// core
import { SelectInputBase } from '_core/components/SelectInputBase'

export const SelectInput = props => {
  const {
    value,
    options,
    onChange,
    setFieldValue,
    setFieldTouched,
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(false)

  if (value == null || value === '') {
    setFieldValue(`${options[0].id}`)
    setFieldTouched()
  }

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
    setFieldTouched()
  }

  const handleOnChange = event => {
    setFieldTouched()
    onChange(event)
  }

  return (
    <SelectInputBase
      value={value}
      options={options}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onChange={handleOnChange}
      {...rest}
    />
  )
}

SelectInput.defaultProps = {
  setFieldValue: _noop,
  setFieldTouched: _noop,
}

SelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}
