import React, { useLayoutEffect } from 'react';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

// @material-ui
import { useTheme } from '@material-ui/core/styles';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { customCallback } from '_core/utils/customCallback';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { HistoryListener } from '_core/components/HistoryListener';
import { Loading } from '_core/components/Loading';
import { Client } from 'basic/layouts/Client';
import { Guest } from 'basic/layouts/Guest';


const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const RawRootView = (props) => {
  const { isGuest, isReady, visitorInfo, ...rest } = props;
  const theme = useTheme();

  const {
    config: { ROUTE_URL, VISITOR_REDIRECT },
    routes
  } = useGlobal();

  React.useEffect(() => {
    RedirectController.checkRedirect(VISITOR_REDIRECT, visitorInfo, window.location.pathname);
  }, [visitorInfo]);

  const locationCallback = customCallback(theme.pages.locationCallback);
  const onLocationChange = ({ pathname }) => {
    RedirectController.checkRedirect(VISITOR_REDIRECT, visitorInfo, pathname);
    
    return locationCallback(pathname);
  };

  const renderLayout = () => {
    const Layout = isGuest ? Guest : Client;

    return (
      <Router history={history}>
        <HistoryListener onChange={onLocationChange}>
          <Layout
            {...rest}
            render={({ setLayoutProps }) => (
              <Switch>
                {renderMainRoutes(routes, setLayoutProps)}
                <Redirect to={ROUTE_URL.INDEX} />
              </Switch>
            )}
          />
        </HistoryListener>
      </Router>
    );
  };

  const renderMainRoutes = (list, setLayoutProps) => {
    if (list == null || !(list instanceof Object)) {
      return null
    }

    return Object.keys(list).map(key => {
      const {
        Component,
        RouteComponent,
        componentProps,
        layoutProps,
        routeProps,
        privileges
      } = list[key]
      
      if (!visitorInfo.isAllowed(privileges))
        return null;
      
      return (
        <RouteComponent {...routeProps} key={key}>
          <RouteRender
            layoutProps={layoutProps}
            setLayoutProps={setLayoutProps}
          >
            <RedirectController {...routeProps} visitorInfo={visitorInfo}>
              <Component {...componentProps}>
                {renderSubRoutes(list[key].routes)}
              </Component>
            </RedirectController>
          </RouteRender>
        </RouteComponent>
      )
    }).filter((item) => !!item);
  };

  const renderSubRoutes = list => {
    if (list == null || !(list instanceof Object)) {
      return null
    }

    return Object.keys(list).map((key, index) => {
      const { Component, componentProps, routeProps } = list[key]

      return (
        <Route {...routeProps} key={index}>
          <RedirectController {...routeProps} visitorInfo={visitorInfo}>
            <Component {...componentProps}>
              {renderSubRoutes(list[key].routes)}
            </Component>
          </RedirectController>
        </Route>
      )
    })
  };

  const RouteRender = props => {
    const { children, layoutProps, setLayoutProps } = props

    useLayoutEffect(() => {
      typeof setLayoutProps === 'function' && setLayoutProps(layoutProps)
    }, [])

    return children
  };

  return isReady ? renderLayout() : <Loading />;
};

RawRootView.defaultProps = {};

RawRootView.propTypes = {
  // self props
  isReady: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool,
  children: PropTypes.node,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired
};

export const RootView = withVisitorInfo(RawRootView);


const RedirectController = (props) => {
  const { config: { VISITOR_REDIRECT } } = useGlobal();
  
  
  if (RedirectController.checkRedirect(VISITOR_REDIRECT, props.visitorInfo, window.location.pathname))
    return null;
  
  return props.children;
};

RedirectController.checkRedirect = (VISITOR_REDIRECT, visitorInfo, pathname) => {
  if (!Array.isArray(VISITOR_REDIRECT))
    return false;
  
  if (!pathname)
    pathname = '/';
  
  const urlPrefix = process.env.PUBLIC_URL;
  
  if (urlPrefix && urlPrefix.length > 1 && pathname.startsWith(urlPrefix))
    pathname = pathname.substring(urlPrefix.length);
  
  if (pathname.length > 1 && pathname.endsWith('/'))
    pathname = pathname.substring(0, pathname.length - 1);
  
  return VISITOR_REDIRECT.some((item) => {
    const paths = !item.pathname ? null : Array.isArray(item.pathname) ? item.pathname : [item.pathname];
    
    if (paths && !paths.some((path) => path instanceof RegExp ? path.test(pathname) : path === pathname))
      return false;
    
    if (item.privileges && (visitorInfo.isLoading || !visitorInfo.isAllowed(item.privileges)))
      return false;
    
    window.location.assign(item.to);
    
    return true;
  });
};
