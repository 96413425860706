import React from 'react';
import PropTypes from 'prop-types';

import lodash from 'lodash';
import { geolocated } from 'react-geolocated';
import { AddressSuggestions } from 'react-dadata';

import 'react-dadata/dist/react-dadata.css';

import { TextField } from '@material-ui/core';

import { getOfficeSettingsValue } from '_core/utils/api';
import { join as addressJoin } from '_core/utils/address';

// https://github.com/vitalybaev/react-dadata
// https://dadata.ru/suggestions/#address-granular

const EQUALS_ADDRESS_FIELDS = [
  'id', 'area_with_type', 'block', 'city_district_with_type', 'city_with_type', 'country_iso_code', 
  'divisions', 'entrance', 'federal_district', 'flat', 'flat_area', 'floor', 'geo_lat', 'geo_lon', 
  'house', 'postal_code', 'region_iso_code', 'settlement_with_type', 'stead', 'street_with_type'
];


const AddressTextField = ({
  onChange,
  onKeyPress,
  onKeyDown,
  onFocus,
  onBlur,
  
  onInputChange,
  onInputKeyPress,
  onInputKeyDown,
  onInputFocus,
  onInputBlur,
  
  ...props
}) => {
  props.onChange = (...args) => { onInputChange && onInputChange(...args); onChange && onChange(...args); };
  props.onKeyPress = (...args) => { onInputKeyPress && onInputKeyPress(...args); onKeyPress && onKeyPress(...args); };
  props.onKeyDown = (...args) => { onInputKeyDown && onInputKeyDown(...args); onKeyDown && onKeyDown(...args); };
  props.onFocus = (...args) => { onInputFocus && onInputFocus(...args); onFocus && onFocus(...args); };
  props.onBlur = (...args) => { onInputBlur && onInputBlur(...args); onBlur && onBlur(...args); };
  
  return <TextField {...props} />
};

const AddressInput = class extends React.Component {
  
  static propTypes = {
    type: PropTypes.string.isRequired
  };
  
  static defaultProps = {
  };
  
  
  constructor(props) {
    super(props);
    
    this.state = { dadataToken: null };
  }
  
  
  handleSelectAddress(address) {
    if (!this.isMounded)
      return;
    
    const { type, onSelectValue } = this.props;
    
    this.setState((state) => {
      if (EQUALS_ADDRESS_FIELDS.every((name) => state?.address?.data?.[name] === address?.data?.[name]))
        return state;
      
      if (address.data)
        address.value = addressJoin(type, address.data) || address.value;
      
      if (onSelectValue)
        setTimeout(() => onSelectValue(address), 1);
      
      return { ...state, address };
    });
    
    //console.log('handleSelectAddress(', address, ')');
  }
  
  determineAddressByIpAddress() {
    const { dadataToken } = this.props;
    
    fetchDadata(dadataToken, 'iplocate/address')
      .then((data) => this.handleSelectAddress(data?.location))
      .catch((error) => console.error(error));
  }
  
  determineAddressByGeolocation() {
    const { dadataToken, coords } = this.props;
    
    if (!coords)
      return this.determineAddressByIpAddress();
    
    fetchDadata(dadataToken, 'geolocate/address', { lat: coords.latitude, lon: coords.longitude, radius_meters: 1000, count: 1 })
      .then((data) => { const list = data?.suggestions; if (list?.length) { this.handleSelectAddress(list[0]); } else { this.determineAddressByIpAddress(); } })
      .catch((error) => { console.error(error); this.determineAddressByIpAddress(); });
  }
  
  determineAddress() {
    if (this.state.dadataToken === null) {
      getOfficeSettingsValue('client_dadata_token', '')
        .then((value) => this.setState((state) => ({ ...state, dadataToken: value })));
      
      return;
    }
    
    if (this.addressIsFetch)
      return;
    
    const { value, isGeolocationEnabled, coords } = this.props;
    
    if (value) {
      this.addressIsFetch = true;
    } else if (isGeolocationEnabled) {
      if (coords && !this.state?.address) {
        this.addressIsFetch = true;
        
        this.determineAddressByGeolocation();
      }
    } else {
      this.addressIsFetch = true;
      
      this.determineAddressByIpAddress();
    }
  }
  
  componentDidMount() {
    this.isMounded = true;
  }
  
  componentWillUnmount() {
    this.isMounded = false;
  }
  
  render() {
    const {
      type,
      value,
      ownerValue,
      defaultQuery,
      hintText,
      url,
      containerClassName,
      selectOnBlur,
      inputVariant,
      onChange,
      onKeyPress,
      onKeyDown,
      onFocus,
      onBlur,
      
      ...props
    } = this.props;
    
    delete props.onSelectValue;
    delete props.isGeolocationAvailable;
    delete props.isGeolocationEnabled;
    delete props.positionError;
    delete props.coords;
    
    const lang = 'ru'; // todo
    
    const addressProps = {
      token: this.state.dadataToken,
      value,
      defaultQuery: defaultQuery,
      delay: 150,
      minChars: 1,
      hintText: hintText,
      url: url,
      httpCache: true,
      filterLanguage: lang,
      containerClassName: ['react-dadata', 'react-dadata__container', containerClassName || ''].join(' '),
      selectOnBlur: selectOnBlur !== false,
      onChange: (data) => this.handleSelectAddress(data),
      customInput: AddressTextField,
      inputProps: {
        ...props,
        
        variant: inputVariant,
        
        onInputChange: onChange,
        onInputKeyPress: onKeyPress,
        onInputKeyDown: onKeyDown,
        onInputFocus: onFocus,
        onInputBlur: onBlur
      }
    };
    
    if (type === 'city') {
      this.determineAddress();
      
      addressProps.filterFromBound = 'city';
      addressProps.filterToBound = 'settlement';
      
      if (addressProps.value) {
        addressProps.value = {
          value: addressJoin(type, addressProps.value.data) || addressProps.value.value,
          data: { ...addressProps.value.data }
        };
      }
      
      if (ownerValue) {
        addressProps.filterLocations = [{
          country_iso_code: ownerValue.data.country_iso_code,
          region_iso_code: ownerValue.data.region_iso_code,
          region_fias_id: ownerValue.data.region_fias_id
        }];
      } else {
        addressProps.filterLocations = [{
          country: '*'
        }];
      }
    } else if (type === 'street') {
      this.determineAddress();
      
      addressProps.restrictValue = true;
      addressProps.filterFromBound = 'street';
      addressProps.filterToBound = 'street';
      
      if (addressProps.value) {
        if (addressProps.value.data.street_with_type) {
          addressProps.value = {
            value: addressJoin(type, addressProps.value.data) || addressProps.value.value,
            data: addressProps.value.data
          };
        } else {
          addressProps.value = null;
        }
      }
      
      if (ownerValue) {
        addressProps.filterLocations = [{
          country_iso_code: ownerValue.data.country_iso_code,
          region_iso_code: ownerValue.data.region_iso_code,
          region_fias_id: ownerValue.data.region_fias_id,
          city_fias_id: ownerValue.data.city_fias_id
        }];
      }
    } else {
      if (addressProps.value) {
        addressProps.value = {
          value: addressJoin(type, addressProps.value.data) || addressProps.value.value,
          data: { ...addressProps.value.data }
        };
      }
    }
    
    if (!addressProps.defaultQuery && addressProps.value)
      addressProps.defaultQuery = addressJoin(type, addressProps.value.data) || addressProps.value.value;
    
    return (
      <AddressSuggestionsEx {...addressProps} />
    );
  }
  
};

const GeolocatedAddressInput = geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 5000
})(AddressInput);

const geolocatedComponentDidMount = GeolocatedAddressInput.prototype?.componentDidMount;

if (typeof geolocatedComponentDidMount === 'function') {
  GeolocatedAddressInput.prototype.componentDidMount = function() {
    try {
      geolocatedComponentDidMount.call(this);
    } catch (e) {
    }
  };
}

export default GeolocatedAddressInput;


class AddressSuggestionsEx extends AddressSuggestions {
  
  constructor(props) {
    super(props);
    
    this.getLoadSuggestionsData = () => {
      const { count, filterFromBound, filterToBound, filterLocations, filterLocationsBoost, filterLanguage } = this.props;
      
      const query = this.state.query;
      
      const result = {
        query: query,
        count: count || 10
      };
      
      // Ограничение поиска по типу
      if (filterFromBound && filterToBound) {
        result.from_bound = { value: filterFromBound };
        result.to_bound = { value: filterToBound };
      }
      
      // Язык подсказок
      if (filterLanguage)
        result.language = filterLanguage;
      
      // Сужение области поиска
      if (filterLocations)
        result.locations = filterLocations;
      
      // Приоритет города при ранжировании
      if (filterLocationsBoost)
        result.locations_boost = filterLocationsBoost;
      
      if (props.restrictValue)
        result.restrict_value = true;
      
      return result;
    };
    
    const superPerformFetchSuggestions = this.performFetchSuggestions;
    
    this.performFetchSuggestions = () => {
      const { url } = this.props;
      
      if (typeof url === 'function') {
        const { minChars } = this.props;
        const { query } = this.state;
        
        const queryLength = query?.length || 0;
        
        if (typeof minChars === 'number' && minChars > 0 && queryLength < minChars) {
          this.setState({ suggestions: [], suggestionIndex: -1 });
          
          return;
        }
        
        url(this.getLoadSuggestionsData())
          .then((suggestions) => this.didMount && this.setState({ suggestions, suggestionIndex: -1 }));
      } else {
        superPerformFetchSuggestions();
      }
    };
    
    if (typeof props.delay === 'number' && props.delay > 0) {
      this.fetchSuggestions = lodash.debounce(this.performFetchSuggestions, props.delay);
    } else {
      this.fetchSuggestions = this.performFetchSuggestions;
    }
  }
  
}

const fetchDadata = (token, method, post) => {
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/' + method;
  
  var options = {
    method: post ? 'POST' : 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Token ' + token
    },
    body: post ? JSON.stringify(post) : undefined
  };
  
  return fetch(url, options).then((response) => response.json());
};
