import React, { useState } from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { StepsControls } from '_core/components/StepsControls'
import { PaymentConfirm } from 'basic/views/PaymentConfirm'
import { SecurityCheck } from '_core/components/SecurityCheck'
import { OrderDetails } from '_core/views/OrderDetails'

export const RawPaymentStepConfirm = props => {
  const { load, goPrev, goNext, isTopup, isInvestment } = props

  const {
    config: { PAYMENT_GROUP, PAYMENT_GROUP_TYPE },
  } = useGlobal()

  const [hasDetails, setHasDetails] = useState(false)

  let methodData = null
  let useAccount = true

  if (load.method) {
    methodData = load.payment.external.find(
      item => `${item.id}` === `${load.method}`
    )
    useAccount =
      PAYMENT_GROUP_TYPE[methodData.group.id] === PAYMENT_GROUP.ACCOUNT
  }

  const hasCheck = load.isInternalCash || useAccount

  const getOnNextStep = check => async () => {
    if (hasCheck && !(await check())) {
      return
    }

    goNext({
      order: load.order,
      method: load.method,
      account: load.account,
      payment: load.payment,
      documentId: load.documentId,
      isInternalFull: load.isInternalFull,
      isInternalCash: load.isInternalCash,
    })
  }

  return (
    <>
      <PaymentConfirm
        method={load.method}
        payment={load.payment}
        isTopup={isTopup}
        isInvestment={isInvestment}
        isInternalFull={load.isInternalFull}
        isInternalCash={load.isInternalCash}
      />

      {load.order != null ? (
        <OrderDetails
          order={load.order}
          isModal={true}
          isOpen={hasDetails}
          onClose={() => setHasDetails(false)}
        />
      ) : null}

      <SecurityCheck
        render={({ check, renderCheckForm }) => (
          <>
            <StepsControls
              nextButtonText="r._.finance.transmit.control.submit"
              onPrevStep={() => goPrev()}
              onNextStep={getOnNextStep(check)}
            />
            {hasCheck ? renderCheckForm() : null}
          </>
        )}
      />
    </>
  )
}

RawPaymentStepConfirm.defaultProps = {}

RawPaymentStepConfirm.propTypes = {
  // self props
  load: PropTypes.object.isRequired,
  goPrev: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  isTopup: PropTypes.bool,
  isInvestment: PropTypes.bool,
}

export const PaymentStepConfirm = RawPaymentStepConfirm
