import React from 'react';
import PropTypes from 'prop-types';

import { usePopper } from 'react-popper';


export default (props) => { // https://popper.js.org/react-popper/v2/
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  
  const modifiers = [];
  
  if (props.arrow)
    modifiers.push({ name: 'arrow', options: { element: arrowElement } });
  
  const { styles, attributes, ...popperProps } = usePopper(referenceElement, popperElement, {
    placement: props.placement || 'bottom', // auto | auto-start | auto-end | top | top-start | top-end | bottom | bottom-start | bottom-end | right | right-start | right-end | left | left-start | left-end
    strategy: props.strategy || 'absolute', // absolute | fixed
    modifiers
  });
  
  const popperStyles = { ...styles.popper };
  
  if (referenceElement) {
    if (props.fullWidth)
      popperStyles.width = referenceElement.offsetWidth + 'px';
    
    if (props.fullHeight)
      popperStyles.height = referenceElement.offsetHeight + 'px';
  }
  
  const paramProps = { popperProps: { ref: setPopperElement, style: popperStyles, ...attributes.popper } };
  
  if (props.arrow)
    paramProps.arrowProps = { ref: setArrowElement, style: styles.arrow };
  
  const children = typeof props.children === 'function' && props.children({ ref: setReferenceElement, ...popperProps });
  const popper = typeof props.popper === 'function' && props.popper(paramProps);
  
  return (
    <>
      {children}
      {popper}
    </>
  );
};
