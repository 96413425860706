export function loadTopicEntry (data) {
  const topic = loadTopic(data.topic);
  const lastMessageTime = new Date(data.last_message_time);

  return createTopicEntry(topic, lastMessageTime);
}

export function createTopicEntry (topic, lastMessageTime) {
  return { ...topic, lastMessageTime };
}

export function loadTopic (data) {
    const author = data.author;

    return {
      id: data.id,
      isSystem: data.is_system,
      photo: author.photo.path,
      author: `${author.fullname || author.login}`,
      subject: data.subject
    };
}

export function loadMessage (data) {
  return {
    id: data.id,
    timestamp: new Date(data.time),
    content: data.content,
    files: data.files,
    isRead: data.is_read,
    author: data.author,
    isDashboardShow: data.is_dashboard_show
  }
}

export function isMine (visitorInfo, message) {
  return (visitorInfo.info.id === message.author.id);
}
