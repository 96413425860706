export const profileVerificationStyle = theme => ({
  panel: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },

  panelSummary: {
    padding: 0,
  },

  panelSummarySuccess: {
    display: 'flex',
    margin: theme.spacing(1.5, 0),
  },

  panelDetails: {
    paddingLeft: theme.spacing(7),
    paddingRight: 0,
  },

  panelDetailsBlank: {},

  panelDetailsFailure: {
    flexDirection: 'column',
  },

  errorMessage: {
    marginBottom: theme.spacing(4),
  },

  panelIcon: {},

  panelIconBlank: {},

  panelIconPending: {
    color: theme.variables.colors.info,
  },

  panelIconSuccess: {
    color: theme.palette.success.main,
  },

  panelIconFailure: {
    color: theme.palette.error.main,
  },

  panelTitle: {},

  panelTitleBlank: {
    fontWeight: theme.typography.fontWeightBold,
  },

  panelTitlePending: {},

  panelTitleSuccess: {},

  panelTitleFailure: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main,
  },
})
