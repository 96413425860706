import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

// @material
import { withStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { customCallback } from '_core/utils/customCallback'
import { getFieldOptions } from '_core/utils/getFieldOptions'
import { RegisterFormView } from './RegisterFormView'

const RawRegisterForm = props => {
  const {
    parentLogin,
    onSuccess,
    onFailure,
    successMessage,
    hasSuccessMessage,
    hasFailureMessage,
    isParentHidden,
    fieldsOrder,
    fieldsOptions,
    hasParentName,
    hasParentSwitch,
    getLangString: l,
  } = props

  const {
    notify: { enqueueSnackbar },
    request: { queue },
    config: {
      API_METHOD,
      REGISTER_FIELD,
      // CLIENT_AGE_MAX,
      // CLIENT_AGE_MIN,
    },
  } = useGlobal()

  const theme = useTheme()
  const [cookies] = useCookies(['parent'])

  const parent = useMemo(() => {
    try {
      return parentLogin || decodeURIComponent(cookies['parent'].replace(/\-/g, '%'))
    } catch (_) {
      return cookies['parent']
    }
  }, [cookies['parent']])

  const [values, setValues] = useState({ parent })
  const [canSubmit, setCanSubmit] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const [hasParent, setHasParent] = useState(!isParentHidden)
  const registerCallback = customCallback(theme.pages.goalCallback, 'REGISTER')

  const hiddenFields = isParentHidden ? { parent } : undefined
  const fieldNames = Object.values(REGISTER_FIELD)
  const rawHasParentSwitch = !!hasParentSwitch && parent == null

  const method =
    API_METHOD.VISITOR_CREATE +
    (rawHasParentSwitch && !hasParent ? '_no_parent' : '')

  const getOnChange = field => value => {
    setValues(oldValues => ({
      ...oldValues,
      [field]: value,
    }))
  }

  const onSubmit = async event => {
    event.preventDefault()
    registerCallback()
    let newInfo = {
      ...values,
      ...(values.birth_date
        ? {
            birth_date:
              typeof values.birth_date === 'string'
                ? values.birth_date
                : values.birth_date.format('DD.MM.YYYY'),
          }
        : {}),
      ...(values.city
        ? {
            city: values.city.id,
          }
        : {}),
    }

    try {
      const result = await queue(API_METHOD.VISITOR_CREATE, {
        form: method,
        ...newInfo,
      })

      hasSuccessMessage &&
        enqueueSnackbar(l(successMessage), { variant: 'success' })

      typeof onSuccess === 'function' && onSuccess({ values, result })
    } catch (error) {
      process.env.NODE_ENV !== 'production' &&
        hasFailureMessage &&
        enqueueSnackbar(error.message, { variant: 'error' })

      typeof onFailure === 'function' && onFailure({ values, error })
    }
  }

  // const now = Date.now()

  const getFieldOnChangeSave = () => (value) => {
    setValues((oldValues) => {
      oldValues = { ...oldValues };
      
      if (value) {
        oldValues.city = { ...value.data.city, value: value.value };
        oldValues.region = { ...value.data.region };
        oldValues.country = { ...value.data.country };
      } else {
        delete oldValues.city;
        delete oldValues.region;
        delete oldValues.country;
      }
      
      return oldValues;
    })
  }

  const fields = fieldsOrder.reduce((result, field) => {
    if (field === 'parent' && !hasParent) {
      return result
    }

    if (fieldNames.includes(field)) {
      result[field] = getFieldOptions({
        value: values[field],
        label: l(`r._.guest.register.form.${field}.label`),
        onChange: getOnChange(field),
        onChangeSave: getFieldOnChangeSave(field),
        options: fieldsOptions[field],
      })
    }

    return result
  }, {})

  return (
    <RegisterFormView
      method={method}
      fields={fields}
      parentLogin={parentLogin}
      hiddenFields={hiddenFields}
      fieldsOrder={fieldsOrder}
      fieldsOptions={fieldsOptions}
      onSubmit={onSubmit}
      setCanSubmit={setCanSubmit}
      setAllChecked={setAllChecked}
      submitDisabled={!canSubmit || !allChecked}
      hasParent={hasParent}
      hasParentName={hasParentName}
      parentCookie={parent}
      hasParentSwitch={rawHasParentSwitch}
      onParentToggle={() => setHasParent(v => !v)}
    />
  )
}

RawRegisterForm.defaultProps = {
  hasSuccessMessage: true,
  hasFailureMessage: true,
  successMessage: 'r._.register.text.success',
}

RawRegisterForm.propTypes = {
  // self props
  fieldsOptions: PropTypes.object,
  fieldsOrder: PropTypes.arrayOf(PropTypes.string),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  isParentHidden: PropTypes.bool,
  hasParentName: PropTypes.bool,
  hasParentSwitch: PropTypes.bool,
  hasSuccessMessage: PropTypes.bool,
  hasFailureMessage: PropTypes.bool,
  successMessage: PropTypes.string,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const RegisterForm = withCatch(
  withLang(withStyles({}, { name: 'RtsRegisterForm' })(RawRegisterForm))
)
