const spacingStyle = {
  marginTop: 0,
  marginBottom: 0,
}

export const overrides = theme => ({
  root: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },

  'spacing-xs-1': spacingStyle,
  'spacing-xs-2': spacingStyle,
  'spacing-xs-3': spacingStyle,
  'spacing-xs-4': spacingStyle,
  'spacing-xs-5': spacingStyle,
  'spacing-xs-6': spacingStyle,
  'spacing-xs-7': spacingStyle,
  'spacing-xs-8': spacingStyle,
  'spacing-xs-9': spacingStyle,
  'spacing-xs-10': spacingStyle,
})

export const props = {
  spacing: 2,
}
