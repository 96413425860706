import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Box, Tooltip, IconButton } from '@material-ui/core'
import { FirstPage, LastPage } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'
import { useGlobal } from '_core/hooks/useGlobal'

// style
import { appLogoStyle } from './appLogoStyle'

export const RawAppLogo = props => {
  const { classes, logo, isMini, minimizeSidebar, getLangString: l } = props

  const {
    config: { REACT_APP_INDEX_URL },
  } = useGlobal()

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.rootMini]: isMini,
  })

  const logoClasses = clsx({
    [classes.logo]: true,
    [classes.logoMini]: isMini,
  })

  const linkClasses = clsx({
    [classes.link]: true,
    [classes.linkMini]: isMini,
  })

  const hideClasses = clsx({
    [classes.hide]: true,
    [classes.hideMini]: isMini,
  })

  return (
    <Box
      className={rootClasses}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <a
        className={linkClasses}
        href={REACT_APP_INDEX_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src={logo}
          className={logoClasses}
          alt={process.env.REACT_APP_NAME}
        />
      </a>

      <Tooltip
        title={isMini ? l('r.show.menu') : l('r.hide.menu')}
        placement="right"
      >
        <IconButton
          className={hideClasses}
          onClick={minimizeSidebar}
          disableRipple={true}
          size="small"
        >
          {isMini ? <LastPage /> : <FirstPage />}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

RawAppLogo.propTypes = {
  // self props
  logo: PropTypes.string,
  isMini: PropTypes.bool,
  minimizeSidebar: PropTypes.func.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const AppLogo = withLang(
  withStyles(appLogoStyle, { name: 'RtsAppLogo' })(RawAppLogo)
)
