import React, { useState } from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { State } from '_core/components/State'
import { ModalOuter } from '_core/components/ModalOuter'
import { ModalInner } from '_core/components/ModalInner'
import { ServiceSteps } from '_core/views/ServiceSteps'
import { PaymentResult } from '_core/views/PaymentResult'

export const RawServiceModal = props => {
  const { onClose, service, agreement, getLangString: l } = props
  const [result, setResult] = useState()

  const {
    request: { queue },
    config: { API_METHOD, REPLACE_CURRENCY },
  } = useGlobal()

  const rawOnClose = () => {
    setResult()
    onClose()
  }
  if (!service) {
    return null
  }
  
  const getData = () => {
    const params = /^\d+$/.test(service) ? { id: service } : { path: service };
    
    params.currency = REPLACE_CURRENCY.SHOP_GOODS;
    
    return queue(API_METHOD.GOODS_INFO, params);
  };
  
  const createDoc = () => {
    if (agreement !== 'annual_fee') {
      return Promise.resolve()
    } else {
      return queue(
        API_METHOD.ORDER_CREATE,
        {
          shop: 'start',
          goods: [{ goods_id: parseInt(service, 10), count: 1 }],
        },
        RawServiceModal.displayName
      )
    }
  }

  return (
    <ModalOuter open={true} onClose={rawOnClose} fullWidth={true} maxWidth="md">
      <ModalInner
        title={l('r.oplata')}
        content={
          result != null ? (
            <PaymentResult
              onRetry={() => setResult()}
              onClose={rawOnClose}
              {...result}
            />
          ) : (
            <State
              getProps={{ data: getData, paymentDoc: createDoc }}
              render={({ data, paymentDoc }) => {
                return (
                  <ServiceSteps
                    goodsData={data}
                    paymentDoc={paymentDoc}
                    setResult={setResult}
                  />
                )
              }}
            />
          )
        }
      />
    </ModalOuter>
  )
}

RawServiceModal.defaultProps = {}

RawServiceModal.propTypes = {
  // self props
  service: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ServiceModal = withLang(RawServiceModal)
