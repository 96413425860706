// @material-ui
import { makeStyles } from '@material-ui/styles'

export const serviceAgreementStyle = theme => ({
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
})

export const useServiceAgreementStyle = makeStyles(serviceAgreementStyle)
