import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Icon,
  Box,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// style
import { modalInnerStyle } from './modalInnerStyle'

// eslint-disable-next-line react/display-name
const RawModalInner = props => {
  const {
    classes,
    header,
    title,
    actions,
    content,
    hasHeader,
    icon,
    className,
  } = props

  return (
    <Box className={[classes.root, className]}>
      {hasHeader && (header || title) ? (
        <DialogTitle disableTypography className={classes.header}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {icon != null ? <Icon className={classes.icon}>{icon}</Icon> : null}

            {title != null ? (
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            ) : null}
          </Box>

          {header}
        </DialogTitle>
      ) : null}

      {content != null ? (
        <DialogContent className={classes.content}>{content}</DialogContent>
      ) : null}

      {actions != null ? (
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      ) : null}
    </Box>
  )
}

RawModalInner.defaultProps = {
  hasHeader: true,
}

RawModalInner.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.node,
  header: PropTypes.node,
  actions: PropTypes.node,
  content: PropTypes.node,
  hasHeader: PropTypes.bool,
}

export const ModalInner = withStyles(modalInnerStyle)(RawModalInner)
