export const overrides = theme => ({
  root: {
    color: theme.palette.text.primary,
    fontFamily: `"PT Sans Caption Bold", ${theme.variables.fontFamily}`,
  },
  error: {
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.error.main,
    },
  },
  input: {
    fontSize: theme.typography.body1.fontSize,
  },
})
