export const valueStyle = theme => ({
  value: {
    fontSize: '34px',
    lineHeight: 1.2,
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    width: '100%',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  textPrimaryColor: {
    color: theme.palette.text.primary,
  },
  textSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blackColor: {
    color: theme.palette.text.primary,
  },
  whiteColor: {
    color: 'white',
  },
  smallSize: {
    fontSize: theme.typography.h5.fontSize,
  },
  smallerSize: {
    fontSize: theme.typography.h6.fontSize,
  },
  textSize: {
    fontSize: theme.typography.body1.fontSize,
  },
  bottomMargin: {
    marginBottom: '4px',
  },
})
