//import React from 'react';


export const join = (type, data) => {
  if (!data)
    return null;
  
  if (data.id && typeof data.country === 'object' && typeof data.region === 'object' && typeof data.city === 'object')
    return data.country.title + ', ' + data.region.title + ', ' + data.city.title;
  
  let result;
  
  if (type === 'address') {
    result = join('city', data);
    
    // todo
  } else if (type === 'city') {
    result = data.country;
    
    if (data.region_with_type !== data.city_with_type)
      result += ', ' + data.region_with_type;
    
    if (data.city_with_type)
      result += ', ' + data.city_with_type;
    
    if (data.city_district_with_type)
      result += ', ' + data.city_district_with_type;
    
    if (data.area_with_type)
      result += ', ' + data.area_with_type;
    
    if (data.settlement_with_type)
      result += ', ' + data.settlement_with_type;
  } else {
    result = data[type + '_with_type']
  }
  
  return result;
};
