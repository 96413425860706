import React, { useState } from 'react'

// core
import { getDisplayName } from '_core/utils/getDisplayName'
import { withLang } from '_core/hocs/withLang'
import { State } from '_core/components/State'

export const withCatch = (Component, message) => {
  const WithCatch = props => {
    const [nodeKey, setNodeKey] = useState(0)

    return (
      <State
        key={nodeKey}
        message={message}
        onReset={() => setNodeKey(key => key + 1)}
      >
        <Component {...props} />
      </State>
    )
  }

  const wrappedComponent = getDisplayName(Component)

  WithCatch.displayName = `WithCatch(${wrappedComponent})`

  return withLang(WithCatch)
}
