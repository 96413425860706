import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc'

export const getProfilePhotoId = (profile, defaultId) => {
  const { photo: { id = defaultId } = {} } = profile
  return id
}

export const getProfileRegionalAddress = profile => {
  if (profile.city !== null) {
    return profile.city.value
  }
  return ''
}

export const getProfileCity = profile => profile.city

export const getProfilePhotoPath = (profile, defaultPath) => {
  const { photo: { path = defaultPath } = {} } = profile
  return path
}

export const getProfilePhotoSrc = (profile, size) =>
  getVisitorPhotoSrc(getProfilePhotoPath(profile), size)

export const hasProfilePhoto = profile => getProfilePhotoId(profile) > 0
