import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

// moment
import moment from 'moment'

// @material-ui
import { Button, IconButton, TextField, Typography } from '@material-ui/core'
import { AttachFile, Attachment, Send } from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'
import { useGlobal } from '_core/hooks/useGlobal'

import { withVisitorInfo } from '_core/utils/visitorInfo';

import { FileUpload } from '_core/components/FileUpload'
import { Loading } from '_core/components/Loading'
import NoteView from '_core/components/NoteView'

import {
  isMine,
  loadMessage,
  loadTopic
} from '_core/views/MessagesSplash/util.js'

import './Messenger.css'

const RawTopicView = (props) => {
  const { topicId, selectedMessageId, visitorInfo, getLangString: l } = props;

  const {
    request: { queue },
    config: { API_METHOD },
    notify: { enqueueSnackbar }
  } = useGlobal();

  const [topic, setTopic] = useState([]);

  const [messages, setMessages] = useState([]);

  const [currentMessage, setCurrentMessage] = useState(null);

  const [files, setFiles] = useState(null); // message input files

  const [readQueue, setReadQueue] = useState(new Set());

  const [submitWorkState, setSubmitWorkState] = useState(0);

  const messageRefs = useRef({
    messagesByElement: new Map(),
    elementsByMessage: new Map()
  });

  const getCurrentMessage = (messages) => {
    if (selectedMessageId != null) {
      for (let message in messages) {
        if (message.id === selectedMessageId) {
          return message;
        }
      }
    }
    
    // выбираем первое непрочитанное сообщение
    for (var message of messages) {
      if (!message.isRead) {
        return message;
      }
    }

    // выбираем последнее сообщение
    if (messages.length > 0) {
      return messages[(messages.length - 1)];
    }

    // нет сообщений
    return null;
  }

  useEffect(() => {
    queue(API_METHOD.VISITOR_MESSAGE_LIST, { "topic_id": topicId }, RawTopicView.displayName)
      .then(result => {
        const topic = loadTopic(result.topic);
        const messages = result.messages.map(loadMessage);

        setTopic(topic);
        setMessages(messages);

        const messageOrNull = getCurrentMessage(messages);
        setCurrentMessage(messageOrNull);
      });
  }, [topicId]);

  const findLast = (array, fn) => {
    for (var i = (array.length - 1); i >= 0; i--) {
      const item = array[i];
      const result = fn(item);
      if (result) {
        return item;
      }
    }

    return null;
  };

  useEffect(() => {
    const unreadMessages = visitorInfo.listUnreadMessagesForTopic(topicId);
    setMessages(messages => {
      const newMessages = [ ];
      for (var unreadMessage of unreadMessages) {
        const found = findLast(messages, x => x.id === unreadMessage.id);
        if (!found) {
          newMessages.push(unreadMessage);
        }
      }

      return [ ...messages, ...newMessages ];
    });
  }, [visitorInfo]);

  useEffect(() => {
    if (currentMessage != null) {
      const messageElement = messageRefs.current.elementsByMessage.get(currentMessage);
      if (messageElement != null) {
        messageElement.scrollIntoView();
      }
    };
  }, [currentMessage]);

  const callbackFunction = (entries) => {
    const sent = new Set();
    
    for (let entry of entries) {
      const element = entry.target;
      const message = messageRefs.current.messagesByElement.get(element);
      if (message != null) {
        if (!readQueue.has(message) && !isMine(visitorInfo, message) && !message.isRead) {
          readQueue.add(message);
          sent.add(message);
        }
      } else {
        console.warn("message for element =" + element + " not found");
      }
    }

    if (sent.size > 0) {
      const messageIds = [ ];
      for (let message of sent.values()) {
        messageIds.push(message.id);
      }
      
      queue(API_METHOD.VISITOR_MESSAGE_HAD_READ, { "message_ids": messageIds }, RawTopicView.displayName)
        .then(() => {
          setReadQueue(readQueue => {
            sent.forEach(message => {
              message.isRead = true;
              readQueue.delete(message);
            });

            return readQueue;
          });
        });
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    thresold: 1.0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);

    const observing = [ ];

    for (let messageElement of messageRefs.current.elementsByMessage.values()) {
        observer.observe(messageElement);
        observing.push(messageElement);
    }
    
    return () => {
      for (let messageElement of observing) {
        observer.unobserve(messageElement);
      }
    }
  });
  
  const renderMessages = () => {
    if (messages == null) {
      return (<Loading />);
    } else {
      let i = 0;
      let messageCount = messages.length;
      let tempMessages = [];
   
      while (i < messageCount) {
        let previous = messages[i - 1];
        let current = messages[i];
        let next = messages[i + 1];

        let messageId = current.id;
        let mine = isMine(visitorInfo, current);
        let currentMoment = moment(current.timestamp);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        let files = current.files;
        let author = current.author;
   
        if (previous) {
          let previousMoment = moment(previous.timestamp);
          let previousDuration = moment.duration(currentMoment.diff(previousMoment));
          prevBySameAuthor = (previous.author.id === current.author.id);
          
          if (prevBySameAuthor && previousDuration.as('days') < 1) {
            startsSequence = false;
          }
   
          if (previousDuration.as('days') < 1) {
            showTimestamp = false;
          }
        }
   
        if (next) {
          let nextMoment = moment(next.timestamp);
          let nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = (next.is_mine === current.is_mine);
   
          if (nextBySameAuthor && nextDuration.as('days') < 1) {
            endsSequence = false;
          }
        }

        let currentTimestamp = moment(current.timestamp);

        // если предыдущее сообщение не от того опльзователя, 
        if (showTimestamp) {
          const friendlyTimestamp = currentTimestamp.format('LL');
          tempMessages.push(
            <div key={"timestamp_" + current.timestamp} className="timestamp">
              {friendlyTimestamp}
            </div>
          );
        }
        
        if (startsSequence) {
          if (mine) {
            tempMessages.push(
              <div key={"start-sequence-" + messageId} className="sequence-header-mine">
                {currentTimestamp.format('HH:mm')}
              </div>
            );
          } else {
            tempMessages.push(
              <div key={"start-sequence-" + messageId} className="sequence-header">
                {author.fullname || author.login} {currentTimestamp.format('HH:mm')}
              </div>
            );
          }
        }
   
        tempMessages.push(
          <div
            ref={(el) => {
             if (el != null) {
               messageRefs.current.messagesByElement.set(el, current);
               messageRefs.current.elementsByMessage.set(current, el);
             }
            }}
            key={messageId}
            className={[
              'message',
              `${mine ? 'mine' : ''}`,
              `${startsSequence ? 'start' : ''}`,
              `${endsSequence ? 'end' : ''}`
            ].join(' ')}>
    
            <div className="bubble-container">
              <div className="bubble">
                <NoteView>{current.content}</NoteView>
              </div>
            </div>
          </div>
        );
        
        files.forEach(file => {
          const title = file.title;
          const path = file.path;

          tempMessages.push(
            <div key={messageId + '-file-' + title}
              className={[
                'message',
                `${mine ? 'mine' : ''}`,
                `${startsSequence ? 'start' : ''}`,
                `${endsSequence ? 'end' : ''}`
              ].join(' ')}>
      
              <div className="bubble-container">
                <div className="attachment">
                  <Button
                    startIcon={<Attachment />}
                    onClick={() => {
                      const link = document.createElement("a");
                      link.download = title;
                      link.href = path;
                      link.click();
                    }}
                    variant="text"
                    color="third"
                    size="small">
                      {title}
                  </Button>
                </div>
              </div>
            </div>
          );
        });
   
        // Proceed to the next message.
        i += 1;
      }
   
      return tempMessages;
    }
  };
  
  const messageTextInputRef = useRef(null);

  const onSubmit = async event => {
    event.preventDefault();

    const text = messageTextInputRef.current.value;
    const fileIds = files?.map((file) => file.id);

    if (text !== "") {
      const parameters = {
        "topic_id": topicId,
        "message": {
          "text": text,
          "files": fileIds
        }
      };

      queue(API_METHOD.VISITOR_MESSAGE_SEND, parameters, RawTopicView.displayName)
        .then(result => {
          const message = loadMessage(result.message);

          setMessages(messages => [...messages, message]);
          setCurrentMessage(message);

          messageTextInputRef.current.value = "";
          setFiles(null);

          setSubmitWorkState(prev => prev + 1);
        }).catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  const messageFooter = () => {
    if (!topic.isSystem) {
      return (
        <form onSubmit={onSubmit} method="POST" style={{ "width": "100%" }}>
          <FileUpload
            key={submitWorkState}
            category="message"
            onResultChange={setFiles}
            hasHelperText={false}
            title={""}
            buttonProps={{
              children: (<AttachFile />)
            }}
          />
          <div style={{ display: "flex" }}>
            <TextField
              inputRef={messageTextInputRef}
              name="message"
              type="text"
              className="compose-input"
              placeholder="Type a message, @name"
              style={{ "width": "79%" }}
            />
            <IconButton type="submit">
              <Send />
            </IconButton>
          </div>
        </form>
      );
    } else {
      return (
        <Typography>
          {l('r._.messenger.system.message')}
        </Typography>
      );
    }
  }

  return (
    <div className="content">
      <div className="scrollable content">
        <div className="message-list">
          <div className="message-list-container">
            {renderMessages()}
          </div>
        </div>
      </div>
      <div className="compose">
        {messageFooter()}
      </div>
    </div>
  );
}

RawTopicView.defaultProps = {}

RawTopicView.propTypes = {
  // self props
  topicId: PropTypes.number.isRequired,
  selectedMessageId: PropTypes.number,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const TopicView = withVisitorInfo(withLang((RawTopicView)))
