import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Select from 'react-select'

// @material-ui
import { useTheme } from '@material-ui/core/styles'
import {
  Typography,
  TextField,
  Paper,
  Chip,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/styles'

import { withLang } from '_core/hocs/withLang'
import autocompleteInputStyle from './autocompleteInputStyle'

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
}

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  )
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

function RawAutocompleteInput({ ...props }) {
  const classes = autocompleteInputStyle()
  const theme = useTheme()
  const [isOpen, setIsOpen] = React.useState(false)
  const { onChange, options, helperText, setFieldTouched, ...rest } = props

  function handleChangeSingle(value) {
    setFieldTouched()
    onChange({
      target: {
        id: props.id,
        name: props.name,
        value: value.value,
      },
    })
  }
  function getOptionFromId() {
    if (!!props.options && !!props.value) {
      const option = props.options.find(option => option.id === props.value)
      return {
        value: option.id,
        label: option.title,
      }
    }
    return {
      value: '',
      label: '',
    }
  }
  const onMenuOpen = () => {
    setIsOpen(true)
    setFieldTouched()
  }
  const onMenuClose = () => {
    setIsOpen(false)
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.secondary,
      '& input': {
        font: 'inherit',
      },
    }),
  }
  // var test1 = {
  //   classes: {},
  //   id: 'orfpevg4ila-1568834749776-h6it1m0adki',
  //   name: 'country2',
  //   value: '',
  //   error: false,
  //   success: false,
  //   label: 'Страна',
  //   onBlur: 'bound handleBlur()',
  //   onChange: 'bound handleChange()',
  //   setFieldValue: 'setFieldValue()',
  //   setFieldTouched: 'setFieldTouched()',
  //   dispatch: 'dispatch()',
  //   wrappedComponent: 'RawFieldValidationWrapper',
  // }
  var suggestions = []
  if (props.options != null) {
    suggestions = props.options.map(suggestion => ({
      value: suggestion.id,
      label: suggestion.title,
    }))
  }
  return (
    <>
      <Select
        {...rest}
        classes={classes}
        styles={selectStyles}
        inputId="react-select-single"
        TextFieldProps={{
          label: props.label,
          InputLabelProps: {
            htmlFor: 'react-select-single',
            shrink: true,
          },
        }}
        placeholder={'Выбирете страну'}
        options={suggestions}
        components={components}
        value={getOptionFromId()}
        onChange={handleChangeSingle}
        error={props.error}
        menuIsOpen={isOpen}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
      />
      {helperText != null ? (
        <FormHelperText id={props.id + '-text'}>{helperText}</FormHelperText>
      ) : null}
    </>
  )
}
export const AutocompleteInput = withLang(
  withStyles(autocompleteInputStyle)(RawAutocompleteInput)
)
