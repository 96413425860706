import fnv from 'fnv-lite'

// core
import { code, decode } from '_core/utils/codeToBase64'

const DEFAULT_OPTIONS = {
  prefixKey: true,
  encode: process.env.NODE_ENV === 'production'
};

const currentValues = {};
const listnersMap = new Map();

export const encodeKey = (key, options) => {
  options = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS;
  
  if (options.encode)
    key = fnv.base64Url(key);
  
  if (options.prefixKey)
    key = process.env.REACT_APP_STORAGE_PREFIX + '.' + key;
  
  return key;
}

export const getValue = (key, options) => {
  options = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS;
  
  if (typeof window.localStorage === 'undefined')
    return currentValues[key];
  
  if (options.encode)
    key = fnv.base64Url(key);
  
  if (options.prefixKey)
    key = process.env.REACT_APP_STORAGE_PREFIX + '.' + key;
  
  let value = window.localStorage.getItem(key);
  
  if (value) {
    try {
      if (options.encode)
        value = decode(value);
      
      return JSON.parse(value);
    } catch (e) {
    }
  }
  
  return null;
};

export const setValue = (key, value, options) => {
  options = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS;
  
  const remove = value !== false && !value;
  
  if (remove) {
    delete currentValues[key];
  } else {
    currentValues[key] = value;
  }
  
  if (typeof window.localStorage === 'undefined')
    return;
  
  if (!remove)
    value = JSON.stringify(value);
  
  if (options.encode) {
    key = fnv.base64Url(key);
    value = code(value);
  }
  
  if (options.prefixKey)
    key = process.env.REACT_APP_STORAGE_PREFIX + '.' + key;
  
  if (remove) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, value);
  }
};

export const addListener = (key, callback, options) => {
  options = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS;
  
  if (options.encode)
    key = fnv.base64Url(key);
  
  if (options.prefixKey)
    key = process.env.REACT_APP_STORAGE_PREFIX + '.' + key;
  
  const cb = (event) => {
    if (event.key !== key)
      return;
    
    if (options.encode) {
      if (event.oldValue) {
        try {
          if (options.encode)
            event.oldValue = decode(event.oldValue);
          
          event.oldValue = JSON.parse(event.oldValue);
        } catch (e) {
          event.oldValue = null;
        }
      }
      
      if (event.newValue) {
        try {
          if (options.encode)
            event.newValue = decode(event.newValue);
          
          event.newValue = JSON.parse(event.newValue);
        } catch (e) {
          event.newValue = null;
        }
      }
    }
    
    callback(event);
  };
  
  listnersMap.add(callback, cb);
  
  window.addEventListener('storage', cb);
};

export const removeListener = (key, callback) => {
  window.removeEventListener('storage', listnersMap.remove(callback));
};


const memoryValues = new Map();


export const get = (key) => {
  if (typeof window !== 'object')
    return null;
  
  if (typeof window.localStorage === 'undefined')
    return memoryValues.get(key);
  
  let value = window.localStorage.getItem(key);
  
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
    }
  }
  
  return null;
};

export const set = (key, value) => {
  if (typeof window !== 'object')
    return;
  
  const remove = value !== false && !value;
  
  if (typeof window.localStorage === 'undefined') {
    if (remove) {
      memoryValues.delete(key);
    } else {
      memoryValues.set(key, value);
    }
    
    return;
  }
  
  if (!remove)
    value = JSON.stringify(value);
  
  if (remove) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, value);
  }
};
