import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('s', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <s>{this.getComponents()}</s>
    );
  }
  
});
