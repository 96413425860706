// @material-ui
import { makeStyles } from '@material-ui/styles'

export const sidebarUserStyle = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),

    ...theme.mixins.sidebarUser,
  },

  rootMini: {
    padding: theme.spacing(0, 0.75),
  },

  textWrap: {
    flexGrow: 1,
    overflow: 'hidden',
  },

  text: {
    flexGrow: 1,
    overflow: 'hidden',
    justifyContent: 'center',
  },

  avatar: {
    marginRight: theme.spacing(2),
  },

  edit: {
    marginLeft: theme.spacing(2),
  },

  editMini: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  login: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

export const useSidebarUserStyle = makeStyles(sidebarUserStyle)
