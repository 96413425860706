import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useCookies } from 'react-cookie'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withCatch } from '_core/hocs/withCatch'
import { encodeKey } from '_core/utils/storage'
import { VerificationSplashView } from './VerificationSplashView'

const RawVerificationSplash = () => {
  const {
    request: { queue },
    notify: { enqueueSnackbar },
    config: {
      API_METHOD,
      ROUTE_URL_PARAM,
      PROFILE_TAB_URL,
      COOKIES_KEY,
      VERIFICATION_TYPE,
      VERIFICATION_STATUS,
      VERIFICATION_POSTPONE_TIME,
    },
  } = useGlobal()

  const history = useHistory()
  const location = useLocation()
  const [, setCookies] = useCookies([encodeKey(COOKIES_KEY.VERIFY)])
  const [verification, setVerification] = useState()
  const [isOpen, setIsOpen] = useState(true)

  const getVerification = async () => {
    try {
      const result = await queue(API_METHOD.VISITOR_VERIFICATION)
      setVerification(result)
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    getVerification()
  }, [])

  if (verification == null || verification.constructor !== Object) {
    return null
  }

  const showStates = [VERIFICATION_STATUS.BLANK, VERIFICATION_STATUS.FAILURE]
  const types = Object.values(VERIFICATION_TYPE)

  const requiredTypes = Object.keys(verification.types).filter(
    key =>
      types.includes(key) &&
      verification.types[key].is_required &&
      showStates.includes(verification.types[key].state)
  )

  if (requiredTypes.length === 0) {
    return null
  }

  const onPostpone = () => {
    const stamp = Date.now() + VERIFICATION_POSTPONE_TIME

    setCookies(encodeKey(COOKIES_KEY.VERIFY), 1, {
      path: '/',
      expires: new Date(stamp),
    })

    setIsOpen(false)
  }

  const onVerify = () => {
    const params = new URLSearchParams(location.search)

    params.set(ROUTE_URL_PARAM.PROFILE, PROFILE_TAB_URL.VERIFICATION)
    location.search = params.toString()

    setIsOpen(false)
    history.push(location)
  }

  return (
    <VerificationSplashView
      isOpen={isOpen}
      onPostpone={onPostpone}
      onVerify={onVerify}
      types={requiredTypes}
      verification={verification}
    />
  )
}

RawVerificationSplash.defaultProps = {}

RawVerificationSplash.propTypes = {}

export const VerificationSplash = withCatch(RawVerificationSplash)
