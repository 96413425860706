import isEmpty from 'lodash/isEmpty'

export const articleContainerStyle = theme => ({
  root: {
    maxWidth: '100%',
    '& *': {
      fontFamily: [theme.typography.fontFamily, '!important'],
      color: theme.palette.text.primary,

      '.MuiAlert-root &': {
        color: 'inherit !important',
      },
    },

    '& img, & video, & audio': {
      maxWidth: '100%',
    },
    '& a': {
      color: theme.variables.colors.contentLinksColor,
    },

    '& audio, & canvas, & progress, & video': {
      display: 'inline-block',
      verticalAlign: 'baseline',
    },
    '& p': {
      textAlign: 'justify',
    },

    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
    },

    '& :first-child': {
      marginTop: 0,
    },

    '& :last-child': {
      marginBottom: 0,
    },
  },

  video: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '56.25%',
    margin: theme.spacing(2, 0),

    '& iframe': {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
      top: 0,
      left: 0,
    },
  },

  table: {
    overflow: 'auto',
    display: 'block',

    '& table': {
      maxWidth: '100%',
      WebkitOverflowScrolling: 'touch',
      borderColor: `${theme.variables.colors.divider} !important`,
      borderRight: '0.01rem solid',
      borderTop: '0.01rem solid',

      '& td, & th': {
        fontFamily: [theme.variables.fontFamily, '!important'],
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        width: 'auto',
        height: 'auto',
        padding: [theme.spacing(0.5, 1)],
        borderColor: `${theme.variables.colors.divider} !important`,
        borderLeft: '0.01rem solid',
        borderBottom: '0.01rem solid',
        vertical: 'top',
      },
    },
    ...(isEmpty(theme.mixins.dangerouslySetInnerHTMLTable)
      ? {}
      : theme.mixins.dangerouslySetInnerHTMLTable),
  },
  copyDisable: {
    // userSelect: 'none',
  },
})
