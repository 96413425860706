export const overrides = theme => ({
  title: {
    // fontSize: '60px',
    // lineHeight: '28px',
    color: theme.palette.titleColor,
  },
  avatar: {
    display: 'flex',
  },
})

export const props = {
  titleTypographyProps: {
    variant: 'h2',
    component: 'p',
    gutterBottom: true,
  },
  subheaderTypographyProps: {
    variant: 'caption',
    component: 'p',
  },
}
