export const overrides = theme => ({
  multiline: {
    marginTop: 0,
    marginBottom: 0,
  },
})

export const props = {
  primaryTypographyProps: {
    variant: 'body2',
  },
  secondaryTypographyProps: {
    variant: 'subtitle2',
  },
}
