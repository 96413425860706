import React from 'react';

import { isEqual } from 'lodash';

const reducer = (state, action) => {
  switch (action.type) {
    case 'options':
      state.options = { ...state.options, ...action.value };
      
      return state;

    case 'info':
      return { ...state, info: action.value, isLoading: false };

    case 'info-update':
      return { ...state, info: action.value, update: state.update + 1, isLoading: false };

    case 'catalog-data':
      const catalogData = typeof action.value === 'function' ? action.value.call(null, state.catalogData) : action.value;
      
      if (state.catalogData === catalogData)
        return state;
      
      return { ...state, catalogData };

    case 'messages-init': {
      const lastMessages = new Map();

      for (var message of action.messages) {
        const topicId = message.topic.id;
        const lastMessage = lastMessages.get(topicId);
        if ((lastMessage == null) || (lastMessage.timestamp < message.timestamp)) {
          lastMessages.set(topicId, message);
        }
      }

      return {
        ...state,
        unreadMessages: [ ...action.messages ],
        lastMessages: lastMessages
      };
    }

    case 'message-received': {
      if (state.unreadMessages != null) {
        const message = action.message;
        {
          const lastMessages = state.lastMessages;
          
          const topicId = message.topic.id;
          const lastMessage = lastMessages.get(topicId);
          if ((lastMessage == null) || (lastMessage.timestamp < message.timestamp)) {
            lastMessages.set(topicId, message);
          }
        }

        return { ...state, unreadMessages: [ ...state.unreadMessages, message ] };
      } else {
        return state;
      }
    }

    case 'messages-had-read': {
      if (state.unreadMessages != null) {
        const messageIds = new Set(action.messageIds);
        return {
          ...state, unreadMessages: state.unreadMessages.filter(x => !messageIds.has(x.id))
        };
      } else {
        return state;
      }
    }
    
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const VisitorInfoContext = React.createContext();

export const VisitorInfoProvider = ({ children }) => {
  const initialData = {
    options: {},
    info: null,
    update: 0,
    isLoading: true,
    unreadMessages: null,
    lastMessages: null // lastMessages
  };
  
  const [controller, dispatch] = React.useReducer(reducer, initialData);

  return <VisitorInfoContext.Provider value={[controller, dispatch]}>
    {children}
  </VisitorInfoContext.Provider>;
}

export const useVisitorInfoContext = () => {
  const context = React.useContext(VisitorInfoContext);
  
  if (!context)
    throw new Error('useVisitorInfoContext should be used inside the VisitorInfoProvider');
  
  return context;
};

export const withVisitorInfoContext = (Component) => {
  return (props) => {
    const context = useVisitorInfoContext();
    
    return <Component {...props} visitorInfoContext={context} />
  };
};

export const useVisitorInfo = () => {
  const [controller, dispatch] = useVisitorInfoContext();
  
  const result = {
    ...controller,
    
    putOptions: (value) => {
      dispatch({ type: 'options', value });
    },
    
    set: (value, update = false) => {
      if (value) {
        value = { ...value };
        
        delete value.update_interval;
      }
      
      if (!update && !controller.info === !value && isEqual(controller.info, value))
        return;
      
      dispatch({ type: update ? 'info-update' : 'info', value });
    },
    
    getPrivileges: () => controller.info ? controller.info.privileges : ['__guest__'],
    
    isAuthorized: () => !!controller.info,
    isAllowed: (math) => isAllowedMath(result.getPrivileges(), math),
    
    inGroup: (group) => {
      if (!Array.isArray(group))
        return true;
      
      var visitor_group = controller.info?.visitor_group || [1];
      
      return group.some((item) => visitor_group.indexOf(item) >= 0);
    },
    
    setCatalogData: (value) => {
      dispatch({ type: 'catalog-data', value });
    },
    
    unreadMessagesInit: (messages) => {
      dispatch({
        type: 'messages-init',
        messages: messages
      });
    },

    unreadMessageReceived: (message) => {
      dispatch({
        type: 'message-received',
        message: message
      });
    },

    messagesHadRead: (messageIds) => {
      dispatch({
        type: 'messages-had-read',
        messageIds: messageIds
      });
    },

    listPopupMessages: () => {
      const unreadMessages = controller.unreadMessages;
      return (unreadMessages != null)
        ? unreadMessages.filter(x => x.isDashboardShow)
        : [ ];
    },

    listUnreadMessages: () => {
      return controller.unreadMessages || [ ];
    },

    listUnreadMessagesForTopic: (topicId) => {
      const messages = controller.unreadMessages || [ ];
      return messages.filter(x => x.topic.id === topicId);
    },

    getUnreadMessage: (messageId) => {
      return controller.unreadMessages.find(x => x.id === messageId)
    },

    messageIsMine: (message) => {
      return (controller.id === message.author.id);
    },

    lastMessage: (topicId) => {
      controller.lastMessages.get(topicId);
    }
  };
  
  return result;
};

export const withVisitorInfo = (Component) => {
  return (props) => {
    const visitorInfo = useVisitorInfo();
    
    return <Component {...props} visitorInfo={visitorInfo} />
  };
};

export const byVisitorPrivileges = (Component, privileges) => {
  return (props) => {
    const visitorInfo = useVisitorInfo();
    
    if (!visitorInfo.isAllowed(privileges))
      return null;
    
    return <Component {...props} />;
  };
};

export const isAllowedMath = (privileges, math) => {
  if (math == null || typeof(math) === 'undefined')
    return true;
  
  if (!privileges || !privileges.length || !math)
    return false;
  
  
  const mathList = math.replace(/([()])/g, ' $1 ').trim().split(/\s+/);
  
  while (mathList.length > 1) {
    const start = mathList.lastIndexOf('(');
    const stop = start < 0 ? mathList.length : mathList.indexOf(')', start);
    
    const currList = mathList.slice(start + 1, stop);
    
    
    let index;
    
    while ((index = currList.lastIndexOf('not')) >= 0) {
      const value = index >= currList.length - 1 ? true : currList[index + 1] === 'true' ? true : currList[index + 1] === 'false' ? false : privileges.indexOf(currList[index + 1]) >= 0;
      
      if (index < currList.length - 1)
        currList.splice(index + 1, 1);
      
      currList[index] = String(!value);
    }
    
    while ((index = currList.lastIndexOf('and')) >= 0) {
      const value1 = index === 0 ? false : currList[index - 1] === 'true' ? true : currList[index - 1] === 'false' ? false : privileges.indexOf(currList[index - 1]) >= 0;
      const value2 = index >= currList.length - 1 ? false : currList[index + 1] === 'true' ? true : currList[index + 1] === 'false' ? false : privileges.indexOf(currList[index + 1]) >= 0;
      
      if (index < currList.length - 1)
        currList.splice(index + 1, 1);
      
      if (index > 0)
        currList.splice(index, 1);
      
      currList[index - 1] = String(value1 && value2);
    }
    
    while ((index = currList.lastIndexOf('or')) >= 0) {
      const value1 = index === 0 ? false : currList[index - 1] === 'true' ? true : currList[index - 1] === 'false' ? false : privileges.indexOf(currList[index - 1]) >= 0;
      const value2 = index >= currList.length - 1 ? false : currList[index + 1] === 'true' ? true : currList[index + 1] === 'false' ? false : privileges.indexOf(currList[index + 1]) >= 0;
      
      if (index < currList.length - 1)
        currList.splice(index + 1, 1);
      
      if (index > 0)
        currList.splice(index, 1);
      
      currList[index - 1] = String(value1 || value2);
    }
    
    
    if (currList.length != 1)
      return false; // throw new Error('bad math: ' + currList.join(' '));
    
    
    if (start < 0 && stop >= mathList.length) {
      mathList.length = 0;
      mathList.push(...currList);
    } else {
      mathList.splice(Math.max(start, 0), Math.min(stop, mathList.length - 1) - Math.max(start, 0) + 1, ...currList);
    }
  }
  
  
  return mathList.length === 1 && (mathList[0] === 'true' ? true : mathList[0] === 'false' ? false : privileges.indexOf(mathList[0]) >= 0);
}
