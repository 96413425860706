import { mergeWith } from 'lodash'

// @material-ui
import { makeStyles } from '@material-ui/styles'

// core
import { dataMerger } from '_core/utils/dataMerger'

export const appLogoStyle = theme =>
  mergeWith(
    {
      root: {
        padding: theme.spacing(1, 1, 1, 2),

        ...theme.mixins.headerHeight,
      },

      rootMini: {
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },

      link: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-start',
      },

      linkMini: {
        marginRight: 0,
      },

      logo: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        objectPosition: 'left center',
      },

      logoMini: {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },

      hide: {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      hideMini: {
        [theme.breakpoints.up('md')]: {
          width: '100%',
          height: '100%',
          borderRadius: 0,
        },
      },
    },

    theme.mixins.appLogo,
    dataMerger
  )

export const useAppLogoStyle = makeStyles(appLogoStyle)
