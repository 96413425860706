import React from 'react'
import PropTypes from 'prop-types'

// core
import { useCartContext } from '_core/contexts/Cart';

import { CurrencySwitchView } from './CurrencySwitchView'


const RawCurrencySwitch = (props) => {
  const {
    className,
    tooltip,
    inMenu,
    hasSigns
  } = props;

  const cart = useCartContext();

  const onChange = (newCode) => cart.setCurrency(cart.currencyList.find((item) => item.code === newCode.toLowerCase())?.id);

  return (
    <CurrencySwitchView
      className={className}
      tooltip={tooltip}
      onChange={onChange}
      hasSigns={hasSigns}
      inMenu={inMenu}
    />
  );
};

RawCurrencySwitch.defaultProps = {};

RawCurrencySwitch.propTypes = {
  // self props
  className: PropTypes.string,
  tooltip: PropTypes.string,
  hasSigns: PropTypes.bool,
  inMenu: PropTypes.bool
};

export const CurrencySwitch = RawCurrencySwitch;
