export const overrides = theme => ({
  root: {
    width: theme.variables.sidebarWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    // overflowX: 'hidden',
    // flexShrink: 0,
    // whiteSpace: 'nowrap',
  },

  paper: {},
})

export const props = {
  variant: 'permanent',
}
