// @material-ui
import { makeStyles } from '@material-ui/styles'

export const productListItemStyle = theme => ({
  root: {
    color: theme.palette.text.primary,
    height: `calc(100% - ${theme.spacing(3)}px)`,
  },

  details: {
    flexBasis: '100%',
  },

  actions: {
    alignItems: 'stretch',
  },

  row: {
    paddingTop: theme.spacing(2),
  },

  column: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  price: {
    width: '100%',
  },
})

export const useProductListItemStyle = makeStyles(productListItemStyle)
