import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Icon,
  Tooltip,
  Chip,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

// core
import { withLang } from '_core/hocs/withLang';
import { getFormattedValue } from '_core/utils/formatPrice';
import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc';
import { useCartContext } from '_core/contexts/Cart';
import { PriceMix } from '_core/components/PriceMix';
import { goodsListItemModalStyle } from '_core/components/GoodsListItem/goodsListItemStyle';


const RawGoodsListItemModal = (props) => {
  const {
    classes,
    data,
    goodsUrl,
    getLangString: l
  } = props;
  
  const theme = useTheme();
  
  const cart = useCartContext();
  
  const cartControl = cart.getControl(data);
  
  const { isLoading, title, description, photo, price, discount, points, group } = cartControl.info;
  
  const groupPrice = group?.length ? group[0].price : price?.value || price;
  const groupDiscount = group?.length ? group[0].discount : discount?.value || discount;
  const groupPoints = group?.length ? group[0].points : points;
  
  return (
    <Card key={data.id} className={classes.card}>
      <Link to={`${goodsUrl}/${data.id}`}>
        {photo?.id ? (
          <CardMedia
            className={classes.cardMedia}
            image={getVisitorPhotoSrc(photo.path, 'shop')}
          />
        ) : null}
      </Link>

      <CardContent className={classes.cardContent}>
        <Box
          height="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.headerTitle}
          >
            {isLoading ? (
              <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
            ) : title}
          </Typography>

          {description ? (
            <Typography
              variant="caption"
              color="textPrimary"
              className={classes.headerDesciption}
            >
              {l(description)}
            </Typography>
          ) : null}
        </Box>
      </CardContent>

      <CardActions className={classes.cardActions}>
        {cartControl.count ? (
          <Icon
            className={classes.clearIcon}
            onClick={() => cartControl.remove()}
          >
            cancel
          </Icon>
        ) : (
          <Tooltip title={l('r.core.good.disabledelete')}>
            <Icon className={classes.clearIconDisable}>cancel</Icon>
          </Tooltip>
        )}
      </CardActions>

      <Box width="100%" marginTop="16px" align="right">
        <Typography variant="body2" className={classes.priceText}>
          {cartControl.countTotal} {l('r.core.good.pieces')}
        </Typography>

        {group?.length > 1 ? (
          group.map((item, index) => (
            <PriceMix
              key={index}
              item={{
                currency: cart.currency.id,
                value: item.price - item.discount
              }}
              itemProps={{
                className: classes.priceText,
                rootProps: { color: 'secondary', style: index > 0 ? { fontSize: '1rem' } : null },
                postfix: ` (${item.count} ${l('r.core.good.pieces')})`
              }}
            />
          ))
        ) : (
          <PriceMix
            item={{
              currency: cart.currency.id,
              value: groupPrice - groupDiscount
            }}
            itemProps={{
              className: classes.priceText,
              rootProps: { color: 'secondary' },
            }}
          />
        )}

        {groupDiscount > 0 ? (
          <PriceMix
            item={{
              currency: cart.currency.id,
              value: groupPrice
            }}
            className={classes.lineThrough + ' ' + classes.sale}
            itemProps={{
              className: classes.priceText,
              rootProps: { variant: 'caption' },
            }}
          />
        ) : null}
      </Box>

      {groupPoints > 0 ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.points}
        >
          <Chip
            size="small"
            color="secondary"
            className={classes.pointsChip}
            label={
              <Typography
                component="span"
                variant="caption"
                className={classes.pointsValue}
              >
                {l(
                  ['r._.goods.text.points', groupPoints],
                  getFormattedValue(groupPoints, {
                    scale: theme.variables.pointsScale,
                  })
                )}
              </Typography>
            }
          />
        </Box>
      ) : null}
    </Card>
  );
};

RawGoodsListItemModal.defaultProps = {};

RawGoodsListItemModal.propTypes = {
  // self props
  data: PropTypes.object.isRequired,
  goodsUrl: PropTypes.string,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
};

export const GoodsListItemModal = withLang(
  withStyles(goodsListItemModalStyle)(RawGoodsListItemModal)
);
