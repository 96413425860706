export const balanceDetailsStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    overflowY: 'auto',
    padding: theme.spacing(2),

    '&:first-child': {
      padding: theme.spacing(1, 2),
    },
  },

  divider: {},

  account: {
    marginBottom: theme.spacing(1),
  },

  columnCaption: {
    fontWeight: theme.typography.fontWeightBold,
  },

  row: {},

  captionRow: {},

  currency: {},

  value: {
    display: 'inline-block',
    maxWidth: '100%',
  },

  valueAmount: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },

  valueBlocked: {
    color: theme.palette.error.main,
  },

  valueClear: {
    color: theme.palette.text.disabled,
  },
})
