import { useSnackbar } from 'notistack';

// core
import * as api from '_core/utils/api';


export const useRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onFailure = (error) => {
    process.env.NODE_ENV !== 'production' && console.error(error);
    
    enqueueSnackbar(error.message, { variant: 'error' });
  };
  
  const queue = async (method, param, props) => {
    try {
      return await api.request(method, param, props);
    } catch (e) {
      onFailure(e);
      
      throw e;
    }
  };
  
  return { queue, onFailure };
};
