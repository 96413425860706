import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { GoodsCategoryMenu } from '_core/components/GoodsCategoryMenu';

// style
import { useAppMenuStyle } from './appMenuStyle';


const getMenusOrder = data =>
  Object.keys(data).map(key =>
    data[key].menus != null ? { [key]: getMenusOrder(data[key].menus) } : key
  );

const RtsLink = forwardRef((props, ref) => <Link ref={ref} {...props} />);
const RtsNavLink = forwardRef((props, ref) => <NavLink ref={ref} {...props} />);
const RtsExternalLink = forwardRef((props, ref) => <a href={ref} {...props} />);

export const RawAppMenu = (props) => {
  const {
    icons,
    order,
    menuId,
    isMini,
    className,
    hasTooltip,
    hasAnimation,
    animationProps,
    AnimationComponent,
    visitorInfo,
    getLangString: l,
  } = props;

  const classes = useAppMenuStyle();
  const [active, setActive] = useState();

  const {
    config: { GOODS_CATEGORY_MENU },
    menus
  } = useGlobal();
  
  const menusOrder = order || getMenusOrder(menus);

  useEffect(setActive, [menuId]);

  const getOnClick = (id) => () => {
    setActive(active !== id ? id : undefined)
  };

  const renderIcon = (key) => <Icon>{icons[key] ?? 'fiber_manual_record'}</Icon>;

  const renderLink = (key, data) => {
    if (data === undefined) {
      return null
    }
    const icon = renderIcon(key)

    const item = (
      <ListItem
        button={true}
        className={classes.link}
        activeClassName={classes.linkActive}
        component={data.isExternal ? RtsExternalLink : data.path?.startsWith('?') ? RtsLink : RtsNavLink}
        {...(data.isExternal ? { href: data.path } : { to: data.path })}
        {...data}
      >
        {icon != null ? (
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          classes={{ primary: classes.text }}
          primary={l(data.name)}
        />
      </ListItem>
    );

    return (
      <li className={classes.item} key={key}>
        {hasTooltip ? (
          <Tooltip title={l(data.name)} placement="right" enterDelay={0}>
            {item}
          </Tooltip>
        ) : (
          item
        )}
      </li>
    );
  };

  const renderGroup = (key, data, order) => {
    const list = renderList(data.menus, order);

    if (list == null) {
      return null;
    }

    const icon = renderIcon(key);
    const isParent = data.menus[menuId] != null;
    const isActive = isParent || active === key;

    const groupClasses = clsx({
      [classes.group]: true,
      [classes.groupActive]: isParent,
    });

    const groupTitleClasses = clsx({
      [classes.groupTitle]: true,
      [classes.groupTitleActive]: isActive,
    });

    const item = (
      <ListItem
        component="div"
        button={!isParent}
        className={groupTitleClasses}
        onClick={!isParent ? getOnClick(key) : undefined}
      >
        {icon != null ? (
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          classes={{ primary: classes.text }}
          primary={l(data.name)}
        />
        <ListItemIcon className={classes.toggle}>
          {isActive ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
      </ListItem>
    );

    return (
      <li className={groupClasses} key={key}>
        {hasTooltip ? (
          <Tooltip title={l(data.name)} placement="right" enterDelay={0}>
            {item}
          </Tooltip>
        ) : (
          item
        )}

        {hasAnimation ? (
          <AnimationComponent in={isActive} {...animationProps}>
            {list}
          </AnimationComponent>
        ) : (
          list
        )}
      </li>
    );
  };

  const renderList = (data, order) => {
    if (!Array.isArray(order) || order.length === 0)
      return null;
    
    const list = order.map(item => {
      if (item instanceof Object) {
        const key = Object.keys(item)[0];
        
        return renderGroup(key, data[key], item[key]);
      }
      
      const current = data[item];
      
      if (!current || !visitorInfo.isAllowed(current.privileges))
        return null;
      
      return renderLink(item, data[item])
    }).filter((item) => !!item);
    
    if (!list.length)
      return null;
    
    return <List className={classes.list}>{list}</List>;
  };

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.isMini]: isMini,
    [className]: className != null,
  });

  return (
    <div className={rootClasses}>
      {GOODS_CATEGORY_MENU ? <GoodsCategoryMenu /> : null}
      
      {renderList(menus, menusOrder)}
    </div>
  );
};

RawAppMenu.defaultProps = {
  icons: {},
  hasAnimation: true,
  AnimationComponent: Collapse,
};

RawAppMenu.propTypes = {
  // self props
  order: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
  ),
  icons: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuId: PropTypes.string,
  isMini: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  hasAnimation: PropTypes.bool,
  animationProps: PropTypes.object,
  AnimationComponent: PropTypes.elementType,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
};

export const AppMenu = withVisitorInfo(withLang(
  withStyles({}, { name: 'RtsAppMenu' })(RawAppMenu)
));
