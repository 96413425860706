//import React from 'react';


export const timeout = (millis) => {
  return new Promise((resolve, reject) => setTimeout(resolve, millis));
};

const loadScriptCache = {};

export const loadScript = (props, parentNode) => {
  if (!props)
    Promise.reject(new Error('Bad script props'));
  
  if (Array.isArray(props))
    return Promise.all(props.map((item) => loadScript(item, parentNode)));
  
  props = props.valueOf();
  
  if (typeof props !== 'object')
    props = { src: props };
  
  if (!props.src)
    Promise.reject(new Error('Bad script src'));
  
  return loadScriptCache[props.src] || (loadScriptCache[props.src] = new Promise((resolve, reject) => {
    const element = document.createElement('script');
    element.type = 'text/javascript';
    element.async = true;
    
    /*const attrs = props.attributes;
    
    delete props.attributes;
    
    if (attrs)
      Object.entries(attrs).forEach(([key, value]) => element.setAttribute(key, value));*/
    
    Object.entries(props).forEach(([key, value]) => element[key] = value);
    
    element.onload = () => {
      element.onerror = element.onload = null;
      
      setTimeout(() => resolve(element), 1);
    };
    
    element.onerror = () => {
      element.onerror = element.onload = null;
      
      setTimeout(() => reject(new Error(`Failed to load ${props.src}`)), 1);
    };
    
    const node = parentNode
      || document.head
      || document.getElementsByTagName('head')[0];
    
    node.appendChild(element);
  }));
};

export const submitForm = async (method, action, params) => {
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', action);
  
  Object.entries(params).forEach(([key, value]) => {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', key);
    field.setAttribute('value', value);
    
    form.appendChild(field);
  });
  
  document.body.appendChild(form);
  
  await timeout(1);
  
  form.submit();
};
