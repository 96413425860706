import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { makeFetch } from '_core/utils/makeFetch';
import { encodeKey } from '_core/utils/storage';

import { ThemeSwitchView } from './ThemeSwitchView';


const RawThemeSwitch = (props) => {
  const {
    className,
    tooltip,
    color,
    inMenu,
    hasSigns
  } = props;
  
  const theme = useTheme();
  
  const {
    config: { COOKIES_TIME, COOKIES_KEY },
    themes
  } = useGlobal();
  
  const onChange = async () => {
    const index = themes.findIndex((item) => item.themeType === theme.themeType);
    const newIndex = index < themes.length - 1 ? index + 1 : 0;
    
    theme.applyTheme(themes[newIndex].themeType);
  };
  
  return (
    <ThemeSwitchView
      className={className}
      tooltip={tooltip}
      color={color}
      themes={themes}
      themeType={theme.themeType}
      onChange={onChange}
      hasSigns={hasSigns}
      inMenu={inMenu}
    />
  );
};

RawThemeSwitch.defaultProps = {};

RawThemeSwitch.propTypes = {
  // self props
  className: PropTypes.string,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  hasSigns: PropTypes.bool,
  inMenu: PropTypes.bool
};

export const ThemeSwitch = RawThemeSwitch;
