import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { TextInput } from '_core/components/TextInput'
import { PriceInput } from '_core/components/PriceInput'
import { PriceFormTax } from '_core/components/PriceFormTax'
import { PriceInputHelperText } from '_core/components/PriceInputHelperText'

const RawPriceFormCount = props => {
  const {
    tax,
    value,
    valueStep,
    minCount,
    maxCount,
    valueLabel,
    countLabel,
    helperText,
    isValid,
    minPrice,
    maxPrice,
    onChange,
    currency,
    getLangString: l,
  } = props

  const helperTextProps = {
    isValid,
    minPrice,
    maxPrice,
    currency,
  }

  const inputProps = {
    min: minCount,
    max: maxCount,
  }

  const onCountChange = event => {
    onChange(event.target.value * valueStep)
  }

  return (
    <>
      <TextInput
        type="number"
        label={l(countLabel)}
        inputProps={{ inputProps }}
        helperText={l(helperText, valueStep)}
        value={value / valueStep}
        onChange={onCountChange}
      />

      <PriceInput
        value={value}
        currency={currency}
        error={!isValid}
        label={l(valueLabel)}
        helperText={<PriceInputHelperText {...helperTextProps} />}
        disabled={true}
      />

      <PriceFormTax
        tax={tax}
        value={value}
        isValid={isValid}
        currency={currency}
      />
    </>
  )
}

RawPriceFormCount.defaultProps = {
  minCount: 1,
  valueLabel: 'r.summa.investicii',
  countLabel: 'r._.shopping.goodscount',
  helperText: 'r._.shopping.itemprice',
}

RawPriceFormCount.propTypes = {
  // self props
  isValid: PropTypes.bool,
  minCount: PropTypes.number,
  valueLabel: PropTypes.string,
  countLabel: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.number.isRequired,
  valueStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minPrice: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceFormCount = withLang(RawPriceFormCount)
