export const clientStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
  },

  headerHeight: {
    ...theme.mixins.headerHeight,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    overflow: 'auto',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  showCart: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(3) + theme.spacing(2) * 2 + 30,
    },
  },
})
