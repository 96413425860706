// @material-ui
import { makeStyles } from '@material-ui/styles'

export const avatarStyle = theme => ({
  avatar: {
    fontWeight: 300,
  },

  xs: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    fontSize: '0.5rem',
  },

  sm: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '1rem',
  },

  md: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },

  lg: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    fontSize: '1.5rem',
  },

  xl: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '2rem',
  },

  disableClick: {
    cursor: 'inherit',

    '&:hover': {
      boxShadow: 'none',
    },
  },
})

export const useAvatarStyle = makeStyles(avatarStyle)
