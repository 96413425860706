import 'basic/assets/scss/_fileupload.scss';
import 'basic/assets/scss/scrollbar.scss';
import 'basic/assets/scss/screen.scss';
import 'basic/assets/scss/material.scss';
import 'basic/assets/scss/sbsans.scss';
import 'basic/assets/scss/manrope.scss';

import 'lightgallery.js/dist/css/lightgallery.min.css';
import 'basic/assets/css/lightgallery.css';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { Provider as StoreProvider } from 'react-redux';
import { LightgalleryProvider } from 'react-lightgallery';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

import { STORAGE_KEY } from 'basic/config';

// core
import * as storage from '_core/utils/storage';
import { initStore } from '_core/store/store';
import { VisitorInfoProvider } from '_core/utils/visitorInfo';
import { DeliveryProvider } from '_core/utils/delivery';
import { CartProvider } from '_core/contexts/Cart';

import { AppView } from './AppView';


const store = initStore();

export const RawApp = (props) => {
  const { globalContext, render, Component, ...rest } = props;
  const [theme, setTheme] = useState();
  
  if (!theme) {
    const applyTheme = (type) => {
      setTheme({ ...globalContext.themes.find((item) => item.themeType === type) || globalContext.themes[0], applyTheme });
      
      storage.set(STORAGE_KEY.THEME, type);
    };
    
    applyTheme(storage.get(STORAGE_KEY.THEME));
  }
  
  if (!theme)
    return null;
  
  const childProps = { globalContext, ...rest };
  
  return (
    <CookiesProvider>
      <StoreProvider store={store}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <VisitorInfoProvider>
              <DeliveryProvider>
                <LightgalleryProvider>
                  <CartProvider>
                    {typeof render === 'function' ? (
                      render(childProps)
                    ) : (
                      <Component {...childProps} />
                    )}
                  </CartProvider>
                </LightgalleryProvider>
              </DeliveryProvider>
            </VisitorInfoProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </StoreProvider>
    </CookiesProvider>
  );
};

RawApp.defaultProps = {
  Component: AppView,
};

RawApp.propTypes = {
  // self props
  globalContext: PropTypes.object.isRequired,
  render: PropTypes.func,
  Component: PropTypes.elementType,
};

export const App = RawApp;
