import { makeActionCreator } from '_core/utils/actions'

// === Action types ===

const LANG_CHANGE = 'LANG_CHANGE'
const LANG_RESET = 'LANG_RESET'
const LANG_UPDATE_LIST = 'LANG_UPDATE_LIST'
const LANG_UPDATE_STRINGS = 'LANG_UPDATE_STRINGS'

// === Default state ===

const defaultState = {
  current: null,
  list: null,
  strings: null,
}

// === Selectors ===

const getLang = store => store.publicData.lang

const getCurrentLangId = store => getLang(store).current

const getLangList = store => getLang(store).list

const getLangStrings = store => getLang(store).strings

const getLangInfoById = (store, langId) => {
  const list = getLangList(store)
  return list != null ? list[langId] : null
}

const getLangStringsById = (store, langId) => {
  const strings = getLangStrings(store)
  return strings != null ? strings[langId] : null
}

// === Actions ===

const changeLang = makeActionCreator(LANG_CHANGE, 'id')

const resetLang = makeActionCreator(LANG_RESET)

const updateLangList = makeActionCreator(LANG_UPDATE_LIST, 'lang')

const updateLangStrings = makeActionCreator(LANG_UPDATE_STRINGS, 'strings')

// === Exports ===

export const langDefaultState = defaultState

export const langActionTypes = {
  LANG_CHANGE,
  LANG_RESET,
  LANG_UPDATE_LIST,
  LANG_UPDATE_STRINGS,
}

export const langSelectors = {
  getLang,
  getCurrentLangId,
  getLangList,
  getLangStrings,
  getLangInfoById,
  getLangStringsById,
}

export const langActions = {
  changeLang,
  resetLang,
  updateLangList,
  updateLangStrings,
}
