// @material-ui
import { makeStyles } from '@material-ui/styles'

export const stairsStyle = theme => ({
  root: {
    // height: '100%',
    // width: '100%',
  },

  group: {
    width: '100%',
  },

  backButton: {
    marginRight: theme.spacing(2),
  },

  buttonRow: {
    marginTop: theme.spacing(2),
  },
})

export const useStairsStyle = makeStyles(stairsStyle)
