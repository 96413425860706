// @material-ui
import { makeStyles } from '@material-ui/styles'

export const balanceButtonStyle = theme => ({
  root: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&$disabled': {
      cursor: 'default',
    },
  },

  active: {},

  disabled: {},

  label: {
    lineHeight: theme.typography.body1.lineHeight,
    color: [theme.palette.text.secondary, '!important'],
  },

  column: {
    display: 'flex',
    alignItems: 'center',
  },

  value: {},

  valueSingle: {
    fontWeight: theme.typography.fontWeightBold,
  },

  icon: {
    marginLeft: '4px',
    fontSize: theme.typography.h6.fontSize,
    color: [theme.palette.text.hint, '!important'],
    transition: theme.transitions.create('transform'),

    '$active &': {
      transform: 'rotate(180deg)',
    },
  },
})

export const useBalanceButtonStyle = makeStyles(balanceButtonStyle)
