import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('color', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    const color = this.params.color;
    
    if (!color)
      return this.getComponents();
    
    return (
      <span style={{ color }}>
        {this.getComponents()}
      </span>
    );
  }
  
});
