import thunk from 'redux-thunk'
// import { createLogger } from "redux-logger";
import { configureStore } from 'redux-starter-kit'

import { app as reducer } from '_core/store/App/App'

// const logger = createLogger();

export const initStore = defaultState =>
  configureStore({
    reducer,
    middleware: [thunk /*, logger*/],
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: defaultState,
  })
