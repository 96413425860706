export const titleStyle = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  icon: {
    color: theme.variables.colors.icon,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(),
  },
})
