// Ritos store
import { langDefaultState } from '_core/store/Lang'
import { countryDefaultState } from '_core/store/Country'

// === Default state ===

const defaultState = {
  lang: langDefaultState,
  country: countryDefaultState
}

// === Selectors ===

const getPublicData = store => store.publicData

const getCurrency = store => getPublicData(store).currency

const getCountry = store => getPublicData(store).country

const getTheme = store => getPublicData(store).theme

// === Exports ===

export const publicDataDefaultState = defaultState

export const publicDataSelectors = {
  getCurrency,
  getCountry,
  getTheme,
}
