import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

// core
import { getCurrencyFormatProp } from '_core/utils/getCurrencyFormatProp';
import { useCartContext } from '_core/contexts/Cart';
import { FormattedInputBase } from '_core/components/FormattedInputBase';


export const PriceInput = props => {
  const {
    onChange,
    currency,
    setFieldValue, // eslint-disable-line no-unused-vars
    setFieldTouched, // eslint-disable-line no-unused-vars
    ...rest
  } = props

  const cart = useCartContext();

  return (
    <FormattedInputBase
      onChange={onChange}
      {...getCurrencyFormatProp(cart.findCurrency(currency))}
      {...rest}
    />
  )
}

PriceInput.defaultProps = {
  setFieldValue: noop,
  setFieldTouched: noop,
}

PriceInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
