import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'

import omit from 'lodash/omit'

// @material-ui
import { Collapse } from '@material-ui/core'

// core
import {
  PAGE_LOAD_DELAY,
  FETCH_SYNC_TIMEOUT,
  FETCH_SYNC_EFFORTS,
  DEFAULT_TIMEOUT_ERROR,
} from 'config'
import { withLang } from '_core/hocs/withLang'
import { Loading } from '_core/components/Loading'
import { ErrorMessage } from '_core/components/ErrorMessage'

class RawState extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isInfo: false,
      gotProps: {},
      hasGotProps: false,
      isReady: false,
      isDelayed: false,
      resetEfforts: props.resetEfforts,
    }

    this._isMounted = false
    this._errorTimer = null
    this._delayTimer = null

    this._onError = this._onError.bind(this)
    this._onDelay = this._onDelay.bind(this)
    this._onReset = this._onReset.bind(this)
    this._onReady = this._onReady.bind(this)
    this._renderFailure = this._renderFailure.bind(this)
    this._getProps = this._getProps.bind(this)
    this._arePropsReady = this._arePropsReady.bind(this)
    this._clearTimer = this._clearTimer.bind(this)
    this._setErrorTimer = this._setErrorTimer.bind(this)
    this._setDelayTimer = this._setDelayTimer.bind(this)
    this._getChildProps = this._getChildProps.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this._getProps()
    this._setErrorTimer()
    this._setDelayTimer()
  }

  componentWillUnmount() {
    this._isMounted = false
    this._clearTimer(this._errorTimer)
    this._clearTimer(this._delayTimer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isReady && this._arePropsReady()) {
      this._onReady()
    }
  }

  componentDidCatch(error, info) {
    process.env.NODE_ENV !== 'production' && console.log(info)

    this._onError(error)
  }

  _setErrorTimer() {
    const { timeout } = this.props

    if (!timeout) {
      return
    }

    this._errorTimer = setTimeout(() => {
      this._onError({ ...DEFAULT_TIMEOUT_ERROR })
    }, timeout)
  }

  _setDelayTimer() {
    const { delay } = this.props

    if (delay == null) {
      return
    }

    this._delayTimer = setTimeout(() => {
      this._onDelay()
    }, delay)
  }

  _clearTimer(timer) {
    clearTimeout(timer)
    timer = null
  }

  async _getProps() {
    const { getProps, sideProps } = this.props
    const childProps = this._getChildProps()

    try {
      await Promise.all([
        // trigger side effect props
        ...Object.keys(sideProps)
          .filter(key => typeof sideProps[key] === 'function')
          .map(key => sideProps[key](childProps)),

        // get functional props results
        ...Object.keys(getProps)
          .filter(key => typeof getProps[key] === 'function')
          .map(key =>
            getProps[key](childProps).then(result => {
              if (!this._isMounted) {
                return
              }

              return this.setState(
                produce(draft => {
                  draft.gotProps[key] = result
                })
              )
            })
          ),
      ])

      if (!this._isMounted) {
        return
      }

      this.setState({ hasGotProps: true })
    } catch (error) {
      this._onError(error)
    }
  }

  _arePropsReady() {
    const { waitProps } = this.props
    const { hasGotProps } = this.state

    return (
      hasGotProps && Object.keys(waitProps).every(key => waitProps[key] != null)
    )
  }

  async _onError(error) {
    const { onBeforeError, onError } = this.props

    this._clearTimer(this._errorTimer)
    this._clearTimer(this._delayTimer)

    if (!this._isMounted) {
      return
    }

    typeof onBeforeError === 'function' && onBeforeError(error)
    await this.setState({ error })
    typeof onError === 'function' && onError(error)
  }

  async _onDelay() {
    const { onBeforeDelay, onDelay } = this.props

    this._clearTimer(this._delayTimer)

    if (!this._isMounted) {
      return
    }

    typeof onBeforeDelay === 'function' && onBeforeDelay()
    await this.setState({ isDelayed: true })
    typeof onDelay === 'function' && onDelay()
  }

  async _onReset() {
    const { onBeforeReset, onReset } = this.props

    typeof onBeforeReset === 'function' && onBeforeReset()

    await this.setState(state => ({
      error: null,
      isReady: false,
      isDelayed: false,
      gotProps: {},
      hasGotProps: false,
      resetEfforts: state.resetEfforts - 1,
    }))

    typeof onReset === 'function' && onReset()

    this._getProps()
    this._setErrorTimer()
    this._setDelayTimer()
  }

  async _onReady() {
    const { onBeforeReady, onReady, waitProps, sideProps } = this.props

    this._clearTimer(this._errorTimer)
    this._clearTimer(this._delayTimer)

    if (!this._isMounted) {
      return
    }

    const readyProps = {
      waitProps,
      sideProps,
      gotProps: this.state.gotProps,
    }

    typeof onBeforeReady === 'function' && onBeforeReady(readyProps)
    await this.setState({ isReady: true })
    typeof onReady === 'function' && onReady(readyProps)
  }

  _renderFailure() {
    const { errorProps } = this.props
    const { error, resetEfforts } = this.state

    process.env.NODE_ENV !== 'production' && console.error(error)

    return (
      <ErrorMessage
        usePrivileges={false}
        severity={error.isInfo ? 'info' : 'error'}
        error={error}
        hasButton={resetEfforts > 0}
        onClick={this._onReset}
        {...errorProps}
      />
    )
  }

  _getChildProps() {
    return omit(this.props, [
      'langInfo',
      'langStrings',
      'getLangObject',
      'getLangString',
      'getLangStringSet',
      'getFirstLangString',
      'delay',
      'timeout',
      'progress',
      'waitProps',
      'render',
      'renderFailure',
      'renderProgress',
      'errorProps',
      'onReset',
      'onReady',
      'onError',
      'onDelay',
      'onBeforeReset',
      'onBeforeError',
      'onBeforeReady',
      'onBeforeDelay',
      'resetEfforts',
      'getProps',
      'sideProps',
      'children',
      'hasAnimation',
      'animationTime',
      'animationProps',
      'AnimationComponent',
    ])
  }

  _renderContent() {
    const {
      children,
      waitProps,
      render,
      renderFailure,
      hasAnimation,
      animationTime,
      animationProps,
      AnimationComponent,
    } = this.props

    const { isReady, error, gotProps } = this.state
    const childProps = this._getChildProps()

    const content = (
      <>
        {!isReady || error != null
          ? null
          : render != null
          ? render({
              snatch: this._onError,
              ...waitProps,
              ...gotProps,
              ...childProps,
            })
          : children}

        {isReady || error == null
          ? null
          : renderFailure != null
          ? renderFailure({
              error,
              ready: this._onReady,
              reset: this._onReset,
              ...childProps,
            })
          : this._renderFailure()}
      </>
    )

    return hasAnimation ? (
      <AnimationComponent
        in={!!error || isReady}
        timeout={animationTime}
        {...animationProps}
      >
        {content}
      </AnimationComponent>
    ) : (
      content
    )
  }

  render() {
    const { progress, renderProgress } = this.props
    const { isReady, isDelayed, error } = this.state
    const childProps = this._getChildProps()

    return (
      <>
        {!isDelayed || isReady || error != null
          ? null
          : renderProgress != null
          ? renderProgress({
              ready: this._onReady,
              reset: this._onReset,
              ...childProps,
            })
          : progress}

        {isReady || error != null ? this._renderContent() : null}
      </>
    )
  }
}

RawState.defaultProps = {
  timeout: FETCH_SYNC_TIMEOUT * FETCH_SYNC_EFFORTS,
  progress: <Loading progressType="linear" />,
  getProps: {},
  sideProps: {},
  waitProps: {},
  resetEfforts: 5,
  delay: PAGE_LOAD_DELAY,
  animationTime: 300,
  hasAnimation: true,
  AnimationComponent: Collapse,
}

RawState.propTypes = {
  // self props
  children: PropTypes.node,
  delay: PropTypes.number,
  timeout: PropTypes.number,
  animationTime: PropTypes.number,
  errorProps: PropTypes.object,
  progress: PropTypes.node,
  onReset: PropTypes.func,
  onReady: PropTypes.func,
  onError: PropTypes.func,
  onDelay: PropTypes.func,
  onBeforeReset: PropTypes.func,
  onBeforeError: PropTypes.func,
  onBeforeReady: PropTypes.func,
  onBeforeDelay: PropTypes.func,
  render: PropTypes.func,
  renderFailure: PropTypes.func,
  renderProgress: PropTypes.func,
  resetEfforts: PropTypes.number,
  getProps: PropTypes.object,
  sideProps: PropTypes.object,
  waitProps: PropTypes.object,
  hasAnimation: PropTypes.bool,
  animationProps: PropTypes.object,
  AnimationComponent: PropTypes.elementType,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const State = withLang(RawState)
