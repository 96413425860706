export const overrides = theme => ({
  root: {
    borderTop: '1px solid ' + theme.palette.divider,
    justifyContent: 'space-between',
    [`${theme.breakpoints.down('xs')}`]: {
      flexWrap: 'wrap',
    },
  },
  spacing: {
    padding: theme.spacing(2, 3),
    [`${theme.breakpoints.down('xs')}`]: {
      padding: theme.spacing(2),
    },
  },
})
