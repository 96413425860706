export const overrides = theme => ({
  toolbar: {
    padding: 0,
    flexDirection: 'row-reverse',
  },

  actions: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
})

export const props = {
  component: 'div',
  rowsPerPageOptions: [],

  labelDisplayedRows: ({ from, to, count }) =>
    `${from}-${to === -1 ? count : to} / ${count}`,
}
