import { makeStyles } from '@material-ui/styles';


export const useStyle = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(3)
  },
  
  marginTop: {
    marginTop: theme.spacing(2)
  },
  
  skeleton: {
    width: '250px'
  }
}));
