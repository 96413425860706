import React from 'react';
import PropTypes from 'prop-types';

import * as convert from '_core/utils/convert';
import decorate from '_core/utils/decorate';


const Component = decorate(class extends React.PureComponent {
  
  static decorateWith = {
  };
  
  static propTypes = {
    type: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultSrc: PropTypes.string
  };
  
  static defaultProps = {
  };
  
  
  constructor(props) {
    super(props);
  }
  
  
  imageState(element) {
    if (!element.complete)
      return 'loading';
    
    if (typeof element.naturalWidth !== 'undefined' && !element.naturalWidth)
      return 'error';
    
    return 'ok';
  }
  
  imagePath(path) {
    if (!path || !path.startsWith('/') || path.startsWith('/assets/'))
      return path;
    
    return '/' + path.substring(1);
  }
  
  imageError(element) {
    if (this.imageComplete)
      return;
    
    const { defaultSrc } = this.props;
    
    this.imageComplete = true;
    
    element.onload = null;
    element.onerror = null;
    
    if (defaultSrc)
      element.src = this.imagePath(defaultSrc);
  }
  
  render() {
    const { type, src, defaultSrc, ...props } = this.props;
    
    const onRef = (element) => {
      if (!element || !defaultSrc || this.imageComplete)
        return;
      
      const state = this.imageState(element);
      
      if (state === 'loading') {
        element.onerror = (event) => this.imageError(element);
        element.onload = (event) => this.imageState(element) === 'error' && this.imageError(element);
      } else if (state === 'ok') {
        this.imageComplete = true;
      } else {
        this.imageError(element);
      }
    };
    
    return <img {...props} src={this.imagePath(Component.getTyped(type, src?.id && src.path || src || defaultSrc))} ref={onRef} />;
  }
  
});

Component.getTyped = (type, path) => {
  type = convert.toString(type);
  path = convert.toString(path);
  
  if (!path || !type || type === 'default' || !path.startsWith('/data/files/'))
    return path;
  
  const pos = path.lastIndexOf('.');
  
  if (pos < 0)
    return path + '-' + type;
  
  return path.substring(0, pos) + '-' + type + path.substring(pos);
};

export default Component;
