import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap as GMap, Marker } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import { useGlobal } from '_core/hooks/useGlobal';
import * as api from '_core/utils/api';
import { useVisitorInfo } from '_core/utils/visitorInfo';
import { Loading } from '_core/components/Loading'


const MAP_URL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places';

const Map = withScriptjs(withGoogleMap((props) => (<GMap {...props.mapProps}>{props.children}</GMap>)));

const toPosition = (value) => {
  return Array.isArray(value) ? { lat: value[0] || 0.0, lng: value[1] || 0.0 } : { lat: value?.lat || 0.0, lng: value?.lng || 0.0 };
};

export const GoogleMap = (props) => {
  const { type, lang, children } = props;
  
  const {
    config: { API_METHOD },
  } = useGlobal();
  
  const visitorInfo = useVisitorInfo();
  
  const [apiKey, setApiKey] = React.useState(visitorInfo.options.googleApiKey);
  
  const updateApiKey = (value) => {
    visitorInfo.putOptions({ googleApiKey: value });
    
    setApiKey(value);
  };
  
  React.useEffect(() => {
    if (visitorInfo.options.googleApiKey)
      return;
    
    visitorInfo.putOptions({ googleApiKey: 'loading' });
    
    api.request(API_METHOD.GOOGLE_MAP_KEY, {})
      .then((data) => updateApiKey(data.value || 'error'))
      .catch(() => updateApiKey('error'));
  }, []);
  
  if (!apiKey)
    return <Loading progressType="linear" />;
  
  if (type === 'map') {
    const mapProps = {
      clickableIcons: false,
      defaultCenter: toPosition(props.defaultCenter),
      defaultZoom: props.defaultZoom || 12
    };
    
    if (props.center || props.zoom) {
      mapProps.center = toPosition(props.center || props.defaultCenter);
      mapProps.zoom = props.zoom || props.defaultZoom || 12;
    }
    
    let mapURL = MAP_URL;
    
    if (lang) {
      const langSplit = lang.split('-');
      
      if (langSplit.length > 1)
        mapURL += '&region=' + encodeURIComponent(langSplit[1].toUpperCase());
      
      mapURL += '&language=' + encodeURIComponent(langSplit[0]);
    }
    
    if (process.env.NODE_ENV === 'production' && apiKey !== 'error')
      mapURL += '&key=' + encodeURIComponent(apiKey);
    
    return (
      <Map 
        googleMapURL={mapURL}
        loadingElement={<Loading />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        mapProps={mapProps}
      >
        {children}
      </Map>
    );
  } else if (type === 'clusterer') {
    return <MarkerClusterer averageCenter enableRetinaIcons gridSize={60} zoomOnClick maxZoom={14} ignoreHidden>{children}</MarkerClusterer>;
  } else if (type === 'marker') {
    let iconProps = null;
    
    if (props.icon) {
      iconProps = {
        url: props.icon.url,
        anchor: { x: props.icon.anchorX || 0, y: props.icon.anchorY || 0 }
      };
      
      if (props.icon.width && props.icon.height)
        iconProps.scaledSize = { width: props.icon.width, height: props.icon.height };
    }
    
    return (
      <Marker
        position={toPosition(props.position)}
        title={props.hint}
        icon={iconProps}
        onClick={props.onClick}
      >
        {children}
      </Marker>
    );
  } else {
    return <>{children}</>;
  }
};

GoogleMap.defaultProps = {};

GoogleMap.propTypes = {
  type: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};
