import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core';

import decorate from '_core/utils/decorate';


const REGEXP_EMAIL = /^[\w\.\-_]+@[\w\.\-_]+$/;
const REGEXP_PHONE = /^[\d\s\(\)\-\+\*]+$/;


export default decorate(class extends React.PureComponent {
  
  static decorateWith = {
  };
  
  static propTypes = {
    type: PropTypes.oneOf(['auto', 'router', 'external', 'button', 'email', 'phone', 'telegram', 'vkontakte', 'odnoklassniki', 'facebook', 'instagram', 'youtube', 'rutube']).isRequired,
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    target: PropTypes.string.isRequired
  };
  
  static defaultProps = {
    type: 'auto',
    target: 'auto' // _blank, _self, _parent, _top
  };
  
  
  constructor(props) {
    super(props);
  }
  
  
  render() {
    const { type, target, href, children, ...props } = this.props;
    
    const isTypeAuto = (type === 'auto' || type === 'button') && (target === 'auto' || target === '_self');
    
    let autoType = 'external';
    let autoTarget = null;
    let linkType = type === 'button' ? type : undefined;
    let linkHref = href;
    
    if (target !== 'auto') {
      autoType = 'external';
      autoTarget = target;
    }
    
    if (isTypeAuto) {
      autoType = 'router';
      
      if (linkHref) {
        if (typeof linkHref === 'string' && (linkHref.indexOf(':') >= 0 || linkHref.startsWith('#')))
          autoType = 'external';
      } else {
        const value = cleanChildren(children);
        
        if (value.length) {
          if (REGEXP_EMAIL.test(value)) {
            autoType = 'email';
          } else if (REGEXP_PHONE.test(value)) {
            autoType = 'phone';
          }
        }
      }
    } else {
      autoType = type;
    }
    
    if (!linkHref) {
      if (autoType === 'email') {
        linkHref = 'mailto:' + cleanChildren(children);
      } else if (autoType === 'phone') {
        linkHref = 'tel:' + cleanChildren(children).replace(/[^\d\+\*]/g, '');
      } else {
        linkHref = cleanChildren(children) || '/';
      }
    } else if (typeof linkHref === 'string') {
      if (autoType === 'email') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'mailto:' + linkHref;
      } else if (autoType === 'phone') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'tel:' + linkHref;
      } else if (autoType === 'telegram') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://t.me/' + encodeURIComponent(linkHref);
      } else if (autoType === 'vkontakte') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://vk.com/' + encodeURIComponent(linkHref);
      } else if (autoType === 'odnoklassniki') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://ok.ru/profile/' + encodeURIComponent(linkHref);
      } else if (autoType === 'facebook') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://www.facebook.com/' + encodeURIComponent(linkHref);
      } else if (autoType === 'instagram') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://www.instagram.com/' + encodeURIComponent(linkHref);
      } else if (autoType === 'youtube') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://www.youtube.com/channel/' + encodeURIComponent(linkHref);
      } else if (autoType === 'rutube') {
        if (linkHref.indexOf(':') < 0)
          linkHref = 'https://rutube.ru/channel/' + encodeURIComponent(linkHref) + '/';
      }
    }
    
    if (isTypeAuto && autoType === 'router' && typeof linkHref === 'string' && linkHref !== '/') {
      if (!linkHref.startsWith('/') && linkHref.endsWith('/'))
        autoType = 'external';
    }
    
    if (autoType === 'router') {
      return (
        <RouterLink {...props} type={linkType} href={linkHref}>{children}</RouterLink>
      );
    } else {
      return (
        <MuiLink {...props} type={linkType} href={linkHref} target={autoTarget}>{children}</MuiLink>
      );
    }
  }
  
});


const cleanChildren = (children) => {
  if (!children)
    return '';
  
  if (typeof children === 'object')
    children = children.valueOf();
  
  if (typeof children === 'string') {
    return children.trim();
  } else if (Array.isArray(children)) {
    return children.map(cleanChildren).join('').trim();
  } else {
    if (children.href)
      return cleanChildren(children.href);
    
    return '';
  }
};
