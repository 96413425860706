import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography, Grid } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { useCartContext } from '_core/contexts/Cart';
import { Price } from '_core/components/Price'
import { PriceMix } from '_core/components/PriceMix'

// style
import { usePaymentBriefStyle } from './paymentBriefStyle'

export const RawPaymentBrief = props => {
  const { delivery, getLangString: l } = props

  const classes = usePaymentBriefStyle()
  const cart = useCartContext();
  
  return (
    <div className={classes.root}>
      <Grid container={true} className={classes.row}>
        <Grid item={true} xs={12} sm={6}>
          <Typography variant="body2" className={classes.label}>
            {l('r.core.cart.total')}{' '}
            {l(
              ['r._.carttotal.label.goodscount', cart.length],
              cart.length
            )}
            :
          </Typography>
        </Grid>

        <Grid item={true} xs={12} sm={6}>
          <PriceMix item={{ currency: cart.currency.id, value: cart.price_with_discount_total }} />
        </Grid>
      </Grid>

      {delivery != null ? (
        <Grid container={true} className={classes.row}>
          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body2" className={classes.label}>
              {l('r.core.cartstep.delivery.price')}:
            </Typography>
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body1">
              <Price
                value={delivery.cost.value}
                currency={delivery.cost.currency.id}
              />
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </div>
  )
}

RawPaymentBrief.defaultProps = {}

RawPaymentBrief.propTypes = {
  // self props
  delivery: PropTypes.object,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentBrief = withLang(RawPaymentBrief)
