import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { RSAKey } from 'jsrsasign'

// core
import {
  API_METHOD,
  SECURITY_CHECK_TYPE,
  SECURITY_CHECK_MODE,
  SECURITY_CHECK_MODE_TITLE,
} from 'basic/config'
import { withApi } from '_core/hocs/withApi'
import { hex2b64 } from '_core/utils/base64'
import { getFormStructUrl } from '_core/utils/getFormStructUrl'
import {
  privateDataActions,
  privateDataSelectors,
} from '_core/store/PrivateData'
import { securityCheck } from 'basic/forms/securityCheck'
import { securityFileCheck } from 'basic/forms/securityFileCheck'
import { SecurityCheckView } from './SecurityCheckView'

const mapState = state => ({
  security: privateDataSelectors.getSecurity(state),
})

const mapDispatch = {
  updateSecurity: privateDataActions.updateSecurity,
}

class RawSecurityCheck extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      promise: null,
      isChecking: false,
    }

    this._isMounted = false

    this.check = this.check.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onFailure = this.onFailure.bind(this)
    this.getSubmitHandler = this.getSubmitHandler.bind(this)

    this._getCurrentMode = this._getCurrentMode.bind(this)
    this._getAuthKeyLogin = this._getAuthKeyLogin.bind(this)
  }

  componentDidMount() {
    const { mode, security, queue, updateSecurity } = this.props

    this._isMounted = true

    if (mode == null && security == null) {
      queue(API_METHOD.CLIENT_SECURITY_INFO, {})
        .then(updateSecurity);
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _getCurrentMode() {
    const { type, mode, security } = this.props

    return mode != null ? mode : security != null ? security[type].mode : null
  }

  async check() {
    const currentMode = this._getCurrentMode()

    if (currentMode == null) {
      return false
    }

    if (currentMode === SECURITY_CHECK_MODE.NONE) {
      return true
    }

    let result
    try {
      await new Promise((resolve, reject) =>
        this.setState({
          isChecking: true,
          promise: { resolve, reject },
        })
      )

      result = true
    } catch (error) {
      result = false
    }

    if (!this._isMounted) {
      return
    }

    await this.setState({
      promise: null,
      isChecking: false,
    })

    return result
  }

  onSuccess() {
    this.state.promise.resolve()
  }

  onFailure() {
    this.state.promise.reject()
  }

  getSubmitHandler({ outerProps, innerProps }) {
    return async (event, ...rest) => {
      event.preventDefault()

      if (!outerProps.canSubmit(innerProps)) {
        return
      }

      try {
        innerProps.values.value = this._getAuthKeyLogin(innerProps.values.value)
        innerProps.handleSubmit(event, ...rest)
      } catch (error) {
        innerProps.setErrors({ value: 'badauthkey' })
      }
    }
  }

  _getAuthKeyLogin(authKey) {
    const { login } = this.props

    if (login == null) {
      return
    }

    const rsa = new RSAKey()
    rsa.readPrivateKeyFromPEMString(authKey)
    return hex2b64(rsa.sign(login, 'sha1'))
  }

  render() {
    const {
      type,
      render,
      mode, // eslint-disable-line no-unused-vars
      security, // eslint-disable-line no-unused-vars

      // Filter out `withApi` props
      queue, // eslint-disable-line no-unused-vars
      addListener, // eslint-disable-line no-unused-vars
      removeAllListeners, // eslint-disable-line no-unused-vars

      ...rest
    } = this.props

    const currentMode = this._getCurrentMode()

    if (currentMode == null) {
      return render({
        ...rest,
        check: this.check,
        renderCheckForm: () => null,
      })
    }

    const title = SECURITY_CHECK_MODE_TITLE[currentMode]

    const decor =
      currentMode === SECURITY_CHECK_MODE.AUTHKEY
        ? securityFileCheck
        : securityCheck

    const getSubmitHandler =
      currentMode === SECURITY_CHECK_MODE.AUTHKEY
        ? this.getSubmitHandler
        : undefined

    const structUrl =
      currentMode === SECURITY_CHECK_MODE.AUTHKEY
        ? API_METHOD.CLIENT_SECURITY_CONFIRM_AUTHKEY
        : currentMode === SECURITY_CHECK_MODE.SMS ||
          currentMode === SECURITY_CHECK_MODE.GAUTH
        ? API_METHOD.CLIENT_SECURITY_CONFIRM_CODE
        : API_METHOD.CLIENT_SECURITY_CONFIRM

    return (
      <SecurityCheckView
        {...rest}
        title={title}
        type={type}
        check={this.check}
        render={render}
        onSuccess={this.onSuccess}
        onFailure={this.onFailure}
        getSubmitHandler={getSubmitHandler}
        isModalOpen={this.state.isChecking}
        structUrl={getFormStructUrl(structUrl)}
        decor={decor}
      />
    )
  }
}

RawSecurityCheck.defaultProps = {
  type: SECURITY_CHECK_TYPE.FINOP,
}

RawSecurityCheck.propTypes = {
  login: PropTypes.string,
  formProps: PropTypes.object,
  validationProps: PropTypes.object,
  render: PropTypes.func.isRequired,
  type: PropTypes.oneOf([SECURITY_CHECK_TYPE.AUTH, SECURITY_CHECK_TYPE.FINOP]),
  mode: PropTypes.oneOf([
    SECURITY_CHECK_MODE.SMS,
    SECURITY_CHECK_MODE.NONE,
    SECURITY_CHECK_MODE.GAUTH,
    SECURITY_CHECK_MODE.PASSWD,
    SECURITY_CHECK_MODE.AUTHKEY,
    SECURITY_CHECK_MODE.FINPASSWD,
  ]),

  // redux props
  security: PropTypes.object,
  updateSecurity: PropTypes.func.isRequired,

  // `withApi` HOC props
  addListener: PropTypes.func,
  removeAllListeners: PropTypes.func,
  queue: PropTypes.func.isRequired
}

export const SecurityCheck = withApi(
  connect(mapState, mapDispatch)(RawSecurityCheck)
)
