export const overrides = theme => ({
  root: {
    width: `calc(100% - ${theme.variables.sidebarWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },

    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(6),
    },
  },

  colorPrimary: {
    backgroundColor: theme.variables.colors.headerBackground,
  },
})
