export const overrides = () => {
  return {}
}

export const props = {
  variantMapping: {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'p',
    body1: 'p',
    body2: 'p',
    caption: 'p',
    button: 'span',
    overline: 'p',
    table: 'table',
    td: 'td',
  },
}
