import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { FormControlLabel } from '@material-ui/core';

// core
import { getFormattedPrice } from '_core/utils/formatPrice';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { PriceMix } from '_core/components/PriceMix';
import { TextInput } from '_core/components/TextInput';
import { CountInputHelperText } from '_core/components/CountInputHelperText';
import { CountInput } from '_core/components/CountInput';


const linearFilterStyle = (theme) => ({
  field: {
    margin: '20px 0 5px 0',
  },
  label: {
    marginRight: theme.spacing(2),
  },
});

const useFormStyle = makeStyles(linearFilterStyle);


export const RawProductForm = (props) => {
  const {
    price,
    count,
    isStart,
    isValid,
    minCount,
    maxCount,
    step,
    valueLabel,
    countLabel,
    helperText,
    onChange,
    getLangString: l,
  } = props

  const classes = useFormStyle()

  const cart = useCartContext();

  const countHelperTextProps = {
    minCount,
    maxCount,
    isValid
  }

  const value = getFormattedPrice(count * price.value, cart.findCurrency(price.currency), price.format)

  const priceHelperText = (
    <>
      {l(helperText)}:{' '}
      <PriceMix
        item={{
          ...price,
          currency: price.currency.id,
        }}
        rootProps={{ component: 'span', display: 'inline' }}
      />
    </>
  )

  return (
    <>
      <TextInput
        value={value}
        label={l(valueLabel)}
        readOnly={true}
        helperText={!isStart ? priceHelperText : undefined}
      />

      {!isStart ? (
        <FormControlLabel
          labelPlacement="start"
          label={l(countLabel)}
          error={!isValid}
          classes={{
            root: classes.field,
            label: classes.label,
          }}
          control={
            <CountInput
              error={!isValid}
              min={minCount}
              max={maxCount}
              step={step}
              value={count}
              onChange={onChange}
              helperText={!isValid || maxCount < Infinity ? <CountInputHelperText {...countHelperTextProps} /> : null}
            />
          }
        />
      ) : null}
    </>
  )
}

RawProductForm.defaultProps = {
  minCount: 1,
  maxCount: Infinity,
  step: 1,
  valueLabel: 'r._.shopping.price',
  countLabel: 'r._.shopping.goodscount',
  helperText: 'r._.shopping.itemprice',
}

RawProductForm.propTypes = {
  // self props
  isStart: PropTypes.bool,
  isValid: PropTypes.bool,
  minCount: PropTypes.number,
  maxCount: PropTypes.number,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  price: PropTypes.object.isRequired,
  amount: PropTypes.object.isRequired,
  valueLabel: PropTypes.string,
  countLabel: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ProductForm = withLang(RawProductForm)
