// Global config

export * from 'config'

// Articles

export const ARTICLE_KEY = {
  // register page
  AGREEMENTS: 'agreements',

  // separate pages
  DASHBOARD_SLOT: 'information/office/dashboard',
  CATALOG_SLOT: 'information/office/catalog',
  PRODUCT_SLOT: 'information/office/product',
  PAYMENT_SLOT: 'information/office/payment',

  // `partner` menu group pages
  ORDER_SLOT: 'information/office/order',
  BINARY_SLOT: 'information/office/binary',
  MATRIX_SLOT: 'information/office/matrix',
  STRUCTURE_SLOT: 'information/office/structure',

  // `finance` menu group pages
  TOPUP_SLOT: 'information/office/topup',
  TRANSMIT_SLOT: 'information/office/transmit',
  EXCHANGE_SLOT: 'information/office/exchange',
  WITHDRAW_SLOT: 'information/office/withdraw',

  // `history` menu group pages
  FINANCELOG_SLOT: 'information/office/financelog',
  WITHDRAWLOG_SLOT: 'information/office/withdrawlog',
  PURCHASELOG_SLOT: 'information/office/purchaselog',

  // `support` menu group pages
  FAQ: 'faq',
  FAQ_SLOT: 'information/office/faq',
  TICKETS_SLOT: 'information/office/tickets',

  NEWS: 'information/news',
  EVENTS: 'information/events',
  
  BANNERS: 'information/banners'
}

// Routes

export const ROUTE_URL = {
  // guest pages
  UNLOCK: '/unlock',
  ERROR: '/error',
  LOGIN: '/login',
  REMIND: '/remind',
  REGISTER: '/register',
  AUTHSVC: '/authsvc',

  // client pages
  DASHBOARD: '/dashboard',
  CATALOG: '/catalog',
  PRODUCT: '/product',
  PAYMENT: '/payment',
  CART: '/cart',

  // `partner` menu group
  LINEAR: '/linear',
  BINARY: '/binary',
  MATRIX: '/matrix',
  STRUCTURE: '/structure',
  ORDER: '/order',
  BONUS: '/bonus',

  // `finance` menu group
  TOPUP: '/topup',
  TRANSMIT: '/transmit',
  EXCHANGE: '/exchange',
  WITHDRAW: '/withdraw',

  // `history` menu group
  FINANCELOG: '/financelog',
  WITHDRAWLOG: '/withdrawlog',
  PURCHASELOG: '/purchaselog',

  // `documents` menu group
  STATUTORY: '/statutory',
  VIDEO: '/video',

  // `info` menu group
  NEWS: '/news',
  EVENTS: '/events',

  STUDY: '/study',

  // `support` menu group
  FAQ: '/faq',
  TICKETS: '/tickets',
}

ROUTE_URL.INDEX = ROUTE_URL.DASHBOARD

export const ROUTE_URL_PARAM = {
  ACCOUNT: 'account',
  AGREEMENT: 'agreement',
  CODE: 'code',
  COUNT: 'count',
  DEPTH: 'depth',
  GOTO: 'goto',
  INACTIVE: 'inactive',
  LOGIN: 'login',
  LOT: 'lot',
  ORDER: 'order',
  PAGE: 'page',
  PAYMENT: 'payment',
  PERIOD: 'period',
  PRODUCT: 'product',
  PROFILE: 'profile',
  BUSINESS: 'business',
  PURGE: 'purge',
  REGISTER: 'register',
  ROWS: 'rows',
  SERVICE: 'service',
  SERVICE_ORDER: 'serviceorder',
  SHOP: 'shop',
  SORT: 'sort',
  SORT_ORDER: 'sortorder',
  STATUS: 'status',
  STATUSES: 'statuses',
  VERIFY: 'verify',
}

export const PROFILE_TAB_URL = {
  INFO: 'info',
  COMPANY: 'company',
  SECURITY: 'security',
  VERIFICATION: 'verification',
  // CONTRACT: 'contract',
  // SUBSCRIPTION: 'subscription',
  DELIVERY: 'delivery'
}

// Registration

export const CLIENT_AGE_MAX = 118 * 365 * 24 * 60 * 60 * 1000 // 10 years
export const CLIENT_AGE_MIN = 18 * 365 * 24 * 60 * 60 * 1000 // 10 years

// Misc

export const FILE_UPLOAD_MAX_SIZE = 5242880; // 5 Mb

export const CALCULATOR_INITIAL_VALUE = 100000

export const HAS_CART = false

export const CUSTOMCURRENCY = ''

export const IS_SERVICE = false

export const HAS_CHAT = false

export const HAS_DELIVERY = false

export const HAS_WALLET = true

export const HAS_COOKIE = true

export const HAS_CONTACTS_BUTTON = false

export const HAS_CONTACTSPHONE1 = false

export const HAS_CONTACTSPHONE2 = false

export const HAS_CONTACTS_EMAIL = false

export const HAS_MESSAGES = false

export const HAS_BINARY_FIRSTNAME_LASTNAME = false

export const HAS_BINARY_FULLNAME = false

