import * as api from '_core/utils/api';


export default {
  tokenProperty: null,
  
  buildCitySimple: (type, item) => {
    const list = item?.value;
    
    if (!list?.length)
      return null;
    
    return list[list.length - 1];
  },
  
  buildCityValue: (type, item) => {
    const list = item?.value;
    
    if (!list?.length)
      return [];
    
    return [...list];
  },
  
  selectItem: async (self, item) => {
  },
  
  loadSuggestions: async (self, value) => {
    //const { lang } = self.props;
    
    
    return (await api.request('delivery/address/cities', { value }))?.list || [];
  },
  
  determineAddress: async (self) => {
    const { type } = self.props;
    
    
    if (type === 'city')
      return await api.request('delivery/address/locate', {});
  },
  
  locate: async (value, defaultValue) => {
    if (value) {
      const cities = await api.request('delivery/address/cities', { value });
      
      if (cities.list?.length)
        return { ...cities.list[0], isConfirmed: true };
    }
    
    try {
      return await api.request('delivery/address/locate', {});
    } catch (e) {
      if (defaultValue) {
        const cities = await api.request('delivery/address/cities', { value: defaultValue });
        
        if (cities.list?.length) {
          return cities.list[0];
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  }
};
