import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Button,
  // Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'
import { Tabs } from '_core/components/Tabs'
import { ModalOuter } from '_core/components/ModalOuter'

const style = theme => ({
  dialogTitle: {
    paddingBottom: 0,
  },
})

const RawAgreementsModal = props => {
  const { classes, tab, tabs, onClose, onTabChange, getLangString: l } = props

  if (tab == null) {
    return null
  }

  let tabData = tabs.find(item => item.value === tab)

  if (tabData == null) {
    tabData = tabs[0]
    onTabChange(tabData.value)
  }

  return (
    <ModalOuter open={true} onClose={onClose}>
      <Tabs
        onChange={(event, value) => onTabChange(value)}
        value={tab}
        items={tabs}
        renderTabs={({ tabs, items, value }) => (
          <DialogTitle className={classes.dialogTitle} disableTypography>
            {tabs}
          </DialogTitle>
        )}
        renderTabContents={({ contents, items, value }) => (
          <DialogContent>{contents}</DialogContent>
        )}
      />

      <DialogActions>
        <Button onClick={onClose}>{l('r.ponyatno')}</Button>
      </DialogActions>
    </ModalOuter>
  )
}

RawAgreementsModal.defaultProps = {}

RawAgreementsModal.propTypes = {
  // self props
  isOpen: PropTypes.bool,
  tab: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const AgreementsModal = withLang(withStyles(style)(RawAgreementsModal))
