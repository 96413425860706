// @material-ui

export const calendarStyle = theme => {
  return {
    popper: {
      zIndex: '11',
    },
    calendar: {
      border: 0,
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__day--selected': {
        backgroundColor: theme.variables.colors.primary,
      },
      '& .react-datepicker__day--keyboard-selected': {
        backgroundColor: theme.variables.colors.primary,
      },
      '& .react-datepicker__day--in-range': {
        backgroundColor: theme.variables.colors.primary,
      },
      '& .react-datepicker__day--in-selecting-range': {
        backgroundColor: theme.variables.colors.primary,
        opacity: '0.1',
      },

      '& .react-datepicker__day--outside-month': {
        opacity: '0.25',
      },
      '& .react-datepicker__month-container': {
        boxShadow:
          '0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 7%), 0px 2px 1px -1px rgb(0 0 0 / 12%)',
        color: '#4F4F4F',
        backgroundColor: '#fff',
        border: 0,
        '& .react-datepicker__header ': {
          color: '#4F4F4F',
          backgroundColor: '#fff',
          // backgroundColor: theme.palette.background.default,
          border: 0,

          '& .react-datepicker__day-names': {
            // border-bottom-width: 0;
            color: '#000',
            cursor: 'pointer',
            fontWeight: 'bold',
            // font-size: inherit
          },
        },
      },
    },
  }
}

export default calendarStyle
