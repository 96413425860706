import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// @material-ui
import { IconButton, Badge, Typography, List, ListItem, ListItemIcon, ListSubheader, ListItemText } from '@material-ui/core'
import { Message, Drafts } from '@material-ui/icons'

// core
import { withCatch } from '_core/hocs/withCatch'
import { withLang } from '_core/hocs/withLang'

import { useGlobal } from '_core/hooks/useGlobal'

import { withVisitorInfo } from '_core/utils/visitorInfo';

import { Menu } from '_core/components/Menu'
import { Loading } from '_core/components/Loading'
import { MessagesSplashView } from '_core/views/MessagesSplash'
import { ModalOuter } from '_core/components/ModalOuter'

import {
  isMine,
  loadMessage,
  loadTopic
} from '_core/views/MessagesSplash/util.js'

// style
import { useStyles } from './headerControlsStyle'

const RawMessageNotifier = (props) => {
  const { visitorInfo, getLangString: l } = props;

  const classes = useStyles()
  
  const {
    request: { queue },
    config: { API_METHOD, API_NOTICE }
  } = useGlobal();

  const [state, setState] = useState({ topicId: null, isOpen: false })
  
  React.useEffect(() => {
    queue(API_METHOD.VISITOR_MESSAGE_LIST_UNREAD, {})
      .then((result) => {
        const data = result.list.map(x => {
          return {
            ...loadMessage(x.message), topic: loadTopic(x.topic)
          }
        });
        
        visitorInfo.unreadMessagesInit(data);
      });
  }, [API_METHOD.VISITOR_MESSAGE_LIST_UNREAD, queue]);

  React.useEffect(() => {
    const notifyListener = (event) => {
      if (event.detail.name === API_NOTICE.VISITOR_MESSAGE_CREATE) {
        const messageId = event.detail.data.id;
        
        if (messageId) {
          queue(API_METHOD.VISITOR_MESSAGE_FIND, { "message_id": messageId }, RawMessageNotifier.displayName)
            .then(result => {
              const message = loadMessage(result.message);
              const topic = loadTopic(result.topic);
              if (message.isDashboardShow || !isMine(visitorInfo, message)) {
                visitorInfo.unreadMessageReceived({ ...message, topic: topic });
              }
            });
        }
      } else if (event.detail.name === API_NOTICE.VISITOR_MESSAGE_HAD_READ) {
        visitorInfo.messagesHadRead(event.detail.data.message_ids);
      }
    };
    
    window.addEventListener('notify', notifyListener);
    
    return () => window.removeEventListener('notify', notifyListener);
  }, []);

  const unreadMessages = visitorInfo.listUnreadMessages();

  const renderButton = (btnProps) => (
    <IconButton {...btnProps}>
      <Badge classes={{ badge: classes.userNotifierBadge }} color="primary" badgeContent={unreadMessages.length} max={99}>
        <Message />
      </Badge>
    </IconButton>
  );

  const dateTimeFormat = new Intl.DateTimeFormat(undefined, { 
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  })

  const renderItem = ({ onPopoverClose }, item) => {
    const onClick = () => {
      onPopoverClose();
      setState({ topicId: item.topic.id, isOpen: true });
    };

    const timestamp = new Date(item.timestamp);
    
    return (
      <ListItem button key={`${item.id}`} component={Link} to="mmm" onClick={onClick}>
        <ListItemIcon><Drafts /></ListItemIcon>
        <ListItemText
          primary={<Typography className={classes.userNotifierListItemPrimary} noWrap>{String(item.content || '').trim().replace(/[\n\r][\s\S]*/, '…')}</Typography>}
          secondary={dateTimeFormat.format(timestamp)} />
      </ListItem>
    );
  };

  const renderContent = (props) => (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          {l('r._.header.messenger')}
          
          {!unreadMessages ? <Loading /> : null}
        </ListSubheader>
      }
    >
      {unreadMessages ? (unreadMessages.length ? unreadMessages.map((item) => renderItem(props, item)) : (
        <ListItem button onClick={() => {
          const { onPopoverClose } = props;

          onPopoverClose();
          setState({ topicId: null, isOpen: true });
        }}>
          <ListItemText secondary={l('r._.header.messenger.button.title')} />
        </ListItem>
      )) : null}
    </List>
  );

  const onClose = () => {
    setState(prev => {
      return ({ ...prev, ...{ isOpen: false } });
    });
  }

  return (
    [<Menu
      ButtonComponent={renderButton}
      renderContent={renderContent}
      tooltip={l('r._.header.messenger')}
      paperProps={{ className: classes.userNotifierPaper }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
    />,
    <ModalOuter open={state.isOpen} closeIcon={true} onClose={onClose}>
      <MessagesSplashView topicId={state.topicId} />
    </ModalOuter>]
  )
}

RawMessageNotifier.defaultProps = { }

RawMessageNotifier.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const MessageNotifier = withCatch(
  withVisitorInfo(
    withLang(RawMessageNotifier)
  )
)
