//import React from 'react';


const URL_REGEXP = /((?<proto>https?):\/\/(?<host>[^\/]+))?\/(?<uri>[^\?]+)(\?(?<query>.*))?/;


export const param = (location, query) => {
  query = query?.valueOf();
  
  if (typeof query === 'undefined') {
    query = location;
    
    location = undefined;
  }
  
  if (!query)
    return location;
  
  if (typeof query === 'object') {
    const result = [];
    
    Object.entries(query).map(([key, value]) => {
      if (!key && typeof key !== 'boolean' || !value && typeof value !== 'boolean')
        return;
      
      if (Array.isArray(value)) {
        value.forEach((item) => result.push(encodeURIComponent(key) + '=' + encodeURIComponent(item)));
      } else {
        result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    });
    
    query = result.join('&');
  } else {
    query = String(query ?? '');
  }
  
  if (!location)
    return query;
  
  if (!query)
    return location;
  
  return location + (location.indexOf('?') < 0 ? '?' : '&') + query;
};

export const parse = (url) => {
  const result = URL_REGEXP.exec(url)?.groups;
  
  if (!result)
    return null;
  
  result.query = result.query?.split('&').map((item) => item.split('=', 2))
      .reduce((result, [key, value]) => (result[decodeURIComponent(key)] = decodeURIComponent(value), result), {})
    || {};
  
  return result;
};
