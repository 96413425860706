import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Card,
  Slide,
  Dialog,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import { Close } from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'

const style = theme => ({
  closeCard: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    zIndex: 1,
    borderRadius: '50%',
  },

  closeButton: {
    textShadow: 'none',
  },

  closeIcon: {},
})

const RawModalOuter = props => {
  const {
    classes,
    className,
    children,
    transition,
    onClose,
    closeIcon,
    getLangString: l,

    // Filter out `withLang` HOC props
    langInfo, // eslint-disable-line no-unused-vars
    langStrings, // eslint-disable-line no-unused-vars
    getLangObject, // eslint-disable-line no-unused-vars
    getLangStringSet, // eslint-disable-line no-unused-vars
    getFirstLangString, // eslint-disable-line no-unused-vars

    ...rest
  } = props

  const isXs = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      className={className}
      TransitionComponent={transition}
    >
      {closeIcon ? (
        <Card elevation={0} className={classes.closeCard}>
          <Tooltip title={l('global.buttons.close')}>
            <IconButton
              onClick={onClose}
              className={classes.closeButton}
              size={isXs ? 'small' : undefined}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
          </Tooltip>
        </Card>
      ) : null}

      {children}
    </Dialog>
  )
}

RawModalOuter.defaultProps = {
  // eslint-disable-next-line react/display-name
  transition: props => <Slide direction="down" {...props} />,
  closeIcon: true,
  // transition: Slide,
}

RawModalOuter.propTypes = {
  // self props
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeIcon: PropTypes.bool,
  className: PropTypes.string,
  transition: PropTypes.elementType,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ModalOuter = withLang(withStyles(style)(RawModalOuter))
