import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { get, union, merge, mergeWith } from 'lodash'

// core
import { THEME_TYPE } from 'config'
import * as light from './light'
import * as dark from './dark'
import { getThemeBase } from './getThemeBase'
import { dataMerger } from '_core/utils/dataMerger'
import { getStaticPath } from '_core/utils/getStaticPath'
import { getObjectField } from '_core/utils/getObjectField'

export const getTheme = (data = {}, raw = false) => {
  const themeBase = data.themeType === THEME_TYPE.DARK ? dark : light

  const typography = raw
    ? getObjectField(data, 'typography')
    : merge(
        {},
        getObjectField(themeBase, 'typography'),
        getObjectField(data, 'typography')
      )

  const theme = createMuiTheme({
    ...getThemeBase(data),
    typography,
    utils: {
      getPath: getStaticPath,
    },
  })

  const fields = ['mixins', 'props', 'overrides']
  fields.forEach(field => {
    const args = raw ? [data] : [themeBase, data]
    const keys = union(
      ...args.map(item => Object.keys(getObjectField(item, field)))
    )

    keys.forEach(key => {
      theme[field][key] = mergeStyles(
        theme,
        {},
        ...args.map(item => get(item, `${field}.${key}`))
      )
    })
  })

  return theme
}

const mergeStyles = (theme, ...args) => {
  const seq = args.map(item => {
    if (item != null) {
      if (item instanceof Function) {
        return item(theme)
      }

      if (item instanceof Object || item instanceof Array) {
        return item
      }
    }

    return {}
  })

  return mergeWith(...seq, dataMerger)
}
