import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('h5', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
    
    this.STRIP_OUTER = true;
  }
  
  
  toReact() {
    return (
      <h5>{this.getComponents()}</h5>
    );
  }
  
});
