import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box, Button, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'

// style
import { stepsControlsStyle } from './stepsControlsStyle'

export const RawStepsControls = props => {
  const {
    classes,
    isValid,
    isCheckboxDisabled,
    hasBackButton,
    hasCheckbox,
    backButtonText,
    nextButtonText,
    checkboxText,
    backButtonProps,
    nextButtonProps,
    checkboxProps,
    checkboxLabelProps,
    onPrevStep,
    onNextStep,
    getLangString: l,
  } = props

  const [canNext, setNext] = useState(!hasCheckbox);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row-reverse"
      className={classes.buttonRow}
    >
      <Button disabled={!canNext || !isValid} onClick={onNextStep} {...nextButtonProps}>
        {l(nextButtonText)}
      </Button>

      {hasBackButton ? (
        <Button
          variant="text"
          onClick={onPrevStep}
          className={classes.backButton}
          {...backButtonProps}
        >
          {l(backButtonText)}
        </Button>
      ) : null}
      
      {hasCheckbox ? (
        <FormControlLabel
          disabled={isCheckboxDisabled}
          control={
            <Checkbox
              onClick={(e) => setNext(e.target.checked)}
              className={classes.checkbox}
              {...checkboxProps}
            />
          }
          label={
            <Typography
              display="inline"
              variant="overline"
              className={classes.checkboxLabel}
              {...checkboxLabelProps}
            >
              {l(checkboxText)}
            </Typography>
          }
        />
      ) : null}
    </Box>
  )
}

RawStepsControls.defaultProps = {
  isValid: true,
  isCheckboxDisabled: false,
  hasBackButton: true,
  hasCheckbox: false,
  backButtonText: 'r.nazad',
  nextButtonText: 'r.dalee',
  checkboxText: 'r.ya.prinimayu.usloviya.soglasheniya',
}

RawStepsControls.propTypes = {
  // self props
  isValid: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  backButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  checkboxText: PropTypes.string,
  onPrevStep: PropTypes.func,
  onNextStep: PropTypes.func,
  backButtonProps: PropTypes.object,
  nextButtonProps: PropTypes.object,
  checkboxProps: PropTypes.object,
  checkboxLabelProps: PropTypes.object,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const StepsControls = withLang(
  withStyles(stepsControlsStyle)(RawStepsControls)
)
