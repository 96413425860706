import React from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map, Clusterer, Placemark, ZoomControl, GeolocationControl, TypeSelector, FullscreenControl } from 'react-yandex-maps';

// core
import { ResizeObservable } from '_core/components/ResizeObservable';


const toPosition = (value) => {
  return Array.isArray(value) ? [value[0] || 0.0, value[1] || 0.0] : [value?.lat || 0.0, value?.lng || 0.0];
};

export const YandexMap = (props) => {
  const { type, lang, children } = props;
  
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  
  if (type === 'map') {
    const state = props.center || props.zoom ? { center: toPosition(props.center || props.defaultCenter), zoom: props.zoom || props.defaultZoom || 12 } : undefined;
    const defaultState = { center: toPosition(props.defaultCenter), zoom: props.defaultZoom || 12 };
    
    return (
      <YMaps query={{ lang }}>
        <ResizeObservable onChange={setSize}>
          <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
            <Map width={size.width} height={size.height} state={state} defaultState={defaultState} options={{ yandexMapDisablePoiInteractivity: true }}>
              <ZoomControl options={{ float: 'right' }} />
              <GeolocationControl options={{ float: 'left' }} />
              <TypeSelector options={{ float: 'right' }} />
              <FullscreenControl />
              
              {children}
            </Map>
          </div>
        </ResizeObservable>
      </YMaps>
    );
  } else if (type === 'clusterer') {
    return <Clusterer options={{ preset: 'islands#invertedVioletClusterIcons', groupByCoordinates: false }}>{children}</Clusterer>;
  } else if (type === 'marker') {
    const options = {};
    
    if (props.icon) {
      options.iconLayout = 'default#image';
      options.iconImageHref = props.icon.url;
      options.iconImageOffset = [-(props.icon.anchorX || 0), -(props.icon.anchorY || 0)];
      
      if (props.icon.width && props.icon.height)
        options.iconImageSize = [props.icon.width, props.icon.height];
    }
    
    return (
      <Placemark
        modules={['geoObject.addon.hint']}
        options={options}
        defaultGeometry={toPosition(props.position)}
        properties={{ hintContent: props.hint }}
        onClick={props.onClick}
      >
        {children}
      </Placemark>
    );
  } else {
    return <>{children}</>;
  }
};

YandexMap.defaultProps = {};

YandexMap.propTypes = {
  type: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};
