import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
// core
import { useGlobal } from '_core/hooks/useGlobal'
import { StepsControls } from '_core/components/StepsControls'

import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { withLang } from '_core/hocs/withLang'

// style
import { useServiceAgreementStyle } from './serviceAgreementStyle'
import { Loading } from '../../components/Loading'

export const RawServiceAgreementStep = props => {
  const {
    controlsProps,
    setCache,
    goNext,
    goodsData,
    documentId,
    order,
    load,
    getLangString: l,
  } = props

  const {
    request: { queue },
    config: { API_METHOD, REPLACE_CURRENCY },
  } = useGlobal()

  const orderData = load.order != null ? load.order : order
  const docId = documentId || orderData.id

  const classes = useServiceAgreementStyle()
  const [isLoading, setIsLoading] = useState(false)

  const [serviceAgreement, setServiceAgreement] = useState(null)
  const [agree, setAgree] = useState(false)

  const getData = () => queue(API_METHOD.ORDER, { id: docId })

  const getAgreement = (agreement, documentId) => {
    const serviceAgreement = queue(
      API_METHOD.CGCG_SERVICE_AGREEMENT,
      {
        agreement_name: agreement,
        document_id: documentId,
      },
      RawServiceAgreementStep.displayName
    )
    return serviceAgreement
  }

  useEffect(() => {
    if (docId) {
      if (isEmpty(goodsData)) {
        getData().then(response => {
          getAgreement(response.entries[0].goods.agreement, docId).then(
            response => {
              setServiceAgreement(response.agreements)
            }
          )
        })
      } else {
        getAgreement(goodsData.agreement, docId).then(response => {
          setServiceAgreement(response.agreements)
        })
      }
    }
  }, [goodsData, orderData])

  const handleCheckboxChange = event => {
    setAgree(event.target.checked)
  }

  const handleServiceAgreement = async () => {
    let params = {
      purchase_agreement:
        serviceAgreement.findIndex(
          agreement => agreement.type === 'purchase'
        ) !== -1,
      annual_maintenance_agreement:
        serviceAgreement.findIndex(
          agreement => agreement.type === 'annual_maintenance'
        ) !== -1,
    }
    return await queue(
      API_METHOD.CGCG_SERVICE_AGREEMENT_CONFIRM,
      params,
      RawServiceAgreementStep.displayName
    )
  }

  const payment = id =>
    queue(API_METHOD.MONEY_TOPUP_METHOD, {
      currency: REPLACE_CURRENCY.SHOP_PAYMENT,
      document: id
    })

  const onNextStep = () => {
    setIsLoading(true)
    handleServiceAgreement().then(() => {
      payment(docId).then(response => {
        setIsLoading(false)

        const data = {
          goodsData,
          order: isEmpty(orderData) ? null : { ...orderData },
          payment: { ...response },
        }
        setCache(data)
        goNext(data)
      })
    })
  }

  return isLoading ? (
    <Loading progressProps={{ size: 36 }} />
  ) : (
    <>
      <CardContent>
        {serviceAgreement !== null ? (
          <>
            {serviceAgreement.map((agreement, index) => (
              <Typography
                key={index}
                variant="body1"
                align="right"
                dangerouslySetInnerHTML={{ __html: agreement.body }}
              />
            ))}
            <FormControlLabel
              className={classes.marginTop}
              label={l('r._.guest.register.agreements.title')}
              control={
                <Checkbox
                  checked={agree}
                  name="agree"
                  onClick={handleCheckboxChange}
                />
              }
            />
            <StepsControls
              isValid={agree}
              onNextStep={onNextStep}
              {...controlsProps}
            />
          </>
        ) : null}
      </CardContent>
    </>
  )
}

RawServiceAgreementStep.defaultProps = {}

RawServiceAgreementStep.propTypes = {
  // self props
  order: PropTypes.object,
  goodsData: PropTypes.object,
  controlsProps: PropTypes.object,
  load: PropTypes.object.isRequired,
  goPrev: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  cache: PropTypes.object.isRequired,
  setCache: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ServiceAgreementStep = withLang(RawServiceAgreementStep)
