import { useHistory } from 'react-router';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import {
  getPaymentRequest,
  postPaymentRequest,
  scriptPaymentRequest,
} from '_core/utils/paymentRequest';


export const usePayment = () => {
  const {
    config: { DEFAULT_MESSAGE, PAYMENT_REQUEST },
  } = useGlobal();

  const history = useHistory();

  const getMethodData = (payment, method) =>
    payment.external.find(item => `${item.id}` === `${method}`);

  const getPaymentResult = ({ type, data, print }) => {
    switch (type) {
      case PAYMENT_REQUEST.SHOW_ADDRESS:
        return { crypto: data };

      case PAYMENT_REQUEST.QUICK:
        return { quick: data };

      case PAYMENT_REQUEST.RECEIPT_RUS:
      case PAYMENT_REQUEST.RECEIPT_USA:
      case PAYMENT_REQUEST.RECEIPT_EUR:
        return {
          print,
          invoice: data,
          type: type.split('-')[1]
        };

      default:
        return {};
    }
  };

  const processPayment = ({
    request,
    payment,
    method,
    callback,
    onScript,
    emptyResponseCallback,
    completePath
  }) => {
    if (
      request == null ||
      !(request instanceof Object) ||
      !(request.process instanceof Object) ||
      !Object.values(PAYMENT_REQUEST).includes(request.process.type)
    ) {
      if (emptyResponseCallback) {
        emptyResponseCallback();
        
        if (completePath)
          history.push(completePath);
        
        return;
      } else if (completePath) {
        history.push(completePath);
        
        return;
      } else {
        throw new Error(DEFAULT_MESSAGE.ERROR);
      }
    }

    if (request.process.type === PAYMENT_REQUEST.FORM_GET) {
      if (getPaymentRequest(request.process) && completePath)
        history.push(completePath);
    } else if (request.process.type === PAYMENT_REQUEST.FORM_POST) {
      postPaymentRequest(request.process);
    } else if (request.process.type === PAYMENT_REQUEST.SCRIPT) {
      scriptPaymentRequest(request.process, onScript);
    } else {
      return callback({
        amount: payment.amount,
        message: request.message,
        method: getMethodData(payment, method),
        ...getPaymentResult(request.process),
      });
    }
  };

  return { getPaymentResult, processPayment, getMethodData };
};
