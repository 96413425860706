import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui
import { IconButton, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ContactPhone, Phone, Mail } from '@material-ui/icons'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import Label from '_core/components/Label/Label'

import { withVisitorInfo } from '_core/utils/visitorInfo'

import { Menu } from '_core/components/Menu'

// style
import { contactsPopupStyle } from './contactsPopupStyle'

const RawContactsPopup = (props) => {
  const { classes, inMenu, tooltip, visitorInfo, getLangString: l, ...rest } = props;

  const {
    config: { HAS_CONTACTS_PHONE1, HAS_CONTACTS_PHONE2, HAS_CONTACTS_EMAIL },
  } = useGlobal()


  const buttonRootClasses = clsx({
    [classes.button]: true,
    [classes.buttonContrast]: inMenu,
  })

  return (
    <Menu
      tooltip={l(tooltip)}
      buttonBody={<ContactPhone />}
      ButtonComponent={IconButton}

      buttonProps={{
        classes: {
          root: buttonRootClasses,
          label: classes.label,
        },
      }}
      content={
        <Box display="flex">
          <List>
          {HAS_CONTACTS_PHONE1 ? (
            <ListItem button className={classes.ContactsList}>
              <a className={classes.ContactsPhone} href={'tel:' + l('r._.header.contactphone1.link')}>
                <ListItemIcon className={classes.ContactsIcon}><Phone /></ListItemIcon>
                <ListItemText primary={l('r._.header.contactphone1')} primaryTypographyProps={{ variant: 'h6', className: classes.ContactsResult }} />
              </a>
              <Label size="small"  className={classes.ContactsLabel}>
                {l('r._.header.contactphone1.label')}
              </Label>
            </ListItem>
          ) : null}
          {HAS_CONTACTS_PHONE2 ? (
            <ListItem button className={classes.ContactsList}>
              <a className={classes.ContactsPhone} href={'tel:' + l('r._.header.contactphone2.link')}>
                <ListItemIcon className={classes.ContactsIcon}><Phone /></ListItemIcon>
                <ListItemText primary={l('r._.header.contactphone2')} primaryTypographyProps={{ variant: 'h6', className: classes.ContactsResult }} />
              </a>
              <Label size="small"  className={classes.ContactsLabel}>
                {l('r._.header.contactphone2.label')}
              </Label>
            </ListItem>
          ) : null}
          {HAS_CONTACTS_EMAIL ? (
            <ListItem button className={classes.ContactsList}>
              <a className={classes.ContactsPhone} href={'mailto:' + l('r._.header.contactemail.link')}>
                <ListItemIcon className={classes.ContactsIcon}><Mail /></ListItemIcon>
                <ListItemText primary={l('r._.header.contactemail')} primaryTypographyProps={{ variant: 'h6', className: classes.ContactsResult }} />
              </a>
              <Label size="small"  className={classes.ContactsLabel}>
                {l('r._.header.contactemail.label')}
              </Label>
            </ListItem>
          ) : null}
          </List>
        </Box>
      }
      paperProps={{
        className: classes.paper,
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
      {...rest}
    />
  )
};

RawContactsPopup.defaultProps = {
  tooltip: 'r._.header.contacts',
}

RawContactsPopup.propTypes = {
  inMenu: PropTypes.bool,
  tooltip: PropTypes.string,

  visitorInfo: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ContactsPopup = withVisitorInfo(withLang(
  withStyles(contactsPopupStyle)(RawContactsPopup)
))
