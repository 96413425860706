import { useHistory, useLocation } from 'react-router'

const updateSearchValue = (search, param, value) => {
  if (value == null) {
    search.delete(param)
  } else {
    search.set(param, value)
  }
}

export const useSearch = () => {
  const history = useHistory()
  const location = useLocation()

  const setSearch = search => {
    history.push({
      ...location,
      search: new URLSearchParams(search).toString(),
    })
  }

  const getSearch = () =>
    Array.from(new URLSearchParams(location.search).entries()).reduce(
      (res, [key, value]) => {
        res[key] = value
        return res
      },
      {}
    )

  const updateSearch = (toAdd = {}, toRemove = []) => {
    const search = new URLSearchParams(location.search)

    Object.entries(toAdd).forEach(([param, value]) => {
      updateSearchValue(search, param, value)
    })

    toRemove.forEach(param => search.delete(param))

    history.push({
      ...location,
      search: search.toString(),
    })
  }

  const setSearchParam = (param, value) => {
    const search = new URLSearchParams(location.search)
    updateSearchValue(search, param, value)

    history.push({
      ...location,
      search: search.toString(),
    })
  }

  const getSearchParam = param =>
    new URLSearchParams(location.search).get(param)

  return {
    setSearch,
    getSearch,
    updateSearch,
    setSearchParam,
    getSearchParam,
  }
}
