import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// @material-ui
import { withStyles } from '@material-ui/styles';

// style
import flexStyle from './flexStyle';


class RawFlex extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  _direction = () => {
    const fd = this.props.fd
    const flexDirection = this.props.flexDirection
    if (
      fd === 'row' ||
      fd === 'r' ||
      flexDirection === 'row' ||
      flexDirection === 'r'
    ) {
      return 'row'
    }
    if (
      fd === 'row-reverse' ||
      fd === 'rr' ||
      flexDirection === 'row-reverse' ||
      flexDirection === 'rr'
    ) {
      return 'rowReverse'
    }
    if (
      fd === 'column' ||
      fd === 'c' ||
      flexDirection === 'column' ||
      flexDirection === 'c'
    ) {
      return 'column'
    }
    if (
      fd === 'column-reverse' ||
      fd === 'cr' ||
      flexDirection === 'column-reverse' ||
      flexDirection === 'cr'
    ) {
      return 'columnReverse'
    }
  }

  _justify = () => {
    const jc = this.props.jc
    const justifyContent = this.props.justifyContent
    if (
      jc === 'fs' ||
      jc === 'flex-start' ||
      justifyContent === 'fs' ||
      justifyContent === 'flex-start'
    ) {
      return 'start'
    }
    if (
      jc === 'fe' ||
      jc === 'flex-end' ||
      justifyContent === 'fe' ||
      justifyContent === 'flex-end'
    ) {
      return 'end'
    }
    if (
      jc === 'c' ||
      jc === 'center' ||
      justifyContent === 'c' ||
      justifyContent === 'center'
    ) {
      return 'center'
    }
    if (
      jc === 'sb' ||
      jc === 'space-between' ||
      justifyContent === 'sb' ||
      justifyContent === 'space-between'
    ) {
      return 'between'
    }
    if (
      jc === 'sa' ||
      jc === 'space-around' ||
      justifyContent === 'sa' ||
      justifyContent === 'space-around'
    ) {
      return 'around'
    }
  }

  _alignItems = () => {
    const ai = this.props.ai
    const alignItems = this.props.alignItems
    if (
      ai === 'fs' ||
      ai === 'flex-start' ||
      alignItems === 'fs' ||
      alignItems === 'flex-start'
    ) {
      return 'start'
    }
    if (
      ai === 'fe' ||
      ai === 'flex-end' ||
      alignItems === 'fe' ||
      alignItems === 'flex-end'
    ) {
      return 'end'
    }
    if (
      ai === 'c' ||
      ai === 'center' ||
      alignItems === 'c' ||
      alignItems === 'center'
    ) {
      return 'center'
    }
    if (
      ai === 'st' ||
      ai === 'stretch' ||
      alignItems === 'st' ||
      alignItems === 'stretch'
    ) {
      return 'stretch'
    }
    if (
      ai === 'bl' ||
      ai === 'baseline' ||
      alignItems === 'bl' ||
      alignItems === 'baseline'
    ) {
      return 'baseline'
    }
  }

  _wrap = () => {
    const fw = this.props.fw
    const flexWrap = this.props.flexWrap
    if (
      fw === 'nw' ||
      fw === 'nowrap' ||
      flexWrap === 'nw' ||
      flexWrap === 'nowrap'
    ) {
      return 'nowrap'
    }
    if (
      fw === 'w' ||
      fw === 'wrap' ||
      flexWrap === 'w' ||
      flexWrap === 'wrap'
    ) {
      return 'wrap'
    }
    if (
      fw === 'wr' ||
      fw === 'wrap-reverse' ||
      flexWrap === 'wr' ||
      flexWrap === 'wrap-reverse'
    ) {
      return 'wrapReverse'
    }
  }

  _alignContent = () => {
    const ac = this.props.ac
    const alignContent = this.props.alignContent
    if (
      ac === 'fs' ||
      ac === 'flex-start' ||
      alignContent === 'fs' ||
      alignContent === 'flex-start'
    ) {
      return 'start'
    }
    if (
      ac === 'fe' ||
      ac === 'flex-end' ||
      alignContent === 'fe' ||
      alignContent === 'flex-end'
    ) {
      return 'end'
    }
    if (
      ac === 'c' ||
      ac === 'center' ||
      alignContent === 'c' ||
      alignContent === 'center'
    ) {
      return 'center'
    }
    if (
      ac === 'sb' ||
      ac === 'space-between' ||
      alignContent === 'sb' ||
      alignContent === 'space-between'
    ) {
      return 'between'
    }
    if (
      ac === 'sa' ||
      ac === 'space-around' ||
      alignContent === 'sa' ||
      alignContent === 'space-around'
    ) {
      return 'around'
    }
    if (
      ac === 'st' ||
      ac === 'stretch' ||
      alignContent === 'st' ||
      alignContent === 'stretch'
    ) {
      return 'stretch'
    }
  }
  
  render() {
    const {
      classes,
      children,
      className,
      fd,
      flexDirection,
      jc,
      justifyContent,
      ai,
      alignItems,
      fw,
      flexWrap,
      ac,
      alignContent,
      fullWidth,
      wrap,
      gap,
      ...rest
    } = this.props;
    
    
    const flexClasses = classNames({
      [classes.flex]: true,
      [classes[this._direction() + 'Direction']]: fd || flexDirection,
      [classes[this._justify() + 'Justify']]: jc || justifyContent,
      [classes[this._alignItems() + 'Align']]: ai || alignItems,
      [classes[this._wrap()]]: fw || flexWrap,
      [classes[this._alignContent() + 'AlignContent']]: ac || alignContent,
      [classes['wrapTo' + wrap]]: wrap,
      [classes['gap' + gap]]: gap,
      [classes.fullWidth]: !!fullWidth,
      [className]: className !== undefined,
    });
    
    return (
      <div {...rest} className={flexClasses}>
        {children}
      </div>
    );
  }
  
};

RawFlex.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const Flex = withStyles(flexStyle)(RawFlex);
