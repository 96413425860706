import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { Price } from '_core/components/Price'

export const RawPriceInputHelperText = props => {
  const {
    isValid,
    minPrice,
    maxPrice,
    currency,
    errorText,
    getLangString: l,
  } = props

  if (!isValid) {
    return l(errorText)
  }

  const hasMinPrice = minPrice != null && minPrice > 0
  const hasMaxPrice = maxPrice != null && maxPrice > minPrice

  if (hasMinPrice && hasMaxPrice) {
    return (
      <>
        {l('r.core.shopping.pricemin')}{' '}
        <Price value={minPrice} currency={currency} />{' '}
        {l('r.core.shopping.pricemax')}{' '}
        <Price value={maxPrice} currency={currency} />
      </>
    )
  }

  if (hasMinPrice && !hasMaxPrice) {
    return (
      <>
        {l('r.core.shopping.pricemin')}{' '}
        <Price value={minPrice} currency={currency} />
      </>
    )
  }

  if (!hasMinPrice && hasMaxPrice) {
    return (
      <>
        {l('r.core.shopping.pricemax')}{' '}
        <Price value={maxPrice} currency={currency} />
      </>
    )
  }

  return null
}

RawPriceInputHelperText.defaultProps = {
  errorText: 'r.nedopustimoe.znachenie',
}

RawPriceInputHelperText.propTypes = {
  // self props
  isValid: PropTypes.bool,
  errorText: PropTypes.string,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceInputHelperText = withLang(RawPriceInputHelperText)
