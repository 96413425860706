import { loadScriptPromise } from '_core/utils/loadScript';


export const getPaymentRequest = ({ link, data, is_optional }) => {
  const url = new URL(link);

  if (data != null && data instanceof Object) {
    const params = Object.keys(data).reduce((result, key) => {
      result[encodeURIComponent(key)] = encodeURIComponent(data[key]);
      return result;
    }, {})

    url.search = new URLSearchParams(params);
  }

  if (is_optional)
    return true;
  
  window.location.assign(url);
  
  return false;
};

export const postPaymentRequest = ({ link, data }) => {
  const form = document.createElement('form');

  form.setAttribute('method', 'POST');
  form.setAttribute('action', link);
  form.style.display = 'none';

  if (data != null && data instanceof Object) {
    Object.keys(data).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('name', key)
      input.setAttribute('value', data[key])
      input.setAttribute('type', 'hidden')
      form.appendChild(input)
    });
  }

  document.body.appendChild(form);
  form.submit();
};

export const scriptPaymentRequest = async ({ data }, onClose) => {
  const { click, load } = data;

  if (Array.isArray(load)) {
    await Promise.all(
      load.map(item => {
        const { src, className, dataset, ...attrs } = item

        return loadScriptPromise({
          src,
          className,
          dataset,
          attrs,
        })
      })
    );
  }

  const e = new Event('load');
  window.dispatchEvent(e);

  if (typeof onClose !== 'function') {
    return;
  }

  const callback = new Function('onclose', click);
  callback(onClose);
};
