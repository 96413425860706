//import React from 'react';


export const toFloat = (value) => value !== true && Number(value) || 0;

export const toUFloat = (value) => Math.max(toFloat(value), 0);

export const toInt = (value) => Math.round(toFloat(value));

export const toUInt = (value) => Math.round(toUFloat(value));

export const toString = (value) => value && value !== true && String(value || '') || '';

export const toUTF8Array = (value) => {
  const result = [];
  
  value = toString(value);
  
  for (let i = 0, length = value.length; i < length; i++) {
    let charcode = value.charCodeAt(i);
    
    if (charcode < 0x80) {
      result.push(charcode);
    } else if (charcode < 0x800) {
      result.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      result.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    } else { // surrogate pair
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by subtracting 0x10000 and splitting the 20 bits of 0x0-0xFFFFF into two halves
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (value.charCodeAt(i) & 0x3ff));
      
      result.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
  }
  
  return result;
}
