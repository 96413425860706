import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { get } from 'lodash'

// @material-ui
import { CssBaseline } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'

// core
import { GlobalContext } from '_core/hooks/useGlobal'
import { useRequest } from '_core/hooks/useRequest'
import { Root } from '_core/components/Root'
import { GlobalNotice } from '_core/views/GlobalNotice'

const style = theme => ({
  '@global': {
    html: {
      fontSize: theme.typography.htmlFontSize,
    },

    '*::selection': {
      color: theme.variables.colors.textSelectionFont,
      backgroundColor: theme.variables.colors.textSelectionBackground,
    },
  },
})

export const RawAppView = props => {
  const { globalContext, ...rest } = props
  const theme = useTheme()

  const context = {
    ...globalContext,
    notify: useSnackbar(),
    request: useRequest(),
    getSlot: key => get(globalContext.slots, key, []),
  }

  // Append custom code from theme to <body />
  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      typeof theme?.pages?.customCode !== 'string'
    ) {
      return
    }

    let range = document.createRange()
    range.selectNode(document.body)

    let fragment = range.createContextualFragment(theme?.pages?.customCode)
    document.body.appendChild(fragment)
  }, [theme?.pages?.customCode])

  return (
    <GlobalContext.Provider value={context}>
      <CssBaseline />
      <Root {...rest} />
      <GlobalNotice />
    </GlobalContext.Provider>
  )
}

RawAppView.defaultProps = {}

RawAppView.propTypes = {
  // self props
  globalContext: PropTypes.object.isRequired,
}

export const AppView = withStyles(style)(RawAppView)
