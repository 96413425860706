// @material-ui
import { makeStyles } from '@material-ui/styles'

export const productStyle = theme => ({
  title: {
    marginTop: 0,
  },

  price: {},

  crumbs: {
    paddingBottom: theme.spacing(4),
  },
})

export const useProductStyle = makeStyles(productStyle)
