import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import {
  Help,
  CheckCircle,
  ErrorOutline,
  HourglassEmpty,
} from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'
import { useGlobal } from '_core/hooks/useGlobal'
import { ModalInner } from '_core/components/ModalInner'
import { ModalOuter } from '_core/components/ModalOuter'
import { StepsControls } from '_core/components/StepsControls'

// style
import { verificationSplashStyle } from './verificationSplashStyle'

const RawVerificationSplashView = props => {
  const {
    classes,
    isOpen,
    onVerify,
    onPostpone,
    types,
    verification,
    getLangString: l,
  } = props

  const {
    config: { VERIFICATION_STATUS },
  } = useGlobal()

  const statusData = {
    [VERIFICATION_STATUS.BLANK]: {
      suffix: 'blank',
      icon: <Help className={`${classes.icon} ${classes.iconBlank}`} />,
      itemTextProps: {
        variant: 'subtitle2',
        className: `${classes.itemText} ${classes.itemTextBlank}`,
      },
    },
    [VERIFICATION_STATUS.PENDING]: {
      suffix: 'pending',
      icon: (
        <HourglassEmpty className={`${classes.icon} ${classes.iconPending}`} />
      ),
      itemTextProps: {
        variant: 'subtitle2',
        className: `${classes.itemText} ${classes.itemTextPending}`,
      },
    },
    [VERIFICATION_STATUS.SUCCESS]: {
      suffix: 'success',
      icon: (
        <CheckCircle className={`${classes.icon} ${classes.iconSuccess}`} />
      ),
      itemTextProps: {
        variant: 'caption',
        className: `${classes.itemText} ${classes.itemTextSuccess}`,
      },
    },
    [VERIFICATION_STATUS.FAILURE]: {
      suffix: 'failure',
      icon: (
        <ErrorOutline className={`${classes.icon} ${classes.iconFailure}`} />
      ),
      itemTextProps: {
        variant: 'subtitle2',
        className: `${classes.itemText} ${classes.itemTextFailure}`,
      },
    },
  }

  const renderItems = () =>
    types.map(key => {
      const status = verification.types[key].state
      const data = statusData[status]

      return (
        <div className={classes.item} key={key}>
          <ListItemIcon>{data.icon}</ListItemIcon>
          <ListItemText
            primary={l(`r._.profile.verify.${key}.title${data.suffix}`)}
            primaryTypographyProps={data.itemTextProps}
          />
        </div>
      )
    })

  return (
    <ModalOuter open={isOpen} closeIcon={false}>
      <ModalInner
        title={l('r._.splash.verify.text.title')}
        content={
          <>
            <Typography className={classes.text}>
              {l('r._.splash.verify.text.body')}
            </Typography>

            <div className={classes.items}>{renderItems()}</div>
          </>
        }
        actions={
          <StepsControls
            backButtonText="r._.splash.verify.control.cancel"
            nextButtonText="r._.splash.verify.control.ok"
            onNextStep={onVerify}
            onPrevStep={onPostpone}
          />
        }
      />
    </ModalOuter>
  )
}

RawVerificationSplashView.defaultProps = {}

RawVerificationSplashView.propTypes = {
  // self props
  isOpen: PropTypes.bool.isRequired,
  onVerify: PropTypes.func.isRequired,
  onPostpone: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  verification: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const VerificationSplashView = withLang(
  withStyles(verificationSplashStyle)(RawVerificationSplashView)
)
