export const properties = (text, callback, raw) => {
  text = String(text || '');
  
  if (typeof(callback) !== 'function') {
    if (typeof(callback) !== 'object')
      return text;
    
    const map = { ...callback };
    
    callback = (property, value) => {
      if (!property || !(value in map))
        return;
      
      return map[value];
    };
  }
  
  let start = text.indexOf('{');
  
  if (start >= 0) {
    let last = 0;
    
    const result = [];
    
    while (start >= 0) {
      if (last < start) {
        const value = text.substring(last, start);
        
        let write = callback(false, value);
        
        if (write == null || write == undefined)
          write = value;
        
        result.push(write);
      }
      
      last = text.indexOf('}', start) + 1;
      
      if (last <= 0) {
        const value = text.substring(start);
        
        let write = callback(false, value);
        
        if (write == null || write == undefined)
          write = value;
        
        result.push(write);
        
        break;
      }
      
      const value = text.substring(start + 1, last - 1);
      
      let write = callback(true, value);
      
      if (write == null || write == undefined)
        write = '{' + value + '}';
      
      result.push(write);
      
      start = text.indexOf('{', last);
    }
    
    if (last > 0 && last < text.length) {
      const value = text.substring(last);
      
      let write = callback(false, value);
      
      if (write == null || write == undefined)
        write = value;
      
      result.push(write);
    }
    
    if (!raw)
      return result.join('');
    
    return result;
  } else {
    let write = callback(false, text);
    
    if (write == null || write == undefined)
      write = text;
    
    return write;
  }
}

export const parseParam = (param) => {
  if (!param)
    return {};
  
  let ind = param.indexOf('#');
  
  if (ind >= 0)
    param = param.substring(0, ind);
  
  ind = param.indexOf('?');
  
  if (ind >= 0)
    param = param.substring(ind + 1);
  
  const result = {};
  
  param.replace(/\+/g, '%20').split('&').forEach((key) => {
    ind = key.indexOf('=');
    
    let val = '';
    
    if (ind >= 0) {
      val = key.substring(ind + 1);
      key = key.substring(0, ind);
    }
    
    result[decodeURIComponent(key)] = decodeURIComponent(val);
  });
  
  return result;
};
