import React from 'react';
import PropTypes from 'prop-types';

// core
import { clearMoneyAccountBalance } from '_core/utils/api';
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';


export const RawGlobalNotice = (props) => {
  const { getLangString: l } = props

  const {
    notify: { enqueueSnackbar },
    config: { API_NOTICE },
  } = useGlobal()

  React.useEffect(() => {
    const notifyListener = (event) => {
      if (event.detail.name === API_NOTICE.APP_RELOAD) {
        window.location.reload(true);
      } else if (event.detail.name === API_NOTICE.VISITOR_BALANCE) {
        clearMoneyAccountBalance();
        
        enqueueSnackbar(l('r._.notice.balanceupdate'), { variant: 'info' });
      } else if (event.detail.name === API_NOTICE.CART_UPDATE) {
        enqueueSnackbar(l('r.core.good.cartimposed'), { variant: 'info' });
      } else if (event.detail.name === API_NOTICE.INSUFFICIENT_GOODS_QUANTITY) {
        enqueueSnackbar(event.detail.data, { variant: 'info' });
      }
    };
    
    window.addEventListener('notify', notifyListener);
    
    return () => window.removeEventListener('notify', notifyListener);
  }, []);

  return null;
};

RawGlobalNotice.displayName = 'RawGlobalNotice'

RawGlobalNotice.defaultProps = {}

RawGlobalNotice.propTypes = {
  // self props

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const GlobalNotice = withLang(RawGlobalNotice)
