export const inputProgressStyle = theme => ({
  root: {
    position: 'relative',
  },

  wrapper: {
    left: 0,
    right: 0,
    position: 'absolute',
  },

  progress: {
    height: theme.spacing(0.25),
  },
})
