import React from 'react'
import PropTypes from 'prop-types'

// core
import { TextInputBase } from '_core/components/TextInputBase'

export const TextInput = props => {
  // eslint-disable-next-line no-unused-vars
  const { setFieldValue, setFieldTouched, ...rest } = props

  return <TextInputBase {...rest} />
}

TextInput.defaultProps = {}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
}
