// @material-ui
import { makeStyles } from '@material-ui/styles'

// core
import { langSwitchStyle } from '_core/components/LangSwitch/langSwitchStyle'

export const useCurrencySwitchStyle = makeStyles(theme => {
  const style = langSwitchStyle(theme)

  return {
    label: style.label,
    button: style.button,
    buttonContrast: style.buttonContrast,
    progress: style.progress,

    root: {},

    sign1: {
      fontSize: '1em',
      lineHeight: 1,
    },

    sign2: {
      fontSize: '0.8em',
      lineHeight: 1,
    },

    sign3: {
      fontSize: '0.65em',
      lineHeight: 1,
    },

    option: {},

    optionPrimary: {
      verticalAlign: 'bottom',
    },

    optionSecondary: {
      display: 'inline-block',
    },

    optionSecondarySign: {
      marginLeft: theme.spacing(2),
    },
  }
})
