import React from 'react';
// import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useGlobal } from '_core/hooks/useGlobal';

// style
import { useStyle } from './style';


export const RawPhoneInput = props => {
  const classes = useStyle();

  const {
    config: { PHONE_INPUT_PROPS },
  } = useGlobal()

  return (
    <MuiPhoneNumber
      placeholder=""
      classes={{ flagButton: classes.button }}
      {...PHONE_INPUT_PROPS}
      {...props}
    />
  );
};

RawPhoneInput.defaultProps = {};

RawPhoneInput.propTypes = {};

export const PhoneInput = RawPhoneInput;
