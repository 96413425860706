export const props = {
  fieldsOrder: [
    'photo',
    'mobile',
    'email',
    'lastname',
    'firstname',
    'patronymic',
    'birth_date',
    'city',
  ],

  fieldsOptions: {
    photo: {
      required: false,
      image: true,
      gridProps: { xs: 12, md: 12 },
    },
    login2: {
      required: false,
      serverCheck: true,
      min: 3,
      max: 50,
      ban: ['[^-_.@A-Za-z0-9]', 'g'],
      exclude: ['www', 'web', 'backoffice','root','admin','administrator'],
    },
    mobile: {
      required: false,
      phone: true,
    },

    email: {
      required: false,
      email: true,
      ban: ['\\s', 'g'],
    },

    lastname: {
      required: false,
      max: 50,
    },

    firstname: {
      required: false,
      max: 50,
    },

    patronymic: {
      required: false,
      max: 50,
    },

    birth_date: {
      required: false,
      minAge: 0,
      maxAge: 100,
    },
    regionaddress: {
      required: true,
    },
    address: {
      required: true,
    },
  },
}
