import fnv from 'fnv-lite'

const getRandomString = () =>
  Math.random()
    .toString(36)
    .slice(2)

export const createId = (
  key = getRandomString(),
  time = Date.now()
) => {
  const id = `${key}-${time}-${getRandomString()}`

  return process.env.NODE_ENV === 'production' ? fnv.hex(id) : id
}
