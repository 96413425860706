import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

// core
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { ErrorMessage } from '_core/components/ErrorMessage'
import { StepsControls } from '_core/components/StepsControls'
import { PaymentResultQuick } from '_core/views/PaymentResultQuick'
import { PaymentResultCrypto } from '_core/views/PaymentResultCrypto'
import { PaymentResultInvoice } from '_core/views/PaymentResultInvoice'

// style
import { usePaymentResultStyle } from './paymentResultStyle'

const RawPaymentResult = props => {
  const {
    success,
    failure,
    onClose,
    onRetry,
    hasControls,
    failureButtonText,
    successButtonText,
    failureControlsProps,
    successControlsProps,
    getLangString: l,
  } = props

  const classes = usePaymentResultStyle()

  if (failure != null) {
    // TODO: maxBuys error

    // return (
    //   <>
    //     <Alert severity="error">
    //       <AlertTitle>
    //         <Typography variant="h6">
    //           {l('r._.shopping.payment.ban.title')}
    //         </Typography>
    //       </AlertTitle>

    //       {l('r._.shopping.payment.ban.body')}
    //     </Alert>

    //     <StepsControls
    //       hasBackButton={false}
    //       nextButtonText="r._.cartorder.details.action.close"
    //       onNextStep={onClose}
    //     />
    //   </>
    // )

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className={classes.root}
      >
        <ErrorMessage
          error={failure}
          usePrivileges={false}
          message={'r._.cartorder.text.paymentfailure'}
        />

        {hasControls ? (
          <StepsControls
            hasBackButton={false}
            nextButtonText={failureButtonText}
            onNextStep={onRetry}
            {...failureControlsProps}
          />
        ) : null}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className={classes.root}
    >
      {success.isInternalCash ? (
        <Alert severity="success">
          {l('r._.cartorder.text.innerpaymentsuccess')}
        </Alert>
      ) : null}

      {success.request ? (
        <Alert severity="info">
          <AlertTitle>
            <Typography variant="h6">
              {l('r._.shopping.result.request.title')}
            </Typography>
          </AlertTitle>

          {l('r._.shopping.result.request.body')}
        </Alert>
      ) : null}

      {success.quick != null ? <PaymentResultQuick data={success} /> : null}
      {success.crypto != null ? <PaymentResultCrypto data={success} /> : null}
      {success.invoice != null ? <PaymentResultInvoice data={success} /> : null}

      {hasControls ? (
        <StepsControls
          hasBackButton={false}
          nextButtonText={successButtonText}
          onNextStep={onClose}
          {...successControlsProps}
        />
      ) : null}
    </Box>
  )
}

RawPaymentResult.defaultProps = {
  hasControls: true,
  failureButtonText: 'r._.cartorder.action.retry',
  successButtonText: 'r._.cartorder.details.action.close',
}

RawPaymentResult.propTypes = {
  // self props
  hasControls: PropTypes.bool,
  success: PropTypes.object,
  failure: PropTypes.object,
  failureButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  failureControlsProps: PropTypes.object,
  successControlsProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentResult = withCatch(withLang(RawPaymentResult))
