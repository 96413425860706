// @material-ui
import { makeStyles } from '@material-ui/styles'

export const paymentStepMethodStyle = theme => ({
  root: {
    height: '100%',
  },

  group: {
    width: '100%',
  },

  divider: {
    margin: theme.spacing(4, 0),
  },

  price: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightBold,
  },
  
  noPayNote: {
    color: '#080'
  },
  
  noMixedNote: {
    color: '#b00'
  }
})

export const usePaymentStepMethodStyle = makeStyles(paymentStepMethodStyle)
