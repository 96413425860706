import { createSelectorCreator, defaultMemoize } from 'reselect'
import { get as _get, isEqual as _isEqual } from 'lodash'
// Ritos
import { makeActionCreator } from '_core/utils/actions'

// === Action types ===

const VISITOR_LINK_RESET = 'VISITOR_LINK_RESET'
const VISITOR_LINK_UPDATE = 'VISITOR_LINK_UPDATE'

const CAREER_RESET = 'CAREER_RESET'
const CAREER_UPDATE = 'CAREER_UPDATE'

const STAT_RESET = 'STAT_RESET'
const STAT_UPDATE = 'STAT_UPDATE'

const MORE_VOLUME_RESET = 'MORE_VOLUME_RESET'
const MORE_VOLUME_UPDATE = 'MORE_VOLUME_UPDATE'

const LEVEL_RESET = 'LEVEL_RESET'
const LEVEL_UPDATE = 'LEVEL_UPDATE'

const VOLUME_RESET = 'VOLUME_RESET'
const VOLUME_UPDATE = 'VOLUME_UPDATE'

const STRUCTURE_RESET = 'STRUCTURE_RESET'
const STRUCTURE_UPDATE = 'STRUCTURE_UPDATE'

const BALANCE_RESET = 'BALANCE_RESET'
const BALANCE_UPDATE = 'BALANCE_UPDATE'

const ACCOUNT_RESET = 'ACCOUNT_RESET'
const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE'

const INVESTMENT_RESET = 'INVESTMENT_RESET'
const INVESTMENT_UPDATE = 'INVESTMENT_UPDATE'

const INVESTMENT_STAT_RESET = 'INVESTMENT_STAT_RESET'
const INVESTMENT_STAT_UPDATE = 'INVESTMENT_STAT_UPDATE'

const TRANSMIT_TYPE_RESET = 'TRANSMIT_TYPE_RESET'
const TRANSMIT_TYPE_UPDATE = 'TRANSMIT_TYPE_UPDATE'

const TRANSMIT_RESET = 'TRANSMIT_RESET'
const TRANSMIT_UPDATE = 'TRANSMIT_UPDATE'

const WITHDRAWAL_RESET = 'WITHDRAWAL_RESET'
const WITHDRAWAL_UPDATE = 'WITHDRAWAL_UPDATE'

const TICKET_RESET = 'TICKET_RESET'
const TICKET_UPDATE = 'TICKET_UPDATE'

const TICKET_MESSAGE_RESET_ALL = 'TICKET_MESSAGE_RESET_ALL'
const TICKET_MESSAGE_RESET = 'TICKET_MESSAGE_RESET'
const TICKET_MESSAGE_UPDATE = 'TICKET_MESSAGE_UPDATE'

const SECURITY_RESET = 'SECURITY_RESET'
const SECURITY_UPDATE = 'SECURITY_UPDATE'

const GOODS_RESET = 'GOODS_RESET'
const GOODS_UPDATE = 'GOODS_UPDATE'

const GOODS_CATEGORY_RESET = 'GOODS_CATEGORY_RESET'
const GOODS_CATEGORY_UPDATE = 'GOODS_CATEGORY_UPDATE'

const OWN_GOODS_RESET = 'OWN_GOODS_RESET'
const OWN_GOODS_UPDATE = 'OWN_GOODS_UPDATE'

const CURRENCY_RATE_RESET = 'CURRENCY_RATE_RESET'
const CURRENCY_RATE_UPDATE = 'CURRENCY_RATE_UPDATE'

const ORDER_RESET = 'ORDER_RESET'
const ORDER_SET = 'ORDER_SET'

// === Default state ===

const defaultState = {
  visitorLink: null,
  visitorInfo: null,
  balance: null,
  account: null,
  investment: null,
  investmentStat: null,
  transmitType: null,
  transmit: null,
  withdrawal: null,
  ticket: null,
  ticketMessage: null,
  career: null,
  stat: null,
  level: null,
  volume: null,
  volumeMore: null,
  structure: null,
  security: null,
  goods: null,
  goodsCategory: null,
  ownGoods: null,
  currencyRate: null,
  order: null,
}

// === Selectors ===

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _isEqual)

const getPrivateData = store => store.privateData

const getVisitorInfo = store => getPrivateData(store).visitorInfo

const getVisitorLogin = store => {
  const visitorInfo = getVisitorInfo(store)
  return visitorInfo != null ? visitorInfo.login : null
}

const getVisitorPhoto = store => {
  const visitorInfo = getVisitorInfo(store)
  return visitorInfo != null ? visitorInfo.photo : null
}

const _getFileds = (store, fields) =>
  fields.reduce((result, field) => {
    if (store != null) {
      const defaultField = _get(store, field, null)
      result[field] = _get(store, `${field}.id`, defaultField)
    }
    return result
  }, {})

const getVisitorLink = store => getPrivateData(store).visitorLink

const getBalance = store => getPrivateData(store).balance

const getAccount = store => getPrivateData(store).account

const getInvestment = store => getPrivateData(store).investment

const getInvestmentStat = store => getPrivateData(store).investmentStat

const getVolumeMore = store => getPrivateData(store).volumeMore

const getTransmitType = store => getPrivateData(store).transmitType

const getTransmit = store => getPrivateData(store).transmit

const getWithdrawal = store => getPrivateData(store).withdrawal

const getTicket = store => getPrivateData(store).ticket

const getTicketMessage = store => getPrivateData(store).ticketMessage

const getCareer = store => getPrivateData(store).career

const getStat = store => getPrivateData(store).stat

const getLevel = store => getPrivateData(store).level

const getVolume = store => getPrivateData(store).volume

const getStructure = store => getPrivateData(store).structure

const getSecurity = store => getPrivateData(store).security

const getGoods = store => getPrivateData(store).goods

const getOwnGoods = store => getPrivateData(store).ownGoods

// === Actions ===

const updateVisitorLink = makeActionCreator(VISITOR_LINK_UPDATE, 'data')
const resetVisitorLink = makeActionCreator(VISITOR_LINK_RESET)

const updateAccount = makeActionCreator(ACCOUNT_UPDATE, 'data')
const resetAccount = makeActionCreator(ACCOUNT_RESET)

const updateInvestment = makeActionCreator(INVESTMENT_UPDATE, 'data')
const resetInvestment = makeActionCreator(INVESTMENT_RESET)

const updateInvestmentStat = makeActionCreator(INVESTMENT_STAT_UPDATE, 'data')
const resetInvestmentStat = makeActionCreator(INVESTMENT_STAT_RESET)

const updateTransmit = makeActionCreator(TRANSMIT_UPDATE, 'data', 'account')
const resetTransmit = makeActionCreator(TRANSMIT_RESET)

const updateWithdrawal = makeActionCreator(WITHDRAWAL_UPDATE, 'data')
const resetWithdrawal = makeActionCreator(WITHDRAWAL_RESET)

const updateTicket = makeActionCreator(TICKET_UPDATE, 'data')
const resetTicket = makeActionCreator(TICKET_RESET)

const updateTicketMessage = makeActionCreator(
  TICKET_MESSAGE_UPDATE,
  'id',
  'data'
)
const resetTicketMessage = makeActionCreator(TICKET_MESSAGE_RESET, 'id')
const resetTicketMessageAll = makeActionCreator(TICKET_MESSAGE_RESET_ALL)

const updateVolumeMore = makeActionCreator(MORE_VOLUME_UPDATE, 'data')
const resetVolumeMore = makeActionCreator(MORE_VOLUME_RESET)

const updateVolume = makeActionCreator(VOLUME_UPDATE, 'data')
const resetVolume = makeActionCreator(VOLUME_RESET)

const updateStructure = makeActionCreator(STRUCTURE_UPDATE, 'data')
const resetStructure = makeActionCreator(STRUCTURE_RESET)

const updateSecurity = makeActionCreator(SECURITY_UPDATE, 'data')
const resetSecurity = makeActionCreator(SECURITY_RESET)

const updateGoods = makeActionCreator(GOODS_UPDATE, 'data')
const resetGoods = makeActionCreator(GOODS_RESET)

const updateGoodsCategory = makeActionCreator(GOODS_CATEGORY_UPDATE, 'data')
const resetGoodsCategory = makeActionCreator(GOODS_CATEGORY_RESET)

const updateOwnGoods = makeActionCreator(OWN_GOODS_UPDATE, 'data')
const resetOwnGoods = makeActionCreator(OWN_GOODS_RESET)

const setOrder = makeActionCreator(ORDER_SET, 'data')
const resetOrder = makeActionCreator(ORDER_RESET)

const resetPrivateData = () => {
  return dispatch => {
    dispatch(resetVisitorLink())
    dispatch(resetAccount())
    dispatch(resetInvestment())
    dispatch(resetInvestmentStat())
    dispatch(resetVolumeMore())
    dispatch(resetTransmit())
    dispatch(resetWithdrawal())
    dispatch(resetTicket())
    dispatch(resetTicketMessageAll())
    dispatch(resetVolume())
    dispatch(resetStructure())
    dispatch(resetSecurity())
    dispatch(resetGoods())
    dispatch(resetGoodsCategory())
    dispatch(resetOwnGoods())
  }
}

// === Exports ===

export const privateDataDefaultState = defaultState

export const privateDataActionTypes = {
  VISITOR_LINK_RESET,
  VISITOR_LINK_UPDATE,
  CAREER_RESET,
  CAREER_UPDATE,
  STAT_RESET,
  STAT_UPDATE,
  MORE_VOLUME_RESET,
  MORE_VOLUME_UPDATE,
  LEVEL_RESET,
  LEVEL_UPDATE,
  VOLUME_RESET,
  VOLUME_UPDATE,
  STRUCTURE_RESET,
  STRUCTURE_UPDATE,
  BALANCE_RESET,
  BALANCE_UPDATE,
  ACCOUNT_RESET,
  ACCOUNT_UPDATE,
  INVESTMENT_RESET,
  INVESTMENT_UPDATE,
  INVESTMENT_STAT_RESET,
  INVESTMENT_STAT_UPDATE,
  TRANSMIT_TYPE_RESET,
  TRANSMIT_TYPE_UPDATE,
  TRANSMIT_RESET,
  TRANSMIT_UPDATE,
  WITHDRAWAL_RESET,
  WITHDRAWAL_UPDATE,
  TICKET_RESET,
  TICKET_UPDATE,
  TICKET_MESSAGE_RESET_ALL,
  TICKET_MESSAGE_RESET,
  TICKET_MESSAGE_UPDATE,
  SECURITY_RESET,
  SECURITY_UPDATE,
  GOODS_RESET,
  GOODS_UPDATE,
  GOODS_CATEGORY_RESET,
  GOODS_CATEGORY_UPDATE,
  OWN_GOODS_RESET,
  OWN_GOODS_UPDATE,
  CURRENCY_RATE_RESET,
  CURRENCY_RATE_UPDATE,
  ORDER_SET,
  ORDER_RESET
}

export const privateDataSelectors = {
  getPrivateData,
  getVisitorInfo,
  getVisitorLogin,
  getVisitorPhoto,
  getVisitorLink,
  getBalance,
  getAccount,
  getInvestment,
  getInvestmentStat,
  getVolumeMore,
  getTransmitType,
  getTransmit,
  getWithdrawal,
  getTicket,
  getTicketMessage,
  getCareer,
  getStat,
  getLevel,
  getVolume,
  getStructure,
  getSecurity,
  getGoods,
  getOwnGoods
}

export const privateDataActions = {
  updateVisitorLink,
  resetVisitorLink,
  updateAccount,
  resetAccount,
  updateInvestment,
  resetInvestment,
  updateInvestmentStat,
  resetInvestmentStat,
  updateVolumeMore,
  resetVolumeMore,
  updateTransmit,
  resetTransmit,
  updateWithdrawal,
  resetWithdrawal,
  updateTicket,
  resetTicket,
  updateTicketMessage,
  resetTicketMessage,
  resetTicketMessageAll,
  updateVolume,
  resetVolume,
  updateStructure,
  resetStructure,
  updateSecurity,
  resetSecurity,
  updateGoods,
  resetGoods,
  updateGoodsCategory,
  resetGoodsCategory,
  updateOwnGoods,
  resetOwnGoods,
  resetPrivateData,
  setOrder,
  resetOrder
}
