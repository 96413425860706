import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
  Icon,
  Button,
  Slide,
  Badge,
  Chip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { getFormattedValue } from '_core/utils/formatPrice';
import { useCartContext } from '_core/contexts/Cart';
import { PriceMix } from '_core/components/PriceMix';
import { GoodsListItem } from '_core/components/GoodsListItem';

// style
import { useGoodsCartModalStyle } from './goodsCartModalStyle'


const RawGoodsCartModal = (props) => {
  const { getLangString: l } = props;
  
  const theme = useTheme();
  const classes = useGoodsCartModalStyle();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const cart = useCartContext();
  
  const {
    config: { ROUTE_URL }
  } = useGlobal();
  
  React.useEffect(() => {
    let handle = (e) => !cart.isModal(e.target) && cart.setModalVisible(false);
    
    document.addEventListener('mousedown', handle);
    
    return () => document.removeEventListener('mousedown', handle);
  });
  
  const renderList = (items) =>
    items.map(item => (
      <GoodsListItem
        key={item.id}
        data={item}
        type="modal"
        close={item.count}
      />
    ));

  const renderConfirmButton = () => (
    <>
      <Box
        className={classes.allCount}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="body1" className={classes.allCountLabel}>
          {l('r.core.good.total')}:
        </Typography>

        <PriceMix
          item={{ currency: cart.currency.id, value: cart.price_with_discount_total }}
          itemProps={{
            className: classes.prices,
            rootProps: { color: 'secondary' },
          }}
          rootProps={{ align: 'right' }}
        />
      </Box>

      {cart.points_total > 0 ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.points}
        >
          <Chip
            size="small"
            color="secondary"
            className={classes.pointsChip}
            label={
              <Typography
                component="span"
                variant="caption"
                className={classes.pointsValue}
              >
                {l(
                  ['r._.goods.text.points', cart.points_total],
                  getFormattedValue(cart.points_total, {
                    scale: theme.variables.pointsScale,
                  })
                )}
              </Typography>
            }
          />
        </Box>
      ) : null}

      <Button
        fullWidth={true}
        color="secondary"
        component={Link}
        to={ROUTE_URL.CART}
        onClick={() => cart.setModalVisible(false)}
        className={classes.button}
      >
        {l('r.core.good.checkout')}
      </Button>

      <Button
        fullWidth={true}
        color="primary"
        component={Link}
        to={ROUTE_URL.CATALOG}
        onClick={() => cart.setModalVisible(false)}
        className={classes.button}
      >
        {l('r.core.good.continueshopping')}
      </Button>
    </>
  );

  if (!isSm && cart.length <= 0)
    return null;

  return (
    <Slide in={cart.isModalVisible || !isSm} direction={!isSm ? 'up' : 'down'} ref={cart.setModalRef}>
      <Card className={classes.root}>
        {!isSm ? (
          <CardActions className={classes.rootActions}>
            <Link to={ROUTE_URL.CART}>
              <Button
                className={classes.mobileButton}
                fullWidth={true}
                color="secondary"
                startIcon={
                  <Badge
                    badgeContent={cart.count_total}
                    color="primary"
                    classes={{
                      root: classes.badge,
                      badge: classes.badgeText,
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                  </Badge>
                }
              >
                {l('r.core.good.gotocart')}
              </Button>
            </Link>
          </CardActions>
        ) : (
          <>
            <CardContent className={classes.rootContent}>
              {cart.length > 0 ? (
                <>
                  {renderList(cart.goods)}
                </>
              ) : (
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.marginBottom}
                >
                  {l('r.core.good.cartempty')}
                </Typography>
              )}
            </CardContent>
            <CardActions className={classes.rootActions}>
              {cart.length > 0 ? (
                renderConfirmButton()
              ) : (
                <Button
                  fullWidth={true}
                  color="secondary"
                  to={ROUTE_URL.CATALOG}
                  component={Link}
                  onClick={() => cart.setModalVisible(false)}
                >
                  {l('r.core.good.startshopping')}
                </Button>
              )}
            </CardActions>
          </>
        )}
      </Card>
    </Slide>
  );
};

RawGoodsCartModal.defaultProps = {};

RawGoodsCartModal.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const GoodsCartModal = withLang(RawGoodsCartModal);
