const labelStyle = ({ palette }) => ({
  label: {
    margin: 0,
    padding: 0,
  },
  smallLabel: {
    fontSize: '13px',
  },
  bigLabel: {
    fontSize: '16px',
  },
  bottomMargin: {
    marginBottom: '4px',
  },
  blackColor: {
    color: palette.text.primary,
  },
  whiteColor: {
    color: 'white',
  },
  redColor: {
    color: palette.primary.main,
  },
  grayColor: {
    color: palette.text.secondary,
  },
  textfield: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    fontSize: '1.1rem',
    fontWeight: 300,
    lineHeight: 1,
    transformOrigin: 'top left',
    letterSpacing: '0.00938em',
  },
})
export default labelStyle
