// @material-ui
import { makeStyles } from '@material-ui/styles'

const modalItemHeight = 60
const modalItemsInCart = 3

export const goodsCartModalStyle = theme => ({
  root: {
    position: 'fixed',
    top: theme.variables.headerHeight.main,
    right: 90,
    zIndex: theme.zIndex.appBar - 1,
    width: 420,
    padding: theme.spacing(3),
    marginBottom: 0,
    maxHeight: `calc(
      100% - 
      ${theme.variables.headerHeight.main}px - 
      ${theme.spacing(2)}px)`,
    [theme.breakpoints.down('md')]: {
      top: theme.variables.headerHeight.md,
      maxHeight: `calc(
        100% - 
        ${theme.variables.headerHeight.md}px - 
        ${theme.spacing(2)}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      // top: theme.variables.headerHeight.xs,
      top: 'unset',
      bottom: 0,
      width: '100%',
      right: 0,
      borderRadius: 0,
      padding: 0,
      maxHeight: `calc(
        100% - 
        ${theme.variables.headerHeight.md}px - 
        ${theme.spacing(1)}px)`,
    },
  },
  rootContent: {
    maxHeight: `calc(
      (
        ${modalItemHeight}px + 1px + 
        ${theme.spacing(2.5)}px * 2) 
        * ${modalItemsInCart} - 
        ${theme.spacing(2.5)}px
      )`,
    overflowY: 'auto',
    padding: 0,
  },

  rootActions: {
    padding: 0,
    border: 'none',
    display: 'block',
    '& > :not(:first-child)': {
      marginLeft: 0,
    },
  },
  allCount: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  allCountLabel: {
    textTransform: 'uppercase',
  },
  allCountValue: {
    marginBottom: 3,
  },
  badge: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },

  button: {
    // remove after merge with `agros/browsers` branch
    display: 'block',
    textAlign: 'center',

    margin: theme.spacing(2, 0),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  mobileButton: {
    borderRadius: 0,
  },

  mixedCount: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },

  prices: {
    fontWeight: theme.typography.fontWeightBold,
  },

  points: {
    width: '100%',
  },

  pointsChip: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },

  pointsValue: {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
})

export const useGoodsCartModalStyle = makeStyles(goodsCartModalStyle)
