import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import { Zoom, Fab, useScrollTrigger } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ArrowUpward } from '@material-ui/icons';


const style = (theme) => ({
  root: {
    position: 'fixed',
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    cursor: 'pointer',
    zIndex: theme.zIndex.appBar + 1,
  },
});

export const RawUpwardButton = (props) => {
  const { classes, target } = props;

  const trigger = useScrollTrigger({
    target: target != null ? target : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={trigger}>
      <Fab color="primary" onClick={UpwardButton.scroll.intoView} className={classes.root}>
        <ArrowUpward />
      </Fab>
    </Zoom>
  );
};

RawUpwardButton.defaultProps = {};

RawUpwardButton.propTypes = {
  // self props
  target: PropTypes.instanceOf(Node),
  anchor: PropTypes.instanceOf(Element),

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,
};

const UpwardButton = withStyles(style)(RawUpwardButton);

UpwardButton.scroll = {
  anchor: null,
  
  intoView: () => {
    if (!UpwardButton.scroll.anchor)
      return;
    
    UpwardButton.scroll.anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

export { UpwardButton };
