import { langSwitchStyle } from '_core/components/LangSwitch/langSwitchStyle'

export const contactsPopupStyle = theme => ({
  ...langSwitchStyle(theme),

  paper: {
    padding: theme.spacing(0.5),
  },
  ContactsList: {
    display: 'block',
  },
  ContactsPhone: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flex: '0 0 auto',
  },
  ContactsIcon: {
    alignItems: 'center',
  },
  ContactsLabel: {
    textAlign: 'center',
    marginTop: '-7px',
  },
  ContactsResult: {
    color: theme.variables.colors.primary,
  },
})
