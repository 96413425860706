export const makeActionCreator = (type, ...params) => (...args) => {
  let payload = {}

  if (params.length === 1 && params[0] instanceof Object) {
    // 'params' is payload object
    payload = { ...params[0] }
  } else {
    // 'params' is list of action arguments names
    params.forEach((arg, index) => {
      payload[params[index]] = args[index]
    })
  }

  return {
    type,
    payload,
  }
}

export const makeErrorActionCreator = type => (code, message) => {
  return {
    type,
    error: {
      code,
      message,
    },
  }
}
