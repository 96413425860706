import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// core
import * as convert from '_core/utils/convert';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';

import { useStyle } from './style';


export const RawDeliveryWarehouseSelect = (props) => {
  const {
    getLangString: l,
  } = props;
  
  
  const classes = useStyle();
  
  const cart = useCartContext();
  
  
  const renderItem = (item) => (
    <div className={classes.marginTop}>
      <div>{item.title}</div>
      <div className={classes.marginLeft}>
        {item.is_external_delivery || item.is_internal_delivery_world ? (<div>{l('r._.profile.delivery.world')}</div>)
          : item.is_internal_delivery_country ? (<div>{l('r._.profile.delivery.country')}</div>)
          : item.is_internal_delivery_region ? (<div>{l('r._.profile.delivery.region')}</div>)
          : null}
        
        {item.is_internal_pickup ? (item.address ? (<div>{l('r._.profile.delivery.pickup.address').replace('{address}', item.address)}</div>) : (<div>{l('r._.profile.delivery.pickup')}</div>)) : null}
      </div>
    </div>
  );
  
  
  return cart.warehouseList && !cart.warehouseList.error && (cart.warehouseList.isLoading || cart.warehouseList.data.length) ? (
    <Grid item xs={12} className={classes.marginTop}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{l('global.field.object')}</FormLabel>
        
        {cart.warehouseList.isLoading ? (
          <RadioGroup
            aria-label="warehouse"
            name="warehouse"
            defaultValue={null}
          >
            <FormControlLabel value={null} control={<Radio />} label={<Skeleton animation="wave" variant="rect" className={classes.skeleton} />} />
          </RadioGroup>
        ) : (
          <RadioGroup
            aria-label="warehouse"
            name="warehouse"
            value={convert.toString(cart.warehouse?.id)}
            onChange={(event) => cart.setWarehouse(cart.warehouseList.data.find((item) => item.id == event.target.value) || null)}
          >
            {cart.warehouseList.data.map((item) => (
              <FormControlLabel
                value={convert.toString(item.id)}
                checked={item.id == cart.warehouse?.id}
                control={<Radio />}
                label={renderItem(item)}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    </Grid>
  ) : null;
};

RawDeliveryWarehouseSelect.defaultProps = {};

RawDeliveryWarehouseSelect.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const DeliveryWarehouseSelect = withLang(RawDeliveryWarehouseSelect);
