import { makeStyles } from '@material-ui/styles'

export const langSwitchStyle = theme => ({
  root: {},

  label: {
    width: '1em !important',
    height: '1em !important',
  },

  button: {},

  buttonContrast: {
    backgroundColor: theme.palette.sidebarItemsHoverColor,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  icon: {
    width: '100%',
  },

  progress: {
    width: '1em !important',
    height: '1em !important',
  },

  listItem: {
    minWidth: 'auto',
    width: theme.spacing(4),
    marginRight: theme.spacing(2),
  },

  listIcon: {
    width: '100%',
  },
})

export const useLangSwitchStyle = makeStyles(langSwitchStyle)
