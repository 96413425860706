import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('q', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <q>{this.getComponents()}</q>
    );
  }
  
});
