import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// @material-ui
import { Divider, Typography } from '@material-ui/core';

// core
import { withLang } from '_core/hocs/withLang';
import { Price } from '_core/components/Price';
import { PriceMix } from '_core/components/PriceMix';
import { PaymentBrief } from '_core/components/PaymentBrief';
import { PaymentSwitch } from '_core/components/PaymentSwitch';
import { TopupOption } from '_core/components/TopupOption';
import { PaymentInternal } from '_core/components/PaymentInternal';
import { StepsControls } from '_core/components/StepsControls';
import { PaymentFee } from '_core/components/PaymentFee';

// style
import { usePaymentStepMethodStyle } from './paymentStepMethodStyle';


export const RawPaymentStepMethod = (props) => {
  const {
    load,
    cache,
    order,
    payment,
    goPrev,
    goNext,
    setCache,
    hasBrief,
    controlsProps,
    // baseUrl,
    // goodsData,
    // history,
    getLangString: l,
  } = props;
  const classes = usePaymentStepMethodStyle();

  const [method, setMethod] = useState(cache.method);
  const [isInternalCash, setUseInternal] = useState(cache.isInternalCash || false);

  const orderData = cache.order != null ? cache.order : load.order != null ? load.order : order;
  
  const paymentData =
    cache.payment != null
      ? cache.payment
      : load.payment != null
      ? load.payment
      : payment;

  if (!paymentData.external?.length) {
    return (
      <Typography align="center" variant="h6" color="textSecondary">
        {l('r._.cartstep.payment.message.unavailable')}
      </Typography>
    )
  }

  // TODO: remove
  // Case for to show "Claim registered" message without payment

  // const noRequest =
  //   goodsData.goods.properties.no_request != null &&
  //   !!+goodsData.goods.properties.no_request

  const currentMethod = paymentData.external.find((item) => item.id == method) || null;
  const oneHasInternal = paymentData.external.some(({ payment }) => !payment.mixed?.every((item) => item.balance == null));
  const canPayByInternal = !isEmpty(paymentData.internal)
    ? parseInt(paymentData.internal[0].amount.value) >=
      parseInt(paymentData.amount.value)
    : false;

  // TODO: remove
  // Case for to show "Claim registered" message without payment

  // if (noRequest) {
  //   history.push(`${baseUrl}/success`, {
  //     request: paymentData.document,
  //   })
  // }

  const onNextStep = () => {
    const data = {
      method,
      isInternalCash,
      isInternalFull: paymentData.is_internal_full_payment,
      payment: paymentData,
      order: orderData,
    }

    setCache(data)
    goNext(data)
  };

  const renderOption = (optionData, optionProps) => {
    const { payment, fixed_fee, percent_fee } = optionData

    const childProps = {
      currency: fixed_fee?.currency?.id,
      fixedFee: fixed_fee?.value,
      percentFee: percent_fee?.value,
    }

    if (Array.isArray(payment.details)) {
      return (
        <TopupOption optionProps={optionProps} {...childProps}>
          <PriceMix
            items={payment.details.map((item) => ({
              ...item,
              className: classes.price,
            }))}
          />
        </TopupOption>
      );
    }
    
    const hasMixed = isInternalCash && payment.mixed.find((item) => item.balance)
    const paymentAmount = isInternalCash ? paymentData.is_internal_full_payment ? 0.00 : payment.mixed.find((item) => !item.balance)?.amount.value || 0.00 : payment.amount.value;
    
    return (
      <TopupOption optionProps={optionProps} {...childProps} childrenAsPrice={false}>
        {!isInternalCash || paymentAmount > 0.00 ? (
          <>
            {isInternalCash && !hasMixed ? (
              <Typography variant="caption" className={classes.noMixedNote}>
                {l('r._.cartstep.payment.nomixed')}
              </Typography>
            ) : null}
            
            <Typography
              component="span"
              display="block"
              variant="caption"
            >
              {l('r._.finance.topup.method.text.amount')}:&nbsp;
              
              <Price
                className={classes.price}
                value={paymentAmount}
                currency={payment.amount.currency.id}
              />
            </Typography>
            
            <PaymentFee
              className={classes.fee}
              amount={childProps.fixedFee}
              percent={childProps.percentFee}
              currency={childProps.currency}
              priceProps={{
                rootProps: { variant: 'caption' },
              }}
              rootProps={{
                component: 'span',
                display: 'block',
                variant: 'caption',
              }}
            />
          </>
        ) : (
          <Typography variant="caption" className={classes.noPayNote}>
            {l('r._.cartstep.payment.nopay')}
          </Typography>
        )}
      </TopupOption>
    );
  };

  return (
    <>
      {hasBrief ? (
        <>
          <PaymentBrief delivery={orderData.delivery} />
          <Divider className={classes.divider} />
        </>
      ) : null}

      <div className={classes.group}>
        {oneHasInternal ? (
          <PaymentInternal
            list={paymentData.is_internal_full_payment && paymentData.internal || currentMethod?.payment.mixed}
            onChange={(event) => setUseInternal(event.target.checked)}
            isChecked={isInternalCash}
          />
        ) : null}

        <PaymentSwitch
          name="payment"
          value={method != null ? method : ''}
          options={paymentData.external}
          title={l('r._.cartstep.payment.label.method')}
          onChange={(id) => setMethod(id)}
          renderOption={renderOption}
          disabled={isInternalCash && paymentData.is_internal_full_payment}
        />
      </div>

      <StepsControls
        isValid={
          (isInternalCash &&
            canPayByInternal &&
            paymentData.is_internal_full_payment) ||
          method !== undefined
        }
        onPrevStep={() => goPrev()}
        onNextStep={onNextStep}
        {...controlsProps}
      />
    </>
  );
};

RawPaymentStepMethod.defaultProps = {};

RawPaymentStepMethod.propTypes = {
  // self props
  deliveryData: PropTypes.object.isRequired,
  order: PropTypes.object,
  payment: PropTypes.object,
  hasBrief: PropTypes.bool,
  controlsProps: PropTypes.object,
  cache: PropTypes.object.isRequired,
  setCache: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  load: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
};

export const PaymentStepMethod = withLang(RawPaymentStepMethod);
