import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc'
import { ArticleContainer } from '_core/components/ArticleContainer'

// style
import { useProductDetailsStyle } from './productDetailsStyle'

export const RawProductDetails = props => {
  const {
    data,
    rootProps,
    className,
    header,
    actions,
    noPhoto,
    noContent,
    noDescription,
    renderHeader,
    renderActions,
    HeaderComponent,
    ActionsComponent,
    getLangString: l,
  } = props

  const classes = useProductDetailsStyle()
  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  const realHeader =
    typeof renderHeader === 'function' ? (
      renderHeader(data)
    ) : HeaderComponent != null ? (
      <HeaderComponent {...data} />
    ) : (
      header
    )

  return (
    <Card className={rootClasses} {...rootProps}>
      {!noPhoto &&
      data.photo != null &&
      data.photo.path != null &&
      data.photo.id !== 0 ? (
        <CardMedia
          className={classes.media}
          image={getVisitorPhotoSrc(data.photo.path, 'shop')}
          title={l(data.title)}
        />
      ) : null}

      <CardContent className={classes.body}>
        {realHeader}

        {!noDescription && data.description != null ? (
          <Typography variant="h6" className={classes.description}>
            {data.description}
          </Typography>
        ) : null}

        {!noContent && data.content != null ? (
          <ArticleContainer variant="body1" className={classes.content}>
            {data.content}
          </ArticleContainer>
        ) : null}
      </CardContent>

      <CardActions className={classes.actions}>
        {typeof renderActions === 'function' ? (
          renderActions(data)
        ) : ActionsComponent != null ? (
          <ActionsComponent {...data} />
        ) : (
          actions
        )}
      </CardActions>
    </Card>
  )
}

RawProductDetails.displayName = 'RawProductDetails'

RawProductDetails.defaultProps = {}

RawProductDetails.propTypes = {
  // self props
  data: PropTypes.object.isRequired,
  rootProps: PropTypes.object,
  className: PropTypes.string,
  header: PropTypes.node,
  actions: PropTypes.node,
  renderHeader: PropTypes.func,
  renderActions: PropTypes.func,
  HeaderComponent: PropTypes.elementType,
  ActionsComponent: PropTypes.elementType,
  noPhoto: PropTypes.bool,
  noContent: PropTypes.bool,
  noDescription: PropTypes.bool,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const ProductDetails = withLang(RawProductDetails)
