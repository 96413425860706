import { profileVerificationStyle } from '_core/views/ProfileVerification/profileVerificationStyle'

export const verificationSplashStyle = theme => {
  const style = profileVerificationStyle(theme)

  return {
    item: style.panelSummarySuccess,
    icon: style.panelIcon,
    iconBlank: style.panelIconBlank,
    iconPending: style.panelIconPending,
    iconSuccess: style.panelIconSuccess,
    iconFailure: style.panelIconFailure,
    itemText: style.panelTitle,
    itemTextBlank: style.panelTitleBlank,
    itemTextPending: style.panelTitlePending,
    itemTextSuccess: style.panelTitleSuccess,
    itemTextFailure: style.panelTitleFailure,

    text: {},

    items: {
      marginTop: theme.spacing(4),
    },
  }
}
