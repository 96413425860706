import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'

// @material-ui
import { Button, Typography } from '@material-ui/core'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { State } from '_core/components/State'
import { Title } from '_core/components/Title'
import { Crumbs } from '_core/components/Crumbs'
import { PriceMix } from '_core/components/PriceMix'
import { ProductDetails } from '_core/components/ProductDetails'
import { useSearch } from '_core/hooks/useSearch'

// style
import { useProductStyle } from './productStyle'

const RawProduct = props => {
  const { id, hasCrumbs, langInfo, getLangString: l } = props
  const { getSearchParam } = useSearch()
  const {
    request: { queue },
    config: {
      IS_SERVICE,
      API_METHOD,
      ROUTE_URL,
      ROUTE_URL_PARAM,
      DEFAULT_MESSAGE,
      API_ERROR: { DATA_WRONG, DATA_NOT_FOUND },
      REPLACE_CURRENCY
    },
  } = useGlobal()

  const classes = useProductStyle()
  const { product } = useParams()
  const [nodeKey, setNodeKey] = useState(0)
  const shop = getSearchParam('shop')
  const realId = id != null ? id : product
  const params = /^\d+$/.test(realId) ? { id: realId } : { path: realId }

  const getData = () =>
    queue(API_METHOD.GOODS_INFO, {
      currency: REPLACE_CURRENCY.SHOP_GOODS,
      ...params,
      shop,
    })

  useEffect(() => {
    setNodeKey(nk => nk + 1)
  }, [langInfo])

  const onPurchase = (location, data) => {
    const search = new URLSearchParams(location.search)

    if (IS_SERVICE) {
      search.set(ROUTE_URL_PARAM.SERVICE, realId)
      search.set(ROUTE_URL_PARAM.AGREEMENT, data.agreement)
    } else {
      search.set(ROUTE_URL_PARAM.PAYMENT, realId)
    }

    search.delete(ROUTE_URL_PARAM.PRODUCT)

    return {
      ...location,
      search: search.toString(),
    }
  }

  const renderCrumbs = ({ path, title } = {}) =>
    hasCrumbs ? (
      <Crumbs
        className={classes.crumbs}
        lastText={title}
        firstText={'r._.shop.goodsitem.toroot'}
        firstUrl={ROUTE_URL.CATALOG}
        crumbs={path}
      />
    ) : null

  const renderHeader = ({ goods }) =>
    goods.title != null ? (
      <Title textProps={{ variant: 'h5' }} className={classes.title}>
        {goods.title}
      </Title>
    ) : null

  const emptyMessage = (
    <Typography align="center" variant="h6" color="textSecondary">
      {l('r.nichego.ne.najdeno')}
    </Typography>
  )

  const failureMessage = (
    <Typography align="center" variant="h6" color="textSecondary">
      {l(DEFAULT_MESSAGE.ERROR)}
    </Typography>
  )

  return (
    <State
      key={nodeKey}
      getProps={{ data: getData }}
      render={({ data }) => (
        <>
          {renderCrumbs(data.goods)}

          <ProductDetails
            data={data.goods}
            header={renderHeader(data)}
            noDescription={true}
            actions={
              <>
                <Button
                  component={Link}
                  to={location => onPurchase(location, data)}
                  className={classes.purchaseButton}
                >
                  {l('r._.shop.buy')}
                </Button>

                <PriceMix
                  item={{
                    ...data.price,
                    currency: data.price.currency.id,
                    hasTooltip: true,
                    rootProps: { variant: 'h6', color: 'secondary' },
                  }}
                  className={classes.price}
                  rootProps={{ noWrap: true }}
                />
              </>
            }
          />
        </>
      )}
      renderFailure={({ error }) => (
        <>
          {renderCrumbs()}
          {[DATA_WRONG, DATA_NOT_FOUND].includes(error.code)
            ? emptyMessage
            : failureMessage}
        </>
      )}
    />
  )
}

RawProduct.defaultProps = {
  hasCrumbs: true,
}

RawProduct.propTypes = {
  // self props
  hasCrumbs: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Product = withCatch(withLang(RawProduct))
