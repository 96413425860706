import React from 'react'

// core
import { getDisplayName } from '_core/utils/getDisplayName'

export const withContext = Context => Component => {
  const wrappedComponent = getDisplayName(Component)

  const WithContext = props => {
    return (
      <Context.Consumer>
        {context => <Component {...context} {...props} />}
      </Context.Consumer>
    )
  }

  WithContext.displayName = `WithContext(${wrappedComponent})`

  return WithContext
}
