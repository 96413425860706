// @material-ui
import { makeStyles } from '@material-ui/styles'

export const paymentSwitchStyle = theme => ({
  option: {
    position: 'relative',
  },

  optionLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },

  radio: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 1),
  },

  helperText: {
    marginLeft: theme.spacing(5),
  },

  group: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 0, 2, 5),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  logo: {
    right: 0,
    top: theme.spacing(4),
    position: 'absolute',
    maxWidth: theme.spacing(8),
    maxHeight: theme.spacing(2),
    objectFit: 'contain',
    objectPosition: 'left center',
  },
})

export const usePaymentSwitchStyle = makeStyles(paymentSwitchStyle)
