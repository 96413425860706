import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { merge } from 'lodash'

// @material-ui
import { IconButton, InputAdornment, Icon } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { TextInputBase } from '_core/components/TextInputBase'

// style
import { useCountInputStyle } from './countInputStyle'

const RawCountInput = props => {
  const {
    className,
    groupProps,
    buttonProps,
    inputProps,
    min,
    max,
    step,
    value,
    upText,
    downText,
    onUp,
    onDown,
    onChange,
    disabled,
    getLangString: l,
    ...rest
  } = props

  const classes = useCountInputStyle()

  const inputClasses = clsx({
    [classes.root]: true,
    [className]: !!className,
  })

  const defaultOnUp = () => {
    const newValue = +value + step
    onChange(max == null ? newValue : newValue <= max ? newValue : max)
  }

  const defaultOnDown = () => {
    const newValue = +value - step
    onChange(min == null ? newValue : newValue >= min ? newValue : min)
  }

  const onInputChange = event => {
    let newValue = +event.target.value
    newValue = max == null ? newValue : newValue <= max ? newValue : max
    newValue = min == null ? newValue : newValue >= min ? newValue : min

    if (+value === +newValue) {
      return
    }

    onChange(newValue)
  }

  const mergedProps = {
    ...groupProps,
    inputProps: {
      min,
      max,
      step,
      inputProps: { style: { textAlign: 'center' } },
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            title={l(downText)}
            className={classes.button}
            disabled={disabled ? disabled : min != null && +value <= +min}
            onClick={onDown || defaultOnDown}
            {...buttonProps}
          >
            <Icon className={classes.icon}>remove</Icon>
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            title={l(upText)}
            className={classes.button}
            disabled={disabled ? disabled : max != null && +value >= +max}
            onClick={onUp || defaultOnUp}
            {...buttonProps}
          >
            <Icon className={classes.icon}>add</Icon>
          </IconButton>
        </InputAdornment>
      ),
      classes: {
        root: classes.textFieldRoot,
        input: classes.textFieldInput,
      },
    },
    classes: {
      root: classes.formControlRoot,
    },
    margin: 'none',
  }

  merge(mergedProps, { inputProps }, rest)

  return (
    <TextInputBase
      type="number"
      value={value}
      className={inputClasses}
      onChange={onInputChange}
      disabled={disabled}
      {...mergedProps}
    />
  )
}

RawCountInput.defaultProps = {
  step: 1,
  min: 1,
  max: 99999,
}

RawCountInput.propTypes = {
  // self props
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
  groupProps: PropTypes.object,
  inputProps: PropTypes.object,
  upText: PropTypes.string,
  downText: PropTypes.string,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const CountInput = withLang(RawCountInput)
