import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { useScrollTrigger } from '@material-ui/core'

export const ElevationScroll = props => {
  const { children, disableHysteresis, threshold, target, elevation } = props

  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target,
  })

  return React.cloneElement(children, {
    elevation: trigger ? elevation : 0,
  })
}

ElevationScroll.defaultProps = {}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  disableHysteresis: PropTypes.bool,
  threshold: PropTypes.number,
  elevation: PropTypes.number,
  target: PropTypes.instanceOf(Node),
}
