import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { MenuItem, MenuList } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { Menu } from '_core/components/Menu'

const style = theme => ({
  root: {},
  button: {},
  paper: {},
  list: {},
  item: {},
  itemActive: {},
  itemDisabled: {
    color: theme.palette.text.disabled,
  },
})

export const RawDropdown = props => {
  const {
    classes,
    items,
    activeKey,
    listProps,
    itemProps,
    activeItemProps,
    disabledItemProps,
    showActive,
    renderItem,
    onChange,
    ...rest
  } = props

  const getOnChange = (item, onClose) => event => {
    onClose()
    onChange.constructor === Function && onChange(event, item)
  }

  const renderItems = onClose =>
    items.map(item => {
      const isActive = `${item.key}` === `${activeKey}`

      if (isActive && !showActive) {
        return null
      }

      const disabledProps = item.disabled ? disabledItemProps : {}
      const activeProps = isActive && !item.disabled ? activeItemProps : {}
      const onClick =
        !isActive && !item.disabled ? getOnChange(item, onClose) : undefined

      const className = clsx(
        classes.item,
        item.disabled && classes.itemDisabled,
        isActive && !item.disabled && classes.itemActive
      )

      return renderItem != null ? (
        renderItem({
          item,
          onClick,
          isActive,
          itemProps,
          activeProps,
          disabledProps,
        })
      ) : (
        <MenuItem
          key={item.key}
          onClick={onClick}
          className={className}
          disabled={item.disabled}
          {...itemProps}
          {...activeProps}
          {...disabledProps}
        >
          {item.content}
        </MenuItem>
      )
    })

  const renderContent = ({ onPopoverClose }) => (
    <MenuList className={classes.list} {...listProps}>
      {renderItems(onPopoverClose)}
    </MenuList>
  )

  return (
    <Menu renderContent={items.length > 0 ? renderContent : null} {...rest} />
  )
}

RawDropdown.defaultProps = {
  popoverProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
}

RawDropdown.propTypes = {
  // self props
  showActive: PropTypes.bool,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listProps: PropTypes.object,
  itemProps: PropTypes.object,
  activeItemProps: PropTypes.object,
  disabledItemProps: PropTypes.object,
  renderItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.node,
    })
  ).isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,
}

export const Dropdown = withStyles(style)(RawDropdown)
