export const headerStyle = theme => ({
  appBar: {
    boxShadow: theme.shadows[1],
  },

  wrapper: {
    ...theme.mixins.headerHeight,

    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    '& .MuiSvgIcon-root, .MuiTypography-root': {
      color: theme.variables.colors.headerText,
    },
  },

  title: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
    },

    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
      fontSize: '1rem',
    },
  },

  mini: {
    width: 'calc(100% - ' + theme.spacing(7) + 'px + 1px)',
  },

  menuIcon: {
    marginRight: theme.spacing(2),
  },
})
