import React from 'react';
import PropTypes from 'prop-types';

// core
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { ProductForm } from '_core/components/ProductForm';
import { PriceFormFree } from '_core/components/PriceFormFree';
import { PriceFormCount } from '_core/components/PriceFormCount';
import { PriceFormOptions } from '_core/components/PriceFormOptions';
import { PriceFormChildren } from '_core/components/PriceFormChildren';
import { PriceInput } from '_core/components/PriceInput';
import { StepsControls } from '_core/components/StepsControls';


const PRICE_TYPE = {
  COUNT: 'count',
  FIXED: 'fixed'
};


export const RawPaymentStepProduct = (props) => {
  const {
    cache,
    setCache,
    goNext,
    goodsData,
    childrenGoods,
    optionGoods,
    visitorInfo,
    getLangString: l
  } = props;
  
  const { config: { PRODUCT_TYPE } } = useGlobal();
  
  const isPriceValue = goodsData.goods.type.id === PRODUCT_TYPE.INVEST || goodsData.goods.type.id === PRODUCT_TYPE.INSTALLMENT;
  
  const priceType = goodsData.goods.properties.price_type;
  const childGoods = cache.childGoods != null ? cache.childGoods : childrenGoods
  const options = cache.options != null ? cache.options : optionGoods;
  const tax = Number(goodsData.goods.properties.tax) || null;
  
  const minValue = isPriceValue
    ? goodsData.price_map?.minimum || 0.00
    : Math.max(goodsData.goods.bound?.min || 1, 1);
  const maxValue = isPriceValue
    ? goodsData.price_map?.maximum || 0.00
    : Math.max(goodsData.goods.bound?.max || 0, 0) || Infinity;
  const scaleValue = isPriceValue ? 0 : Math.max(goodsData.goods.bound?.scale || 1, 1);
  const defaultValue = isPriceValue
    ? cache.optionId && options?.find((item) => item.goods.id == cache.optionId)?.price.value
      || cache.value
      || goodsData.goods.type.id === PRODUCT_TYPE.INSTALLMENT && Math.max((visitorInfo?.info?.properties?.installment_amount || 0.00) - (visitorInfo?.info?.properties?.installment_paid || 0.00), 0)
      || goodsData.price.value
    : cache.value
      || Math.max(goodsData.goods.bound?.min || 1, 1);
  
  const cart = useCartContext();
  
  const [isValid, setIsValid] = React.useState(true);
  const [value, setValue] = React.useState(defaultValue);
  const [optionId, setOptionId] = React.useState(options && (cache.optionId || options[0].goods.id) || null);
  
  const onChange = (newValue) => {
    if (!isPriceValue && /[^0-9]/.test(newValue)) {
      setIsValid(false);
      
      return;
    }
    
    setIsValid(newValue > 0 && newValue >= minValue && (!maxValue || newValue <= maxValue) && (minValue + newValue) % scaleValue === 0);
    setValue(newValue);
  };
  
  const renderForm = () => {
    if (!isPriceValue) {
      const formProps = {
        count: value,
        minCount: minValue,
        maxCount: maxValue,
        step: scaleValue,
        price: goodsData.price,
        amount: goodsData.amount,
        isStart: goodsData.goods.type.id === PRODUCT_TYPE.START,
        isValid,
        onChange
      };
      
      return <ProductForm {...formProps} />;
    } else if (childGoods) {
      const optionsFormProps = {
        childGoods,
        currency: cart.currency.id,
      };
      
      return <PriceFormChildren {...optionsFormProps} />;
    } else if (options) {
      const optionsFormProps = {
        options,
        optionId,
        currency: cart.currency.id,
        value: options.find((item) => item.goods.id == optionId)?.price.value,
        onChange: setOptionId
      };
      
      return <PriceFormOptions {...optionsFormProps} />;
    } else if (priceType === PRICE_TYPE.COUNT) {
      const countFormProps = {
        tax,
        value,
        isValid,
        isFixed: priceType === PRICE_TYPE.FIXED,
        minPrice: minValue,
        maxPrice: maxValue,
        currency: cart.currency.id,
        onChange,
        valueStep: goodsData.price.value
      };
      
      return <PriceFormCount {...countFormProps} />;
    } else {
      const freeFormProps = {
        tax,
        value,
        isValid,
        isFixed: priceType === PRICE_TYPE.FIXED,
        minPrice: minValue,
        maxPrice: maxValue,
        currency: cart.currency.id,
        onChange
      };
      
      if (goodsData.goods.type.id === PRODUCT_TYPE.INSTALLMENT) {
        freeFormProps.label = 'r._.payment.installment.value';
        
        return (
          <>
            <PriceInput
              value={visitorInfo?.info?.properties?.installment_amount || 0.00}
              label={l('r._.payment.installment.amount')}
              readOnly={true}
              disabled={true}
              currency={cart.currency.id}
            />
            
            <PriceInput
              value={visitorInfo?.info?.properties?.installment_paid || 0.00}
              label={l('r._.payment.installment.paid')}
              readOnly={true}
              disabled={true}
              currency={cart.currency.id}
            />
            
            <PriceInput
              value={(visitorInfo?.info?.properties?.installment_amount || 0.00) - (visitorInfo?.info?.properties?.installment_paid || 0.00)}
              label={l('r._.payment.installment.rest')}
              readOnly={true}
              disabled={true}
              currency={cart.currency.id}
            />
            
            <PriceFormFree {...freeFormProps} />
          </>
        );
      } else {
        return <PriceFormFree {...freeFormProps} />;
      }
    }
  };
  
  const getLoad = () => {
    if (!isPriceValue) {
      return [
        { value },
        { goods: [{ count: value, id: goodsData.goods.id }] }
      ];
    } else if (childGoods) {
      return [
        { childGoods },
        { goods: childGoods.map(item => ({ id: item.goods.id })) }
      ];
    } else if (options) {
      return [
        { options },
        { goods: [ { id: optionId, amount: options.find((item) => item.goods.id == optionId)?.price.value }] }
      ];
    } else {
      return [
        { value },
        { goods: [{ id: goodsData.id, amount: tax != null ? value * (1 + tax) : value }] }
      ];
    }
  };
  
  const onNextStep = () => {
    const [newCache, newLoad] = getLoad();
    
    setCache(newCache);
    goNext(newLoad);
  };
  
  return (
    <>
      {renderForm()}
      
      <StepsControls
        isValid={isValid}
        hasBackButton={false}
        onNextStep={onNextStep}
      />
    </>
  );
};

RawPaymentStepProduct.defaultProps = {
};

RawPaymentStepProduct.propTypes = {
  // self props
  cache: PropTypes.object.isRequired,
  goodsData: PropTypes.object.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  setCache: PropTypes.func.isRequired,
  
  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,
  
  // withLang
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const PaymentStepProduct = withLang(withVisitorInfo(RawPaymentStepProduct));
