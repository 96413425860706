import React from 'react';

import { PlayCircleOutline } from '@material-ui/icons';

import Video from '_core/components/Video';

import Tag from './AbstractTag';


const PreviewVideo = (props) => {
  const { type, user, src, ratio, width, height } = props;
  
  const [preview, setPreview] = React.useState(true);
  
  
  const style = {};
  
  if (preview)
    style.cursor = 'pointer';
  
  if (width || height) {
    style.maxWidth = !width ? null : width.endsWith('%') ? width : width + 'px';
    style.maxHeight = !height ? null : height.endsWith('%') ? height : height + 'px';
  }
  
  return (
    <Video
      style={style}
      type={type}
      user={user}
      src={src}
      ratio={ratio}
      preview={preview}
      after={preview ? (<PlayCircleOutline style={{ fill: '#fff', opacity: 0.7, margin: 'auto', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, width: '70%', height: '70%', maxWidth: 150, maxHeight: 150 }} />) : null}
      onClick={(event) => {
        if (!preview)
          return;
        
        setPreview(false);
      }}
    />
  );
};


Tag.parser.registerTag('video', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <PreviewVideo {...this.params} src={this.getContent(true)} />
    );
  }
  
});
