import BigNumber from 'bignumber.js'

// core
import { CURRENCY_FORMAT_CHAR } from 'basic/config'

const PRICE_FORMAT = {
  decimalSeparator: '.',
  groupSeparator: '\u00a0',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '\u00a0',
  fractionGroupSize: 3,
}

export const getFormatScale = (value = 0, currency = { scale: 0 }, format) => {
  const { scale } = currency
  const { decimalSeparator, fractionGroupSize } = {
    ...PRICE_FORMAT,
    ...format,
  }

  const fract = `${value}`.split(decimalSeparator)[1]
  const valueScale = fract != null ? fract.replace(/0*$/, '').length : 0
  const minScale = scale > fractionGroupSize ? fractionGroupSize : scale

  return valueScale > scale
    ? scale
    : valueScale < minScale
    ? minScale
    : valueScale
}

export const getFormattedValue = (value = 0, currency = { scale: 0 }, format) =>
  BigNumber(value).toFormat(getFormatScale(value, currency), 1, {
    ...PRICE_FORMAT,
    ...format,
  })

export const getFormattedPrice = (value, currency, format) =>
  (currency?.format || CURRENCY_FORMAT_CHAR).replace(
    CURRENCY_FORMAT_CHAR,
    getFormattedValue(value, currency, format)
  )

export const getFormattedPriceMix = (items = [], divider = ' + ') =>
  items
    .map(({ value, currency, format }) =>
      getFormattedPrice(value, currency, format)
    )
    .join(divider)
