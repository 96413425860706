import { merge } from 'lodash'

// core
import { THEME_TYPE } from 'config'
import * as light from '../light'

export * from '../light'

export const themeName = 'Dark';
export const themeType = THEME_TYPE.DARK;
export const themeIcon = 'brightness_4';

export const variables = merge({}, light.variables, {
  colors: {
    sidebarItemsActive: '#3b67ea',
    primary: '#3b67ea',
    secondary: '#3fa0e4',
    sidebarBackgroundGradientSecond: 'rgba(36, 63, 142, 0.43)',
    primaryContrastText: '#fff',
    secondaryContrastText: '#fff',
    background: '#252525',
    paper: '#414141',
    textPrimary: '#fff',
    textSecondary: '#a5a5a5',
    textDisabled: 'rgba(255, 255, 255, 0.38)',
    textHint: 'rgba(255, 255, 255, 0.38)',
    icon: '#eeeeee',
    divider: '#8c8c8c',
    headerBackground: 'rgba(0, 0, 0, 0.75)',
    headerText: '#fff',
    sidebarItems: '#fff',
    sidebarBackground: 'rgba(16, 16, 16, 0.97)',
    sidebarHeaderBg: 'transparent',
    textSelectionFont: '#000',
    textSelectionBackground: '#fff',
    // shadows: ['(255, 255, 255, 0.1)', '(255, 255, 255, 0.07)', '(255, 255, 255, 0.12)'],
    // error: '#d54459',
    // success: '#81C975',
    shadows: ['(0, 0, 0, 0.1)', '(0, 0, 0, 0.07)', '(0, 0, 0, 0.12)'],
    error: '#F44336',
    success: '#81C975',
    warning: '#ffc107',
    info: '#17a2b8',
  },
})
