import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  IconButton,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/styles'

// @material-ui icons
import ExitToApp from '@material-ui/icons/ExitToApp'

// core
import { withLang } from '_core/hocs/withLang'
import { Loading } from '_core/components/Loading'

// style
// import { logoutButtonStyle } from './logoutButtonStyle'

// const useStyles = makeStyles(logoutButtonStyle)

const RawLogoutButtonView = props => {
  const {
    isLoading,
    onClick,
    tooltip,
    inMenu,
    isMini,
    getLangString: l,
  } = props

  // const classes = useStyles()

  const renderIcon = () => {
    return isLoading ? <Loading progressProps={{ size: 24 }} /> : <ExitToApp />
  }

  if (inMenu) {
    return (
      // <div className={classes.itemLink} onClick={onClick}>
      //   <div className={classes.itemIcon}>{renderIcon()}</div>
      //   {isMini ? null : <p className={classes.itemText}>{l('r.vyhod')}</p>}
      // </div>
      <Tooltip
        title={l(tooltip)}
        placement="right"
        disableFocusListener={isMini ? false : true}
        disableHoverListener={isMini ? false : true}
        disableTouchListener={isMini ? false : true}
        enterDelay={0}
      >
        <ListItem button key="exit" onClick={onClick}>
          <ListItemIcon>{renderIcon()}</ListItemIcon>
          <ListItemText primary={l('r.vyhod')} />
        </ListItem>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={l(tooltip)}>
      <IconButton onClick={onClick}>{renderIcon()}</IconButton>
    </Tooltip>
  )
}

RawLogoutButtonView.defaultProps = {
  tooltip: 'r.logout',
}

RawLogoutButtonView.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  isMini: PropTypes.bool,
  isLoading: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const LogoutButtonView = withLang(RawLogoutButtonView)
