import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { momentLocales } from '_core/utils/momentLocales'
import { langSelectors, langActions } from '_core/store/Lang'
import { LangSwitchView } from './LangSwitchView'

const mapState = state => ({
  langs: langSelectors.getLangList(state),
  langStrings: langSelectors.getLangStrings(state),
  langId: langSelectors.getCurrentLangId(state),
})

const mapDispatch = {
  changeLang: langActions.changeLang,
  updateLangStrings: langActions.updateLangStrings,
}

export const RawLangSwitch = props => {
  const {
    className,
    tooltip,
    inMenu,
    langs,
    langId,
    langStrings,
    changeLang,
    updateLangStrings,
  } = props

  const {
    request: { queue, onFailure },
    config: { API_METHOD, DEFAULT_MOMENT_LOCALE },
  } = useGlobal()

  const [isLoading, setIsLoading] = useState(false)

  const onChange = async newCode => {
    // Get new language id by it's ISO code
    const code = newCode.toLowerCase()
    const newId = +Object.keys(langs).find(id => langs[id].code === code)

    setIsLoading(true)

    try {
      // Change user language on server
      await Promise.all([
        queue(API_METHOD.LANG_CHANGE, { id: newId }),
        queueNewKeys(newId),
      ])

      // Change app language in store
      changeLang(newId)

      // Change `moment` locale
      momentLocales.includes(code) && (await import(`moment/locale/${code}`))
      moment.locale([code, DEFAULT_MOMENT_LOCALE])
    } catch (error) {
      onFailure(error)
    }

    setIsLoading(false)
  }

  const getNewKeys = newId => {
    if (langStrings[langId] == null) {
      return []
    }

    const currentLangKeys = Object.keys(langStrings[langId])

    if (langStrings[newId] == null) {
      return currentLangKeys
    }

    return currentLangKeys.reduce((result, key) => {
      langStrings[newId][key] == null && result.push(key)
      return result
    }, [])
  }

  const queueNewKeys = async newId => {
    // Collect keys for absent strings for new language
    const newKeys = getNewKeys(newId)

    if (newKeys.length > 0) {
      // Get absent strings for new language
      return queue(API_METHOD.LANG_STRINGS, { id: newId, list: newKeys })
        .then(updateLangStrings);
    }
  }

  return (
    <LangSwitchView
      className={className}
      inMenu={inMenu}
      tooltip={tooltip}
      langs={langs}
      isLoading={isLoading}
      langId={langId}
      onChange={onChange}
    />
  )
}

RawLangSwitch.defaultProps = {}

RawLangSwitch.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string,

  // `redux-connect` HOC props
  langs: PropTypes.object.isRequired,
  langStrings: PropTypes.object.isRequired,
  langId: PropTypes.number.isRequired,
  changeLang: PropTypes.func.isRequired,
  updateLangStrings: PropTypes.func.isRequired,
}

export const LangSwitch = connect(mapState, mapDispatch)(RawLangSwitch)
