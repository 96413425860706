import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import { DialogTitle, DialogContent } from '@material-ui/core';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { useSearchTabs } from '_core/hooks/useSearchTabs';
import { withCatch } from '_core/hocs/withCatch';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { Tabs } from '_core/components/Tabs';
import { ModalOuter } from '_core/components/ModalOuter';

// style
import { useProfileStyle } from './profileStyle';


const RawProfile = (props) => {
  const classes = useProfileStyle();
  
  const {
    getSlot,
    config,
    request
  } = useGlobal();
  
  const [state, setState] = React.useState({
    isOpen: false,
    tabs: getSlot('profileTabs').map((item) => ({ ...item, state: 'loading' }))
  });
  
  state.tabs.forEach((item) => {
    const getVisibility = item.ContentComponent.getVisibility;
    
    const tabState = typeof getVisibility === 'function'
      ? getVisibility({ ...props, config, request, state, setState, tab: item })
      : 'visible';
    
    if (item.state !== tabState) {
      setTimeout(() => setState((state) => {
        state = { ...state };
        
        item.state = tabState;
        
        return state;
      }), 1);
    }
    
    return item;
  });
  
  const visibleTabs = state.tabs.filter((tab) => tab.state === 'visible');
  
  const { tab, onTabChange } = useSearchTabs({
    tabs: visibleTabs,
    param: config.ROUTE_URL_PARAM.PROFILE,
    canBeBlank: true
  });
  
  React.useLayoutEffect(() => setState((state) => ({ ...state, isOpen: !!tab })), [tab]);
  
  const onClose = () => setState((state) => ({ ...state, isOpen: false }));
  
  return (
    <ModalOuter
      open={state.isOpen}
      onClose={onClose}
      onExited={() => onTabChange()}
    >
      <Tabs
        value={tab}
        items={visibleTabs}
        onChange={(event, tab) => onTabChange(tab)}
        contentProps={{ onClose }}
        renderTabs={({ tabs }) => (
          <DialogTitle className={classes.title}>{tabs}</DialogTitle>
        )}
        renderTabContents={({ contents }) => (
          <DialogContent>{contents}</DialogContent>
        )}
      />
    </ModalOuter>
  );
};

RawProfile.defaultProps = {};

RawProfile.propTypes = {
  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired
};

export const Profile = withCatch(withVisitorInfo(RawProfile));
