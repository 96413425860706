/* eslint-disable */
import React from 'react';


const GLOBAL_STORAGE = {};


export const get = async (storage, key, process, clearMillis = 0, reduceKey = undefined, reduceMillis = 0) => {
  storage = buildStorage(storage);
  key = buildKey(key);
  
  const entry = storage[key] || (storage[key] = { context: {}, reduce: null, processPromise: null, clearTimeout: null });
  
  let result;
  let error = null;
  
  if (reduceKey !== undefined) {
    if (!entry.reduce) {
      entry.reduce = [];
      entry.reduceEntry = null;
      entry.reduceData = {};
    }
    
    let reduceEntry = entry.reduceData[reduceKey];
    
    if (reduceEntry) {
      if (reduceEntry.clearTimeout) {
        clearTimeout(reduceEntry.clearTimeout);
        
        reduceEntry.clearTimeout = null;
      }
    } else {
      entry.reduce.push(reduceKey);
      
      reduceEntry = entry.reduceEntry;
      
      if (!reduceEntry) {
        entry.reduceEntry = reduceEntry = {
            keys: null,
            timeoutPromise: timeout(reduceMillis),
            processPromise: null,
            clearTimeout: null
          };
      }
      
      entry.reduceData[reduceKey] = reduceEntry;
    }
    
    if (reduceEntry.timeoutPromise) {
      await reduceEntry.timeoutPromise;
      
      reduceEntry.timeoutPromise = null;
    }
    
    if (!reduceEntry.processPromise) {
      reduceEntry.keys = entry.reduce;
      
      entry.reduce = [];
      entry.reduceEntry = null;
      
      reduceEntry.processPromise = process(entry.context, reduceEntry.keys);
    }
    
    let data;
    
    try {
      data = await reduceEntry.processPromise;
    } catch (e) {
      data = {};
      error = e;
    }
    
    result = {};
    reduceEntry.keys.forEach((key) => result[key] = data[key]);
    
    if (clearMillis > 0 && clearMillis < Infinity) {
      reduceEntry.clearTimeout = setTimeout(() => {
          reduceEntry.clearTimeout = null;
          
          Object.entries(entry.reduceData).forEach(([key, value]) => {
            if (value === reduceEntry)
              delete entry.reduceData[key];
          });
          
          if (!Object.keys(entry.reduceData).length)
            delete storage[key];
        }, clearMillis);
    }
  } else {
    if (entry.clearTimeout) {
      clearTimeout(entry.clearTimeout);
      
      entry.clearTimeout = null;
    }
    
    try {
      if (!entry.processPromise)
        entry.processPromise = process(entry.context);
      
      result = await entry.processPromise;
    } catch (e) {
      if (!entry.processPromise)
        entry.processPromise = Promise.reject(e);
      
      result = undefined;
      error = e;
    }
    
    if (clearMillis > 0 && clearMillis < Infinity) {
      entry.clearTimeout = setTimeout(() => {
          entry.clearTimeout = null;
          
          delete storage[key];
        }, clearMillis);
    }
  }
  
  if (error) {
    error.result = result;
    
    throw error;
  }
  
  return result;
};

export const use = (storage, key, process, complete = null, clearMillis = 0, reduceKey = undefined, reduceMillis = 0) => {
  const [result, setResult] = React.useState({ isLoading: true, error: null, data: null });
  
  React.useEffect(() => {
    if (!result.isLoading && complete)
      complete(result);
  }, [result]);
  
  if (!key)
    return null;
  
  get(storage, key, process, clearMillis, reduceKey, reduceMillis)
    .then((data) => {
      if (result.isLoading || result.data !== data)
        setResult({ isLoading: false, error: null, data });
    })
    .catch((error) => {
      console.error(error);
      
      if (result.isLoading || result.error !== error)
        setResult({ isLoading: false, error, data: null });
    });
  
  return result;
};

export const clear = (storage, key) => {
  storage = buildStorage(storage);
  key = buildKey(key);
  
  const entry = storage[key];
  
  if (entry) {
    if (entry.clearTimeout) {
      clearTimeout(entry.clearTimeout);
      
      entry.clearTimeout = null;
    }
    
    if (entry.reduceData) {
      Object.values(entry.reduceData).forEach((data) => {
        if (data.clearTimeout) {
          clearTimeout(data.clearTimeout);
          
          data.clearTimeout = null;
        }
      });
    }
    
    delete storage[key];
  }
};


const timeout = (millis) => new Promise((resolve, reject) => setTimeout(resolve, millis));

const buildStorage = (storage) => storage || GLOBAL_STORAGE;

const buildKey = (key) => Array.isArray(key) ? key.join('/') : String(key);
