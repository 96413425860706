const flexStyle = (theme) => ({
  flex: { display: 'flex' },

  rowDirection: { flexDirection: 'row' },
  rowReverseDirection: { flexDirection: 'row-reverse' },
  columnDirection: { flexDirection: 'column' },
  columnReverseDirection: { flexDirection: 'column-reverse' },

  startJustify: { justifyContent: 'flex-start' },
  endJustify: { justifyContent: 'flex-end' },
  centerJustify: { justifyContent: 'center' },
  betweenJustify: { justifyContent: 'space-between' },
  aroundJustify: { justifyContent: 'space-around' },

  startAlign: { alignItems: 'flex-start' },
  endAlign: { alignItems: 'flex-end' },
  centerAlign: { alignItems: 'center' },
  stretchAlign: { alignItems: 'stretch' },
  baselineAlign: { alignItems: 'baseline' },

  nowrap: { flexWrap: 'nowrap' },
  wrap: { flexWrap: 'wrap' },
  wrapReverse: { flexWrap: 'wrap-reverse' },

  startAlignContent: { alignContent: 'flex-start' },
  endAlignContent: { alignContent: 'flex-end' },
  centerAlignContent: { alignContent: 'center' },
  betweenAlignContent: { alignContent: 'space-between' },
  aroundAlignContent: { alignContent: 'space-around' },
  stretchAlignContent: { alignContent: 'stretch' },

  fullWidth: {
    width: '100%',
  },

  wrapToxs: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  wrapTosm: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  wrapTomd: {
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  wrapTolg: {
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
  
  gap1: {
    gap: theme.spacing(1)
  },
  
  gap2: {
    gap: theme.spacing(2)
  },
  
  gap3: {
    gap: theme.spacing(3)
  },
  
  gap4: {
    gap: theme.spacing(4)
  },
  
  gap5: {
    gap: theme.spacing(5)
  },
  
  gap6: {
    gap: theme.spacing(6)
  }
});

export default flexStyle;
