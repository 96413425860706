import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { Loading } from '_core/components/Loading'

// style
import { inputProgressStyle } from './inputProgressStyle'

export const RawInputProgress = props => {
  const { classes, className, progressType, progressProps, ...rest } = props

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  return (
    <div className={rootClasses}>
      <Loading
        className={classes.wrapper}
        progressType={progressType}
        progressProps={{
          className: classes.progress,
          ...progressProps,
        }}
        {...rest}
      />
    </div>
  )
}

RawInputProgress.defaultProps = {
  progressType: 'linear',
}

RawInputProgress.propTypes = {
  // self props
  progressProps: PropTypes.object,
  progressType: PropTypes.oneOf(['circular', 'linear']),

  // `withStyles` HOC props
  classes: PropTypes.object,
}

export const InputProgress = withStyles(inputProgressStyle)(RawInputProgress)
