export const overrides = theme => ({
  filledSuccess: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.variables.colors.success,
  },

  filledInfo: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.variables.colors.info,
  },

  filledWarning: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.variables.colors.warning,
  },

  filledError: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.variables.colors.error,
  },
})

export const props = {
  severity: 'info',
  variant: 'filled',
}
