import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Button, Tooltip, Paper, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {},
  button: {},
  paper: {},
  list: {},
  item: {},
}))

export const Menu = props => {
  const {
    className,
    tooltip,
    content,
    renderButton,
    renderContent,
    ButtonComponent,
    buttonBody,
    buttonProps,
    popoverProps,
    paperProps,
    rootProps,
    disabled,
    onOpen,
    onClose,
  } = props

  const classes = useStyles()
  const [anchor, setAnchor] = useState(null)

  const onButtonClick = disabled
    ? undefined
    : event => {
        if (anchor == null) {
          setAnchor(event.currentTarget)
          typeof onOpen === 'function' && onOpen()
        } else {
          onPopoverClose()
        }
      }

  const onPopoverClose = () => {
    setAnchor(null)
    typeof onClose === 'function' && onClose()
  }

  const getButton = () => {
    const button =
      renderButton != null ? (
        renderButton({
          body: buttonBody,
          props: buttonProps,
          onClick: onButtonClick,
          active: !!anchor,
          disabled,
        })
      ) : (
        <ButtonComponent
          onClick={onButtonClick}
          active={!!anchor}
          className={classes.button}
          disabled={disabled}
          {...buttonProps}
        >
          {buttonBody}
        </ButtonComponent>
      )

    return tooltip != null ? (
      <Tooltip title={tooltip}>{button}</Tooltip>
    ) : (
      button
    )
  }

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: !!className,
  })

  const hasPopover = !disabled && (content != null || renderContent != null)

  return (
    <div className={rootClasses} {...rootProps}>
      {getButton()}

      {hasPopover ? (
        <Popover
          open={!!anchor}
          anchorEl={anchor}
          onClose={onPopoverClose}
          {...popoverProps}
        >
          <Paper className={classes.paper} {...paperProps}>
            {renderContent != null
              ? renderContent({ onPopoverClose })
              : content}
          </Paper>
        </Popover>
      ) : null}
    </div>
  )
}

Menu.defaultProps = {
  ButtonComponent: Button,
}

Menu.propTypes = {
  // self props
  className: PropTypes.string,
  tooltip: PropTypes.string,
  content: PropTypes.node,
  popoverProps: PropTypes.object,
  buttonProps: PropTypes.object,
  paperProps: PropTypes.object,
  rootProps: PropTypes.object,
  ButtonComponent: PropTypes.elementType,
  buttonBody: PropTypes.node,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  renderButton: PropTypes.func,
  renderContent: PropTypes.func,
}
