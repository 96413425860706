import React from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { usePayment } from '_core/hooks/usePayment'
import { withLang } from '_core/hocs/withLang'
import { Stairs } from '_core/components/Stairs'
import { PaymentStepMethod } from '_core/views/PaymentStepMethod'
import { PaymentStepConfirm } from '_core/views/PaymentStepConfirm'
import { ServiceAgreementStep } from '../ServiceAgreementStep'

const RawOrderSteps = props => {
  const { documentId, setResult, getLangString: l } = props
  const { processPayment } = usePayment()

  const {
    request: { queue },
    config: { ROUTE_URL, API_METHOD, IS_SERVICE, REPLACE_CURRENCY },
  } = useGlobal()

  const stairs = [
    {
      title: l('r._.cartstep.payment.title'),
      Component: PaymentStepMethod,
      controlsProps: { hasBackButton: false },
      useCache: true,
      getProps: {
        payment: () =>
          queue(
            API_METHOD.MONEY_TOPUP_METHOD,
            { currency: REPLACE_CURRENCY.SHOP_PAYMENT, document: documentId },
            RawOrderSteps.displayName
          ),
      },
    },
    {
      title: l('r._.cartstep.confirm.title'),
      Component: PaymentStepConfirm,
    },
    {
      render: () => null,
      getProps: {
        result: async ({ load, goPrev }) => {
          const { payment, method, isInternalCash } = load

          const url =
            document.location.origin +
            process.env.PUBLIC_URL +
            ROUTE_URL.PURCHASELOG +
            '/' +
            documentId

          try {
            const request = await queue(
              API_METHOD.PAYMENT_REQUEST_CREATE,
              {
                currency: REPLACE_CURRENCY.SHOP_PAYMENT,
                id: method,
                document: documentId,
                internal: isInternalCash,
                value: payment.amount.value,
                visitor_error_url: url,
                visitor_success_url: url,
              },
              RawOrderSteps.displayName
            )

            processPayment({
              request,
              payment,
              method,
              completePath: '/dashboard',
              onScript: () => goPrev(),
              callback: (result) => setResult({
                success: {
                  isInternalCash,
                  ...result,
                },
              })
            })
          } catch (error) {
            setResult({ failure: error })
          }
        },
      },
    },
  ]

  if (IS_SERVICE) {
    stairs.unshift({
      title: l('r._.dagavor'),
      Component: ServiceAgreementStep,
      controlsProps: { hasBackButton: false },
      useCache: true,
    })
  }

  return <Stairs documentId={documentId} stairs={stairs} />
}

RawOrderSteps.displayName = 'RawOrderSteps'

RawOrderSteps.defaultProps = {}

RawOrderSteps.propTypes = {
  // self props
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setResult: PropTypes.func.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const OrderSteps = withLang(RawOrderSteps)
