import {
  FETCH_TIMEOUT,
  FETCH_EFFORTS,
  FETCH_RETRY_DELAY,
} from 'basic/config';
import { serializeFormData } from '_core/utils/serializeFormData';
import { spread } from '_core/utils/spread';
import * as http from '_core/utils/http';

const getPreset = {
  method: 'GET',
  contentType: 'application/json',
  timeout: FETCH_TIMEOUT,
  retryDelay: FETCH_RETRY_DELAY,
  efforts: FETCH_EFFORTS,

  onStart: () => {},

  onSuccess: result => result,

  onError: error => {
    throw error
  },
}

const postPreset = {
  ...getPreset,
  method: 'POST',
  contentType: 'application/x-www-form-urlencoded',
}

const filePreset = {
  ...postPreset,
  timeout: undefined,
  contentType: 'multipart/form-data',
}

const presets = {
  GET: getPreset,
  POST: postPreset,
  FILE: filePreset,
}

export const makeFetch = ({
  url,
  data,
  preset,
  params,
  headers,
  external = false,
}) => {
  if (params == null) {
    params = presets[preset != null ? preset : 'GET']
  } else {
    params = preset != null ? spread(presets[preset], params) : params
  }

  let options = {
    method: params.method,
    headers: headers || {}
  }

  if (params.method === 'GET')
    url = http.param(url, data);

  if (params.method === 'POST') {
    if (params.contentType === 'multipart/form-data') {
      options.body = serializeFormData(data)
    } else {
      if (data != null) {
        options.body = external
          ? JSON.stringify(data)
          : `data=${encodeURIComponent(JSON.stringify(data))}`
      }

      options.headers['Content-Type'] = params.contentType;
    }
  }

  const onResponse = async response => {
    if (!response.ok) {
      // console.log('===== fetch: NOT ok', response, url, data);
      return retryFunc(response)
    }

    // console.log('===== fetch: OK', response, url, data);
    return response.json()
  }

  const retryFunc = getRetryFunc({ url, data, preset, params })

  params.onStart()

  // console.log('===== fetch: start', url, data);

  return fetch(url, options)
    .then(onResponse, retryFunc)
    .then(params.onSuccess, params.onError)
}

const getRetryFunc = ({ url, data, preset, params }) => error => {
  if (params.efforts > 0) {
    // console.log('===== fetch: RETRY', params.efforts, error, url, data);
    return new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id)
        resolve()
      }, params.retryDelay)
    }).then(() =>
      makeFetch({
        url,
        data,
        preset,
        params: spread(params, {
          efforts: params.efforts - 1,
        }),
      })
    )
  } else {
    // console.log('===== fetch: ERROR', error, url, data)
    return params.onError(error)
  }
}
