import { THEME_TYPE } from 'config'

// components
import * as MuiAlert from './components/MuiAlert'
import * as MuiAlertTitle from './components/MuiAlertTitle'
import * as MuiAppBar from './components/MuiAppBar'
import * as MuiAvatar from './components/MuiAvatar'
import * as MuiButton from './components/MuiButton'
import * as MuiButtonGroup from './components/MuiButtonGroup'
import * as MuiCard from './components/MuiCard'
import * as MuiCardActions from './components/MuiCardActions'
import * as MuiCardContent from './components/MuiCardContent'
import * as MuiCardHeader from './components/MuiCardHeader'
import * as MuiCardMedia from './components/MuiCardMedia'
import * as MuiCheckbox from './components/MuiCheckbox'
import * as MuiDialog from './components/MuiDialog'
import * as MuiDialogActions from './components/MuiDialogActions'
import * as MuiDialogContent from './components/MuiDialogContent'
import * as MuiDialogTitle from './components/MuiDialogTitle'
import * as MuiDrawer from './components/MuiDrawer'
import * as MuiFab from './components/MuiFab'
import * as MuiGrid from './components/MuiGrid'
import * as MuiInput from './components/MuiInput'
import * as MuiInputBase from './components/MuiInputBase'
import * as MuiOutlinedInput from './components/MuiOutlinedInput'
import * as MuiTab from './components/MuiTab'
import * as MuiTablePagination from './components/MuiTablePagination'
import * as MuiTableCell from './components/MuiTableCell'
import * as MuiTabs from './components/MuiTabs'
import * as MuiTextField from './components/MuiTextField'
import * as MuiToolbar from './components/MuiToolbar'
import * as MuiTooltip from './components/MuiTooltip'
import * as MuiTypography from './components/MuiTypography'
import * as MuiFormControlLabel from './components/MuiFormControlLabel'
import * as MuiFormHelperText from './components/MuiFormHelperText'
import * as MuiInputLabel from './components/MuiInputLabel'
import * as MuiList from './components/MuiList'
import * as MuiListItemText from './components/MuiListItemText'
import * as MuiExpansionPanel from './components/MuiExpansionPanel'
import * as MuiInputAdornment from './components/MuiInputAdornment'

import * as RtsBalancePopup from './components/RtsBalancePopup'
import * as RtsBalanceDetails from './components/RtsBalanceDetails'
import * as RtsRegisterForm from './components/RtsRegisterForm'
import * as RtsProfileInfoForm from './components/RtsProfileInfoForm'
import * as RtsSidebarUser from './components/RtsSidebarUser'
import * as RtsSidebar from './components/RtsSidebar'
import * as RtsAppMenu from './components/RtsAppMenu'
import * as RtsPurchaseLogListItem from './components/RtsPurchaseLogListItem'

// mixins
import { header } from './mixins/header'
import { sidebar } from './mixins/sidebar'
import { sidebarRest } from './mixins/sidebarRest'
import { sidebarContent } from './mixins/sidebarContent'
import { sidebarItemActive } from './mixins/sidebarItemActive'
import { sidebarItem } from './mixins/sidebarItem'
import { appLogo } from './mixins/appLogo'
import { sidebarDivider } from './mixins/sidebarDivider'
import { sidebarUser } from './mixins/sidebarUser'
import { headerHeight } from './mixins/headerHeight'
import { guestBackground } from './mixins/guestBackground'

// assets
import logo from 'basic/assets/img/logo.svg'
import bgImage from 'basic/assets/img/bg.jpg'
import bgVideo from 'basic/assets/video/bg.mp4'
// import errorBgImage from 'basic/assets/img/clint-mckoy.jpg'

export const themeName = 'Light';
export const themeType = THEME_TYPE.LIGHT;
export const themeIcon = 'brightness_7';

export const mixins = {
  header,
  sidebar,
  sidebarRest,
  sidebarContent,
  appLogo,
  sidebarDivider,
  sidebarItem,
  sidebarItemActive,
  sidebarUser,
  headerHeight,
  guestBackground,
}

const components = {
  MuiAlert,
  MuiAlertTitle,
  MuiAppBar,
  MuiAvatar,
  MuiButton,
  MuiButtonGroup,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCardMedia,
  MuiCheckbox,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiDrawer,
  MuiFab,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiGrid,
  MuiInput,
  MuiInputLabel,
  MuiInputBase,
  MuiOutlinedInput,
  MuiList,
  MuiListItemText,
  MuiTab,
  MuiTabs,
  MuiTablePagination,
  MuiTableCell,
  MuiTextField,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
  MuiExpansionPanel,
  MuiInputAdornment,

  RtsBalancePopup,
  RtsBalanceDetails,
  RtsRegisterForm,
  RtsProfileInfoForm,
  RtsSidebarUser,
  RtsSidebar,
  RtsAppMenu,
  RtsPurchaseLogListItem,
}

export const props = {}
export const overrides = {}

Object.keys(components).forEach(key => {
  props[key] = components[key].props
  overrides[key] = components[key].overrides
})

// const logo = 'assets/images/logo.svg'
// const bgImage = 'assets/images/bg.jpg'
// const bgVideo = 'assets/video/bg.mp4'
// const errorBgImage = 'assets/images/clint-mckoy.jpg'

export const pages = {
  iframes: {},
  customCode: null,
  goalCallback: null,

  login: {
    logo,
    bgColor: null,
    bgImage: null,
    hasShade: false,
    bgVideo,
  },
  register: {
    logo,
    bgColor: null,
    bgImage: null,
    hasShade: false,
    bgVideo,
  },
  remind: {
    logo,
    bgColor: null,
    bgImage: null,
    hasShade: false,
    bgVideo,
  },
  unlock: {
    logo,
    bgColor: null,
    bgImage: null,
    hasShade: false,
    bgVideo,
  },
  sidebar: {
    logo,
    backgroundImage: bgImage,
    backgroundRestImage: null,
    backgroundContentImage: null,
  },
  header: {
    logo,
    backgroundImage: null,
  },
}

export const variables = {
  spacing: 8,
  fontSize: 16,
  sidebarWidth: 240,
  borderRadius: 7.5,
  fontFamily: 'Verdana, Helvetica, Arial, sans-serif',
  pointsScale: 2,
  topBannerMHC: '10px',
  topBannerPositionX: 'center',
  topBannerPositionY: 'center',
  topBannerPositionSmallX: 'center',
  topBannerPositionSmallY: 'center',
  topBannerBgImgSize: 'cover',
  topBannerTitleBgColor: 'unset',
  topBannerBgImgSmallSize: 'cover',
  topBannerAspectRatioPersent: 'unset',

  headerHeight: {
    main: 64,
    md: 56,
    xs: 48,
  },

  colors: {
    primary: '#fc5124',
    secondary: '#0060d1',
    primaryContrastText: '#fff',
    secondaryContrastText: '#fff',
    background: '#f1f5f8',
    paper: '#fff',
    level1: '#fff',
    level2: '#38393E',
    textPrimary: '#38393E',
    textSecondary: '#a5a5a5',
    textDisabled: 'rgba(0, 0, 0, 0.38)',
    textHint: 'rgba(0, 0, 0, 0.38)',
    icon: '#a4afc1',
    divider: '#e6edf3',
    headerBackground: 'rgba(230, 237, 243, 0.87)',
    headerText: '#38393e',
    sidebarItems: '#fff',
    sidebarItemsActive: '#fc5124',
    sidebarGroupBgActive: 'rgba(0, 0, 0, 0.1)',
    sidebarBackground: '#313338',
    sidebarBackgroundGradientSecond: 'transparent',
    sidebarHeaderBg: 'transparent',
    textSelectionFont: '#fff',
    textSelectionBackground: '#accef7',
    shadows: ['(0, 0, 0, 0.1)', '(0, 0, 0, 0.07)', '(0, 0, 0, 0.12)'],
    error: '#f44336',
    success: '#81c975',
    warning: '#ffc107',
    info: '#17a2b8',
	  bannerColor: '#fff',
	  bannerTitleColor: '#fff',
	  bannerBgColor: '#313338',
	  bannerGrdColor: 'transparent',
	  bannerBgRoundColor: 'transparent',
	  bannerRoundColor: '#fff',
	  contentLinksColor: '#365397',
  },
}

export const typography = {
  htmlFontSize: 16,
  fontSize: variables.fontSize,
  fontFamily: variables.fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  bannerPositionX: 'center',
  bannerPositionY: 'center',

  h1: {
    fontFamily: variables.fontFamily,
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: 1,
    letterSpacing: '-0.01562em',
  },

  h2: {
    fontFamily: variables.fontFamily,
    fontWeight: 300,
    fontSize: '3.75rem',
    lineHeight: 1,
    letterSpacing: '-0.00833em',
  },

  h3: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: 1.04,
    letterSpacing: '0em',
  },

  h4: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },

  h5: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.33,
    letterSpacing: '0em',
  },

  h6: {
    fontFamily: variables.fontFamily,
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },

  subtitle1: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },

  subtitle2: {
    fontFamily: variables.fontFamily,
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },

  body1: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },

  body2: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },

  button: {
    fontFamily: variables.fontFamily,
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },

  caption: {
    color: variables.colors.textSecondary,
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '0.8125rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },

  overline: {
    fontFamily: variables.fontFamily,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },

}
