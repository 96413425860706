import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { noop as _noop } from 'lodash'

// @material-ui
import { Box, Typography, Slider } from '@material-ui/core'

// style
import { useStepsStyle } from './stepsStyle'

export const RawSteps = props => {
  const {
    steps,
    activeStep,
    minSliderValue,
    maxSliderValue,
    onStepClick,
  } = props

  const classes = useStepsStyle()
  const stepKeys = Object.keys(steps)

  const realActiveStep = stepKeys.includes(`${activeStep}`)
    ? `${activeStep}`
    : stepKeys[0]

  const activeIndex = stepKeys.indexOf(realActiveStep)

  const getSliderValue = () => {
    const stepSize = (maxSliderValue - minSliderValue) / (stepKeys.length - 1)
    return minSliderValue + activeIndex * stepSize
  }

  const renderLabels = () =>
    stepKeys.map((key, index) => {
      const onClick = index < activeIndex ? () => onStepClick(key) : undefined

      const labelClasses = clsx({
        [classes.sliderLabel]: true,
        [classes.labelActive]: key === realActiveStep,
        [classes.labelDisabled]: index > activeIndex,
      })

      return (
        <Typography
          key={key}
          variant="body2"
          onClick={onClick}
          className={labelClasses}
        >
          {steps[key]}
        </Typography>
      )
    })

  return (
    <div className={classes.fullWidth}>
      <Box display="flex" justifyContent="space-between">
        {renderLabels()}
      </Box>

      <div className={classes.sliderContainer}>
        <Slider
          classes={{
            root: classes.slider,
            thumb: classes.thumb,
            track: classes.trackBefore,
            rail: classes.trackAfter,
          }}
          // thumb={<div className={classes.sliderThumb} />}
          value={getSliderValue()}
        />
      </div>
    </div>
  )
}

RawSteps.defaultProps = {
  minSliderValue: 10,
  maxSliderValue: 90,
  onStepClick: _noop,
}

RawSteps.propTypes = {
  // self props
  onStepClick: PropTypes.func,
  activeStep: PropTypes.string,
  minSliderValue: PropTypes.number,
  maxSliderValue: PropTypes.number,
  steps: PropTypes.object.isRequired,
}

export const Steps = RawSteps
