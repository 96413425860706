import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
// @material-ui
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// style
import { articleContainerStyle } from './articleContainerStyle'
const RawArticleContainer = props => {
  const { classes, children, className, copy, ...rest } = props
  const articleClasses = clsx({
    [classes.root]: true,
    [classes.table]: true,
    [classes.copyDisable]: !copy,
    [className]: className != null,
  })

  let content = children
  let videoId = []
  const getId = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|^\/)([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }
  const checkIfEmbed = html => html.match(/(<oembed[\s\S]*?(<\/oembed|\/)>)/gim)
  if (checkIfEmbed(children)) {
    const matches = [...children.matchAll(/url="([^"]*)/g)]
    matches.forEach(match => {
      videoId.push(getId(match[1]))
    })
  }

  const parse = html => {
    let newHTML = html
    if (!isEmpty(videoId)) {
      let i = -1
      newHTML = html.replace(/(<oembed[\s\S]*?(<\/oembed|\/)>)/gi, () => {
        i++
        return `<span class="${classes.video}"><iframe width="560" height="315" src="//www.youtube.com/embed/${videoId[i]}" frameborder="0" allowfullscreen></iframe></span>`
      })
    }

    newHTML.replace(
      /(<table[\s\S]*?<\/table>)/gim,
      `<span class="${classes.table}">$1</span>`
    )
    return newHTML
  }

  return (
    <Typography
      className={[articleClasses, 'ck-content']}
      component="div"
      dangerouslySetInnerHTML={{ __html: parse(content) }}
      {...rest}
    />
  )
}

RawArticleContainer.defaultProps = {
  copy: false,
}

RawArticleContainer.propTypes = {
  // self props
  copy: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,
}

export const ArticleContainer = withStyles(articleContainerStyle)(
  RawArticleContainer
)
