import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import * as Yup from 'yup'
import {
  noop as _noop,
  isEmpty as _isEmpty,
  debounce as _debounce,
  cloneDeep as _cloneDeep,
} from 'lodash'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { makeFetch } from '_core/utils/makeFetch'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { withContext } from '_core/hocs/withContext'
import { GlobalContext } from '_core/hooks/useGlobal'
import { alterObjectKeys } from '_core/utils/alterObjectKeys'
import { getFormStructUrl } from '_core/utils/getFormStructUrl'
import { formFieldTypes, yupString } from '_core/utils/formFieldTypes'
import { FormInPlain } from '_core/components/FormInPlain'
import { FieldValidationWrapper } from '_core/components/FieldValidationWrapper'

const VALIDATION_DEBOUNCE_DELAY = 250

export class RawFormWithValidation extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isReady: false,
      isPreReady: false,
      submitError: null,
      submitSuccess: false,
    }

    this._decor = alterObjectKeys(props.decor, key => key.replace('.', '='))
    this._initialValues = alterObjectKeys(props.initialValues, key =>
      key.replace('.', '=')
    )

    this._isMounted = false
    this._struct = null
    this._yupSchema = null
    this._styledFields = null
    this._fieldQueries = null
    this._hiddenFields = _cloneDeep(props.hiddenFields)

    this._initialStatus = {
      dbErrors: {},
    }
  }

  // Prepare for work
  async componentDidMount() {
    this._isMounted = true

    await this._getStruct()

    if (!this._isMounted) {
      return
    }

    this._getInitialValues()
    this._getStyledFields()

    await this._getFieldQueries()

    if (!this._isMounted) {
      return
    }

    this.setState({ isPreReady: true })
  }

  // Component workflow
  componentDidUpdate(prevProps, prevState) {
    const { onReady } = this.props
    const { isPreReady, isReady } = this.state

    if (isReady === false && isPreReady === true) {
      this._getYupSchema()
      this.setState({ isReady: true })
    }

    if (prevState.isReady === false && isReady === true) {
      onReady()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.debouncedValidateWithApi.cancel()
  }

  // Get form structure from static file on server
  _getStruct = async () => {
    const { method, structUrl, onlyFields, exceptFields } = this.props;
    
    const url = structUrl != null ? structUrl : getFormStructUrl(method);
    
    let result;

    try {
      result = await makeFetch({ url })
    } catch (error) {
      console.error(url, '-', error);
    }

    if (!this._isMounted) {
      return
    }

    if (onlyFields != null) {
      this._struct = result.data.filter(field =>
        onlyFields.includes(field.name)
      )
    } else if (exceptFields != null) {
      this._struct = result.data.filter(
        field => !exceptFields.includes(field.name)
      )
    } else {
      this._struct = result.data
    }

    this._struct = this._struct.map(field => {
      field.name = field.name.replace('.', '=')
      return field
    })
  }

  // Request data for fields with external data source
  _getFieldQueries = async () => {
    const {
      request: { queue },
    } = this.props

    const fieldNames = []
    const requests = []

    this._struct.forEach(field => {
      if (field['query'] == null) {
        return
      }

      fieldNames.push(field['name'])
      requests.push(queue(field['query'], field['params']))
    })

    // If no fileds have query for external data
    if (fieldNames.length === 0) {
      this._fieldQueries = {}
      return
    }

    const results = await Promise.all(requests)

    if (!this._isMounted) {
      return
    }

    this._fieldQueries = results.reduce((accum, result, index) => {
      accum[fieldNames[index]] = result.list
      return accum
    }, {})
  }

  // Get styled fields' components once
  _getStyledFields = () => {
    this._styledFields = this._struct.reduce((result, field) => {
      const component = formFieldTypes[field.type].component
      const style =
        this._decor[field.name] != null && this._decor[field.name].style != null
          ? this._decor[field.name].style
          : {}

      result[field.name] = withStyles(style)(component)
      return result
    }, {})
  }

  // Collect fields' itinital values
  _getInitialValues = () => {
    this._initialValues = this._struct.reduce((result, field) => {
      const hasDecorValue =
        this._decor[field.name] != null &&
        this._decor[field.name].initialValue != null

      const hasInitialValue =
        this._initialValues != null && this._initialValues[field.name] != null

      result[field.name] = hasInitialValue
        ? this._initialValues[field.name]
        : hasDecorValue
        ? this._decor[field.name].initialValue
        : ''

      return result
    }, {})
  }

  // Collect server validation errors
  _getDbErrors = errors => {
    return Object.keys(errors).reduce((result, field) => {
      result[field] = errors[field].code
      return result
    }, {})
  }

  // Build validation schema
  _getYupSchema = () => {
    const shape = this._struct.reduce((result, item) => {
      const fieldType = formFieldTypes[item.type]
      const yupType = fieldType != null ? fieldType.yup : yupString

      let field = yupType(item.title)

      if (item.type === 'password2') {
        field = field.oneOf([Yup.ref(item.name.replace(/2$/, ''))], 'bad')
      }

      if (item['required'] === false) {
        field = field.notRequired()
      } else if (item['required'] === true) {
        field = field.required('empty')
      }

      if (item['required'] === true && item['min_length'] != null) {
        field = field.min(item['min_length'], 'short')
      }

      if (item['max_length'] != null) {
        field = field.max(item['max_length'], 'long')
      }

      if (item['black'] != null) {
        field = field.notOneOf(item['black'], 'bad')
      }

      if (item['white'] != null) {
        field = field.oneOf(item['white'], 'bad')
      }

      if (item['pattern'] != null) {
        field = field.test('pattern', 'bad', value =>
          new RegExp(item['pattern']).test(value)
        )
      }

      if (item['query']) {
        const list = this._fieldQueries[item['name']].map(
          queryItem => `${queryItem.id}`
        )

        // console.log(item['name'], list)
        field = field.oneOf(list, 'bad')
      }

      result[item['name']] = field
      return result
    }, {})

    this._yupSchema = Yup.object().shape(shape)

    // console.log(this._yupSchema)
    // try {
    //   console.log(
    //     this._yupSchema.validateSync({ accounts: '3-1-1-1', value: '10' })
    //   )
    // } catch (error) {
    //   console.log(error)
    // }
  }

  // Child method for getting fields' components on each render
  renderFields = () => {
    return this._struct.map((item, index) => {
      const hasProps =
        this._decor[item.name] != null && this._decor[item.name].props != null

      const fieldProps = hasProps ? this._decor[item.name].props : {}
      const fieldQuery = this._fieldQueries[item.name]

      if (fieldQuery != null) {
        fieldProps.options = fieldQuery

        if (fieldQuery.length === 1) {
          // console.log(fieldQuery, fieldQuery[0].id)
          fieldProps.value = fieldQuery[0].id
        }
      }

      return (
        <Field
          key={item.name}
          name={item.name}
          render={({ form, field }) => (
            <FieldValidationWrapper
              form={form}
              field={field}
              component={this._styledFields[item.name]}
              validateWithApi={this.debouncedValidateWithApi}
              needsApiValidation={item.server}
              type={formFieldTypes[item.type].type}
              label={item.title}
              {...fieldProps}
            />
          )}
        />
      )
    })
  }

  _areRequiredFiledsTouched = ({ touched }) => {
    const requiredFields = this._struct.reduce((result, field) => {
      const isDisabled =
        this._decor[field.name] != null &&
        this._decor[field.name].props != null &&
        this._decor[field.name].props.disabled

      // Skip select fields witch have value
      if (
        field.type !== 'select' &&
        field.required != null &&
        field.required &&
        !isDisabled
      ) {
        result.push(field.name)
      }

      return result
    }, [])

    return requiredFields.length <= Object.keys(touched).length
  }

  _areValuesChanged = ({ values }) => {
    const { checkHiddenValuesChanged } = this.props

    const visibleValuesChanged = !Object.keys(this._initialValues).every(key =>
      this._initialValues[key] === ''
        ? values[key] == null || values[key] === ''
        : values[key] === this._initialValues[key]
    )

    // Check if any hidden value has changed
    return checkHiddenValuesChanged
      ? this._areHiddenValuesChanged() || visibleValuesChanged
      : visibleValuesChanged
  }

  _areHiddenValuesChanged = () => {
    const { hiddenFields } = this.props

    return !Object.keys(hiddenFields).every(
      key => hiddenFields[key] === this._hiddenFields[key]
    )
  }

  canSubmit = innerProps => {
    const { isSubmitting, errors, status } = innerProps
    const { canSubmit, checkRequiredTouched, checkValuesChanged } = this.props

    return (
      // Check if it is not submitting right now
      !isSubmitting &&
      // Check if there are now errors
      _isEmpty(errors) &&
      _isEmpty(status.dbErrors) &&
      // Check if any value has changed
      (checkValuesChanged ? this._areValuesChanged(innerProps) : true) &&
      // Check if all required fields are touched
      (checkRequiredTouched
        ? this._areRequiredFiledsTouched(innerProps)
        : true) &&
      // Custom check
      (canSubmit == null
        ? true
        : typeof canSubmit === 'function'
        ? canSubmit(innerProps)
        : canSubmit)
    )
  }

  onSubmit = async (values, actions) => {
    const {
      method,
      hiddenFields,
      onBeforeSubmit,
      request: { queue },
    } = this.props

    if (typeof onBeforeSubmit === 'function') {
      onBeforeSubmit({ values })
    }

    await this.setState({
      submitError: null,
      submitSuccess: false,
    })

    if (!this._isMounted) {
      return
    }

    try {
      const result = await queue(method, {
        ...alterObjectKeys(values, key => key.replace('=', '.')),
        ...hiddenFields,
      })

      if (!this._isMounted) {
        return
      }

      await this.setState({ submitSuccess: true })

      if (this.props.resetOnSubmit) {
        actions.resetForm()
      }
      this._onSuccess({
        result,
        values: {
          ...values,
          ...hiddenFields,
        },
        actions,
      })
      actions.setSubmitting(false)
    } catch (error) {
      if (error.errors != null) {
        actions.setStatus({ dbErrors: this._getDbErrors(error.errors) })
      } else {
        actions.setTouched({})

        if (!this._isMounted) {
          return
        }

        await this.setState({ submitError: error })
      }

      actions.setSubmitting(false)
      this._onFailure({
        error,
        values: {
          ...values,
          ...hiddenFields,
        },
        actions,
      })
    }
  }

  onReset = async (values, actions) => {
    await this.setState({
      submitError: null,
      submitSuccess: false,
    })

    this.props.onReset(values, actions)
  }

  _onSuccess = ({ result, values, actions }) => {
    const {
      showNotice,
      onSuccess,
      successText,
      notify: { enqueueSnackbar },
      getLangString: l,
    } = this.props

    if (typeof onSuccess === 'function') {
      onSuccess({ result, values, actions })
    }

    if (showNotice) {
      const message =
        successText != null ? successText : l('r.dannye.uspeshno.otpravleny')

      enqueueSnackbar(message, { variant: 'success' })
    }
  }

  _onFailure = ({ error, values, actions }) => {
    const {
      showNotice,
      onFailure,
      notify: { enqueueSnackbar },
      config: { DEFAULT_MESSAGE },
      getLangString: l,
    } = this.props

    if (typeof onFailure === 'function') {
      onFailure({ error, values, actions })
    }

    if (showNotice) {
      const message =
        error == null ||
        error.constructor !== Object ||
        error.code === 'internal-error'
          ? l(DEFAULT_MESSAGE.ERROR)
          : l(error.message)

      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  // Server validation on form input change
  validateWithApi = async form => {
    const {
      method,
      validateMethod,
      request: { queue },
      config: { API_METHOD },
    } = this.props

    // Collect values only from fileds which need server validation
    const values = this._struct.reduce((result, item) => {
      item.server && (result[item.name] = form.values[item.name])
      return result
    }, {})

    const result = await queue(API_METHOD.FORM_CHECK, {
      form: validateMethod != null ? validateMethod : method,
      ...values,
    })

    if (!this._isMounted) {
      return
    }

    form.setStatus({ dbErrors: this._getDbErrors(result.errors) })
  }

  debouncedValidateWithApi = _debounce(
    this.validateWithApi,
    VALIDATION_DEBOUNCE_DELAY
  )

  fixSubmitHandler = renderProps => (event, ...rest) => {
    event.preventDefault()
    this.canSubmit(renderProps) && renderProps.handleSubmit(event, ...rest)
  }

  render() {
    const {
      render,
      children,
      decor, // eslint-disable-line no-unused-vars
      onReady, // eslint-disable-line no-unused-vars
      onReset, // eslint-disable-line no-unused-vars
      onSuccess, // eslint-disable-line no-unused-vars
      onFailure, // eslint-disable-line no-unused-vars
      canSubmit, // eslint-disable-line no-unused-vars
      initialValues, // eslint-disable-line no-unused-vars
      hiddenFields, // eslint-disable-line no-unused-vars
      readyProgress, // eslint-disable-line no-unused-vars
      checkValuesChanged, // eslint-disable-line no-unused-vars
      checkRequiredTouched, // eslint-disable-line no-unused-vars
      checkHiddenValuesChanged, // eslint-disable-line no-unused-vars

      // filter out `withLang` HOC props
      langInfo, // eslint-disable-line no-unused-vars
      getLangObject, // eslint-disable-line no-unused-vars
      getLangString, // eslint-disable-line no-unused-vars
      getLangStringSet, // eslint-disable-line no-unused-vars
      getFirstLangString, // eslint-disable-line no-unused-vars

      ...rest
    } = this.props

    const { isReady, submitError, submitSuccess } = this.state

    const formikProps = {
      onReset: this.onReset,
      onSubmit: this.onSubmit,
      initialStatus: this._initialStatus,
      initialValues: this._initialValues,
      validationSchema: this._yupSchema,
    }

    const formBaseProps = {
      isReady,
      submitError,
      submitSuccess,
      canSubmit: this.canSubmit,
      renderFields: this.renderFields,
    }

    const childProps = {
      ...rest,
      formikProps,
      formBaseProps,
      fixSubmitHandler: this.fixSubmitHandler,
    }

    return children != null ? (
      children(childProps)
    ) : render != null ? (
      render(childProps)
    ) : (
      <FormInPlain {...childProps} />
    )
  }
}

RawFormWithValidation.defaultProps = {
  resetOnSubmit: true,
  decor: {},
  onReady: _noop,
  onReset: _noop,
  onSuccess: _noop,
  onFailure: _noop,
  hiddenFields: {},
  initialValues: {},
  checkValuesChanged: false,
  checkRequiredTouched: false,
  checkHiddenValuesChanged: false,
  showNotice: true,
}

RawFormWithValidation.propTypes = {
  // self props
  resetOnSubmit: PropTypes.bool,
  decor: PropTypes.object,
  render: PropTypes.func,
  children: PropTypes.func,
  structUrl: PropTypes.string,
  method: PropTypes.string.isRequired,
  validateMethod: PropTypes.string,
  onlyFields: PropTypes.arrayOf(PropTypes.string),
  exceptFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.object,
  initialValues: PropTypes.object,
  onReady: PropTypes.func,
  onReset: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onBeforeSubmit: PropTypes.func,
  canSubmit: PropTypes.func,
  checkValuesChanged: PropTypes.bool,
  checkRequiredTouched: PropTypes.bool,
  checkHiddenValuesChanged: PropTypes.bool,
  readyProgress: PropTypes.node,
  successText: PropTypes.node,
  showNotice: PropTypes.bool,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const FormWithValidation = withCatch(
  withContext(GlobalContext)(withLang(RawFormWithValidation))
)
