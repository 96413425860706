import React, { useState } from 'react'

// @material-ui
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

// core
import { TextInputBase } from '_core/components/TextInputBase'

export const PasswordInput = props => {
  const [show, setShow] = useState(false)

  return (
    <TextInputBase
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(v => !v)}
              onMouseDown={event => event.preventDefault()}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
      type={show ? 'text' : 'password'}
    />
  )
}

PasswordInput.defaultProps = {}
PasswordInput.propTypes = {}
