import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

// @material-ui
import {
  Box,
  Badge,
  Divider,
  SwipeableDrawer,
  Hidden,
  Icon,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import { Chat, Home } from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'
import { useGlobal } from '_core/hooks/useGlobal'
import { useCartContext } from '_core/contexts/Cart';
import { AppMenu } from '_core/views/AppMenu'
import { AppLogo } from '_core/components/AppLogo'
import { SidebarUser } from '_core/components/SidebarUser'
import { Clock } from '_core/components/Clock'
import { SettingsPopup } from '_core/components/SettingsPopup'
import { ContactsPopup } from '_core/components/ContactsPopup'
import { BalancePopup } from '_core/components/BalancePopup'
import { LogoutButton } from '_core/components/LogoutButton'

// style
import { sidebarStyle } from './sidebarStyle'

export const RawSidebar = (props) => {
  const {
    classes,
    logo,
    menuId,
    isMini,
    isOpen,
    hideCart,
    onProfileOpen,
    toggleDrawer,
    minimizeSidebar,
    getLangString: l,
  } = props

  const {
    config: { ROUTE_URL, HAS_CONTACTS_BUTTON, HAS_HOME_BUTTON, HAS_CART, HAS_WALLET, HAS_CHAT },
  } = useGlobal()

  const theme = useTheme()
  const cart = useCartContext();
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const hasTooltip = isMini && isMd ? true : false
  const hasBalanceValues = theme.props.RtsBalancePopup.hasValues

  const rootClasses = clsx({
    [classes.drawerClose]: isMini,
  })

  const paperClasses = clsx({
    [classes.paper]: true,
    [classes.drawerClose]: isMini,
  })

  const hide = clsx({
    [classes.hide]: isMini,
  })

  return (
    <SwipeableDrawer
      classes={{ root: rootClasses, paper: paperClasses }}
      variant={isMd ? 'permanent' : 'temporary'}
      open={isMd ? false : isOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Box display="flex" flexDirection="column" className={classes.content}>
        <AppLogo
          logo={logo}
          isMini={isMini}
          minimizeSidebar={minimizeSidebar}
        />

        <div className={classes.body}>
          <Divider className={classes.divider} />

          <SidebarUser
            isMini={isMini}
            hasTooltip={hasTooltip}
            onProfileOpen={onProfileOpen}
          />

          <Divider className={classes.divider} />

          <AppMenu hasTooltip={hasTooltip} menuId={menuId} isMini={isMini} />

          <Hidden xsDown mdUp implementation="css">
            <BalancePopup inMenu={true} />
          </Hidden>

          {HAS_WALLET && hasBalanceValues ? ( 
            <Hidden smUp implementation="css">
              <BalancePopup inMenu={true} />
            </Hidden>
          ) : null}

          <Hidden smUp implementation="css">
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              className={classes.controls}
            >
              {HAS_WALLET && !hasBalanceValues ? ( 
                <BalancePopup inMenu={true} />
              ) : null}
              {HAS_CHAT ? (
                <Tooltip title={l('r._.header.chat')}>
                  <a target="_blank" href="/templ/discord.html">
                    <IconButton>
                      <Chat />
                    </IconButton>
                  </a>
                </Tooltip>
              ) : null}

              {HAS_HOME_BUTTON ? (
                <Tooltip title={l('r._.gotoold')}>
                  <IconButton
                    href={HAS_HOME_BUTTON}
                    target="_blank"
                  >
                    <Home />
                  </IconButton>
                </Tooltip>
              ) : null}

              {HAS_CART && !hideCart ? (
                <Tooltip title={l('r._.good.checkout')}>
                  <IconButton component={Link} to={ROUTE_URL.CART}>
                    <Badge
                      color="secondary"
                      badgeContent={cart.count_total}
                      classes={{ badge: classes.cartBadge }}
                    >
                      <Icon>shopping_cart</Icon>
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : null}

              <SettingsPopup />
              
              {HAS_CONTACTS_BUTTON ? (
                  <ContactsPopup />
              ) : null}
              
              <LogoutButton />
            </Box>
          </Hidden>

          <Divider className={clsx(hide, classes.divider)} />

          <Hidden smDown implementation="css">
            <Clock className={hide} />

            <Divider className={classes.divider} />
          </Hidden>
        </div>
      </Box>

      <div className={classes.rest}></div>
    </SwipeableDrawer>
  )
};

RawSidebar.propTypes = {
  // self props
  logoText: PropTypes.string,
  logo: PropTypes.string,
  isOpen: PropTypes.bool,
  menuId: PropTypes.string,
  isMini: PropTypes.bool,
  hideCart: PropTypes.bool,
  minimizeSidebar: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onProfileOpen: PropTypes.func.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Sidebar = withLang(
  withStyles(sidebarStyle, { name: 'RtsSidebar' })(RawSidebar)
)
