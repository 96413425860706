import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('i', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <i>{this.getComponents()}</i>
    );
  }
  
});
