import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui
import {
  Collapse,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

// core
import { useVisitorInfo } from '_core/utils/visitorInfo';
import { useGlobal } from '_core/hooks/useGlobal';

import { useStyle } from './style';


const RawGoodsCategoryMenu = (props) => {
  const [open, setOpen] = React.useState();
  
  const visitorInfo = useVisitorInfo();
  
  const classes = useStyle();
  
  const {
    config: { ROUTE_URL }
  } = useGlobal();
  
  
  const catalogData = visitorInfo.catalogData;
  
  const pagePath = catalogData?.path;
  const categories = catalogData?.categories;
  
  React.useEffect(() => {
    const currentCategory = pagePath && categories?.find((item) => item.pathString === pagePath) || null;
    
    setOpen(currentCategory?.path?.length ? currentCategory.path[0].name : currentCategory ? currentCategory.name : null);
  }, [pagePath, categories]);
  
  if (!catalogData?.isVisible)
    return null;
  
  if (catalogData.isLoading) {
    return (
      <List className={classes.list}>
        {Array(3).fill(0).map((zero, index) => (
          <li className={classes.group} key={index}>
            <ListItem component="div" button={false} className={classes.item}>
              <ListItemIcon className={classes.icon}><Icon>data_usage</Icon></ListItemIcon>
              <ListItemText classes={{ primary: classes.text }} primary={<Skeleton animation="wave" variant="rect" className={classes.skeleton} />} />
            </ListItem>
          </li>
        ))}
      </List>
    );
  }
  
  if (!categories?.length)
    return null;
  
  const renderCategories = (parent) => (
      <List className={classes.list}>
        {categories.map((item) => {
          const isCollapseable = !parent && item.hasChildren;
          
          const isOpen = isCollapseable && open === item.pathString;
          
          const selected = pagePath && (pagePath === item.pathString || pagePath.startsWith(item.pathString + '/')) || false;
          
          const groupClasses = clsx({
            [classes.group]: true,
            [classes.groupActive]: selected
          });
          
          const itemClasses = clsx({
            [classes.item]: !parent,
            [classes.active]: selected,
            [classes.child]: !!parent
          });
          
          return !parent && !item.path?.length || parent && item.pathString.startsWith(parent + '/') ? (
            <li className={groupClasses} key={item.id}>
              {/*<Tooltip title={item.title} placement="right" enterDelay={0}>*/}
                <ListItem
                  component={NavLink}
                  button={true}
                  className={itemClasses}
                  activeClassName={parent ? classes.childActive : null}
                  selected={parent && selected || false}
                  to={ROUTE_URL.CATALOG + '/' + item.pathString}
                  onClick={isCollapseable ? (event) => setOpen(open !== item.pathString ? item.pathString : undefined) : null}
                >
                  {!parent ? (
                    <>
                      <ListItemIcon className={classes.icon}>
                        {item.icon?.id ? (
                         <img src={item.icon.path} />
                        ) : (
                          <Icon>data_usage</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.text }}
                        primary={item.title}
                      />
                    </>
                    ): (
                    <ListItemText
                      classes={{ primary: classes.text + ' ' + classes.secondarytext }}
                      primary={item.title}
                    />
                    )
                  }
                 
                  
                  {isCollapseable ? (
                    <ListItemIcon className={classes.toggle}>
                      {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  ) : null}
                </ListItem>
              {/*</Tooltip>*/}
              
              {isCollapseable ? (
                <Collapse in={isOpen}>
                  {renderCategories(item.pathString)}
                </Collapse>
              ) : null}
            </li>
          ) : null;
        })}
      </List>
    );
  
  return (
    <>
      {renderCategories()}
      
      <Divider className={classes.divider} />
    </>
  );
};

RawGoodsCategoryMenu.defaultProps = {
};

RawGoodsCategoryMenu.propTypes = {
};

export const GoodsCategoryMenu = RawGoodsCategoryMenu;
