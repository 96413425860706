import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { flagNames } from '_core/utils/flagNames'
import { langToFlag } from '_core/utils/langToFlag'
import { Loading } from '_core/components/Loading'
import { Dropdown } from '_core/components/Dropdown'

// style
import { useLangSwitchStyle } from './langSwitchStyle'

const RawLangSwitchView = props => {
  const {
    className,
    tooltip,
    inMenu,
    isLoading,
    langs,
    langId,
    onChange,
    getLangString: l,
  } = props

  const classes = useLangSwitchStyle()

  if (langId == null || langs == null || Object.keys(langs).length < 2) {
    return null
  }

  const getFlag = code => {
    const codes = code.toLowerCase().split('-')
    const name = codes.length === 1 ? langToFlag[codes[0]] : codes[1]
    return flagNames.includes(name) ? name : null
  }

  const renderItems = () =>
    Object.values(langs).map(item => {
      const flag = getFlag(item.code)

      return {
        key: item.code,
        content: (
          <>
            <ListItemIcon className={classes.listItem}>
              {flag != null ? (
                <img
                  src={require(`_core/assets/img/flags/${flag}.png`)}
                  className={classes.listIcon}
                  alt=""
                />
              ) : (
                <Typography variant="caption" color="textPrimary">
                  {item.code.toUpperCase()}
                </Typography>
              )}
            </ListItemIcon>
            <ListItemText primary={item.code.toUpperCase()} />
          </>
        ),
      }
    })

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  const buttonRootClasses = clsx({
    [classes.button]: true,
    [classes.buttonContrast]: inMenu,
  })

  const renderButton = ({ onClick }) => {
    const flag = getFlag(langs[langId].code)

    return (
      <IconButton
        onClick={onClick}
        classes={{
          root: buttonRootClasses,
          label: classes.label,
        }}
      >
        {isLoading ? (
          <Loading
            progressProps={{ className: classes.progress }}
            color="secondary"
          />
        ) : flag != null ? (
          <img
            src={require(`_core/assets/img/flags/${flag}.png`)}
            className={classes.icon}
            alt=""
          />
        ) : (
          <Typography variant="caption" color="textPrimary">
            {langs[langId].code.toUpperCase()}
          </Typography>
        )}
      </IconButton>
    )
  }

  return (
    <Dropdown
      activeKey={langs[langId].code}
      className={rootClasses}
      tooltip={l(tooltip)}
      items={renderItems()}
      onChange={(event, item) => onChange(item.key)}
      renderButton={renderButton}
    />
  )
}

RawLangSwitchView.defaultProps = {
  tooltip: 'r.switch.lang',
}

RawLangSwitchView.propTypes = {
  // self props
  tooltip: PropTypes.string,
  inMenu: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  langs: PropTypes.object.isRequired,
  langId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export const LangSwitchView = withLang(RawLangSwitchView)
