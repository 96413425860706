import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

const RawHistoryListener = props => {
  const { children, onChange } = props
  const history = useHistory()

  useEffect(() => {
    if (typeof onChange === 'function') {
      return history.listen(onChange)
    }
  }, [])

  return children
}

RawHistoryListener.defaultProps = {}

RawHistoryListener.propTypes = {
  // self props
  children: PropTypes.node,
  onChange: PropTypes.func,
}

export const HistoryListener = RawHistoryListener
