import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withRouter } from 'react-router'

// @material-ui
import {
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { OpenInNew, FindInPage } from '@material-ui/icons'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { State } from '_core/components/State'
import { ArticleContainer } from '_core/components/ArticleContainer'
import { AgreementsModal } from 'basic/views/AgreementsModal'

// style
import { guestStyle } from 'basic/layouts/Guest/guestStyle'

const style = theme => ({
  ...guestStyle,

  iconButton: {
    marginLeft: theme.spacing(),
  },

  checklist: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
})

const RawAgreementsChecks = props => {
  const {
    classes,
    className,
    onChange,
    history,
    location,
    langInfo,
    getLangString: l,
  } = props

  const rootClasses = clsx({
    [classes.checklist]: true,
    [className]: !!className,
  })

  const {
    request: { queue },
    config: { API_METHOD, ARTICLE_KEY, ROUTE_URL_PARAM },
  } = useGlobal()

  const [data, setData] = useState()
  const [checked, setChecked] = useState(0)
  const [nodeKey, setNodeKey] = useState(0)

  // Remount component in case of language change
  useEffect(() => {
    setNodeKey(key => key + 1)
  }, [langInfo])

  const params = new URLSearchParams(location.search)

  const setModalTab = tab => {
    tab == null
      ? params.delete(ROUTE_URL_PARAM.AGREEMENT)
      : params.set(ROUTE_URL_PARAM.AGREEMENT, tab)

    location.search = params.toString()
    history.push(location)
  }

  const isEmpty = data => !Array.isArray(data) || data.length === 0

  const getData = () => queue(API_METHOD.ARTICLE, { path: ARTICLE_KEY.AGREEMENTS })
    .then((result) => {
      isEmpty(result.list) && onChange(true)
      setData(result.list)
    });

  const getTabs = data =>
    data
      .filter(
        item =>
          !item.link_replaced &&
          (item.description != null || item.content != null)
      )
      .map(item => ({
        value: item.name,
        label: item.title,
        content: (
          <>
            <Typography component="div" variant="body2">
              {item.description}
            </Typography>

            <ArticleContainer variant="body1">{item.content}</ArticleContainer>
          </>
        ),
      }))

  const getOnCheck = total => event => {
    const newChecked = checked + (event.target.checked ? 1 : -1)
    onChange(total - newChecked === 0)
    setChecked(newChecked)
  }

  const getButton = item =>
    item.link_replaced && item.link ? (
      <IconButton
        className={classes.iconButton}
        size="small"
        color="secondary"
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenInNew />
      </IconButton>
    ) : (
      <IconButton
        className={classes.iconButton}
        size="small"
        color="secondary"
        onClick={() => setModalTab(item.name)}
      >
        <FindInPage />
      </IconButton>
    )

  const renderChecklist = data => {
    const onCheck = getOnCheck(data.length)

    return data.map(item => (
      <div key={item.name}>
        <FormControlLabel
          label={
            <>
              <Typography display="inline" variant="overline">
                {item.title}
              </Typography>
              {getButton(item)}
            </>
          }
          control={<Checkbox name={item.name} onClick={onCheck} />}
        />
      </div>
    ))
  }

  return (
    <div className={rootClasses}>
      <State
        key={nodeKey}
        waitProps={{ data }}
        sideProps={{ getData }}
        render={({ data }) => {
          const tabs = getTabs(data)

          return isEmpty(data) ? null : (
            <>
              <Typography variant="caption" gutterBottom={true}>
                {l('r._.guest.register.agreements.title')}
              </Typography>

              {renderChecklist(data)}

              {!isEmpty(tabs) ? (
                <AgreementsModal
                  tabs={getTabs(data)}
                  tab={params.get(ROUTE_URL_PARAM.AGREEMENT)}
                  onTabChange={setModalTab}
                  onClose={() => setModalTab()}
                />
              ) : null}
            </>
          )
        }}
      />
    </div>
  )
}

RawAgreementsChecks.displayName = 'RawAgreementsChecks'

RawAgreementsChecks.defaultProps = {}

RawAgreementsChecks.propTypes = {
  // self props
  onChange: PropTypes.func,
  className: PropTypes.string,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withRouter` HOC props
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const AgreementsChecks = withCatch(
  withRouter(withLang(withStyles(style)(RawAgreementsChecks)))
)
