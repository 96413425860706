import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { debounce } from 'lodash'

// @material-ui
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  Icon,
  withWidth,
} from '@material-ui/core'
import { isWidthUp } from '@material-ui/core/withWidth'

// core
import { withLang } from '_core/hocs/withLang'

const RawTabs = props => {
  const {
    items,
    value,
    onChange,
    tabProps,
    tabsProps,
    iconProps,
    contentProps,
    contentsProps,
    swipeableProps,
    renderTabs,
    renderTabContents,
    width,
    getLangString: l,
  } = props

  const tabsActions = useRef(null)
  const tabIndex = items.findIndex(tab => tab.value === value)

  const onChangeIndex = index => {
    onChange(items[index].value)
  }

  const onWindowResize = useCallback(
    debounce(() => {
      if (
        tabsActions.current != null &&
        tabsActions.current.updateIndicator != null
      ) {
        tabsActions.current.updateIndicator()
      }
    }, 250),
    [tabsActions.current, width]
  )

  useEffect(onWindowResize, [tabsActions.current, width])

  const scrollTabsProps = isWidthUp('sm', width)
    ? {}
    : {
        scrollButtons: 'on',
        variant: 'scrollable',
      }

  const tabs = (
    <MuiTabs
      value={value}
      onChange={onChange}
      action={tabsActions}
      variant={isWidthUp('sm', width) ? 'fullWidth' : 'scrollable'}
      {...scrollTabsProps}
      {...tabsProps}
    >
      {items.map((prop, key) => {
        const itemProps = {
          value: prop.value,
          label: <span>{l(prop.label)}</span>,
        }

        if (prop.icon != null) {
          itemProps.icon = <Icon {...iconProps}>{prop.icon}</Icon>
        }

        return <MuiTab key={key} {...tabProps} {...itemProps} />
      })}
    </MuiTabs>
  )

  const renderItemContent = item => {
    const {
      renderContent,
      ContentComponent,
      content,
      value,
      label,
      icon
    } = item

    const itemContentProps = {
      ...contentProps,
      
      value,
      label,
      icon,
    }

    return renderContent != null ? (
      renderContent(itemContentProps)
    ) : ContentComponent != null ? (
      <ContentComponent {...itemContentProps} />
    ) : (
      content
    )
  }

  const contents =
    swipeableProps != null ? (
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={onChangeIndex}
        {...swipeableProps}
      >
        {items.map((item, key) => (
          <div key={key} {...contentsProps}>
            {renderItemContent(item)}
          </div>
        ))}
      </SwipeableViews>
    ) : (
      <div {...contentsProps}>{renderItemContent(items[tabIndex])}</div>
    )

  return [
    renderTabs != null
      ? renderTabs({
          tabs,
          items,
          value,
        })
      : tabs,

    renderTabContents != null
      ? renderTabContents({
          contents,
          items,
          value,
        })
      : contents,
  ]
}

RawTabs.defaultProps = {
  // swipeableProps: {},
}

RawTabs.propTypes = {
  // self props
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      icon: PropTypes.elementType,
      content: PropTypes.node,
      ContentComponent: PropTypes.elementType,
      renderContent: PropTypes.func,
    })
  ).isRequired,
  tabProps: PropTypes.object,
  tabsProps: PropTypes.object,
  iconProps: PropTypes.object,
  contentsProps: PropTypes.object,
  swipeableProps: PropTypes.object,
  renderTabs: PropTypes.func,
  renderTabContents: PropTypes.func,
  isRtl: PropTypes.bool,

  // `withWidth` HOC props
  width: PropTypes.string,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

// export const Tabs = withLang(withWidth()(withStyles(style)(RawTabs)))
export const Tabs = withLang(withWidth()(RawTabs))
