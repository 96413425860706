import React from 'react';

// core
import { useSearch } from '_core/hooks/useSearch';


export const useSearchTabs = ({ tabs, param = 'tab', canBeBlank }) => {
  const { setSearchParam, getSearchParam } = useSearch();
  
  const tabParam = getSearchParam(param);
  
  const tab = tabs.find((item) => item.value === tabParam)?.value ?? (!canBeBlank ? tabs[0].value : null);
  
  return {
    tab,
    onTabChange: (value) => setSearchParam(param, value)
  };
};
