import React from 'react';

import Tag from './AbstractTag';


const ListTag = class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
    
    this.STRIP_INNER = true;
    this.STRIP_OUTER = true;
  }
  
  
  toReact() {
    const rawContent = this.getContent(true);
    
    const content = rawContent.match(/\[li[=\s\]]/) ? this.getComponents() : rawContent.replaceAll('[br="auto"]', '\n').trim().split(/\n+/g).map((item, index) => (<li key={index}>{item}</li>));
    
    if (this.name === 'ol') {
      return (
        <ol>{content}</ol>
      );
    } else {
      return (
        <ul>{content}</ul>
      );
    }
  }
  
};

Tag.parser.registerTag('ol', ListTag);
Tag.parser.registerTag('ul', ListTag);

Tag.parser.registerTag('li', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <li>{this.getComponents()}</li>
    );
  }
  
});
