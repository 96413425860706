// @material-ui
import { makeStyles } from '@material-ui/styles'

export const productDetailsStyle = theme => ({
  root: {
    margin: 0,
  },

  media: {
    minHeight: theme.spacing(20),
  },

  title: {
    position: 'relative',
  },

  body: {
    position: 'relative',
  },

  description: {
    position: 'relative',
    marginBottom: theme.spacing(),

    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  content: {},

  actions: {
    position: 'relative',
  },
})

export const useProductDetailsStyle = makeStyles(productDetailsStyle)
