export const overrides = theme => ({
  root: {
    cursor: 'pointer',
    fontSize: '1.125rem',
    transition: theme.transitions.create(['box-shadow', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  colorDefault: {
    color: [theme.palette.primary.contrastText, '!important'],
    backgroundColor: theme.palette.primary.main,
  },
})
