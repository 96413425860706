// @material-ui
import { makeStyles } from '@material-ui/styles'

export const guestStyle = theme => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
  },
  fullPage: {
    padding: '25px 0',
    position: 'relative',
    minHeight: '100vh',
    display: 'flex !important',
    margin: '0',
    border: '0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  shadeOn: {
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '2',
      backgroundColor: '#000',
      opacity: 0.5,
    },
  },
  logoBigLink: {
    zIndex: 4,
    width: '100%',
    maxWidth: 320,
    maxHeight: 110,
    position: 'relative',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      maxHeight: 80,
      marginBottom: theme.spacing(2),
    },
  },
  logoBig: {
    width: '100%',
    maxWidth: 320,
    maxHeight: 110,
    position: 'relative',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      maxHeight: 80,
    },
  },

  container: {
    zIndex: '4',
    width: '100%',
    margin: 0,
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  linkHover: {
    // color: theme.palette.sext.secondary,
    marginBottom: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  unmarginBottom: {
    marginBottom: -theme.spacing(1),
  },
  avatar: {
    position: 'absolute',
    margin: 0,
    top: -theme.spacing(1),
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'unset',
  },
  unlockCardContent: {
    paddingTop: theme.spacing(1) + 50,
  },
  unlockCard: {
    overflow: 'unset',
    marginTop: 50,
  },
  settings: {
    flexDirection: 'row',
    position: 'fixed',
    top: theme.spacing(4),
    right: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      right: theme.spacing(),
      alignSelf: 'flex-end',
    },
  },
  videoContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    '& video': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
    },
  },
  regionaddressContainer: {
    paddingBottom: '2em'
  }
})

export const useGuestStyle = makeStyles(guestStyle)
