import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { API_METHOD } from 'basic/config'
import { withLang } from '_core/hocs/withLang'
import { FormWithValidation } from '_core/components/FormWithValidation'
import { FormInModal } from '_core/components/FormInModal'

const style = theme => ({})

const RawSecurityCheckView = props => {
  const {
    title,
    type,
    formProps,
    validationProps,
    render,
    check,
    onSuccess,
    onFailure,
    getSubmitHandler,
    isModalOpen,
    structUrl,
    decor,

    // Filter out `withLang` HOC props
    langInfo, // eslint-disable-line no-unused-vars
    langStrings, // eslint-disable-line no-unused-vars
    getLangObject, // eslint-disable-line no-unused-vars
    getLangStringSet, // eslint-disable-line no-unused-vars
    getLangString, // eslint-disable-line no-unused-vars
    getFirstLangString: l,

    ...rest
  } = props

  const renderCheckForm = () => {
    return (
      <FormWithValidation
        decor={decor}
        method={API_METHOD.CLIENT_SECURITY_CONFIRM}
        structUrl={structUrl}
        hiddenFields={{ type }}
        successText={l('r.proverka.uspeshno')}
        {...validationProps}
        onSuccess={onSuccess}
        render={childProps => (
          <FormInModal
            title={l(title)}
            {...formProps}
            isOpen={isModalOpen}
            onClose={onFailure}
            getSubmitHandler={getSubmitHandler}
            {...childProps}
          />
        )}
      />
    )
  }

  return render({
    ...rest,
    check,
    renderCheckForm,
  })
}

RawSecurityCheckView.defaultProps = {
  formProps: {
    modalOuterProps: {
      maxWidth: 'sm',
    },
  },
}

RawSecurityCheckView.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['auth', 'finop']),
  formProps: PropTypes.object,
  validationProps: PropTypes.object,
  render: PropTypes.func.isRequired,

  // parent props
  check: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  getSubmitHandler: PropTypes.func,
  isModalOpen: PropTypes.bool,
  structUrl: PropTypes.string,
  decor: PropTypes.object,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const SecurityCheckView = withLang(
  withStyles(style)(RawSecurityCheckView)
)
