export const overrides = theme => ({
  // regular: {
  //   minHeight: theme.spacing(6),
  //   [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
  //     minHeight: theme.spacing(6),
  //   },
  //   [theme.breakpoints.up('sm')]: {
  //     minHeight: theme.spacing(11),
  //   },
  // },
  // gutters: {
  //   paddingLeft: theme.spacing(3),
  //   paddingRight: theme.spacing(3),
  //   [theme.breakpoints.up('sm')]: {
  //     paddingLeft: theme.spacing(5),
  //     paddingRight: theme.spacing(5),
  //   },
  // },
})
