// @material-ui
import { makeStyles } from '@material-ui/styles'

export const modalInnerStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  
  title: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0,
  },

  header: {
    paddingRight: theme.spacing(8),
  },

  icon: {
    marginRight: theme.spacing(1),
    color: theme.variables.colors.icon,
    fontSize: theme.typography.h5.fontSize,
  },

  content: {
    position: 'relative',
  },

  actions: {},
})

export const useModalInnerStyle = makeStyles(modalInnerStyle)
