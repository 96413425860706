export const clipboardInputStyle = theme => ({
  root: {},

  input: {},

  inputLabel: {},

  inputHelperText: {},

  adornment: {
    margin: 0,
    height: '100%',
    cursor: 'pointer',
  },

  icon: {},

  iconPath: {
    fill: theme.palette.primary.main,
  },

  iconPathCopied: {
    fill: theme.palette.secondary.main,
  },
})
