import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Avatar as MuiAvatar, Tooltip } from '@material-ui/core'

// style
import { useAvatarStyle } from './avatarStyle'

export const RawAvatar = props => {
  const {
    className,
    size,
    tooltip,
    tooltipProps,
    disableClick,
    letterProps,
    defaultLetter,
    getLangString: l,
    ...rest
  } = props

  const classes = useAvatarStyle()
  const { firstname, lastname, login } = letterProps

  const letter =
    typeof firstname === 'string' &&
    typeof lastname === 'string' &&
    firstname.length > 1 &&
    lastname.length > 1
      ? firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase()
      : typeof login === 'string' && login.length > 1
      ? login.charAt(0).toUpperCase()
      : defaultLetter

  const rootClasses = clsx({
    [classes.avatar]: true,
    [classes.xs]: size === 'xs',
    [classes.sm]: size === 'sm',
    [classes.md]: size === 'md',
    [classes.lg]: size === 'lg',
    [classes.xl]: size === 'xl',
    [classes.disableClick]: disableClick,
    [className]: className != null,
  })

  const content = (
    <MuiAvatar classes={{ root: rootClasses }} {...rest}>
      {letter}
    </MuiAvatar>
  )

  return tooltip || tooltipProps ? (
    <Tooltip title={tooltip} {...tooltipProps}>
      {content}
    </Tooltip>
  ) : (
    content
  )
}

RawAvatar.defaultProps = {
  size: 'md',
  defaultLetter: '?',
  letterProps: {},
}

RawAvatar.propTypes = {
  // self props
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  defaultLetter: PropTypes.string,
  disableClick: PropTypes.bool,
  letterProps: PropTypes.object,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
}

export const Avatar = RawAvatar
