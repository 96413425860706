import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// @material-ui
import { IconButton, Badge, Typography, List, ListItem, ListItemIcon, ListSubheader, ListItemText } from '@material-ui/core'
import { Notifications, Help, Drafts } from '@material-ui/icons'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { Menu } from '_core/components/Menu'
import { Loading } from '_core/components/Loading'

// style
import { useStyles } from './headerControlsStyle'

const EVENT_TYPES = {
  ticket: { icon: Help, uri: '/tickets/{id}' },
  
  message: { icon: Drafts },
  
  _default: { icon: Drafts }
};

const RawUserNotifier = (props) => {
  const { getLangString: l } = props;

  const classes = useStyles()
  
  const {
    request: { queue },
    config: { API_METHOD, API_NOTICE }
  } = useGlobal();
  
  const [eventData, setEventData] = useState();
  
  const fetchEvents = () => {
    queue(API_METHOD.VISITOR_EVENT_LIST, {})
      .then((result) => setEventData(result))
      .finally(() => setEventData({ total: 0 }));
  };
  
  React.useEffect(fetchEvents, []);
  
  React.useEffect(() => {
    const notifyListener = (event) => (event.detail.name === API_NOTICE.TICKET_STATUS || event.detail.name === API_NOTICE.TICKET_MESSAGE) && fetchEvents();
    
    window.addEventListener('notify', notifyListener);
    
    return () => window.removeEventListener('notify', notifyListener);
  }, []);

  const renderButton = (btnProps) => (
    <IconButton {...btnProps}>
      <Badge classes={{ badge: classes.userNotifierBadge }} color="primary" badgeContent={eventData?.total} max={99}>
        <Notifications />
      </Badge>
    </IconButton>
  );

  const renderItem = ({ onPopoverClose }, item) => {
    const type = EVENT_TYPES[item.type] || EVENT_TYPES._default;
    
    const Icon = type.icon;
    
    const onClick = () => {
      setEventData((state) => {
        const stateList = state.list.filter((m) => m.id !== item.id);
        
        return { total: state.total - state.list.length + stateList.length, list: stateList };
      });
      
      onPopoverClose();
    };
    
    return (
      <ListItem button key={`${item.type}-${item.id}-${item.message}`} component={Link} to={type.uri?.replace('{id}', item.id)} onClick={onClick}>
        <ListItemIcon><Icon /></ListItemIcon>
        <ListItemText primary={<Typography className={classes.userNotifierListItemPrimary} noWrap>{String(item.excerpt || '').trim().replace(/[\n\r][\s\S]*/, '…')}</Typography>} secondary={item.time.format} />
      </ListItem>
    );
  };

  const renderContent = (props) => (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          {l('r._.header.notifier')}
          
          {!eventData ? <Loading /> : null}
        </ListSubheader>
      }
    >
      {eventData ? (eventData.list?.length ? eventData.list.map((item) => renderItem(props, item)) : (
        <ListItem button>
          <ListItemText secondary={l('r._.header.notifier.none')} />
        </ListItem>
      )) : null}
    </List>
  );

  return (
    <Menu
      ButtonComponent={renderButton}
      renderContent={renderContent}
      tooltip={l('r._.header.notifier')}
      paperProps={{ className: classes.userNotifierPaper }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
    />
  )
}

RawUserNotifier.defaultProps = {
}

RawUserNotifier.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const UserNotifier = withLang(RawUserNotifier)
