// @material-ui
import { makeStyles } from '@material-ui/styles'

export const profileStyle = theme => ({
  title: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

export const useProfileStyle = makeStyles(profileStyle)
