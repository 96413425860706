import { mergeWith } from 'lodash'

// core
import { dataMerger } from '_core/utils/dataMerger'

const backgound = {
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

const pseudo = {
  '&:before,&:after': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    display: 'block',
    top: '0',
    ...backgound,
  },

  '&:before': {
    zIndex: -2,
  },

  '&:after': {
    zIndex: -1,
  },
}

export const sidebarStyle = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: theme.variables.sidebarWidth,
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    border: 'unset !important',
    zIndex: theme.zIndex.drawer + 5,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: theme.variables.colors.sidebarBackground,
    backgroundImage: `url("${theme.utils.getPath(
      theme.pages.sidebar.backgroundImage
    )}")`,

    '& *': {
      color: theme.variables.colors.sidebarItems,
    },

    ...mergeWith({}, backgound, pseudo, theme.mixins.sidebar, dataMerger),
  },

  content: {
    maxHeight: '100%',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 10,
    flexShrink: 0,

    backgroundColor: theme.variables.colors.sidebarContetBackground,
    backgroundImage: `url("${theme.utils.getPath(
      theme.pages.sidebar.backgroundContentImage
    )}")`,

    ...mergeWith(
      {},
      backgound,
      pseudo,
      theme.mixins.sidebarContent,
      dataMerger
    ),
  },

  rest: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 10,
    flexGrow: 1,

    backgroundColor: theme.variables.colors.sidebarRestBackground,
    backgroundPosition: 'center bottom',
    backgroundImage: `url("${theme.utils.getPath(
      theme.pages.sidebar.backgroundRestImage
    )}")`,

    ...mergeWith({}, backgound, pseudo, theme.mixins.sidebarRest, dataMerger),
  },

  body: {
    overflowX: 'hidden',
    overflowY: 'auto',
    flexGrow: 1,
  },

  hide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  controls: {
    padding: theme.spacing(0, 1, 2, 1),
  },

  divider: {
    ...theme.mixins.sidebarDivider,
  },

  drawerClose: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7) + 1,
    },
  },

  cartBadge: {
    color: [theme.palette.primary.contrastText, '!important'],
  },
})
