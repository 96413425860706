export const headerHeight = theme => ({
  height: theme.variables.headerHeight.main,
  minHeight: theme.variables.headerHeight.main,

  [`${theme.breakpoints.down('md')}`]: {
    height: theme.variables.headerHeight.md,
    minHeight: theme.variables.headerHeight.md,
  },

  [`${theme.breakpoints.down('xs')}`]: {
    height: theme.variables.headerHeight.xs,
    minHeight: theme.variables.headerHeight.xs,
  },
})
