import { createSelector } from 'redux-starter-kit'

// Ritos utils
import { publicDataSelectors } from '_core/store/PublicData'
import { makeActionCreator } from '_core/utils/actions'

// === Action types ===

const COUNTRY_RESET = 'COUNTRY_RESET'
const COUNTRY_UPDATE = 'COUNTRY_UPDATE'

// === Default state ===

const defaultState = {
  list: null,
}

// === Selectors ===

const getCountryList = store => publicDataSelectors.getCountry(store).list

const getCountrySelectOptions = createSelector(
  [getCountryList],
  list =>
    Object.keys(list || {}).reduce((result, key) => {
      result.push({
        value: list[key].id,
        title: list[key].title,
      })

      return result
    }, [])
)

// === Actions ===

const updateCountry = makeActionCreator(COUNTRY_UPDATE, 'data')
const resetCountry = makeActionCreator(COUNTRY_RESET)

// === Exports ===

export const countryDefaultState = defaultState

export const countryActionTypes = {
  COUNTRY_RESET,
  COUNTRY_UPDATE,
}

export const countrySelectors = {
  getCountryList,
  getCountrySelectOptions,
}

export const countryActions = {
  updateCountry,
  resetCountry,
}
