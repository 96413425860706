const grayLinkStyle = theme => ({
  container: {cursor: "pointer"},
  icon: {
    fontSize: "14px!important",
    color: theme.palette.text.secondary,
    margin: "4px 5px 0 0!important",
    alignSelf: "flex-start",
    lineHeight: "1!important",
  },
  title: {
    color: theme.palette.text.secondary,
    margin: 0,
    textTransform: "uppercase",
    backgroundImage: "linear-gradient(to right, " + theme.palette.text.secondary +" 25%, transparent 0%)",
    backgroundPosition: "1.5px 98%",
    backgroundSize: "4px 1px",
    backgroundRepeat: "repeat-x",
  },
  menuColor:{
    '& $icon':{
      color: theme.variables.colors.sidebarItems,
    },
    '& $title':{
      color: theme.variables.colors.sidebarItems,
      backgroundImage: "linear-gradient(to right, " + theme.variables.colors.sidebarItems +" 25%, transparent 0%)",
    }
  }

});

export default grayLinkStyle;
