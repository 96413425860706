import * as api from '_core/utils/api';
import * as http from '_core/utils/http';
import { join } from '_core/utils/address';


export default {
  tokenProperty: 'dadataApiToken',
  
  buildCitySimple: (type, item) => {
    return item?.data?.city_with_type;
  },
  
  buildCityValue: (type, item) => {
    if (!item?.data)
      return null;
    
    const result = [item.data.country];
    
    if (item.data.region_with_type && item.data.region_with_type !== item.data.city_with_type)
      result.push(item.data.region_with_type);
    
    if (item.data.city_with_type)
      result.push(item.data.city_with_type);
    
    if (item.data.city_district_with_type)
      result.push(item.data.city_district_with_type);
    
    if (item.data.area_with_type)
      result.push(item.data.area_with_type);
    
    if (item.data.settlement_with_type)
      result.push(item.data.settlement_with_type);
    
    return result;
  },
  
  selectItem: async (self, item) => {
    //const { lang } = self.props;
    
    
    if (item?.data) {
      try {
        const ensureData = await api.request('client/address/ensure_city', { dadata_suggestion: item });
        
        item.data.id = ensureData?.city?.id || null;
      } catch (e) {
        console.error(e);
      }
    }
  },
  
  loadSuggestions: async (self, query) => {
    const { lang, token, type, ownerValue } = self.props;
    
    
    const param = { language: lang === 'ru' ? 'ru' : 'en', query, count: 10 };
    
    if (type === 'city') {
      param.from_bound = { value: 'city' };
      param.to_bound = { value: 'settlement' };
      
      if (ownerValue) {
        param.locations = [{
          country_iso_code: ownerValue.data.country_iso_code,
          region_iso_code: ownerValue.data.region_iso_code,
          region_fias_id: ownerValue.data.region_fias_id
        }];
      } else {
        param.locations = [{
          country: '*'
        }];
      }
    } else if (type === 'street') {
      param.from_bound = { value: 'street' };
      param.to_bound = { value: 'street' };
      
      if (ownerValue) {
        param.locations = [{
          country_iso_code: ownerValue.data.country_iso_code,
          region_iso_code: ownerValue.data.region_iso_code,
          region_fias_id: ownerValue.data.region_fias_id,
          city_fias_id: ownerValue.data.city_fias_id
        }];
      }
    }
    
    return (await fetchDadata(token, 'suggest/address', null, param))?.suggestions || [];
  },
  
  determineAddress: async (self) => {
    const { lang, token, type } = self.props;
    
    
    if (type === 'city')
      return (await fetchDadata(token, 'iplocate/address', { language: lang === 'ru' ? 'ru' : 'en' }))?.location;
  }
};


const fetchDadata = (token, method, get, post) => {
  if (!token)
    console.error('dadata token is not set');
  
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/' + method;
  
  const options = {
    method: post ? 'POST' : 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Token ' + token
    },
    body: post ? JSON.stringify(post) : undefined
  };
  
  return fetch(http.param(url, get), options)
    .then((response) => response.json());
};
