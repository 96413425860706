import { combineReducers } from 'redux'

// Ritos store
import { countryReducers } from '_core/store/Country'
import { langReducers } from '_core/store/Lang'


export const publicDataReducers = combineReducers({
  lang: langReducers.lang,
  country: countryReducers.country
})
