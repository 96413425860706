import 'react-daterange-picker/dist/css/react-calendar.css'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ru } from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'
import DatePicker from 'react-datepicker'
// @material-ui
import { IconButton, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Clear } from '@material-ui/icons'

import isArray from 'lodash/isArray'

import 'react-datepicker/dist/react-datepicker.css'

// core
import { createId } from '_core/utils/createId'
import { TextInputBase } from '_core/components/TextInputBase'

import calendarStyle from './calendarStyle'

registerLocale('ru', ru)

class RawCalendarInput extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
      dates: [],
      selected: false
    }

    this._id = createId()
    this._inputRef = React.createRef()

    this.onSelect = this.onSelect.bind(this)

    this._toggle = this._toggle.bind(this)
    this._clearInput = this._clearInput.bind(this)
    this._onClickAway = this._onClickAway.bind(this)
  }

  onSelect(value, ...args) {
    if (isArray(value)) {
      this.setState({
        dates: value,
        selected: true
      })
      if (value[0] !== null && value[1] !== null) {
        this.props.onChange(
          [moment(value[0]), moment(value[1])],
          this._inputRef.current
        )
      }
    } else {
      this.props.onChange(value !== null ? moment(value) : value, this._inputRef.current)
    }
    this._toggle(false)
  }

  _clearInput() {
    this.props.onChange(null)
  }

  _toggle(isVisible) {
    this.setState({ isVisible })
  }

  _onClickAway(event) {
    event.target !== this._inputRef.current && this._toggle(false)
  }

  toDate(value) {
    return value && (typeof value === 'string' ? moment(value, 'DD.MM.YYYY') : value).toDate();
  }

  render() {
    const {
      value,
      noClear,
      disabled,
      inputProps,
      calendarProps,
    } = this.props;

    
    const startDate = !this.state.selected && this.toDate(calendarProps?.selectsRange ? value?.start : value) || this.state.dates[0];
    const endDate = !this.state.selected && (calendarProps?.selectsRange ? this.toDate(value?.end) : startDate) || this.state.dates[1];

    return (
      <>
        <DatePicker
          popperClassName={this.props.classes.popper}
          calendarClassName={this.props.classes.calendar}
          selected={this.state.dates[0] || startDate || ''}
          onChange={this.onSelect}
          locale="ru"
          {...calendarProps}
          startDate={startDate}
          endDate={endDate}
          customInput={
            <TextInputBase
              disabled={disabled}
              inputRef={this._inputRef}
              aria-describedby={this._id}
              readOnly
              inputProps={{
                endAdornment: !noClear ? (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={value == null}
                      onClick={this._clearInput}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              {...inputProps}
            />
          }
        />
      </>
    )
  }
}

RawCalendarInput.defaultProps = {
  disabled: false,
  noClear: false,
  inputProps: {
    fullWidth: true,
  },
}

RawCalendarInput.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  format: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  calendarProps: PropTypes.object,
  popupProps: PropTypes.object,
  inputProps: PropTypes.object,
  noClear: PropTypes.bool,
}

export const CalendarInput = withStyles(calendarStyle)(RawCalendarInput)
