import React from 'react';
import PropTypes from 'prop-types';

import { API_METHOD } from 'config';

import * as api from '_core/utils/api';

import DadataInput from './DadataInput';


const ADDRESS_INPUT_DEFAULT = 'dadata';


export default class extends React.Component {
  
  static propTypes = {
    variant: PropTypes.string,
    type: PropTypes.string.isRequired
  };
  
  static defaultProps = {
    type: 'address'
  };
  
  static fetchFromAPI = (param) => api.request(API_METHOD.CITY_SUGGEST, param).then((data) => data.list);
  
  static fetchFromAPINative = (param) => this.fetchFromAPI({ ...param, type: 'native' });
  
  
  constructor(props) {
    super(props);
  }
  
  
  render() {
    const { variant, ...props } = this.props;
    
    let AddressInputComponent;
    
    let useVariant = variant || ADDRESS_INPUT_DEFAULT;
    
    switch (useVariant) {
      case 'dadata': AddressInputComponent = DadataInput; break;
      default: return null;
    }
    
    return (
      <AddressInputComponent {...props} />
    );
  }
  
};
