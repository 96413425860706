export const overrides = theme => ({
  root: {
    '&.success': {
      '& .MuiInputBase-root.MuiInput-underline': {
        '&::after, &::before': {
          borderBottomColor: theme.palette.success,
        },
        '&::after': {
          transform: 'scaleX(1)',
        },
      },
      '& .MuiFormHelperText-root,& .MuiInputLabel-root': {
        color: theme.palette.success,
      },
    },
  },
})

export const props = {
  helperText: ' ',
  margin: 'dense',
  fullWidth: true,
}
