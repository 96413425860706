import React, { useState } from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { getValue, setValue } from '_core/utils/storage'
import { CookieNoticeView } from './CookieNoticeView'

export const RawCookieNotice = props => {
  const { render, Component } = props

  const {
    config: { COOKIES_KEY },
  } = useGlobal()

  const [isOpen, setIsOpen] = useState(true)

  if (getValue(COOKIES_KEY.COOKIE))
    return null;

  const onClick = () => {
    setIsOpen(false)

    setTimeout(() => {
      setValue(COOKIES_KEY.COOKIE, 1)
    }, 0)
  }

  if (!isOpen)
    return null;

  const childProps = { onClick }

  return render != null ? render(childProps) : <Component {...childProps} />
}

RawCookieNotice.defaultProps = {
  Component: CookieNoticeView,
}

RawCookieNotice.propTypes = {
  // self props
  render: PropTypes.func,
  Component: PropTypes.elementType,
}

export const CookieNotice = RawCookieNotice
