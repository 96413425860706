import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box, Button, Typography, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Print, CropFree } from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'
import { Menu } from '_core/components/Menu'
import { PriceInput } from '_core/components/PriceInput'
import { ClipboardInput } from '_core/components/ClipboardInput'

// style
import { paymentResultInvoiceStyle } from './paymentResultInvoiceStyle'

const DATA_GROUPS_ORDER = ['company', 'bank', 'agent']

const DATA_FIELDS_ORDER = {
  company: ['name', 'account', 'address', 'inn', 'kpp', /* 'ogrn', */ 'nds'],
  bank: ['name', 'bic', 'swift', 'account', 'address', 'branch', 'office'],
  agent: ['name', 'address', 'swift'],
}

const RawPaymentResultInvoice = props => {
  const { classes, data, getLangString: l } = props
  const { amount, invoice, type, print } = data

  // Preload qr-code image
  new Image().src = invoice.qrcode.link

  const renderFields = group =>
    DATA_FIELDS_ORDER[group].map(field =>
      invoice[group][field] != null ? (
        <ClipboardInput
          key={field}
          value={invoice[group][field]}
          label={l(`r._.finance.invoice.${type}.label.${group}${field}`)}
          helperText=""
        />
      ) : null
    )

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" className={classes.title}>
          {l('r._.finance.invoice.text.title')}
        </Typography>

        <Menu
          tooltip={l('r._.finance.invoice.control.qrcode')}
          content={
            <img src={invoice.qrcode.link} alt="" className={classes.code} />
          }
          buttonBody={<CropFree />}
          buttonProps={{
            size: 'small',
            className: classes.button,
          }}
          paperProps={{
            className: classes.popup,
          }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
        />

        <Tooltip title={l('r._.finance.invoice.control.print')}>
          <Button
            href={print}
            className={classes.button}
            size="small"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Print />
          </Button>
        </Tooltip>
      </Box>

      <div className={classes.group}>
        <ClipboardInput
          render={childProps => <PriceInput {...childProps} />}
          label={l(`r._.finance.invoice.${type}.label.amount`)}
          value={invoice.request.amount}
          currency={amount.currency.id}
          className={classes.valueInput}
          variant="outlined"
          helperText=""
        />
      </div>

      <div className={classes.group}>
        <ClipboardInput
          label={l(`r._.finance.invoice.${type}.label.note`)}
          value={invoice.note}
          multiline={true}
          helperText=""
        />
      </div>

      {DATA_GROUPS_ORDER.map(group =>
        invoice[group] != null && invoice[group].constructor === Object ? (
          <div key={group} className={classes.group}>
            {renderFields(group)}
          </div>
        ) : null
      )}
    </div>
  )
}

RawPaymentResultInvoice.defaultProps = {}

RawPaymentResultInvoice.propTypes = {
  // self props
  data: PropTypes.object.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentResultInvoice = withLang(
  withStyles(paymentResultInvoiceStyle)(RawPaymentResultInvoice)
)
