import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

// core
import { withLang } from '_core/hocs/withLang';
import { getFormattedPriceMix } from '_core/utils/formatPrice';
import { useCartContext } from '_core/contexts/Cart';
import { PriceInput } from '_core/components/PriceInput';
import { TextInput } from '_core/components/TextInput';
import Label from '_core/components/Label/Label';
import Value from '_core/components/Value/Value';

import { PAYMENT_GROUP, PAYMENT_GROUP_TYPE } from 'basic/config';

// style
import { paymentConfirmStyle } from './paymentConfirmStyle';


const methodDataRaw = {
  description: '',
  group: null,
  id: null,
  image: null,
  payment: null,
  title: '',
};


export const RawPaymentConfirm = (props) => {
  const {
    classes,
    method,
    payment,
    isTopup,
    isInvestment,
    isInternalFull,
    isInternalCash,
    getLangString: l,
  } = props

  const cart = useCartContext();

  let methodData = {
    ...methodDataRaw,
    ...payment.external.find(item => `${item.id}` === `${method}`),
  }

  if (isInternalFull && isInternalCash) {
    methodData = {
      ...methodDataRaw,
      ...payment.internal[0],
    }
  }

  const paymentType = PAYMENT_GROUP_TYPE[methodData?.group?.id]

  const hasDisclaimer = [
    PAYMENT_GROUP.GATEWAY,
    PAYMENT_GROUP.CRYPTO,
    PAYMENT_GROUP.INVOICE,
    PAYMENT_GROUP.CARD,
  ].includes(paymentType)

  const renderTitle = title => (
    <Typography variant="h6" className={classes.title}>
      {title}
    </Typography>
  )

  const renderValue = () =>
    Array.isArray(methodData.payment.details)
      ? renderMixedValue()
      : renderBasicValue()

  const renderAmountLabel = () =>
    l(`r.core.finance.topup.label.${isTopup ? 'topupvalue' : 'purchasevalue'}`)

  const renderBasicValue = () => (
    <>
      <PriceInput
        label={renderAmountLabel()}
        value={payment.amount.value}
        currency={payment.amount.currency.id}
        disabled={true}
      />

      <PriceInput
        className={classes.valueInput}
        value={methodData.payment.amount.value}
        label={l('r._.finance.topup.label.paymentvalue')}
        currency={methodData.payment.amount.currency.id}
        variant="outlined"
        readOnly={true}
      />
    </>
  )

  const renderMixedValue = () => {
    const amount = getFormattedPriceMix(
      methodData.payment.details.map(item => ({
        value: item.value,
        currency: cart.findCurrency(item.currency),
      }))
    )

    return (
      <>
        {/* <TextInput value={value} disabled={true} label={renderAmountLabel()} /> */}

        <TextInput
          value={amount}
          className={classes.valueInput}
          label={l('r._.finance.topup.label.paymentvalue')}
          variant="outlined"
          readOnly={true}
        />
      </>
    )
  }

  const renderMethod = () => {
    if (isInternalCash && isInternalFull) {
      return null
    }

    switch (paymentType) {
      case PAYMENT_GROUP.GATEWAY:
        return renderGateway()

      case PAYMENT_GROUP.INVOICE:
      case PAYMENT_GROUP.CRYPTO:
      case PAYMENT_GROUP.CACHE:
        return renderInvoice()

      // TODO
      // case PAYMENT_GROUP.CARD:

      // Payment from internal account as a regular external method
      default:
        return renderAccount()
    }
  }

  const renderInvestment = () => (
    <>
      <Label margin="bottom" textfield>
        {l('r.investicionnyy.paket')}
      </Label>

      <Value color="black" className={classes.value}>
        {payment.document}
      </Value>
    </>
  )

  const renderInvoice = () => (
    <>
      {methodData.image != null ? (
        <img
          className={classes.paymentImage}
          src={methodData.image}
          alt={methodData.title}
        />
      ) : null}

      {renderTitle(methodData.title)}
      {renderValue()}
    </>
  )

  const renderGateway = () => (
    <>
      {methodData.image != null ? (
        <img
          className={classes.paymentImage}
          src={methodData.image}
          alt={methodData.title}
        />
      ) : null}

      {renderTitle(methodData.title)}
      {renderValue()}
    </>
  )

  const renderInternal = () => (
    <>
      {renderTitle(l('r.vnutrennie.sredstva'))}

      <List>
        {payment.internal
          .filter(item => item.balance != null)
          .map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={l(item.title)}
                secondary={`${l('r.budet.spisano')} ${item.amount.format}`}
              />
            </ListItem>
          ))}
      </List>
    </>
  )

  const renderAccount = () => (
    <>
      {renderTitle(methodData.title)}
      {renderValue()}
    </>
  )

  return (
    <>
      {isInternalCash ? renderInternal() : null}

      {renderMethod()}

      {isInvestment ? renderInvestment() : null}

      {hasDisclaimer && !(isInternalCash && isInternalFull) ? (
        <Label size="small">
          * {l('r.popolnenie.licevogo.scheta.proizoydet')}
        </Label>
      ) : null}
    </>
  )
}

RawPaymentConfirm.defaultProps = {}

RawPaymentConfirm.propTypes = {
  // self props
  isTopup: PropTypes.bool,
  isInvestment: PropTypes.bool,
  isInternalFull: PropTypes.bool,
  isInternalCash: PropTypes.bool,
  payment: PropTypes.object.isRequired,
  method: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
  getLangStringSet: PropTypes.func,
  getFirstLangString: PropTypes.func,
}

export const PaymentConfirm = withLang(
  withStyles(paymentConfirmStyle)(RawPaymentConfirm)
)
