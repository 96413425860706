import React, { forwardRef } from 'react'

// core
import { getDisplayName } from '_core/utils/getDisplayName'
import { useRequest } from '_core/hooks/useRequest'

export const withApi = Component => {
  const wrappedComponent = getDisplayName(Component)

  const WithApi = forwardRef((props, ref) => (
    <Component ref={ref} {...useRequest()} {...props} />
  ))

  WithApi.displayName = `WithApi(${wrappedComponent})`

  return WithApi
}
