import React from 'react';
import PropTypes from 'prop-types';
import { geolocated } from 'react-geolocated';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';

import { GoogleMap } from './GoogleMap';
import { YandexMap } from './YandexMap';

const MapContext = React.createContext(null);

const RawMap = (props) => {
  const { variant, type, children, langInfo, getLangString: l, isGeolocationEnabled, coords, ...othereProps } = props;
  
  const {
    config: { MAP_TYPE, MAP_DEFAULT, MAP_AUTO_LANG, MAP_LOCATION_ICON },
  } = useGlobal();
  
  const lang = langInfo?.code || 'en';
  
  let MapComponent = React.useContext(MapContext);
  
  if (type === 'map') {
    if (MapComponent)
      return null;
    
    let useVariant = variant || MAP_DEFAULT;
    
    if (useVariant === MAP_TYPE.AUTO)
      useVariant = Object.keys(MAP_AUTO_LANG).find((type) => type !== 'DEFAULT' && MAP_AUTO_LANG[type].indexOf(lang) >= 0) || MAP_AUTO_LANG.DEFAULT;
    
    switch (useVariant) {
      case MAP_TYPE.GOOGLE: MapComponent = GoogleMap; break;
      case MAP_TYPE.YANDEX: MapComponent = YandexMap; break;
      default: return null;
    }
    
    return (
      <MapContext.Provider value={MapComponent}>
        <MapComponent {...othereProps} type={type} lang={lang}>
          {children}
          
          {isGeolocationEnabled && coords ? (
            <MapComponent
              type="marker"
              lang={lang}
              position={{ lat: coords.latitude, lng: coords.longitude }}
              hint={l('r._.map.location')}
              icon={MAP_LOCATION_ICON}
            />
          ) : null}
        </MapComponent>
      </MapContext.Provider>
    );
  } else {
    if (!MapComponent)
      return null;
    
    return (
      <MapComponent {...othereProps} type={type} lang={lang}>
        {children}
      </MapComponent>
    );
  }
};

RawMap.defaultProps = {
  type: 'map'
};

RawMap.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string.isRequired,
  
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
  
  // geolocated
  isGeolocationEnabled: PropTypes.bool.isRequired,
  coords: PropTypes.object
};

export const Map = geolocated({
  positionOptions: { enableHighAccuracy: true },
  userDecisionTimeout: 5000
})(withLang(RawMap));

const geolocatedComponentDidMount = Map.prototype?.componentDidMount;

if (typeof geolocatedComponentDidMount === 'function') {
  Map.prototype.componentDidMount = function() {
    try {
      geolocatedComponentDidMount.call(this);
    } catch (e) {
    }
  };
}
