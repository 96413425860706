import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'

// @material-ui
import { Typography, Tooltip } from '@material-ui/core'

// core
import { getFormattedPrice } from '_core/utils/formatPrice';
import { useCartContext } from '_core/contexts/Cart';

// style
import { usePriceStyle } from './priceStyle'


export const RawPrice = (props) => {
  const {
    className,
    value,
    format,
    currency,
    rootProps,
    hasTooltip,
    tooltipProps,
    renderTooltip,
    useCustomCurrency,
    postfix,
    simple
  } = props

  const classes = usePriceStyle();
  
  const cart = useCartContext();
  
  const currencyData = cart.findCurrency(currency);

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  const content = getFormattedPrice(value, currencyData, format)

  const tooltip =
    typeof renderTooltip === 'function'
      ? renderTooltip({
          value,
          format,
          currency,
          content,
        })
      : content

  if (simple) {
    return (
      <>
        {useCustomCurrency ? `${value} ${currency}` : content}
        {postfix}
      </>
    );
  }

  return (
    <Tooltip
      title={tooltip}
      open={!hasTooltip ? false : undefined}
      {...tooltipProps}
    >
      <Typography
        noWrap={true}
        display="inline"
        component="span"
        className={rootClasses}
        {...rootProps}
      >
        {useCustomCurrency ? `${value} ${currency}` : content}
        {postfix}
      </Typography>
    </Tooltip>
  )
};

RawPrice.defaultProps = {
  simple: false
}

RawPrice.propTypes = {
  // self props
  className: PropTypes.string,
  format: PropTypes.object,
  hasTooltip: PropTypes.bool,
  rootProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  renderTooltip: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  useCustomCurrency: PropTypes.bool,
  Component: PropTypes.elementType,
  postfix: PropTypes.string,
  simple: PropTypes.bool.isRequired
}

export const Price = RawPrice
