import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

// Uncontrolled example:
//
// <NumberFormat
//   customInput={TextInputBase}
//   value={10500}
//   label={'Label'}
//   helperText={'Helper text'}
//   suffix={`\u00a0$`}
//   {...NUMBER_FORMAT_PROPS}
// />

export const NumberFormatBase = props => {
  const { onChange, inputRef, ...rest } = props

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            id: rest.id,
            name: rest.name,
            value: values.value,
          },
        })
      }}
    />
  )
}

NumberFormatBase.defaultProps = {}

NumberFormatBase.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
