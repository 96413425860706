import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Box, Typography } from '@material-ui/core'

// core
import { withCatch } from '_core/hocs/withCatch'
import { withLang } from '_core/hocs/withLang'

import { TopicList } from '_core/views/MessagesSplash/TopicList'
import { TopicView } from '_core/views/MessagesSplash/TopicView'

import './Messenger.css'

const getCurrentTopic = (topics, topicId) => {
  // если указан идентификатор топика
  if (topicId != null) {
    for (let topic of topics) {
      if (topic.id === topicId) {
        return topic;
      }
    }
  }

  // выбираем первый топик в списке (с сервера они отсортированы по убыванию даты последнего сообщения)
  if (topics.length > 0) {
    return topics[0];
  }

  // если список тем пуст
  return null;
}

const RawMessagerDesktop = (props) => {
  const { topics, topicId, selectedMessageId, getLangString: l } = props;

  const [selectedTopic, setSelectedTopic] = useState(getCurrentTopic(topics, topicId));

  const topicSelected = (topic) => {
    setSelectedTopic(topic);
  }

  return (
    <div>
      <div>
        <Typography variant="h6">
          {l('r.soobscheniya')}
        </Typography>
      </div>
      <div className="messenger">
        <TopicList topics={topics} initialSelection={selectedTopic} onSelect={topicSelected} selectOnLoad={true} />
        {(selectedTopic != null) ? (<TopicView topicId={selectedTopic.id} selectedMessageId={selectedMessageId} />) : (<Box />)}
      </div>
    </div>
  );
}

RawMessagerDesktop.defaultProps = {}

RawMessagerDesktop.propTypes = {
  // self props
  topicId: PropTypes.number,
  selectedMessageId: PropTypes.number,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const MessagerDesktop = withCatch(
  withLang(
    (RawMessagerDesktop)
  )
)
