import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Typography, IconButton, ListItemText } from '@material-ui/core'

import { CURRENCY_FORMAT_CHAR } from 'config';

// core
import { withLang } from '_core/hocs/withLang'
import { useCartContext } from '_core/contexts/Cart';
import { Loading } from '_core/components/Loading'
import { Dropdown } from '_core/components/Dropdown'

// style
import { useCurrencySwitchStyle } from './currencySwitchStyle'


const RawCurrencySwitchView = (props) => {
  const {
    className,
    inMenu,
    tooltip,
    isLoading,
    onChange,
    hasSigns,
    getLangString: l,
  } = props

  const classes = useCurrencySwitchStyle()

  const cart = useCartContext();

  if (cart.currencyList.filter((item) => item.switch_visible).length < 2)
    return null

  const getCurrencySign = (currency) => {
    const sign = currency.format
      .replace(CURRENCY_FORMAT_CHAR, '')
      .trim()
      .toUpperCase()

    const signClasses =
      sign.length > 2
        ? classes.sign3
        : sign.length > 1
        ? classes.sign2
        : classes.sign1

    return (
      <Typography className={signClasses} align="center">
        {sign}
      </Typography>
    )
  };

  const renderItems = () =>
    cart.currencyList
      .filter(currency => currency.switch_visible)
      .map(currency => {
        const primaryProp = hasSigns
          ? { primary: getCurrencySign(currency) }
          : {}

        const secondaryClasses = clsx({
          [classes.optionSecondary]: true,
          [classes.optionSecondarySign]: hasSigns,
        })

        return {
          key: currency.code,
          content: (
            <ListItemText
              classes={{
                root: classes.option,
                primary: classes.optionPrimary,
                secondary: secondaryClasses,
              }}
              secondary={currency.code.toUpperCase()}
              {...primaryProp}
            />
          ),
        }
      });

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  });

  const buttonRootClasses = clsx({
    [classes.button]: true,
    [classes.buttonContrast]: inMenu,
  });

  const renderButton = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      classes={{
        root: buttonRootClasses,
        label: classes.label,
      }}
    >
      {isLoading ? (
        <Loading
          progressProps={{ className: classes.progress }}
          color="secondary"
        />
      ) : (
        getCurrencySign(cart.currency)
      )}
    </IconButton>
  )

  return (
    <Dropdown
      activeKey={cart.currency.code}
      className={rootClasses}
      tooltip={l(tooltip)}
      items={renderItems()}
      onChange={(event, item) => onChange(item.key)}
      renderButton={renderButton}
    />
  )
}

RawCurrencySwitchView.defaultProps = {
  tooltip: 'r.switch.currency',
}

RawCurrencySwitchView.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  className: PropTypes.string,
  hasSigns: PropTypes.bool,
  isLoading: PropTypes.bool,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export const CurrencySwitchView = withLang(RawCurrencySwitchView)
