import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { Loading } from '_core/components/Loading'

// style
import { useThemeSwitchStyle } from './themeSwitchStyle'


const DEFAULT_ICON = 'brightness_low'

const RawThemeSwitchView = (props) => {
  const {
    className,
    inMenu,
    color,
    tooltip,
    isLoading,
    themes,
    themeType,
    onChange,
    getLangString: l,
  } = props

  const classes = useThemeSwitchStyle()

  if (!themeType || !themes || themes.length < 2)
    return null

  const nextIndex = (themes.findIndex((theme) => theme.themeType === themeType) + 1) % themes.length;

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.buttonContrast]: inMenu,
    [className]: className != null,
  })

  const button = (
    <IconButton
      onClick={onChange}
      classes={{
        root: rootClasses,
        label: classes.label,
      }}
    >
      {isLoading ? (
        <Loading
          progressProps={{ className: classes.progress }}
          color={color}
        />
      ) : (themes[nextIndex]?.themeIcon ? (
          <Icon className={classes.icon} color={color}>
            {themes[nextIndex].themeIcon}
          </Icon>
        ) : (
          <>
            <Icon className={classes.icon} color={color}>{DEFAULT_ICON}</Icon>
            <Typography className={classes.index}>{themes[nextIndex]?.title?.substring(0, 1) || nextIndex}</Typography>
          </>
        )
      )}
    </IconButton>
  )

  return tooltip != null ? (
    <Tooltip title={l(tooltip)}>{button}</Tooltip>
  ) : (
    button
  )
}

RawThemeSwitchView.defaultProps = {
  tooltip: 'r.switch.theme',
}

RawThemeSwitchView.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.object),
  themeId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export const ThemeSwitchView = withLang(RawThemeSwitchView)
