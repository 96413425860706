import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import { Typography } from '@material-ui/core';

// core
import { withLang } from '_core/hocs/withLang';
import { PaymentFee } from '_core/components/PaymentFee';
import { PaymentOption } from '_core/components/PaymentOption';

// style
import { useTopupOptionStyle } from './topupOptionStyle';


export const RawTopupOption = (props) => {
  const {
    children,
    childrenAsPrice,
    currency,
    fixedFee,
    percentFee,
    optionProps,
    getLangString: l,
  } = props;

  const classes = useTopupOptionStyle();

  return (
    <PaymentOption {...optionProps}>
      {childrenAsPrice ? (
        <>
          <Typography
            component="span"
            display="block"
            variant="caption"
            className={classes.amount}
          >
            {l('r._.finance.topup.method.text.amount')}
            {': '}
            {children}
          </Typography>
          
          <PaymentFee
            className={classes.fee}
            amount={fixedFee}
            percent={percentFee}
            currency={currency}
            priceProps={{
              rootProps: { variant: 'caption' },
            }}
            rootProps={{
              component: 'span',
              display: 'block',
              variant: 'caption',
            }}
          />
        </>
      ) : children}
    </PaymentOption>
  );
};

RawTopupOption.defaultProps = {
  childrenAsPrice: true
};

RawTopupOption.propTypes = {
  // self props
  payment: PropTypes.object,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childrenAsPrice: PropTypes.bool.isRequired,
  fixedFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentFee: PropTypes.number,
  optionProps: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const TopupOption = withLang(RawTopupOption)
