// @material-ui
import { makeStyles } from '@material-ui/styles'

export const topupOptionStyle = theme => ({
  amount: {
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightBold,
  }
})

export const useTopupOptionStyle = makeStyles(topupOptionStyle)
