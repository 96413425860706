import React from 'react';

import Photo from '_core/components/Photo';

import Tag from './AbstractTag';


Tag.parser.registerTag('img', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  toReact() {
    return (
      <Photo
        role="presentation"
        type={this.params.type}
        src={this.getContent(true)}
        defaultSrc={this.params.default}
        width={this.params.width}
        height={this.params.height}
        alt={this.params.img || ''}
      />
    );
  }
  
});
