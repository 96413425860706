import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Textfit } from 'react-textfit'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { Price } from '_core/components/Price'

// style
import { valueStyle } from './valueStyle'

const Value = props => {
  const {
    classes,
    className,
    children,
    number,
    color,
    size,
    margin,
    autoSize,
    currency,
    ...rest
  } = props

  const valueClasses = classNames({
    [classes.value]: true,
    [classes[color + 'Color']]: color,
    [classes[size + 'Size']]: size,
    [classes[margin + 'Margin']]: margin,
    [className]: className != null,
  })

  const child =
    number != null ? <Price value={children} currency={currency} /> : children

  let maxSize = 34

  if (size != null) {
    if (size === 'small') {
      maxSize = 28
    }
    if (size === 'smaller') {
      maxSize = 20
    }
    if (size === 'text') {
      maxSize = 16
    }
  }

  if (autoSize) {
    return (
      <Textfit {...rest} className={valueClasses} mode="single" max={maxSize}>
        {child}
      </Textfit>
    )
  }

  return (
    <p {...rest} className={valueClasses}>
      {child}
    </p>
  )
}

Value.propTypes = {
  // self props
  number: PropTypes.bool,
  autoSize: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  currency: PropTypes.object,
  children: PropTypes.node.isRequired,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,
}

export default withStyles(valueStyle)(Value)
