import { createReducer } from 'redux-starter-kit'

// Ritos utils
import { spread } from '_core/utils/spread'

// Ritos store
import { langDefaultState, langActionTypes } from './index'

// === Reducers ===

const lang = createReducer(langDefaultState, {
  [langActionTypes.LANG_CHANGE]: (draft, action) => {
    draft.current = action.payload.id
  },

  [langActionTypes.LANG_RESET]: draft => {
    Object.keys(langDefaultState).forEach(key => {
      draft[key] = langDefaultState[key]
    })
  },

  [langActionTypes.LANG_UPDATE_LIST]: (draft, action) => {
    draft.current = action.payload.lang.current
    draft.list == null && (draft.list = {})
    action.payload.lang.list.forEach(item => {
      draft.list[item.id] = item
    })
  },

  [langActionTypes.LANG_UPDATE_STRINGS]: (draft, action) => {
    const id = action.payload.strings.id
    delete action.payload.strings.id

    draft.strings == null && (draft.strings = {})
    draft.strings[id] = spread(draft.strings[id] || {}, action.payload.strings)
  },
})

// === Exports ===

export const langReducers = {
  lang,
}
