import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Switch,
  FormControlLabel,
  Button,
  Grid,
  Box
} from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { TextInput } from '_core/components/TextInput'
import { LoginInput } from '_core/components/LoginInput'
import { PhoneInput } from '_core/components/PhoneInput'
import { PasswordInput } from '_core/components/PasswordInput'
import { CalendarInput } from '_core/components/CalendarInput'
import { FormValidator } from '_core/components/FormValidator'
import AddressInput from '_core/components/AddressInput'
import { AgreementsChecks } from 'basic/views/AgreementsChecks'

// style
import { useGuestStyle } from 'basic/layouts/Guest'
import { GenderInput } from '../../components/GenderInput'
import { subYears } from 'date-fns'

import 'react-dadata/dist/react-dadata.css'

const DATE_DISPLAY_FORMAT = 'D MMMM YYYY'
const MIN_DATE = subYears(new Date(), 90)
const MAX_DATE = subYears(new Date(), 15)

const RawRegisterFormView = props => {
  const {
    parentLogin,
    method,
    fields,
    hiddenFields,
    fieldsOrder,
    fieldsOptions,
    onSubmit,
    setCanSubmit,
    setAllChecked,
    hasParent,
    parentCookie,
    // eslint-disable-next-line react/prop-types
    hasParentName,
    hasParentSwitch,
    onParentToggle,
    submitDisabled,
    getLangString: l,
    langInfo,
  } = props

  const classes = useGuestStyle()
  const ParentInput = hasParentName ? LoginInput : TextInput

  const renderField = (name, viewProps, validatorProps) => {
    switch (name) {
      case 'login2':
      case 'email':
      case 'address':
      case 'lastname':
      case 'firstname':
      case 'patronymic':
	    case 'properties.coowner_mobile':
	    case 'properties.coowner_email':
	    case 'properties.coowner_firstname':
	    case 'properties.coowner_lastname':	    
	    case 'properties.coowner_patronymic':
      case 'mail_index':
        return <TextInput {...viewProps} {...validatorProps} />

      case 'mobile':
        return <PhoneInput {...viewProps} {...validatorProps} />

      case 'gender':
        return <GenderInput {...viewProps} {...validatorProps} />

      case 'login':
        return (
          <TextInput
            {...viewProps}
            {...validatorProps}
            autoComplete="new-password"
          />
        )

      case 'regionaddress':
        return (
          <Box className={classes.regionaddressContainer}>
            <AddressInput
              {...viewProps}
              
              url={AddressInput.fetchFromAPINative}
              name={validatorProps.name}
              label={validatorProps.label}
              
              onSelectValue={(address) => { validatorProps.onChange({ valid: true, value: address.value }); validatorProps.onChangeSave(address); }}
              onChange={(e) => { validatorProps.onChange(e.target.value ? { valid: false, value: e.target.value } : null); validatorProps.onChangeSave(null); }}
              onBlur={validatorProps.onBlur}
              
              error={validatorProps.error}
              checking={validatorProps.checking}
              helperText={validatorProps.helperText}
            />
          </Box>
        )

      case 'passwd':
      case 'passwd2':
        return (
          <PasswordInput
            {...viewProps}
            {...validatorProps}
            autoComplete="new-password"
          />
        )

      case 'parent':
        return hasParent ? (
          <ParentInput
            {...viewProps}
            {...validatorProps}
            disabled={parentLogin ? false : parentCookie != null}
          />
        ) : (
          <ParentInput
            {...viewProps}
            label={validatorProps.label}
            disabled={!parentLogin}
          />
        )

      case 'birth_date':
        return (
          <>
            <CalendarInput
              noClear
              format={DATE_DISPLAY_FORMAT}
              inputProps={{
                label: validatorProps.label,
              }}
              popupProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              calendarProps={{
                locale: langInfo.code,
                dateFormat: 'dd MMMM yyyy',
                peekNextMonth: true,
                showMonthDropdown: true,
                showYearDropdown: true,
                scrollableYearDropdown: true,
                dropdownMode: 'select',
                minDate: MIN_DATE,
                maxDate: MAX_DATE,
              }}
              {...viewProps}
              {...validatorProps}
              onChange={(value) => validatorProps.onChange && validatorProps.onChange(value.format('DD.MM.YYYY'))}
              value={validatorProps.value}
            />
          </>
        )

      default:
        return null
    }
  }

  const renderFields = form =>
    fieldsOrder.reduce((result, name) => {
      if (fields[name] instanceof Object) {
        const { viewProps, gridProps } = fieldsOptions[name]

        result.push(
          <Grid item={true} xs={12} md={6} key={name} {...gridProps}>
            {renderField(name, viewProps, form[name])}
          </Grid>
        )
      }

      return result
    }, [])

  return (
    <form onSubmit={onSubmit}>
      {hasParentSwitch ? (
        <FormControlLabel
          label={l('r._.guest.register.form.parentswitch.label')}
          onChange={onParentToggle}
          control={<Switch />}
          checked={!hasParent}
        />
      ) : null}

      <FormValidator
        method={method}
        fields={fields}
        hiddenFields={hiddenFields}
        setCanSubmit={setCanSubmit}
        render={({ form }) => (
          <Grid container={true}>{renderFields(form)}</Grid>
        )}
      />

      <AgreementsChecks onChange={setAllChecked} />

      <Button
        size="large"
        type="submit"
        fullWidth={true}
        className={classes.marginTop}
        disabled={submitDisabled}
      >
        {l('r._.guest.register.control.submit')}
      </Button>
    </form>
  )
}

RawRegisterFormView.defaultProps = {}

RawRegisterFormView.propTypes = {
  // self props
  hiddenFields: PropTypes.object,
  method: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCanSubmit: PropTypes.func.isRequired,
  setAllChecked: PropTypes.func.isRequired,
  onParentToggle: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  hasParent: PropTypes.bool.isRequired,
  hasParentSwitch: PropTypes.bool.isRequired,
  parentCookie: PropTypes.string,
  fieldsOptions: PropTypes.object,
  fieldsOrder: PropTypes.arrayOf(PropTypes.string),

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const RegisterFormView = withLang(RawRegisterFormView)
