import React from 'react'
import PropTypes from 'prop-types'
import { debounce as _debounce } from 'lodash'
import { getIn } from 'formik'

// core
import { withLang } from '_core/hocs/withLang'
import { createId } from '_core/utils/createId'
import { getErrorCode } from '_core/utils/getErrorCode'
import { Loading } from '_core/components/Loading'

const VALIDATION_DEBOUNCE_DELAY = 250

class RawFieldValidationWrapper extends React.PureComponent {
  constructor(props) {
    super(props)

    this._id = createId()
    this._isValue = null
    this._isMounted = false
    this._isValidating = false

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    this._getHelperText = this._getHelperText.bind(this)
    this._getIsTouched = this._getIsTouched.bind(this)
    this._getError = this._getError.bind(this)
    this._validate = this._validate.bind(this)

    this._debouncedValidate = _debounce(
      this._validate,
      VALIDATION_DEBOUNCE_DELAY
    )
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
    this._debouncedValidate.cancel()
  }

  _getIsTouched() {
    const { form, field } = this.props
    return getIn(form.touched, field.name)
  }

  _getError() {
    const { form, field } = this.props
    return getIn(form.errors, field.name)
  }

  _getDbError() {
    const { form, field } = this.props

    return getIn(form.status.dbErrors, field.name)
  }

  async _validate(value) {
    if (!!this._getError() || this._isValue === value) {
      return
    }

    this._isValue = value
    this._isValidating = true
    this.forceUpdate()

    await this.props.validateWithApi(this.props.form)

    if (!this._isMounted) {
      return
    }

    this._isValidating = false
    this.forceUpdate()
  }

  async handleChange(event) {
    const { needsApiValidation, form, field } = this.props

    form.setFieldTouched(field.name)
    await form.handleChange(event)

    if (!this._isMounted) {
      return
    }

    needsApiValidation && this._debouncedValidate(event.target.value)
  }

  async handleBlur(event) {
    const { needsApiValidation, form } = this.props

    await form.handleBlur(event)

    if (!this._isMounted) {
      return
    }

    needsApiValidation && this._debouncedValidate(event.target.value)
  }

  _getHelperText(error) {
    const {
      field,
      helperText,
      getFirstLangString,
      getLangString: l,
    } = this.props

    // Has error
    if (!!error) {
      const codes = getErrorCode(field.name, error)
      return getFirstLangString(...codes)
    }

    return l(helperText != null ? helperText : ' ')
  }

  render() {
    const {
      // Filter out `withLang` HOC props
      langInfo, // eslint-disable-line no-unused-vars
      getLangObject, // eslint-disable-line no-unused-vars
      getLangString: l, // eslint-disable-line no-unused-vars
      getLangStringSet, // eslint-disable-line no-unused-vars
      getFirstLangString, // eslint-disable-line no-unused-vars

      validateWithApi,
      needsApiValidation,
      component: Component,
      form,
      field,
      disabled,
      helperText,
      label,
      ...rest
    } = this.props

    const dbError = this._getDbError()
    const localError = this._getError()
    const isTouched = this._getIsTouched()
    const message = !!isTouched && (dbError || localError)

    const childProps = {
      id: this._id,
      name: field.name,
      value: field.value,
      disabled: form.isSubmitting || disabled,
      error: !!isTouched && (!!dbError || !!localError),
      success: !!isTouched && !dbError && !localError,
      helperText: this._getHelperText(message),
      label: l(label),
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      setFieldValue: value => {
        form.setFieldValue(field.name, value)
      },
      setFieldTouched: () => {
        form.setFieldTouched(field.name)
      },
      // validateField: () => {
      //   form.validateField(field.name)
      // },
      ...rest,
    }

    return (
      <>
        <Component {...childProps} />
        {!!this._isValidating && <Loading progressType="linear" />}
      </>
    )
  }
}

RawFieldValidationWrapper.defaultProps = {}

RawFieldValidationWrapper.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const FieldValidationWrapper = withLang(RawFieldValidationWrapper)
