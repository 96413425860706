// @material-ui
import { makeStyles } from '@material-ui/styles'

export const countInputStyle = theme => ({
  button: {
    padding: 0,
    color: theme.palette.text.primary,
  },

  icon: {
    fontSize: theme.typography.h5.fontSize,
  },

  formControlRoot: {
    margin: 0,
  },

  textFieldRoot: {
    '&::before, &::after': {
      content: 'none',
    },
  },

  textFieldInput: {
    padding: 0,
    minWidth: 35,
  },
})

export const useCountInputStyle = makeStyles(countInputStyle)
