import { combineReducers } from 'redux'

import {
  publicDataReducers,
  publicDataDefaultState,
} from '_core/store/PublicData'
import {
  privateDataReducers,
  privateDataDefaultState,
} from '_core/store/PrivateData'

// === Default state ===

export const defaultAppState = {
  privateData: privateDataDefaultState,
  publicData: publicDataDefaultState,
}

// === Reducer ===

export const app = combineReducers({
  privateData: privateDataReducers.privateData,
  publicData: publicDataReducers,
})
