import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

// core
import { withLang } from '_core/hocs/withLang'
import { getErrorCode } from '_core/utils/getErrorCode'

export const RawFieldErrorMessage = props => {
  const {
    code,
    label,
    field,
    className,
    alertProps,
    alertTitleProps,
    titleProps,
    contentProps,
    getLangString: l,
    getFirstLangString,
  } = props

  return (
    <Alert severity="error" className={className} {...alertProps}>
      <AlertTitle {...alertTitleProps}>
        <Typography variant="h6" {...titleProps}>
          {l(label != null ? label : field)}
        </Typography>
      </AlertTitle>

      <Typography {...contentProps}>
        {getFirstLangString(...getErrorCode(field, code))}
      </Typography>
    </Alert>
  )
}

RawFieldErrorMessage.defaultProps = {}

RawFieldErrorMessage.propTypes = {
  // self props
  label: PropTypes.string,
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  alertProps: PropTypes.object,
  alertTitleProps: PropTypes.object,
  titleProps: PropTypes.object,
  contentProps: PropTypes.object,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const FieldErrorMessage = withLang(RawFieldErrorMessage)
