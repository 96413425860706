import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { TextField, FormHelperText, InputAdornment } from '@material-ui/core'
import { Icon } from '@material-ui/core'

// core
import { InputProgress } from '_core/components/InputProgress'

export const RawTextInputBase = props => {
  const {
    classes,
    error,
    name,
    success,
    readOnly,
    onBlur,
    onChange,
    startAdornment,
    endAdornment,
    helperProps,
    labelProps,
    inputProps,
    htmlProps,
    checking,
    progressProps,
    renderProgress,
    ProgressComponent,
    ...rest
  } = props

  const renderChecking = () => {
    if (!checking) {
      return null
    }

    return renderProgress != null ? (
      renderProgress(progressProps)
    ) : (
      <ProgressComponent {...progressProps} />
    )
  }

  const HelperTextComponent = childProps => (
    <div>
      {renderChecking()}
      <FormHelperText {...childProps} />
    </div>
  )

  return (
    <TextField
      classes={{ root: success ? 'success' : '' }}
      name={name}
      FormHelperTextProps={{
        component: HelperTextComponent,
        ...helperProps,
      }}
      InputLabelProps={labelProps}
      InputProps={{
        onBlur,
        onChange,
        readOnly,
        startAdornment:
          startAdornment != null ? (
            <InputAdornment position="start">
              <Icon>{startAdornment}</Icon>
            </InputAdornment>
          ) : (
            undefined
          ),
        endAdornment:
          endAdornment != null ? (
            <InputAdornment position="end">
              <Icon>{endAdornment}</Icon>
            </InputAdornment>
          ) : (
            undefined
          ),
        ...inputProps,
      }}
      error={error}
      shrink="true"
      inputProps={htmlProps}
      {...rest}
      // `rest` includes:
      // id={id}
      // type={type}
      // name={name}
      // label={label}
      // helperText={helperText}
      // disabled={disabled}
      // multiline={multiline}
      // rows={rows}
      // rowsMax={rowsMax}
    />
  )
}

RawTextInputBase.defaultProps = {
  name: '',
  autoComplete: 'on',
  ProgressComponent: InputProgress,
}

RawTextInputBase.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  checking: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  helperProps: PropTypes.object,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  progressProps: PropTypes.object,
  renderProgress: PropTypes.func,
  ProgressComponent: PropTypes.elementType,
}

export const TextInputBase = RawTextInputBase
