import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { SnackbarContent, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { DEFAULT_MESSAGE } from 'basic/config'
import { withLang } from '_core/hocs/withLang'
import { Loading } from '_core/components/Loading'

const style = theme => ({
  successMessage: {},
  failureMessage: {},

  marginTop: {
    marginTop: theme.spacing(2),
  },
})

class RawFormBase extends React.PureComponent {
  constructor(props) {
    super(props)

    this.renderTitle = this.renderTitle.bind(this)
    this.renderSuccess = this.renderSuccess.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.renderProgress = this.renderProgress.bind(this)
    this.renderFailure = this.renderFailure.bind(this)
    this.renderResetButton = this.renderResetButton.bind(this)
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
  }

  renderTitle(renderProps) {
    return this.props.title
  }

  renderFields(renderProps) {
    return this.props.renderFields()
  }

  renderSubmitButton(renderProps) {
    const {
      classes,
      canSubmit,
      submitText,
      defaultSubmitText,
      submitButtonProps,
      getLangString: l,
    } = this.props
    const disabled = !canSubmit(renderProps)

    return (
      <Button
        type="submit"
        className={classes.marginTop}
        disabled={disabled}
        {...submitButtonProps}
      >
        {submitText != null ? submitText : l(defaultSubmitText)}
      </Button>
    )
  }

  renderResetButton(renderProps) {
    const { resetButton } = this.props

    return resetButton != null ? resetButton : null
  }

  renderProgress(renderProps) {
    const { progress } = this.props

    return renderProps.isSubmitting ? progress : null
  }

  renderFailure(renderProps) {
    const { classes, renderResult, submitError, getLangString: l } = this.props

    const message =
      submitError == null ||
      submitError.constructor !== Object ||
      submitError.code === 'internal-error'
        ? l(DEFAULT_MESSAGE.ERROR)
        : l(submitError.message)

    return renderResult && submitError != null ? (
      <SnackbarContent
        message={message}
        classes={{ root: classes.failureMessage }}
      />
    ) : null
  }

  renderSuccess(renderProps) {
    const {
      classes,
      renderResult,
      successText,
      submitSuccess,
      defaultSuccessText,
      getLangString: l,
    } = this.props

    return renderResult && submitSuccess ? (
      <SnackbarContent
        message={successText != null ? successText : l(defaultSuccessText)}
        classes={{ root: classes.successMessage }}
      />
    ) : null
  }

  render() {
    const { render, ...rest } = this.props

    return render({
      ...rest,
      renderTitle: this.renderTitle,
      renderFields: this.renderFields,
      renderSuccess: this.renderSuccess,
      renderFailure: this.renderFailure,
      renderProgress: this.renderProgress,
      renderResetButton: this.renderResetButton,
      renderSubmitButton: this.renderSubmitButton,
    })
  }
}

RawFormBase.defaultProps = {
  progress: <Loading progressType="linear" />,
  defaultSubmitText: 'r.otpravit',
  defaultSuccessText: 'r.dannye.uspeshno.otpravleny',
  renderResult: false,
}

RawFormBase.propTypes = {
  // self props
  submitError: PropTypes.object,
  submitSuccess: PropTypes.bool,
  renderResult: PropTypes.bool,
  render: PropTypes.func.isRequired,
  resetButton: PropTypes.node,
  progress: PropTypes.node,
  submitText: PropTypes.node,
  successText: PropTypes.node,
  defaultSuccessText: PropTypes.node,
  title: PropTypes.node,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const FormBase = withLang(withStyles(style)(RawFormBase))
