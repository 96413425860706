import React from 'react';
import PropTypes from 'prop-types';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { GoodsListItemCart } from '_core/components/GoodsListItemCart';
import { GoodsListItemShop } from '_core/components/GoodsListItemShop';
import { GoodsListItemModal } from '_core/components/GoodsListItemModal';


const RawGoodsListItem = (props) => {
  const { data, type } = props;

  const sale = !data.isSkeleton ;//&& data.amount.value < data.price.value;

  const {
    config: { ROUTE_URL }
  } = useGlobal();

  const defaultGoodsProps = {
    data,
    sale,
    goodsUrl: `${ROUTE_URL.PRODUCT}`,
  };

  return type === 'cart' ? (
    <GoodsListItemCart {...defaultGoodsProps} />
  ) : type === 'modal' ? (
    <GoodsListItemModal {...defaultGoodsProps} />
  ) : (
    <GoodsListItemShop {...defaultGoodsProps} />
  );
};

RawGoodsListItem.defaultProps = {
  type: 'list'
};

RawGoodsListItem.propTypes = {
  // self props
  type: PropTypes.oneOf(['list', 'cart', 'modal']),
  data: PropTypes.object.isRequired
};

export const GoodsListItem = RawGoodsListItem;
