import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';

import moment from 'moment';
import { connect } from 'react-redux';

// core
//import { makeFetch } from '_core/utils/makeFetch';
import { momentLocales } from '_core/utils/momentLocales';
import { findInObject } from '_core/utils/findInObject';
import { encodeKey, getValue, setValue } from '_core/utils/storage';
import * as api from '_core/utils/api';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { withLang } from '_core/hocs/withLang';
import { withContext } from '_core/hocs/withContext';
import { GlobalContext } from '_core/hooks/useGlobal';
import { privateDataActions } from '_core/store/PrivateData';
import { langActions } from '_core/store/Lang';
import { countryActions } from '_core/store/Country';
import { getTheme } from '_core/themes/getTheme';
import AddressInput from '_core/components/AddressInputNew';

import { RootView } from './RootView';


const mapDispatch = {
  resetPrivateData: privateDataActions.resetPrivateData,
  updateLangList: langActions.updateLangList,
  updateLangStrings: langActions.updateLangStrings,
  updateCountry: countryActions.updateCountry,
};


class RawRoot extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGuestReady: false,
      isPrivateReady: false,
    }

    this._isMounted = false;
    this._rolesTimeout = null;

    this._isReady = this._isReady.bind(this)
    this._getRoles = this._getRoles.bind(this)
    this._publicInit = this._publicInit.bind(this)
    this._privateInit = this._privateInit.bind(this)
    this._onLangUpdate = this._onLangUpdate.bind(this)
    this._onRolesUpdate = this._onRolesUpdate.bind(this)
  }

  notifyListener = (event) => {
    const {
      config: { API_NOTICE }
    } = this.props;
    
    if (event.detail.name === API_NOTICE.VISITOR_STATUS || event.detail.name === API_NOTICE.VISITOR_LOGOUT) {
      this._clearRolesTimeout();
      this._getRoles(false);
    }
  };

  async componentDidMount() {
    const {
      config: { API_NOTICE }
    } = this.props;
    
    this._isMounted = true;

    this._getRoles(true);
    this._publicInit();
    
    window.addEventListener('notify', this.notifyListener);
  }

  componentWillUnmount() {
    window.removeEventListener('notify', this.notifyListener);
    
    this._clearRolesTimeout();
    
    this._isMounted = false
  }

  componentDidUpdate(...args) {
    // Handle lang change
    this._onLangUpdate(...args)

    // Handle auth
    this._onRolesUpdate(...args)
  }

  async _onLangUpdate(prevProps) {
    const {
      langInfo,
      config: { DEFAULT_MOMENT_LOCALE },
    } = this.props

    // Set correct `moment` locale on startup
    if (prevProps.langInfo == null && langInfo != null) {
      const code = langInfo.code.toLowerCase()
      momentLocales.includes(code) && (await import(`moment/locale/${code}`))
      moment.locale([code, DEFAULT_MOMENT_LOCALE])
    }
  }

  _onRolesUpdate(prevProps) {
    const {
      visitorInfo,
      resetPrivateData,
    } = this.props

    this._setRolesTimeout();

    if (prevProps.visitorInfo.update !== visitorInfo.update) {
      resetPrivateData();
      
      this._getRoles(true);
    } else if (!prevProps.visitorInfo.isAuthorized() && visitorInfo.isAuthorized()) {
      // Handle auth data update
      
      this._privateInit();
    } else  if (prevProps.visitorInfo.isAuthorized() && !visitorInfo.isAuthorized()) {
      // Handle auth data reset
      
      resetPrivateData();
      
      this._getRoles(true);
    }
  }
  
  _setRolesTimeout() {
    this._clearRolesTimeout();
    
    const {
      request,
      config: { VISITOR_UPDATE_INTERVAL },
    } = this.props;
    
    this._rolesTimeout = setTimeout(() => {
      const {
        visitorInfo
      } = this.props
      
      if (!visitorInfo.isAuthorized())
        return;
      
      this._setRolesTimeout();
      
      if (request.isNoticeActive)
        this._getRoles(false);
    }, VISITOR_UPDATE_INTERVAL);
  }
  
  _clearRolesTimeout() {
    if (this._rolesTimeout)
      clearTimeout(this._rolesTimeout);
    
    this._rolesTimeout = null;
  }

  _getRoles(loading) {
    const {
      config: { API_METHOD, STORAGE_KEY },
      visitorInfo
    } = this.props;

    loading && this.setState({ visitorLoading: true });

    api.request(API_METHOD.VISITOR_INFO, {})
      .then((result) => {
        visitorInfo.set(result);
        
        if (getValue(STORAGE_KEY.UNLOCK))
          setValue(STORAGE_KEY.UNLOCK, { login: result.login, firstname: result.firstname });
      })
      .catch(() => null)
      .finally(() => loading && this.setState({ visitorLoading: false }));
  }


  async _publicInit() {
    const {
      updateLangList,
      updateLangStrings,
      i18n: { guest },
      config: { API_METHOD },
    } = this.props

    let isGuestReady = true

    await Promise.all([
      api.request(API_METHOD.LANG_LIST, {})
        .then(updateLangList)
        .catch((error) => isGuestReady = false),
      api.request(API_METHOD.LANG_STRINGS, { list: guest })
        .then(updateLangStrings)
        .catch((error) => isGuestReady = false)
    ]);

    this.forceUpdate()

    if (!this._isMounted) {
      return
    }

    this.setState({ isGuestReady })
  }

  async _privateInit() {
    const {
      updateLangStrings,
      i18n: { client },
      config: { API_METHOD }
    } = this.props

    let isPrivateReady = true

    await api.request(API_METHOD.LANG_STRINGS, { list: client })
      .then(updateLangStrings)
      .catch((error) => isPrivateReady = false);

    this.forceUpdate()

    if (!this._isMounted) {
      return
    }

    await this.setState({ isPrivateReady })
  }

  _isReady() {
    const { visitorInfo } = this.props;
    
    const { isGuestReady, isPrivateReady } = this.state;
    
    if (this.state.visitorLoading)
      return false;
    
    const isAuthorized = visitorInfo.isAuthorized();
    
    return !isAuthorized && isGuestReady
      || isAuthorized && isPrivateReady;
  }

  render() {
    const { visitorInfo } = this.props;

    const viewProps = {
      isGuest: !visitorInfo.isAuthorized(),
      isReady: this._isReady()
    };

    return (
      <AddressInput.Provider>
        <RootView {...viewProps} />
      </AddressInput.Provider>
    );
  }
};

RawRoot.defaultProps = {};

RawRoot.propTypes = {
  // `redux-connect` HOC props
  resetPrivateData: PropTypes.func.isRequired,
  updateLangList: PropTypes.func.isRequired,
  updateLangStrings: PropTypes.func.isRequired,
  updateCountry: PropTypes.func.isRequired,

  // `withCookies` HOC props
  cookies: PropTypes.instanceOf(Cookies),

  // `GlobalContext` props
  request: PropTypes.object.isRequired,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func,
};

export const Root = withContext(GlobalContext)(
  withVisitorInfo(withLang(withCookies(connect((state) => ({}), mapDispatch)(RawRoot))))
);
