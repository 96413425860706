import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// @material-ui
import {
  Box,
  AppBar,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Menu } from '@material-ui/icons'

// core
import { withLang } from '_core/hocs/withLang'
import { HeaderControls } from '_core/components/HeaderControls'
import { ElevationScroll } from '_core/components/ElevationScroll'

// style
import { headerStyle } from './headerStyle'

export const RawHeader = props => {
  const {
    classes,
    className,
    isRtl,
    isMini,
    title,
    titleProps,
    appBarProps,
    toolBarProps,
    elevationProps,
    toggleDrawer,
    controlsRender,
    ControlsComponent,

    // filter out `withLang` HOC props
    langInfo, // eslint-disable-line no-unused-vars
    getLangObject, // eslint-disable-line no-unused-vars
    getFirstLangString, // eslint-disable-line no-unused-vars
    getLangStringSet, // eslint-disable-line no-unused-vars
    getLangString: l, // eslint-disable-line no-unused-vars

    ...rest
  } = props

  const controlsProps = {}

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.mini]: isMini,
    [className]: className != null,
  })

  if (elevationProps.target == null) {
    delete elevationProps.target
  }

  return (
    <ElevationScroll {...elevationProps}>
      <AppBar
        position="fixed"
        classes={{ root: appBarClasses }}
        {...appBarProps}
      >
        <div className={classes.wrapper}>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            className={classes.title}
          >
            <Hidden mdUp implementation="css" className={classes.menuIcon}>
              <Tooltip title={l('r.menu')}>
                <IconButton onClick={toggleDrawer}>
                  <Menu />
                </IconButton>
              </Tooltip>
            </Hidden>

            <Typography {...titleProps} noWrap>
              {l(title)}
            </Typography>
          </Box>

          {controlsRender != null ? (
            controlsRender(controlsProps)
          ) : (
            <ControlsComponent {...rest} {...controlsProps} />
          )}
        </div>
      </AppBar>
    </ElevationScroll>
  )
}

RawHeader.defaultProps = {
  isRtl: false,
  title: process.env.REACT_APP_NAME,
  elevationProps: {
    threshold: 0,
    disableHysteresis: true,
  },
  titleProps: {
    variant: 'h6',
    component: 'h2',
  },
  ControlsComponent: HeaderControls,
}

RawHeader.propTypes = {
  // self props
  isRtl: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  titleProps: PropTypes.object,
  appBarProps: PropTypes.object,
  toolBarProps: PropTypes.object,
  elevationProps: PropTypes.object,
  toggleDrawer: PropTypes.func,
  controlsRender: PropTypes.func,
  ControlsComponent: PropTypes.elementType,
  className: PropTypes.string,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Header = withLang(withStyles(headerStyle)(RawHeader))
