import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'

// core
import { API_UPLOAD_URI } from 'basic/config'
import { withCatch } from '_core/hocs/withCatch'
import { GlobalContext } from '_core/hooks/useGlobal'
import { makeFetch } from '_core/utils/makeFetch'
import { FileUploadView } from './FileUploadView'

class RawFileUpload extends PureComponent {
  static contextType = GlobalContext;
  
  constructor(props) {
    super(props)

    this.state = {
      files: null,
      tooBigFiles: [],
      tooManyFiles: [],
    }

    this._isMounted = false

    this.onRemoveFile = this.onRemoveFile.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onTooBigFilesMessageClose = this.onTooBigFilesMessageClose.bind(this)
    this.onTooManyFilesMessageClose = this.onTooManyFilesMessageClose.bind(this)

    this._sendFile = this._sendFile.bind(this)
    this._checkTooManyFiles = this._checkTooManyFiles.bind(this)
    this._checkTooBigFiles = this._checkTooBigFiles.bind(this)
    this._onInfoChange = this._onInfoChange.bind(this)
    this._onResultChange = this._onResultChange.bind(this)
    this._showFilePreview = this._showFilePreview.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async onInputChange(files) {
    const { multiple } = this.props

    const newFiles = multiple
      ? this._checkTooManyFiles(this._checkTooBigFiles(files))
      : this._checkTooBigFiles(files)

    // Do nothing if there is nothing to load
    if (newFiles.length === 0) {
      return
    }

    await this.setState(
      produce(draft => {
        if (draft.files == null || !multiple) {
          draft.files = {}
        }

        newFiles.forEach(file => {
          draft.files[file.name] = {
            data: file,
          }
        })
      })
    )

    if (!this._isMounted) {
      return
    }

    this._onInfoChange()

    await Promise.all(
      newFiles.map(file => {
        this._showFilePreview(file)
        return this._sendFile(file).then(result => {
          if (!this._isMounted) {
            return
          }

          return this.setState(
            produce(draft => {
              draft.files[file.name].result = result[0]
            })
          )
        })
      })
    )

    if (!this._isMounted) {
      return
    }

    this._onResultChange()
  }

  // TODO: remove files from server
  async onRemoveFile(name) {
    await this.setState(
      produce(draft => {
        if (Object.keys(draft.files).length > 1) {
          delete draft.files[name]
        } else {
          draft.files = null
        }
      })
    )

    this._onInfoChange()
    this._onResultChange()
  }

  onTooBigFilesMessageClose() {
    this.setState({ tooBigFiles: [] })
  }

  onTooManyFilesMessageClose() {
    this.setState({ tooManyFiles: [] })
  }

  _onInfoChange() {
    const { files } = this.state
    const { onInfoChange } = this.props

    const result =
      files != null ? Object.keys(files).map(name => files[name].data) : null

    typeof onInfoChange === 'function' && onInfoChange(result)
  }

  _onResultChange() {
    const { files } = this.state
    const { onResultChange } = this.props

    const result =
      files != null ? Object.keys(files).map(name => files[name].result) : null

    typeof onResultChange === 'function' && onResultChange(result)
  }

  _checkTooBigFiles(newFiles) {
    const { config: { FILE_UPLOAD_MAX_SIZE } } = this.context;

    let { maxSize } = this.props;

    if (!maxSize)
      maxSize = FILE_UPLOAD_MAX_SIZE;

    const tooBigFiles = [];
    
    // Filter new files which size exceed maxSize
    newFiles = newFiles.reduce((result, file) => {
      const arr = file.size > maxSize ? tooBigFiles : result
      arr.push(file)

      return result
    }, [])

    // Render or hide too big files message
    this.setState({ tooBigFiles })

    return newFiles
  }

  _checkTooManyFiles(newFiles) {
    const { maxFiles } = this.props
    const { files } = this.state

    // Count unique files among old and new ones
    const oldCount = files != null ? Object.keys(files).length : 0
    const uniqueFiles = files != null ? [] : newFiles
    const overlapingFiles = []

    if (files != null) {
      newFiles.forEach(file => {
        files[file.name] == null
          ? uniqueFiles.push(file)
          : overlapingFiles.push(file)
      })
    }

    // If total number of files exceeds maxFiles
    // remove exceeding files and show message
    if (oldCount + uniqueFiles.length > maxFiles) {
      const index = maxFiles - oldCount > 0 ? maxFiles - oldCount : 0

      // Render or hide too many files message
      this.setState({
        tooManyFiles: uniqueFiles.splice(index),
      })

      return [...overlapingFiles, ...uniqueFiles]
    }

    this.setState({ tooManyFiles: [] })
    return newFiles
  }

  _showFilePreview(file) {
    let reader = new FileReader()

    reader.onloadend = () => {
      if (!this._isMounted) {
        return
      }

      this.setState(
        produce(draft => {
          draft.files[file.name].preview = reader.result
        })
      )
    }

    reader.readAsDataURL(file)
  }

  async _sendFile(file) {
    const { category } = this.props

    try {
      const result = await makeFetch({
        data: {
          file,
          category,
        },
        url: API_UPLOAD_URI,
        preset: 'FILE',
      })

      return result.list
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error)
      // TODO: show error message for each file
      return error
    }
  }

  render() {
    const { config: { FILE_UPLOAD_MAX_SIZE } } = this.context;

    let { maxSize } = this.props;

    if (!maxSize)
      maxSize = FILE_UPLOAD_MAX_SIZE;

    const { files, tooBigFiles, tooManyFiles } = this.state;

    return (
      <FileUploadView
        files={files}
        maxSize={maxSize}
        tooBigFiles={tooBigFiles}
        tooManyFiles={tooManyFiles}
        onRemoveFile={this.onRemoveFile}
        onInputChange={this.onInputChange}
        onTooBigFilesMessageClose={this.onTooBigFilesMessageClose}
        onTooManyFilesMessageClose={this.onTooManyFilesMessageClose}
        {...this.props}
      />
    )
  }
}

RawFileUpload.defaultProps = {
  title: 'r._.fileupload.title',
  maxSizeText: 'r._.fileupload.text.maxsize',
  maxFilesText: 'r._.fileupload.text.maxfiles',
  removeFileText: 'r._.fileupload.action.remove',
  hideMessageText: 'r._.fileupload.action.hide',
  maxFiles: 5,
  category: '',
  multiple: true,
  hasHelperText: true,
  hasMaxSizeMessage: true,
  hasMaxFilesMessage: true,
}

RawFileUpload.propTypes = {
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  category: PropTypes.string,
  onInfoChange: PropTypes.func,
  onResultChange: PropTypes.func,
}

export const FileUpload = withCatch(RawFileUpload)
