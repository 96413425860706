import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Card } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { LangSwitch } from '_core/components/LangSwitch'
import { ThemeSwitch } from '_core/components/ThemeSwitch'
import { CookieNotice } from '_core/components/CookieNotice'
import { useGlobal } from '_core/hooks/useGlobal'

// style
import { guestStyle } from './guestStyle'



export const RawGuest = props => {
  const { theme, classes, render } = props
  const [layoutProps, setLayoutProps] = useState({})
  const { menuId /*, title */ } = layoutProps
  
  const {
    config: { HAS_COOKIE, REACT_APP_INDEX_URL },
  } = useGlobal()
  
  
  useEffect(() => {
    document.body.style.overflow = 'unset'
  }, [])

  const hasStyles = menuId != null && theme.pages[menuId] != null
  const hasColor = hasStyles && theme.pages[menuId].bgColor != null
  const hasVideo = hasStyles && theme.pages[menuId].bgVideo != null
  const hasImage = hasStyles && theme.pages[menuId].bgImage != null
  
  const bgClasses = clsx({
    [classes.fullPage]: true,
    [classes.shadeOn]: hasStyles && theme.pages[menuId].hasShade,
  })

  const bgStyle = hasImage
    ? {
        backgroundColor: theme.pages[menuId].bgColor,
        backgroundPositionX: theme.pages[menuId].bgPositionX,
        backgroundPositionY: theme.pages[menuId].bgPositionY,
        backgroundRepeat: theme.pages[menuId].bgRepeat,
        backgroundSize: theme.pages[menuId].bgSize,
        backgroundImage: `url(${theme.utils.getPath(
          theme.pages[menuId].bgImage
        )})`,
      }
    : hasColor 
    ? {
        backgroundColor: theme.pages[menuId].bgColor,
      }
    : undefined

  return (
    <div className={classes.wrapper}>
      <div className={bgClasses} style={bgStyle}>
        {HAS_COOKIE ? (
          <CookieNotice />
         ) : null}

        {hasVideo ? (
          <div className={classes.videoContainer}>
            <video autoPlay loop muted width="100%" preload="true">
              <source
                src={theme.utils.getPath(theme.pages[menuId].bgVideo)}
                type="video/mp4"
              />
            </video>
          </div>
        ) : null}

        <Card className={classes.settings}>
          <LangSwitch />
          <ThemeSwitch />
        </Card>

        <a
          className={classes.logoBigLink}
          href={REACT_APP_INDEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classes.logoBig}
            src={
              hasStyles
                ? theme.utils.getPath(theme.pages[menuId].logo)
                : undefined
            }
            alt={process.env.REACT_APP_NAME}
          />
        </a>

        {render({ setLayoutProps })}
      </div>
    </div>
  )
}

RawGuest.propTypes = {
  // self props
  render: PropTypes.func,

  // `withStyles` HOC props
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export const Guest = withStyles(guestStyle, { withTheme: true })(RawGuest)
