import { LANG_TEMPLATE_VAR } from 'config'

const escapeText = text => text.replace(/[.+*?^$|()[\]\\]/g, '\\$&')
const escapeRegexp = new RegExp(escapeText(LANG_TEMPLATE_VAR), 'gm')

export const customCallback = (template, defaultValue) => value => {
  if (process.env.NODE_ENV !== 'production' || typeof template !== 'string') {
    return
  }

  try {
    const callback = new Function(
      template.replace(escapeRegexp, value ?? defaultValue)
    )

    callback()
  } catch (error) {}
}
