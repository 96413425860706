import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Grid,
  Paper,
  Typography,
  Portal,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'

const style = theme => ({
  root: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.snackbar,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm,
  },
})

export const RawCookieNoticeView = props => {
  const { classes, onClick, getLangString: l } = props

  return (
    <Portal>
      <Paper elevation={2} className={classes.root}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={12} sm={8}>
            <Typography component="p">
              {l('r.ostavayas.na.etom.sayte')}
            </Typography>
          </Grid>

          <Grid item={true} xs={12} sm={4}>
            <Button onClick={onClick}>{l('r.soglasen')}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Portal>
  )
}

RawCookieNoticeView.defaultProps = {}

RawCookieNoticeView.propTypes = {
  // self props
  onClick: PropTypes.func,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const CookieNoticeView = withLang(withStyles(style)(RawCookieNoticeView))
