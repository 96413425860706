import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import { Icon, Typography } from '@material-ui/core'

// core
import { Flex } from '_core/components/Flex'

// style
import grayLinkStyle from './grayLinkStyle'

const GrayLink = props => {
  const {
    classes,
    className,
    icon,
    title,
    menuColor,
    tag,
    tagProps,
    ...rest
  } = props

  const linkClasses = classNames({
    [classes.container]: true,
    [classes.menuColor]: menuColor,
    [className]: className != null,
  })

  return title != null ? (
    <Flex alignItems="center" className={linkClasses} {...rest}>
      {icon != null ? <Icon className={classes.icon}>{icon}</Icon> : null}
      {title != null ? (
        <props.tag className={classes.title} {...tagProps}>
          <Typography variant="button">{title}</Typography>
        </props.tag>
      ) : null}
    </Flex>
  ) : null
}

GrayLink.defaultProps = {
  tag: 'p',
}

GrayLink.propTypes = {
  tag: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.node.isRequired,
}

export default withStyles(grayLinkStyle)(GrayLink)
