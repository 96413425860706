import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Button, Typography, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import Circular from '@material-ui/core/CircularProgress/CircularProgress'

// core
import { DEFAULT_MESSAGE } from 'basic/config'
import { withLang } from '_core/hocs/withLang'

const style = theme => ({
  circularContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  linearContainer: {
    height: theme.spacing(0.25),
  },

  error: {
    marginTop: theme.spacing(2),
  },

  errorMessage: {
    marginRight: theme.spacing(2),
  },

  button: {
    marginLeft: theme.spacing(2),
  },
})

const RawLoading = props => {
  const {
    classes,
    className,
    progressType,
    progressProps,
    // buttonProps,

    // `withLang` props
    langInfo,
    getLangString: l,

    // `react-loadable` props
    pastDelay,
    timedOut,
    retry,
    error,
  } = props

  const _getRetryButtonText = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.RETRY_RAW
      : l(DEFAULT_MESSAGE.RETRY)
  }

  const _getReloadButtonText = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.RELOAD_RAW
      : l(DEFAULT_MESSAGE.RELOAD)
  }

  const _getOrReloadButtonText = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.OR_RELOAD_RAW
      : l(DEFAULT_MESSAGE.OR_RELOAD)
  }

  const _getTimeoutMessage = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.TIMEOUT_RAW
      : l(DEFAULT_MESSAGE.TIMEOUT)
  }

  const _getErrorMessage = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.ERROR_RAW
      : l(DEFAULT_MESSAGE.ERROR)
  }

  const _getOutdatedMessage = () => {
    return langInfo == null
      ? DEFAULT_MESSAGE.OUTDATED_RAW
      : l(DEFAULT_MESSAGE.OUTDATED)
  }

  if (timedOut) {
    return (
      <>
        <div className={classes.circularContainer}>
          <Circular {...progressProps} />
        </div>

        <Typography align="center">
          {_getTimeoutMessage()}

          <Button onClick={retry} block={false} className={classes.button}>
            {_getRetryButtonText()}
          </Button>
        </Typography>
      </>
    )
  }

  if (error != null) {
    process.env.NODE_ENV !== 'production' && console.error(error)

    if (error.name === 'ChunkLoadError') {
      return (
        <Alert
          severity="error"
          action={
            <Button
              size="small"
              block={false}
              onClick={RawLoading.defaultProps.retry}
              className={classes.button}
            >
              {_getReloadButtonText()}
            </Button>
          }
        >
          {_getOutdatedMessage()}
        </Alert>
      )
    }

    return (
      <Alert
        severity="error"
        action={
          <>
            <Button onClick={retry} block={false} className={classes.button}>
              {_getRetryButtonText()}
            </Button>

            <Button
              onClick={RawLoading.defaultProps.retry}
              block={false}
              className={classes.button}
            >
              {_getOrReloadButtonText()}
            </Button>
          </>
        }
      >
        {_getErrorMessage()}
      </Alert>
    )
  }

  if (pastDelay) {
    if (progressType === 'circular') {
      const rootClasses = clsx({
        [classes.circularContainer]: true,
        [className]: className != null,
      })

      return (
        <div className={rootClasses}>
          <Circular {...progressProps} />
        </div>
      )
    }

    if (progressType === 'linear') {
      const rootClasses = clsx({
        [classes.linearContainer]: true,
        [className]: className != null,
      })

      return (
        <div className={rootClasses}>
          <LinearProgress {...progressProps} />
        </div>
      )
    }
  }

  return null
}

RawLoading.defaultProps = {
  retry: () => window.location.reload(true),
  error: null,
  timedOut: false,
  pastDelay: true,
  progressType: 'circular',
  // buttonProps: {
  //   size: 'sm',
  //   color: 'info',
  // },
}

RawLoading.propTypes = {
  // self props
  className: PropTypes.string,
  error: PropTypes.object,
  retry: PropTypes.func,
  buttonProps: PropTypes.object,
  progressProps: PropTypes.object,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
  progressType: PropTypes.oneOf(['circular', 'linear']),

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Loading = withLang(withStyles(style)(RawLoading))
