import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import {
  MenuItem,
  TextField,
  //ListSubheader,
  InputAdornment,
  FormHelperText,
  Icon,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'
import { InputProgress } from '_core/components/InputProgress'

// style
import { selectInputBaseStyle } from './selectInputBaseStyle'

const RawSelectInputBase = props => {
  const {
    value,
    classes,
    error,
    success,
    onOpen,
    isOpen,
    onClose,
    onChange,
    options,
    multiple,
    readOnly,
    checking,
    startAdornment,
    endAdornment,
    helperProps,
    labelProps,
    selectProps,
    inputProps,
    menuProps,
    optionProps,
    categoryProps,
    renderOptions,
    defaultValue,
    defaultTitle,
    defaultChildren,
    progressProps,
    renderProgress,
    ProgressComponent,

    // Filter out   `withLdnang` HOC props
    langInfo, // eslint-disable-line no-unused-vars
    langStrings, // eslint-disable-line no-unused-vars
    getLangObject, // eslint-disable-line no-unused-vars
    getLangStringSet, // eslint-disable-line no-unused-vars
    getFirstLangString, // eslint-disable-line no-unused-vars
    getLangString: l,

    ...rest
  } = props

  const inputRootClasses = clsx({
    [classes.input]: true,
    [classes.success]: success,
    [classes.error]: !!error,
    [classes.open]: isOpen,
  })

  const helperTextRootClasses = clsx({
    [classes.helperText]: true,
    [classes.success]: success,
    [classes.error]: !!error,
  })

  const labelRootClasses = clsx({
    [classes.label]: true,
    [classes.success]: success,
    [classes.error]: !!error,
  })

  const renderChecking = () => {
    if (!checking) {
      return null
    }

    return renderProgress != null ? (
      renderProgress(progressProps)
    ) : (
      <ProgressComponent {...progressProps} />
    )
  }

  const HelperTextComponent = childProps => (
    <div>
      {renderChecking()}
      <FormHelperText {...childProps} />
    </div>
  )

  const defaultRenderOptions = () => {
    const realOptions = []

    if (defaultValue != null) {
      realOptions.push({
        id: defaultValue,
        children:
          defaultChildren != null ? (
            defaultChildren
          ) : (
            <div className={classes.optionDefault}>{l(defaultTitle)}</div>
          ),
      })
    }

    realOptions.push(...options)

    return realOptions.map((option, index) => {
      const { id, title, children, isCategory, selected, ...other } = option

      if (isCategory) {
        return (
          <MenuItem
            key={index}
            className={classes.optionGroup}
            disabled={true}
            {...categoryProps}
            {...other}
          >
            {children != null ? children : l(title)}
          </MenuItem>
        )
      }

      return (
        <MenuItem
          key={id}
          value={`${id}`}
          classes={{
            root: classes.option,
            selected: classes.optionSelected,
          }}
          selected={selected}
          {...optionProps}
          {...other}
        >
          {children != null ? children : l(title)}
        </MenuItem>
      )
    })
  }

  return (
    <TextField
      select={true}
      FormHelperTextProps={{
        classes: { root: helperTextRootClasses },
        component: HelperTextComponent,
        ...helperProps,
      }}
      InputLabelProps={{
        classes: { root: labelRootClasses },
        ...labelProps,
      }}
      SelectProps={{
        multiple,
        onOpen,
        onClose,
        onChange,
        open: isOpen,
        MenuProps: menuProps,
        ...selectProps,
      }}
      InputProps={{
        classes: { root: inputRootClasses },
        readOnly,
        startAdornment:
          startAdornment != null ? (
            <InputAdornment position="start" className={classes.startAdornment}>
              <Icon className={classes.startAdornmentIcon}>
                {startAdornment}
              </Icon>
            </InputAdornment>
          ) : (
            undefined
          ),
        endAdornment:
          endAdornment != null ? (
            <InputAdornment position="end" className={classes.endAdornment}>
              <Icon className={classes.endAdornmentIcon}>{endAdornment}</Icon>
            </InputAdornment>
          ) : (
            undefined
          ),
        ...inputProps,
      }}
      value={value != null ? value : ''}
      error={error}
      {...rest}
      // `rest` includes:
      // id={id}
      // name={name}
      // label={label}
      // helperText={helperText}
      // disabled={disabled}
    >
      {renderOptions != null ? renderOptions(props) : defaultRenderOptions()}
    </TextField>
  )
}

RawSelectInputBase.defaultProps = {
  ProgressComponent: InputProgress,
  fullWidth: true,
  defaultTitle: 'r.core.forms.select.default',
  selectProps: {
    // displayEmpty: true,
  },
}

RawSelectInputBase.propTypes = {
  defaultTitle: PropTypes.string,
  defaultChildren: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  checking: PropTypes.bool,
  readOnly: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  helperProps: PropTypes.object,
  labelProps: PropTypes.object,
  selectProps: PropTypes.object,
  menuProps: PropTypes.object,
  inputProps: PropTypes.object,
  optionProps: PropTypes.object,
  categoryProps: PropTypes.object,
  progressProps: PropTypes.object,
  renderProgress: PropTypes.func,
  ProgressComponent: PropTypes.elementType,
  renderOptions: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      children: PropTypes.node,
      selected: PropTypes.bool,
      isCategory: PropTypes.bool,
    })
  ),

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const SelectInputBase = withLang(
  withStyles(selectInputBaseStyle)(RawSelectInputBase)
)
