import { langSwitchStyle } from '_core/components/LangSwitch/langSwitchStyle'

export const settingsPopupStyle = theme => ({
  ...langSwitchStyle(theme),

  paper: {
    padding: theme.spacing(0.5),
    
    '& .MuiSvgIcon-root, .MuiIcon-root, .MuiTypography-root': {
      color: theme.variables.colors.textSecondary,
    },
  },
})
