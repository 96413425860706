const shopTitleLines = 2
const shopDescriptionLines = 3

export const goodsListItemShopStyle = theme => ({
  card: {
    color: theme.palette.text.primary,
    height: `calc(100% - ${theme.spacing(3)}px)`,
    position: 'relative',
  },

  media: {
    position: 'relative',
    minHeight: theme.spacing(36),
    backgroundSize: 'contain',
  },

  content: {
    position: 'relative',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: 'unset',
    padding: theme.spacing(1.5, 3, 3),
  },

  title: {
    marginBottom: theme.spacing(),
    lineClamp: shopTitleLines,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  desciption: {
    position: 'relative',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    display: '-webkit-box',
    height: `calc(${theme.typography.body2.fontSize} * ${theme.typography.body2.lineHeight} * ${shopDescriptionLines} - 1px)`,
    WebkitLineClamp: shopDescriptionLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',

    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  oldPrice: {
    textDecoration: 'line-through',
  },

  buttonBox: {
    maxWidth: 150,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius / 2.5,
    padding: theme.spacing(1.5, 1),
    marginTop: theme.spacing(),
  },

  purchaseButton: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },

  actions: {
    flexWrap: 'wrap',
    position: 'relative',
    paddingTop: theme.spacing(3),
  },

  mixedPrice: {
    margin: `${theme.spacing(2)}px 0 0 0`,
    marginLeft: '0 !important',
  },

  points: {
    width: '100%',
  },

  pointsChip: {
    position: 'absolute',
    top: theme.spacing(-1.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },

  pointsValue: {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },

  count: {
    padding: theme.spacing(0, 1),
  },

  ...theme.mixins.goodsListItemShop,
})

const cartTitleLines = 2
const cartDescriptionLines = 2

export const goodsListItemCartStyle = theme => ({
  itemContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'relative',

    '&:first-child': {
      paddingTop: 0,
    },

    '&:last-child': {
      paddingBottom: 0,
    },
  },

  cardMedia: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    padding: 0,
  },

  headerTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    display: '-webkit-box',
    maxHeight: `calc(${theme.typography.h6.fontSize} * ${theme.typography.h6.lineHeight} * ${cartTitleLines} - 1px)`,
    WebkitLineClamp: cartTitleLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
  },

  headerDesciption: {
    position: 'relative',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    display: '-webkit-box',
    maxHeight: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight} * ${cartDescriptionLines} - 1px)`,
    WebkitLineClamp: cartDescriptionLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
    fontWeight: theme.typography.fontWeightBold,
  },

  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  counter: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius / 2.5,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    maxWidth: 120,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  adornmentButton: {
    cursor: 'pointer',
    lineHeight: 2.5,
    fontWeight: theme.typography.fontWeightBold,
  },

  priceText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  lineThrough: {
    textDecoration: 'line-through',
  },
  smallCaption: {
    fontSize: `calc(${theme.typography.caption.fontSize} * 0.925)`,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(1),
  },

  uppercase: {
    textTransform: 'uppercase',
  },

  clearIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: 0,
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  clearIconDisable: {
    opacity: 0.3,
  },

  prices: {
    marginTop: theme.spacing(),
  },

  points: {
    width: '100%',
    marginTop: theme.spacing(),
  },

  pointsChip: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },

  pointsValue: {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },

  ...theme.mixins.goodsListItemCart,
})

const modalItemHeight = 60
const modalTitleLines = 1
const modalDescriptionLines = 2

export const goodsListItemModalStyle = theme => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2.5)}px 0`,
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    '&:first-child': {
      paddingTop: 0,
    },
  },

  cardMedia: {
    position: 'relative',
    width: modalItemHeight,
    height: modalItemHeight,
    minWidth: modalItemHeight,
    minHeight: modalItemHeight,
    backgroundSize: 'contain',
    padding: 0,
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
    padding: `0 ${theme.spacing(2)}px`,
    height: modalItemHeight,
    maxWidth: `calc(100% - ${60}px - 20px)`,
  },

  textContent: {
    maxWidth: 'calc(100% - 60px)',
  },
  mixedTextContent: {
    maxWidth: '100%',
  },

  headerTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    display: '-webkit-box',
    height: `calc(${theme.typography.body2.fontSize} * ${theme.typography.body2.lineHeight} * ${modalTitleLines} - 1px)`,
    WebkitLineClamp: modalTitleLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
  },

  headerDesciption: {
    position: 'relative',
    // color: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1.2,
    display: '-webkit-box',
    maxHeight: `calc(${theme.typography.caption.fontSize} * 1.2 * ${modalDescriptionLines} - 1px)`,
    WebkitLineClamp: modalDescriptionLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',

    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  priceContent: {
    paddingLeft: theme.spacing(1),
    textAlign: 'right',
  },

  priceText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  mixedPriceText: {
    textAlign: 'right',
  },

  sale: {
    lineHeight: theme.typography.body2.lineHeight,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  lineThrough: {
    textDecoration: 'line-through',
  },

  cardActions: {
    // flexWrap: 'wrap',
    border: 'none',
    padding: 0,
    height: modalItemHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(0.5),
  },
  clearIcon: {
    fontSize: theme.typography.h6.fontSize,
    cursor: 'pointer',
    zIndex: 5,
  },
  clearIconDisable: {
    fontSize: theme.typography.h6.fontSize,
    cursor: 'unset',
    zIndex: 5,
    opacity: 0.3,
  },

  points: {
    width: '100%',
    marginTop: theme.spacing(),
  },

  pointsChip: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },

  pointsValue: {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },

  ...theme.mixins.goodsListItemModal,
})
