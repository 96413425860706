import React from 'react';
import PropTypes from 'prop-types';

// @material-ui
import {
  Button,
  Grid
} from '@material-ui/core';

// core
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { ModalInner } from '_core/components/ModalInner';
import { ModalOuter } from '_core/components/ModalOuter';
import AddressInput from '_core/components/AddressInputNew';
import { DeliveryWarehouseSelect } from '_core/components/DeliveryWarehouseSelect';

//import { useStyle } from './style';


export const RawDeliveryCityModal = (props) => {
  const {
    getLangString: l,
  } = props;
  
  
  //const classes = useStyle();
  
  const [citySelect, setCitySelect] = React.useState(false);
  
  const cart = useCartContext();
  
  
  return (
    <ModalOuter open={cart.cityRequest} fullWidth={true} maxWidth="md" onClose={() => cart.cityConfirm()}>
      {citySelect ? (
        <ModalInner
          title={l('r._.city.select')}
          content={
            <Grid container>
              <Grid item xs={12}>
                <AddressInput
                  variant="delivery"
                  type="city"
                  label={l('global.field.city')}
                  helperText=""
                  determineAddress={false}
                  selectedItem={cart.cityData}
                  onSelectItem={(data) => cart.setCityData(data)}
                />
              </Grid>
              
              <DeliveryWarehouseSelect />
            </Grid>
          }
          actions={
            <>
              &nbsp;
              
              <Button onClick={() => cart.cityConfirm()}>{l('global.buttons.confirm')}</Button>
            </>
          }
        />
      ) : (
        <ModalInner
          title={l('r._.city.select')}
          content={
            <>
              {l('r._.city.select.request').replace('{city}', cart.cityValue())}
            </>
          }
          actions={
            <>
              <Button variant="text" onClick={() => setCitySelect(true)}>{l('global.field.no')}</Button>
              <Button onClick={() => cart.cityConfirm()}>{l('r._.city.select.request.yes')}</Button>
            </>
          }
        />
      )}
    </ModalOuter>
  );
};

RawDeliveryCityModal.defaultProps = {};

RawDeliveryCityModal.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const DeliveryCityModal = withLang(RawDeliveryCityModal);
