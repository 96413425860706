import * as Yup from 'yup'

// core
// import { SuggestSelect } from '_core/components/SuggestSelect'
import { SelectInput } from '_core/components/SelectInput'
import { AutocompleteInput } from '_core/components/AutocompleteInput'
import { CurrencyInput } from '_core/components/CurrencyInput'
import { GenderInput } from '_core/components/GenderInput'
import { AppealInput } from '_core/components/AppealInput'
// import { PhoneInput } from '_core/components/PhoneInput'
// import { PriceInput } from '_core/components/PriceInput'
import { TextInput } from '_core/components/TextInput'
import { PasswordInput } from '_core/components/PasswordInput'
import { AuthKeyInput } from '_core/components/AuthKeyInput'

export const yupNumber = title => Yup.number(title)

export const yupString = title => Yup.string(title).ensure()

export const yupEmail = title => yupString(title).email()

export const formFieldTypes = {
  login: {
    yup: yupString,
    component: TextInput,
  },

  password: {
    yup: yupString,
    type: 'password',
    component: PasswordInput,
  },

  password2: {
    yup: yupString,
    type: 'password',
    component: PasswordInput,
  },

  gender: {
    yup: yupString,
    component: GenderInput,
  },

  appeal: {
    yup: yupString,
    component: AppealInput,
  },

  text: {
    yup: yupString,
    component: TextInput,
  },

  email: {
    yup: yupEmail,
    component: TextInput,
  },

  phone: {
    yup: yupString,
    component: TextInput,
    // component: PhoneInput,
  },

  date: {
    yup: yupString,
    component: TextInput,
  },

  address: {
    yup: yupString,
    component: TextInput,
  },

  select: {
    yup: yupString,
    component: SelectInput,
    // component: SuggestSelect,
  },

  currency: {
    yup: yupString,
    component: CurrencyInput,
  },

  price: {
    yup: yupString,
    component: TextInput,
  },

  textarea: {
    yup: yupString,
    component: TextInput,
  },

  authKey: {
    yup: yupString,
    component: AuthKeyInput,
  },

  selectSearch: {
    yup: yupString,
    component: AutocompleteInput,
  },
}
