export const overrides = theme => ({
  primary: {
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  secondary: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    '&:hover, &:active, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
})
