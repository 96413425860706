import React from 'react';


export const renderStyles = () => (<style jsx global>{`
  .addrinp-suggests {
    background-color: #fff;
    pointer-events: none;
    user-select: none;
    transition: opacity linear 0.1s;
    opacity: 0;
    
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    margin: 0.5rem 0;
    
    z-index: 1500;
  }
  
  .addrinp-suggests.visible {
    opacity: 1;
  }
  
  .addrinp-suggests-hint {
    font-size: 0.8em;
    font-weight: 500;
    color: #999;
    
    padding: 0.05rem 0.5rem;
  }
  
  .addrinp-suggests-list {
    list-style: none;
    
    margin: 0;
    padding: 0;
  }
  
  .addrinp-suggests-list li {
    font-size: 0.9em;
    
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
  
  .addrinp-suggests.visible .addrinp-suggests-list li {
    pointer-events: all;
  }
  
  .addrinp-suggests-list li:hover {
    background-color: #e5f2fb;
  }
  
  .addrinp-suggests-list li.active {
    background-color: #d9ebf9;
  }
`}</style>);
