// @material-ui
import { makeStyles } from '@material-ui/styles'

export const fileUploadStyle = theme => ({
  root: {},

  file: {
    position: 'relative',
    paddingRight: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },

  filePreview: {
    overflow: 'hidden',
    position: 'relative',
    height: theme.spacing(5),
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(2),
    background: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  },

  fileInfo: {
    overflow: 'hidden',
  },

  fileImage: {
    objectFit: 'cover',
    minHeight: '100%',
    minWidth: '100%',
    height: 'auto',
    width: '100%',
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },

  fab: {
    top: 0,
    right: 0,
    position: 'absolute',
    color: theme.palette.text.secondary,
  },

  files: {},

  button: {
    marginBottom: theme.spacing(2),
  },

  helperText: {
    marginBottom: theme.spacing(2),
  },

  helperTextItem: {},

  message: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(7),
  },

  messageTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
  },

  messageFiles: {},
})

export const useFileUploadStyle = makeStyles(fileUploadStyle)
