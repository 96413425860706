import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { withVisitorInfo } from '_core/utils/visitorInfo'
import { ModalOuter } from '_core/components/ModalOuter'
import { ModalInner } from '_core/components/ModalInner'
import { Stairs } from '_core/components/Stairs'
import { RegisterForm } from '_core/views/RegisterForm'
import { FormResultStep } from '_core/views/FormResultStep'

export const RawRegisterModal = props => {
  const { open, onClose, visitorInfo, getLangString: l } = props
  // const classes = useRegisterModalStyle()

  if (!open) {
    return null
  }

  return (
    <>
      <ModalOuter open={true} onClose={onClose} fullWidth={true} maxWidth="md">
        <ModalInner
          title={l('r._.register.title')}
          content={
            <Stairs
              hasSteps={false}
              stairs={[
                {
                  render: ({ goNext }) => (
                    <RegisterForm
                      parentLogin={visitorInfo.info?.login}
                      onSuccess={({ values }) => goNext({ values })}
                      hasSuccessMessage={false}
                    />
                  ),
                },
                {
                  render: ({ load }) => (
                    <FormResultStep
                      successControlsProps={{
                        hasBackButton: false,
                        successMessage: l(
                          'r._.register.text.success',
                          load.values.login
                        ),
                        nextButtonText: l('r._.register.action.close'),
                        // backButtonText: 'r._.register.action.close',
                        // onPrevStep: onClose,
                        onNextStep: onClose,
                      }}
                    />
                  ),
                },
              ]}
            />
          }
        />
      </ModalOuter>
    </>
  )
}

RawRegisterModal.defaultProps = {}

RawRegisterModal.propTypes = {
  // self props
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const RegisterModal = withVisitorInfo(withLang(RawRegisterModal))
