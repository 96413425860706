import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography } from '@material-ui/core'

// core
import { withLang } from '_core/hocs/withLang'
import { Price } from '_core/components/Price'

export const RawPaymentFee = props => {
  const {
    className,
    amount,
    percent,
    currency,
    labelFee,
    labelNoFee,
    rootProps,
    priceProps,
    Divider,
    getLangString: l,
  } = props

  const fees = (() => {
    const hasAmount = !isNaN(amount) && amount > 0
    const hasPercent = !isNaN(percent) && percent > 0

    if (hasAmount && hasPercent) {
      return (
        <>
          <Price value={amount} currency={currency} {...priceProps} />
          <Divider />
          {`${percent} %`}
        </>
      )
    }

    if (hasAmount) {
      return <Price value={amount} currency={currency} {...priceProps} />
    }

    if (hasPercent) {
      return `${percent} %`
    }

    return null
  })()

  return (
    <Typography className={className} {...rootProps}>
      {fees != null ? (
        <>
          {l(labelFee)}
          {': '}
          {fees}
        </>
      ) : (
        l(labelNoFee)
      )}
    </Typography>
  )
}

RawPaymentFee.defaultProps = {
  Divider: () => ' + ',
  labelFee: 'r._.finance.fee.label.fee',
  labelNoFee: 'r._.finance.fee.label.nofee',
}

RawPaymentFee.propTypes = {
  // self props
  className: PropTypes.string,
  rootProps: PropTypes.object,
  percent: PropTypes.number,
  labelFee: PropTypes.string,
  labelNoFee: PropTypes.string,
  Divider: PropTypes.elementType,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PaymentFee = withLang(RawPaymentFee)
