import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// mterial-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography } from '@material-ui/core'

import labelStyle from './labelStyle'

function Label({ ...props }) {
  const {
    classes,
    children,
    className,
    size,
    margin,
    color,
    textfield,
    ...rest
  } = props

  const labelClasses = classNames({
    [classes.label]: true,
    [classes[color + 'Color']]: color,
    [classes[size + 'Label']]: size,
    [classes[margin + 'Margin']]: margin,
    [classes.textfield]: textfield,
    [className]: className !== undefined,
  })
  return (
    <Typography variant="caption" className={labelClasses} {...rest}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(labelStyle)(Label)
