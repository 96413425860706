import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Icon,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { getFormattedValue } from '_core/utils/formatPrice';
import { getVisitorPhotoSrc } from '_core/utils/getVisitorPhotoSrc';
import { useCartContext } from '_core/contexts/Cart';
import { PriceMix } from '_core/components/PriceMix';

// style
import { goodsListItemShopStyle } from '_core/components/GoodsListItemShop/style';
import { productListItemStyle } from '_core/components/ProductListItem/productListItemStyle';


const style = (theme) => {
  const tempStyle = productListItemStyle(theme)

  return {
    ...goodsListItemShopStyle(theme),
    price: tempStyle.price
  };
};

const RawGoodsListItemShop = (props) => {
  const {
    classes,
    data,
    shop,
    goodsUrl,
    getLangString: l,
  } = props;

  const theme = useTheme();
  
  const cart = useCartContext();
  
  const { config: { ROUTE_URL_PARAM, PRODUCT_TYPE } } = useGlobal();
  
  const { isSkeleton, is_excluded_from_store_accounting, goods, points, price, discount, amount } = data;

  if (isSkeleton) {
    return (
      <Card className={classes.card}>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top={0}
          >
        </Box>
        <CardMedia className={classes.img}>
          <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        </CardMedia>
        
        <CardContent className={classes.content}>
          <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
          <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
          <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        </CardContent>
      </Card>
    );
  }
  
  const cartControl = cart.getControl(goods);
  
  const disabled = !is_excluded_from_store_accounting && (cartControl.rest.isLoading || cartControl.rest.value <= cartControl.count);
  
  const onPurchase = () => {
    if (disabled)
      return;
    
    cartControl.increase();
    
    cart.setModalVisible(true);
  };
  
  const onPurchaseModal = (location) => {
    const search = new URLSearchParams(location.search);
    if (shop) search.set(ROUTE_URL_PARAM.SHOP, shop);
    search.set(ROUTE_URL_PARAM.PAYMENT, goods.id);
    search.delete(ROUTE_URL_PARAM.PRODUCT);
    return { ...location, search: search.toString() };
  };

  const priceNode = (
    <Box display="flex" flexDirection="column">
      {amount?.value > 0.00 ? (
        <PriceMix
          rootProps={{ noWrap: true }}
          itemProps={{
            hasTooltip: true,
            className: classes.priceText,
            rootProps: { variant: 'h6', color: 'secondary' },
          }}
          item={{
            ...amount,
            currency: amount.currency.id,
          }}
        />
      ) : null}

      {discount?.value > 0 ? (
        <PriceMix
          className={classes.oldPrice}
          rootProps={{ variant: 'caption' }}
          item={{
            ...price,
            currency: price.currency.id,
          }}
        />
      ) : null}
    </Box>
  )

  const pointsNode =
    points > 0 ? (
      <Box
        display="flex"
        justifyContent="space-around"
        className={classes.points}
      >
        <Chip
          size="small"
          color="secondary"
          className={classes.pointsChip}
          label={
            <Typography
              component="span"
              variant="caption"
              className={classes.pointsValue}
            >
              {l(
                ['r._.goods.text.points', points],
                getFormattedValue(points, {
                  scale: theme.variables.pointsScale,
                })
              )}
            </Typography>
          }
        />
      </Box>
    ) : null

  return (
    <Card className={classes.card}>
      <Link 
        className={classes.link}
        to={`${goodsUrl}/${goods.id}`}
      >
        
        <CardActionArea>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top={0}
          />
          <CardMedia
            component="img"
            className={classes.img}
            image={
              goods.photo != null &&
              goods.photo.path != null &&
              getVisitorPhotoSrc(goods.photo.path, 'shop')
            }
          />

          <CardContent className={classes.content}>
            <Typography variant="h6" color="primary" className={classes.title}>
              {l(goods.title)}
            </Typography>

            {goods.description != null ? (
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.desciption}
              >
                {l(goods.description)}
              </Typography>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Link>

      <CardActions className={classes.actions}>
        {pointsNode}
        {priceNode}

        {goods.type && (goods.type.id === PRODUCT_TYPE.START || goods.type.id === PRODUCT_TYPE.INVEST || goods.type.id === PRODUCT_TYPE.INSTALLMENT) ? (
          <Button to={onPurchaseModal} component={Link}>
            {l('r._.product.action.buy')}
          </Button>
        ) : (
          <Box display="flex" alignItems="center" className={classes.buttonBox}>
            <Typography color="textSecondary" className={classes.count}>
              {cartControl.count}
            </Typography>
            
            <Icon
              onClick={onPurchase}
              {...(disabled ? { style: { color: 'rgba(0,0,0,0.26)' } } : {})}
              className={classes.purchaseButton}
            >
              shopping_cart
            </Icon>
          </Box>
        )}
      </CardActions>
    </Card>
  )
}

RawGoodsListItemShop.defaultProps = {}

RawGoodsListItemShop.propTypes = {
  // self props
  data: PropTypes.object.isRequired,
  goodsUrl: PropTypes.string,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}
export const GoodsListItemShop = withLang(
  withStyles(style)(RawGoodsListItemShop)
)
