import React, { useLayoutEffect, useState } from 'react'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { useSearch } from '_core/hooks/useSearch'
import { ModalOuter } from '_core/components/ModalOuter'
import { Product } from 'basic/pages/Product'
import PropTypes from 'prop-types'

export const RawProductModal = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { getSearchParam, setSearchParam } = useSearch()

  const {
    config: {
      ROUTE_URL_PARAM: { PRODUCT },
    },
  } = useGlobal()

  const product = getSearchParam(PRODUCT)

  useLayoutEffect(() => setIsOpen(!!product), [product])

  const rawOnClose = () => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <ModalOuter
      open={isOpen}
      onClose={rawOnClose}
      onExited={() => setSearchParam(PRODUCT)}
      fullWidth={true}
      maxWidth="md"
    >
      <Product id={product} hasCrumbs={false} />
    </ModalOuter>
  )
}

RawProductModal.defaultProps = {}

RawProductModal.propTypes = {
  onClose: PropTypes.func,
}

export const ProductModal = RawProductModal
