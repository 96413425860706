import React, { forwardRef } from 'react';
import { connect } from 'react-redux';

// core
import { getDisplayName } from '_core/utils/getDisplayName';
import { properties, parseParam } from '_core/utils/properties';
import { langSelectors } from '_core/store/Lang';

import { LANG_TEMPLATE_VAR } from 'basic/config';


export const withLang = Component => {
  const wrappedComponent = getDisplayName(Component)

  const mapState = state => {
    const currentLangId = langSelectors.getCurrentLangId(state)

    return {
      langInfo:
        currentLangId != null
          ? langSelectors.getLangInfoById(state, currentLangId)
          : null,
      langStrings:
        currentLangId != null
          ? langSelectors.getLangStringsById(state, currentLangId)
          : null,
    }
  }

  class WithLang extends React.PureComponent {
    constructor(props) {
      super(props)

      this._replaceVars = this._replaceVars.bind(this)

      this.getLangObject = this.getLangObject.bind(this)
      this.getLangString = this.getLangString.bind(this)
      this.getLangStringSet = this.getLangStringSet.bind(this)
      this.getFirstLangString = this.getFirstLangString.bind(this)
    }

    getLangObject(obj) {
      return Object.keys(obj).reduce((result, key) => {
        if (obj[key] && typeof obj[key] === 'object') {
          result[key] = this.getLangObject(obj[key])
        } else {
          result[key] = this.getLangString(obj[key])
        }

        return result
      }, {})
    }

    getFirstLangString(...codes) {
      let trans

      for (let i = 0; i < codes.length; i++) {
        trans = this.getLangString(codes[i])

        if (trans === codes[i]) {
          continue
        }

        return trans
      }

      return trans
    }

    getLangString(code, ...vars) {
      const { langStrings } = this.props

      if (langStrings == null) {
        return code
      }

      let realCode = code
      let position = 0

      if (Array.isArray(code)) {
        realCode = code[0]
        position = +`${code[1]}`.trim()
        position = Number.isInteger(+position) ? position % 10 : 2
      }

      const reg = /^r\._\./
      const fixedCode =
        realCode != null
          ? `${realCode}`.replace(/^\$/, '').replace(/\?.+$/, '')
          : ''

      let finalCode = fixedCode

      if (reg.test(fixedCode)) {
        finalCode = fixedCode.replace(
          reg,
          `r.${process.env.REACT_APP_PROJECT}.`
        )

        if (langStrings[finalCode] == null) {
          finalCode = fixedCode.replace(reg, `r.core.`)
        }
      }

      if (langStrings[finalCode] == null) {
        return code
      }

      const templates = langStrings[finalCode].split('|')

      const template = properties(typeof templates[position] === 'string'
          ? templates[position]
          : templates[0], parseParam(realCode));

      return this._replaceVars(template, ...vars)
    }

    getLangStringSet(...codes) {
      return codes.map(code =>
        Array.isArray(code)
          ? this.getLangString(...code)
          : this.getLangString(code)
      )
    }

    _replaceVars(template, ...vars) {
      if (!template.includes(LANG_TEMPLATE_VAR)) {
        return template
      }

      return template
        .split(LANG_TEMPLATE_VAR)
        .reduce((result, fragment, index, source) => {
          let subst = vars[index] != null ? vars[index] : ''
          subst = index !== source.length - 1 ? subst : ''
          return `${result}${fragment}${subst}`
        }, '')
    }

    render() {
      const {
        forthRef,

        // Filter out `redux-connect` HOC props
        dispatch, // eslint-disable-line no-unused-vars
        langStrings, // eslint-disable-line no-unused-vars

        ...rest
      } = this.props

      return (
        <Component
          ref={forthRef}
          getLangObject={this.getLangObject}
          getLangString={this.getLangString}
          getLangStringSet={this.getLangStringSet}
          getFirstLangString={this.getFirstLangString}
          {...rest}
        />
      )
    }
  }

  WithLang.displayName = `WithLang(${wrappedComponent})`

  const ConnectedWithLang = connect(mapState)(WithLang)

  return forwardRef((props, ref) => (
    <ConnectedWithLang forthRef={ref} {...props} />
  ))
}
