import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// @material-ui
import { Box, Icon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// style
import { titleStyle } from './titleStyle'

const RawTitle = props => {
  const {
    classes,
    className,
    children,
    icon,
    iconNode,
    rootProps,
    textProps,
    iconProps,
  } = props

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      className={rootClasses}
      {...rootProps}
    >
      {icon != null ? (
        <Icon className={classes.icon} {...iconProps}>
          {icon}
        </Icon>
      ) : null}

      {iconNode != null ? iconNode : null}

      <Typography variant="h5" component="h3" {...textProps}>
        {children}
      </Typography>
    </Box>
  )
}

RawTitle.defaultProps = {}

RawTitle.propTypes = {
  // self props
  icon: PropTypes.string,
  className: PropTypes.string,
  iconNode: PropTypes.node,
  children: PropTypes.node,
  rootProps: PropTypes.object,
  textProps: PropTypes.object,
  iconProps: PropTypes.object,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,
}

export const Title = withStyles(titleStyle)(RawTitle)
