//import React from 'react';

import bbcode, { Tag } from 'bbcode-to-react';


export default class AbstractTag extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
  }
  
  
  static parser = new bbcode.Parser([]);
  
  
  toText(contentAsHTML = false) {
    const result = [];
    
    if (this.name !== null) {
      result.push('[');
      
      if (!this.params[this.name])
        result.push(this.name, ' ');
      
      Object.entries(this.params).forEach(([key, value], index) => result.push(index ? ' ' : '', key, '="', this.renderer.escape(value), '"'));
      
      result.push(']');
    }
    
    result.push(this.getContent(!contentAsHTML));
    
    if (this.name !== null && !this.SELF_CLOSE && this.CLOSED_BY.indexOf(this.name) < 0)
      result.push('[/', this.name, ']');
    
    return result.join('');
  }
  
};
