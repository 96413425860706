// @material-ui
import { makeStyles } from '@material-ui/styles'

export const serviceCountStyle = theme => ({
  field: {
    margin: '20px 0 5px 0',
  },
  label: {
    marginRight: theme.spacing(2),
  },
})

export const useServiceCountStyle = makeStyles(serviceCountStyle)
