import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// @material-ui

// core
import { withLang } from '_core/hocs/withLang'

import './Messenger.css'

const RawTopicSearch = (props) => {
  const { onSearch, onClear } = props;

  const conversationSearchRef = useRef(null);

  var topicSearchPromise = null;

  const searchFn = (x) => {
    if (topicSearchPromise != null) {
      clearTimeout(topicSearchPromise);
    }

    topicSearchPromise = setTimeout(() => {
      const text = conversationSearchRef.current.value;
      if (text !== "") {
        onSearch(text);
      } else {
        onClear();
      }
    }, 1000);
  };

  return (
    <div className="conversation-search">
      <input
        ref={conversationSearchRef}
        type="search"
        className="conversation-search-input"
        placeholder="Search topic"
        onKeyDown={searchFn}
      />
    </div>
  );
}

RawTopicSearch.defaultProps = {}

RawTopicSearch.propTypes = {
  // self props
  onSearch: PropTypes.func,
  onClear: PropTypes.func,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const TopicSearch = withLang((RawTopicSearch))
