export const paymentConfirmStyle = theme => ({
  root: {
    height: '100%',
  },

  group: {
    width: '100%',
  },

  backButton: {
    marginRight: theme.spacing(2),
  },

  buttonRow: {
    marginTop: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(2),
  },

  value: {
    marginBottom: theme.spacing(2),
  },

  paymentImage: {
    marginBottom: theme.spacing(3),
    maxWidth: '117px',
    maxHeight: '31px',
  },

  valueInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.5rem',
      color: theme.palette.error.main,
    },
  },

  warning: {
    marginBottom: theme.spacing(2),
  },
})
