import React from 'react';
import PropTypes from 'prop-types';

// core
import { useCartContext } from '_core/contexts/Cart';
import { SelectInput } from '_core/components/SelectInput';


const RawCurrencyInput = (props) => {
  const cart = useCartContext();
  
  return <SelectInput {...props} options={cart.currencyList} />;
};

RawCurrencyInput.defaultProps = {};

RawCurrencyInput.propTypes = {
};

export const CurrencyInput = RawCurrencyInput;
