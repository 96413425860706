import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

// core
import { withCatch } from '_core/hocs/withCatch'
import { withLang } from '_core/hocs/withLang'

import { useGlobal } from '_core/hooks/useGlobal'

import { Loading } from '_core/components/Loading'
import { ModalInner } from '_core/components/ModalInner'

import { MessagerDesktop } from '_core/views/MessagesSplash/MessagerDesktop'
import { MessagerMobile } from '_core/views/MessagesSplash/MessagerMobile'

import { loadTopicEntry } from '_core/views/MessagesSplash/util.js'

import './Messenger.css'

const RawMessagesSplashView = (props) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));

  const {
    request: { queue },
    config: { API_METHOD }
  } = useGlobal();

  const [topics, setTopics] = useState(null);

  useEffect(() => {
    if (topics == null) {
      queue(API_METHOD.VISITOR_MESSAGE_TOPICS, null, RawMessagesSplashView.displayName)
        .then(result => {
          const topics = result.list.map(loadTopicEntry);
          setTopics(topics);
        });
    }
  }, []);

  const renderView = () => {
    if (topics == null) {
      return (<Loading />);
    } else if (isMobile) {
      return (<MessagerMobile topics={topics} { ...props } />);
    } else {
      return (<MessagerDesktop topics={topics} { ...props } />);
    }
  }
  
  return (
    <ModalInner content={renderView()} />
  );
}

RawMessagesSplashView.defaultProps = {}

RawMessagesSplashView.propTypes = {
  // self props
  topics: PropTypes.array.isRequired,
  topicId: PropTypes.number,
  selectedMessageId: PropTypes.number,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const MessagesSplashView = withCatch(
  withLang(
    (RawMessagesSplashView)
  )
)
