import * as Yup from 'yup'

// core
import { phoneRegExp } from '_core/utils/phoneRegExp'

const getTextFieldYup = (label) =>
  Yup.string(label)
    .trim()
    .ensure();

const getImageFieldYup = (label) =>
  Yup.number(label)
    .nullable(true)
    .integer('bad')
    .min(0, 'bad');

const getFileFieldYup = (label) =>
  Yup.array(getImageFieldYup())
    .label(label)
    .nullable(true)
    .ensure();

const getRegionaddressFieldYup = (label) =>
  Yup.object()
    .nullable(true)
    .test({
      test(value) {
        if (!value) {
          return new Yup.ValidationError('empty', value, 'regionaddress');
        } else if (!value.valid) {
          return new Yup.ValidationError('bad', value, 'regionaddress');
        }
        
        return true;
      }
    })
    .label(label);

const getPhoneFieldEventData = data => {
  if (typeof data === 'string') {
    return {
      value: data.replace(/[-\s()]/g, ''),
    }
  }

  return {
    target: data.target,
    value: data.target.value.replace(/[-\s()]/g, ''),
  }
};

const getFileFieldEventData = (files) => ({
  value: files != null ? files.map(file => file.id) : null,
});

const getImageFieldEventData = (files) => ({
  value: files != null ? files[0].id : null,
});

export const getFieldOptions = ({ label, options, value, onChange, onChangeSave }) => {
  const {
    required,
    serverCheck,
    length,
    min,
    max,
    ban,
    match,
    file,
    image,
    // date,
    email,
    phone,
    regionaddress,
    include,
    exclude,
    repeat,
  } = options

  const result = {
    value,
    label,
    onChange,
    serverCheck,
    onChangeSave,
  }

  result.shape = image ? getImageFieldYup(label) :
    regionaddress ? getRegionaddressFieldYup(label) :
    file ? getFileFieldYup(label) :
    getTextFieldYup(label)

  if (typeof ban === 'string') {
    result.ban = new RegExp(ban)
  }

  if (Array.isArray(ban)) {
    result.ban = new RegExp(...ban)
  }

  if (required) {
    result.shape = result.shape.required('empty')
  }

  if (typeof length === 'number' && length >= 0) {
    result.shape = result.shape.length(length, 'bad')
  }

  if (typeof min === 'number' && min >= 0) {
    result.shape = result.shape.min(min, 'short')
  }

  if (typeof max === 'number' && max >= 0) {
    result.shape = result.shape.max(max, 'long')
  }

  if (Array.isArray(include)) {
    result.shape = result.shape.oneOf(include, 'bad')
  }

  if (Array.isArray(exclude)) {
    result.shape = result.shape.notOneOf(exclude, 'bad')
  }

  if (typeof match === 'string') {
    result.shape = result.shape.matches(new RegExp(match), 'bad')
  }

  if (match instanceof RegExp) {
    result.shape = result.shape.matches(match, 'bad')
  }

  if (Array.isArray(match)) {
    result.shape = result.shape.matches(new RegExp(...match), 'bad')
  }

  if (typeof repeat === 'string') {
    result.shape = result.shape.oneOf([Yup.ref(repeat)], 'bad')
  }

  if (email) {
    result.shape = result.shape.email()
  }

  if (phone) {
    result.getEventData = getPhoneFieldEventData
    result.shape = result.shape.test('isPhone', 'bad', val =>
      phoneRegExp.test(val.replace(/[-\s()]/g, ''))
    )
  }

  if (file) {
    result.getEventData = getFileFieldEventData;
  }

  if (image) {
    result.getEventData = getImageFieldEventData;
  }
  
  // if (date) {}

  return result;
};

// birth_date: {
//   value: values['birth_date'],
//   label: l('r._.guest.register.form.birth_date.label'),
//   onChange: getOnChange('birth_date'),
//   getEventData: (value, target) => {
//     return {
//       target,
//       value: value.format('DD.MM.YYYY'),
//     }
//   },

//   // shape: Yup.string(l('r._.guest.register.form.birth_date.label'))
//   //   .trim()
//   //   .ensure()
//   //   .required('empty')
//   //   .length(10, 'bad'),

//   // shape: Yup.date(l('r._.guest.register.form.birth_date.label'))
//   //   .required('empty')
//   //   .max(now, 'max'),
//   // .min(new Date(now - CLIENT_AGE_MAX), 'min')
//   // .max(new Date(now - CLIENT_AGE_MIN), 'max')
// },
