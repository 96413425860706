import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { setValue } from '_core/utils/storage'
import { withVisitorInfo } from '_core/utils/visitorInfo'
import { LogoutButtonView } from './LogoutButtonView'

const RawLogoutButton = props => {
  const { visitorInfo, ...rest } = props
  const [isLoading, setIsLoading] = useState(false)

  const {
    request: { queue },
    config: { API_METHOD, STORAGE_KEY },
  } = useGlobal()

  const onExit = () => {
    setIsLoading(true)

    queue(API_METHOD.LOGOUT, {})
      .then((data) => {
        visitorInfo.set(null);
        
        setValue(STORAGE_KEY.UNLOCK);
      })
      .finally(() => setIsLoading(false));
  }

  return <LogoutButtonView {...rest} isLoading={isLoading} onClick={onExit} />
}

RawLogoutButton.displayName = 'RawLogoutButton'

RawLogoutButton.defaultProps = {}

RawLogoutButton.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  isMini: PropTypes.bool,
  tooltip: PropTypes.string,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,
}

export const LogoutButton = withVisitorInfo(RawLogoutButton)
