import { createReducer } from 'redux-starter-kit'

// Ritos store
import { countryActionTypes, countryDefaultState } from './index'

// === Reducers ===

export const country = createReducer(countryDefaultState, {
  [countryActionTypes.COUNTRY_RESET]: draft => {
    draft.list = countryDefaultState.list
  },

  [countryActionTypes.COUNTRY_UPDATE]: (draft, action) => {
    draft.list == null && (draft.list = {})
    action.payload.data.list.forEach(item => {
      draft.list[item.id] = item
    })
  },
})

// === Exports ===

export const countryReducers = {
  country,
}
