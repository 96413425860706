import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

// core
import { withLang } from '_core/hocs/withLang'
import { Title } from '_core/components/Title'
import { ClipboardInput } from '_core/components/ClipboardInput'
import { ModalInner } from '_core/components/ModalInner'
import { ModalOuter } from '_core/components/ModalOuter'
import { StepsControls } from '_core/components/StepsControls'

// style
import { useOrderDetailsStyle } from './orderDetailsStyle'

const DATA_GROUPS_ORDER = ['user_info', 'delivery_info', 'apiship_info']

const DATA_FIELDS_ORDER = {
  user_info: ['firstname', 'lastname', 'phone', 'email', 'note'],

  apiship_info: ['provider.name', 'tariff.name'],

  delivery_info: [
    'country.title',
    'city.title',
    'street',
    'house',
    'house_hull',
    'apartment',
    'postal_code',
  ],
}

const RawOrderDetails = props => {
  const { order, isModal, isOpen, onClose, getLangString: l } = props
  const { date, delivery } = order

  const classes = useOrderDetailsStyle()

  const renderFields = group =>
    DATA_FIELDS_ORDER[group].map(field => {
      const value = get(delivery[group], field)
      const groupSlug = group.replace(/[_.]/g, '')
      const fieldSlug = field.replace(/[_.]/g, '')

      return value != null ? (
        <ClipboardInput
          key={field}
          value={value}
          label={l(`r._.cartorder.details.label.${groupSlug}.${fieldSlug}`)}
          helperText=""
        />
      ) : null
    })

  const content = (
    <div className={classes.root}>
      <div className={classes.group}>
        <ClipboardInput
          label={l('r._.cartorder.details.label.datetime')}
          value={date.format}
          multiline={true}
          helperText=""
        />
      </div>

      {DATA_GROUPS_ORDER.map(group =>
        delivery != null &&
        delivery[group] != null &&
        delivery[group] instanceof Object ? (
          <div className={classes.group} key={group}>
            <Title
              className={classes.label}
              textProps={{
                variant: 'h6',
                component: 'h6',
              }}
            >
              {l(`r._.cartorder.details.label.${group.replace(/[_.]/g, '')}`)}
            </Title>

            {renderFields(group)}
          </div>
        ) : null
      )}
    </div>
  )

  const renderModal = content => (
    <ModalOuter open={isOpen} onClose={onClose}>
      <ModalInner
        title={l('r._.cartorder.details.title', order.id)}
        content={content}
        actions={
          <StepsControls
            onNextStep={onClose}
            hasBackButton={false}
            nextButtonText={l('r._.cartorder.details.action.close')}
          />
        }
      />
    </ModalOuter>
  )

  return isModal ? renderModal(content) : content
}

RawOrderDetails.defaultProps = {}

RawOrderDetails.propTypes = {
  // self props
  isOpen: PropTypes.bool,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
  order: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const OrderDetails = withLang(RawOrderDetails)
