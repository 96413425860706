export const overrides = theme => ({
  root: {
    paddingRight: theme.spacing(7),

    [`${theme.breakpoints.down('xs')}`]: {
      paddingRight: theme.spacing(5),
    },
  },
})

export const props = {
  disableTypography: true,
}
