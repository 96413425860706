import React from 'react';

import Tag from './AbstractTag';


Tag.parser.registerTag('hr', class extends Tag {
  
  constructor(renderer, settings = {}) {
    super(renderer, settings);
    
    this.SELF_CLOSE = true;
    this.STRIP_OUTER = true;
  }
  
  
  toReact() {
    return (
      <hr />
    );
  }
  
});
