import React from 'react'
import PropTypes from 'prop-types'

// core
import { withLang } from '_core/hocs/withLang'
import { PriceInput } from '_core/components/PriceInput'
import { PriceFormTax } from '_core/components/PriceFormTax'
import { PriceInputHelperText } from '_core/components/PriceInputHelperText'

const RawPriceFormFree = props => {
  const {
    tax,
    value,
    label,
    isValid,
    isFixed,
    minPrice,
    maxPrice,
    onChange,
    currency,
    getLangString: l,
  } = props

  const helperText = !isFixed ? (
    <PriceInputHelperText
      isValid={isValid}
      minPrice={minPrice}
      maxPrice={maxPrice}
      currency={currency}
    />
  ) : (
    ''
  )

  return (
    <>
      <PriceInput
        value={value}
        label={l(label)}
        readOnly={isFixed}
        currency={currency}
        error={!isFixed ? !isValid : undefined}
        helperText={helperText}
        onChange={event => onChange(event.target.value)}
      />

      <PriceFormTax
        tax={tax}
        value={value}
        isValid={isValid}
        currency={currency}
      />
    </>
  )
}

RawPriceFormFree.defaultProps = {
  label: 'r.summa.investicii',
}

RawPriceFormFree.propTypes = {
  // self props
  isValid: PropTypes.bool,
  isFixed: PropTypes.bool,
  label: PropTypes.string,
  tax: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const PriceFormFree = withLang(RawPriceFormFree)
