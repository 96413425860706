import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import {
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { withLang } from '_core/hocs/withLang'

const style = theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
})

export const RawPaymentInternal = props => {
  const { classes, list, onChange, isChecked, disableCheckBox, getLangString: l } = props

  const _renderCheckbox = () => {
    return (
      <FormControlLabel
        classes={{
          root: classes.checkboxLabelControl,
          label: classes.checkboxLabel,
        }}
        checked={isChecked}
        onChange={onChange}
        disabled={disableCheckBox}
        control={<Switch color="primary" />}
        label={<span>{l('r.ispolzovat.vnutrennie.sredstva')}</span>}
      />
    )
  }

  const _renderListItems = () => {
    return list
      .filter(item => item.balance != null)
      .map((item, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={l(item.title)}
            secondary={`${l('r.budet.spisano')} ${item.amount.format}`}
          />
        </ListItem>
      ))
  }

  return (
    <div className={classes.root}>
      {_renderCheckbox()}

      {list != null ? (
        <Collapse in={isChecked && list?.some((item) => item.balance)}>
          <List>{_renderListItems()}</List>
        </Collapse>
      ) : null}
    </div>
  )
}

RawPaymentInternal.defaultProps = {}

RawPaymentInternal.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}

export const PaymentInternal = withLang(withStyles(style)(RawPaymentInternal))
