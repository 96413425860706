import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { useSearch } from '_core/hooks/useSearch'
import { withLang } from '_core/hocs/withLang'
import { ModalOuter } from '_core/components/ModalOuter'
import { ModalInner } from '_core/components/ModalInner'
import { OrderSteps } from '_core/views/OrderSteps'
import { PaymentResult } from '_core/views/PaymentResult'

export const RawOrderModal = props => {
  const { getLangString: l } = props
  const [isOpen, setIsOpen] = useState(false)
  const [result, setResult] = useState()
  const { getSearchParam, setSearchParam } = useSearch()

  const {
    config: {
      ROUTE_URL_PARAM: { ORDER },
    },
  } = useGlobal()

  const order = getSearchParam(ORDER)

  useLayoutEffect(() => setIsOpen(!!order), [order])

  const onClose = () => {
    setResult()
    setIsOpen(false)
  }

  return (
    <ModalOuter
      open={isOpen}
      onClose={onClose}
      onExited={() => setSearchParam(ORDER)}
      fullWidth={true}
      maxWidth="md"
    >
      <ModalInner
        title={l('r.oplata')}
        content={
          result != null ? (
            <PaymentResult
              onRetry={() => setResult()}
              onClose={onClose}
              {...result}
            />
          ) : (
            <OrderSteps setResult={setResult} documentId={order} />
          )
        }
      />
    </ModalOuter>
  )
}

RawOrderModal.defaultProps = {}

RawOrderModal.propTypes = {
  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const OrderModal = withLang(RawOrderModal)
