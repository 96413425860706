import { PROCESSED_STATIC_PREFIX } from 'config'

export const getStaticPath = path => {
  if (!path) {
    return ''
  }

  if (path.startsWith(process.env.PUBLIC_URL + PROCESSED_STATIC_PREFIX)) {
    return path
  }

  return `${process.env.PUBLIC_URL}/${path}`
}
