/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'debounce';

import { TextField } from '@material-ui/core';

import decorate from '_core/utils/decorate';
import * as component from '_core/utils/component';
import * as storage from '_core/utils/storage';
import Popper from '_core/components/Popper';

import { renderStyles } from './styles';
import dadataVariant from './variants/dadata';
import deliveryVariant from './variants/delivery';


const STORAGE_CITY_FIELD = 'dw-city';

const VARIANTS_DATA = {
    dadata: dadataVariant,
    delivery: deliveryVariant
  };

const AddressInputContext = React.createContext();


const AddressInput = decorate(class extends React.PureComponent {
  
  static decorateForwardRef = 'forwardRef';
  
  static decorateWith = {
    lang: (props) => 'ru',//block.getClientLangByCode(props.lang || useProperty('lang', 'en')).code,
    token: (props) => null//VARIANTS_DATA[props.variant]?.tokenProperty ? useProperty(VARIANTS_DATA[props.variant].tokenProperty, props.token || '') : null
  };
  
  static contextType = AddressInputContext;
  
  static propTypes = {
    variant: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };
  
  static defaultProps = {
    variant: 'dadata',
    type: 'city',
    
    suggestionsClassName: 'addrinp-suggests',
    suggestionsHintClassName: 'addrinp-suggests-hint',
    suggestionsListClassName: 'addrinp-suggests-list',
    
    determineAddress: true
  };
  
  
  constructor(props) {
    super(props);
    
    this.state = {
      query: '',
      inputQuery: '',
      index: -1,
      item: null,
      isFirstLoad: true
    };
    
    this.update = 0;
  }
  
  
  componentDidMount() {
    if (!this.context)
      return;
    
    const { defaultValue, selectedItem, determineAddress } = this.props;
    
    
    if (selectedItem) {
      this.setState((state) => this.selectSuggestion({ ...state }, { ...selectedItem }, -1));
    } else if (defaultValue && typeof defaultValue === 'object') {
      this.setState((state) => this.selectSuggestion({ ...state }, { ...defaultValue }, -1));
    } else if (defaultValue) {
      this.setState((state) => ({ ...state, query: defaultValue, inputQuery: defaultValue }));
      
      this.loadSuggestions();
    } else if (determineAddress) {
      this.determineAddress();
    }
  }
  
  componentWillUnmount() {
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.context)
      return;
    
    const { selectedItem } = this.props;
    
    
    const selectedValue = this.buildValue(selectedItem);
    
    if (selectedValue !== this.buildValue(prevProps.selectedItem) && selectedValue !== this.buildValue(this.state.item))
      this.setState((state) => this.selectSuggestion({ ...state }, { ...selectedItem }, -1));
  }
  
  render() {
    if (!this.context) {
      return (
        <AddressInput.Provider>
          <AddressInput {...this.props} />
        </AddressInput.Provider>
      );
    }
    
    const {
      label,
      helperText,
      
      forwardRef,
      
      onChange,
      onKeyDown,
      onKeyPress,
      onFocus,
      onBlur,
      
      ...props
    } = this.props;
    
    delete props.lang;
    delete props.token;
    delete props.type;
    delete props.variant;
    delete props.defaultValue;
    delete props.determineAddress;
    delete props.ownerValue;
    delete props.selectedItem;
    delete props.onSelectItem;
    
    delete props.suggestionsId;
    delete props.suggestionsClassName;
    delete props.hintText;
    delete props.suggestionsHintId;
    delete props.suggestionsHintClassName;
    delete props.suggestionsListId;
    delete props.suggestionsListClassName;
    delete props.suggestionsItemId;
    delete props.suggestionsItemClassName;
    
    
    return (
      <TextField
        label={label}
        helperText={helperText}
        
        InputProps={{
          ...props,
          
          ref: component.ref(this.context.popperRef, forwardRef),
          value: this.state.query,
          onChange: component.eventProxy(onChange, (event) => this.setQuery(event.target.value)),
          onKeyDown: component.eventProxy(onKeyDown, this.onInputKeyDown),
          onKeyPress: component.eventProxy(onKeyPress, this.onInputKeyDown),
          onFocus: component.eventProxy(onFocus, this.onInputFocus),
          onBlur: component.eventProxy(onBlur, this.onInputBlur)
        }}
      />
    );
  }
  
  onInputKeyDown = (event) => {
    if (!this.context)
      return;
    
    if (event.which === 38) { // Up
      event.preventDefault();
      
      this.setState((state) => {
        state = { ...state };
        
        if (state.index < 0) {
          state.index = this.context.list.length - 1;
        } else if (state.index === 0) {
          state.index = -1;
        } else {
          state.index--;
        }
        
        state.query = state.index < 0
          ? state.inputQuery
          : this.buildValue(this.context.list[state.index]);
        
        return state;
      });
      
      this.showSuggestions();
    } else if (event.which === 40) { // Down
      event.preventDefault();
      
      this.setState((state) => {
        state = { ...state };
        
        if (state.index >= this.context.list.length - 1) {
          state.index = -1;
        } else {
          state.index++;
        }
        
        state.query = state.index < 0
          ? state.inputQuery
          : this.buildValue(this.context.list[state.index]);
        
        return state;
      });
      
      this.showSuggestions();
    } else if (event.which === 13) { // Enter
      if (this.context.isListVisible) {
        event.preventDefault();
        
        this.hideSuggestions();
        
        this.setState((state) => {
          if (state.index >= 0)
            state = this.selectSuggestion({ ...state }, { ...this.context.list[state.index], isConfirmed: true }, state.index);
          
          return state;
        });
      }
    } else if (event.which === 27) { // Escape
      if (this.context.isListVisible) {
        event.preventDefault();
        
        this.hideSuggestions();
      }
    }
  };
  
  onInputFocus = (event) => {
    if (!this.context)
      return;
    
    this.context.showSuggestions(this);
  };
  
  onInputBlur = (event) => {
    this.hideSuggestions();
  };
  
  onSelectItem = debounce(async (item) => {
    const { variant, onSelectItem } = this.props;
    
    
    await VARIANTS_DATA[variant]?.selectItem(this, item);
    
    if (typeof onSelectItem === 'function')
      onSelectItem(item);
  }, 100);
  
  onSuggestionMouseDown = (item, index) => (event) => {
    this.setState((state) => this.selectSuggestion({ ...state }, { ...item, isConfirmed: true }, index));
    
    this.hideSuggestions();
  };
  
  setQuery = (query) => {
    query = String(query || '');
    
    this.setState((state) => ({
      ...state,
      
      query,
      inputQuery: query,
      index: -1,
      item: null
    }));
    
    this.loadSuggestions();
  };
  
  selectSuggestion = (state, item, index) => {
    state.query = state.inputQuery = this.buildValue(item) || '';
    state.index = index;
    state.item = item;
    
    this.onSelectItem(item);
    
    return state;
  };
  
  showSuggestions = debounce(() => {
    if (this.context)
      this.context.showSuggestions(this);
  }, 100);
  
  hideSuggestions = debounce(() => {
    this.setState((state) => {
      state = { ...state };
      
      if (!state.item && this.context?.list.length)
        this.selectSuggestion(state, { ...this.context.list[Math.min(Math.max(state.index, 0), this.context.list.length - 1)], isConfirmed: true }, 0);
      
      return state;
    });
    
    if (this.context)
      this.context.hideSuggestions(this);
  }, 100);
  
  loadSuggestions = debounce(() => {
    const { variant, type, ownerValue, defaultValue } = this.props;
    
    
    const query = this.state.query;
    
    if (!query || query.length <= 2)
      return;
    
    const update = ++this.update;
    
    const variantsData = VARIANTS_DATA[variant];
    
    if (variantsData) {
      variantsData.loadSuggestions(this, query)
        .then((list) => update === this.update && this.setState((state) => {
          if (this.context)
            this.context.setList(list);
          
          state = {
            ...state,
            
            index: -1,
            item: null
          };
          
          if (state.isFirstLoad) {
            state.isFirstLoad = false;
            
            if (defaultValue && !defaultValue.data && list.length)
              this.selectSuggestion(state, list[0], 0);
          }
          
          return state;
        }))
        .catch((error) => console.error(error));
    }
  }, 200);
  
  determineAddress = debounce(() => {
    const { variant } = this.props;
    
    
    const variantsData = VARIANTS_DATA[variant];
    
    if (variantsData) {
      variantsData.determineAddress(this)
        .then((data) => data && this.setState((state) => this.selectSuggestion({ ...state }, data, -1)))
        .catch((error) => console.error(error));
    }
  }, 200);
  
  buildValue = (item) => {
    const { variant, type } = this.props;
    
    
    return AddressInput.buildValue(variant, type, item, true);
  };
  
});

AddressInput.Provider = ({ children }) => {
  const [currentEntity, setCurrentEntity] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [isListVisible, setListVisible] = React.useState(false);
  
  const updatePopper = React.useRef(null);
  
  const value = {
      popperRef: null,
      
      list,
      setList,
      isListVisible,
      
      showSuggestions: (entity) => {
        if (updatePopper.current)
          updatePopper.current();
        
        setCurrentEntity(entity);
        setListVisible(true);
      },
      
      hideSuggestions: (entity) => {
        if (currentEntity === entity)
          setListVisible(false);
      }
    };
  
  
  return (
    <AddressInputContext.Provider value={value}>
      <Popper fullWidth placement="bottom-start" popper={({ popperProps }) => (
        <div
          {...popperProps}
          
          id={currentEntity?.props.suggestionsId}
          className={component.className(currentEntity?.props.suggestionsClassName, isListVisible && list.length && 'visible')}
        >
          {currentEntity?.props.hintText ? (
            <div
              id={currentEntity?.props.suggestionsHintId}
              className={currentEntity?.props.suggestionsHintClassName}
            >
              {currentEntity?.props.hintText}
            </div>
          ) : null}
          
          <ul
            id={currentEntity?.props.suggestionsListId}
            className={currentEntity?.props.suggestionsListClassName}
          >
            {list.map((item, index) => (
              <li
                key={index}
                id={currentEntity?.props.suggestionsItemId}
                className={component.className(currentEntity?.props.suggestionsItemClassName, index === currentEntity?.state.index && 'active')}
                onMouseDown={currentEntity?.onSuggestionMouseDown(item, index)}
              >
                {currentEntity?.buildValue(item)}
              </li>
            ))}
          </ul>
        </div>
      )}>
        {({ ref, update }) => {
          value.popperRef = ref;
          
          updatePopper.current = update;
          
          return children;
        }}
      </Popper>
      
      {renderStyles()}
    </AddressInputContext.Provider>
  );
};

AddressInput.buildSimple = (variant, type, item) => VARIANTS_DATA[variant]?.buildCitySimple(type, item);

AddressInput.buildValue = (variant, type, item, reverse) => {
  const result = VARIANTS_DATA[variant]?.buildCityValue(type, item, reverse);
  
  if (!result)
    return null;
  
  return (reverse ? result.reverse() : result).join(', ');
};

AddressInput.store = (item) => storage.set(STORAGE_CITY_FIELD, AddressInput.buildValue('delivery', 'city', item));

AddressInput.locate = async (defaultValue) => await VARIANTS_DATA.delivery.locate(storage.get(STORAGE_CITY_FIELD), defaultValue);

export default AddressInput;
