// @material-ui
import { makeStyles } from '@material-ui/styles'

export const stepsStyle = theme => ({
  sliderLabel: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
  },
  labelActive: {
    cursor: 'default',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  labelDisabled: {
    cursor: 'default',
    color: theme.palette.text.disabled,
  },
  fullWidth: {
    width: '100%',
  },
  sliderContainer: {
    overflow: 'hidden',
    marginBottom: '20px',
  },
  slider: {
    padding: '22px 0px',
  },
  thumbIconWrapper: {
    backgroundColor: 'transparent',
    width: '19px',
    height: '19px',
  },
  thumb: {
    cursor: 'default',
    boxShadow: 'none !important',
    width: '19px',
    height: '19px',
    borderRadius: '100%',
    border: '5px solid ' + theme.palette.primary.main,
    background: 'white',
    marginTop: '-9px',
  },

  trackBefore: {
    background: theme.palette.primary.main,
    height: '3px',
  },
  trackAfter: {
    height: '1px',
  },
})

export const useStepsStyle = makeStyles(stepsStyle)
