export const props = {
  icons: {
    dashboard: 'show_chart',
    partner: 'accessibility',
    structure: 'category',
    binary: 'device_hub',
    linear: 'people_alt',
    order: 'shopping_basket',
    finance: 'attach_money',
    topup: 'save_alt',
    transmit: 'transfer_within_a_station',
    exchange: 'compare_arrows',
    withdraw: 'call_made',
    history: 'history',
    financelog: 'storage',
    withdrawlog: 'low_priority',
    purchaselog: 'shopping_basket',
    goods: 'shop',
    documents: 'attachment',
    statutory: 'assignment_turned_in',
    video: 'video_library',
    info: 'local_library',
    news: 'rate_review',
    events: 'event_note',
    support: 'record_voice_over',
    faq: 'live_help',
    tickets: 'question_answer',
  },
}
