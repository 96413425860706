export const props = {
  fieldsOrder: [
    'parent',
    'login',
    'mobile',
    'email',
    'passwd',
    'passwd2',
    'lastname',
    'firstname',
    'patronymic',
    'birth_date',
    'passp_serial',
    'passp_number',
  ],

  fieldsOptions: {
    // EXAMPLE:

    // fieldname: {
    //   required: true,
    //   serverCheck: true,
    //   min: 3,
    //   max: 50,
    //   length: 10,
    //   match: '[A-Za-z0-9_]',
    //   match: ['[A-Za-z0-9_]', 'igm'],
    //   ban: '[^A-Za-z0-9_]',
    //   ban: ['[^A-Za-z0-9_]', 'igm'],
    //   include: ['test', 'support'],
    //   exclude: ['www', 'web'],
    //   phone: true,
    //   email: true,
    //   password: true,
    //   repeat: 'passwd',
    //   minAge: 0,
    //   maxAge: 100,

    //   // Profile info form only
    //   verifiedReadOnly: true,

    //   viewProps: {
    //     readOnly: true,
    //     disabled: true,
    //     ...
    //   },

    //   gridProps: {
    //     xs: 12,
    //     md: 6,
    //     ...
    //   },
    // },

    parent: {
      required: true,
      serverCheck: true,
      min: 2,
      max: 50,
      ban: ['[^-_.@A-Za-z0-9]', 'g'],
    },

    login: {
      required: true,
      serverCheck: true,
      min: 3,
      max: 50,
      ban: ['[^-_.@A-Za-z0-9]', 'g'],
      exclude: ['www', 'web', 'backoffice'],
    },
    login2: {
      required: false,
      serverCheck: true,
      min: 3,
      max: 50,
      ban: ['[^-_.@A-Za-z0-9]', 'g'],
      exclude: ['www', 'web', 'backoffice'],
    },
    mobile: {
      required: false,
      phone: true,
    },

    email: {
      required: false,
      ban: ['\\s', 'g'],
      match: '^[^@\\s]+@([^@\\s\\.]+\\.)+[^@\\s\\.]+$',
      email: true,
    },

    passwd: {
      required: true,
      min: 3,
      max: 50,
      password: true,
      ban: ['\\s', 'g'],
    },

    passwd2: {
      required: true,
      repeat: 'passwd',
    },

    lastname: {
      required: false,
      max: 50,
      gridProps: { md: 4 },
    },

    firstname: {
      required: false,
      max: 50,
      gridProps: { md: 4 },
    },

    patronymic: {
      required: false,
      max: 50,
      gridProps: { md: 4 },
    },

    birth_date: {
      required: false,
      minAge: 0,
      maxAge: 100,
      gridProps: { md: 4 },
    },

    passp_serial: {
      required: false,
      gridProps: { md: 4 },
    },

    passp_number: {
      required: false,
      gridProps: { md: 4 },
    },
  },
}
