import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  userAvatar: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },

  cartActive: {
    color: theme.palette.text.primary,
  },

  cartBadge: {
    color: theme.palette.primary.contrastText,
  },

  userNotifierBadge: {
    fontSize: '9pt',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },

  userNotifierPaper: {
  },

  userNotifierListItemPrimary: {
    maxWidth: '570px'
  },
}))
